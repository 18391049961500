.server-error {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat;
  background-position: center;
  background-image: url('../../assets/images/bkg-texture.webp');

  &__container {
    width: 100%;
    height: 860px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @media (min-width: 1200px){
      max-width: 1220px;
    }

    &--text {
      padding: 20px 100px;
      text-align: center;

      h1 {
        font-size: 30px;
        color: #D69C4F;

        @media (min-width: 768px) {
          font-size: 40px;
        }
      }

      h2 {
        font-size: 20px;

        @media (min-width: 768px) {
          font-size: 28px;
        }
      }

      .redirect {
        font-size: 18px;
        font-family: "Quicksand", sans-serif;
        color: #D69C4F;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          text-decoration: underline;
        }

        @media (min-width: 768px) {
          font-size: 20px;
        }
      }
    }

  }
}