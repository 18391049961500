@import '../../atoms/text/styles.scss';

.card {
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  overflow: hidden;
  position: relative;

  &.explora {
    width: 100%;
    height: 100%;
    min-width: auto;
    min-height: auto;
    max-height: 220px;
    width: 200px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
    text-decoration-line: none;
    color: black;


    img {
      width: 100%;
      height: 120px;
      min-height: 120px;
      object-fit: cover;
    }
  }

  &.food {
    width: 270px;
    height: 313px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
    text-decoration-line: none;
    color: black;

    &:hover {
      @include scale;
    }

    img {
      width: 100%;
      height: 225px;
      object-fit: cover;
    }
  }

  &.relax {
    width: 270px;
    height: 313px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
    text-decoration-line: none;
    color: black;


    img {
      width: 100%;
      height: 225px;
      object-fit: cover;
    }
  }

  &.hotel {
    width: 270px;
    height: 313px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
    text-decoration-line: none;
    color: black;


    img {
      width: 100%;
      height: 225px;
      object-fit: cover;
    }
  }

  &.restaurantes {
    width: 100%;
    height: 100%;
    min-width: 290px;
    height: 440px;
    max-width: 330px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);

    .card-type-img {
      width: 100%;
      height: 200px !important;
      object-fit: cover;
    }
  }

  &.hoteles {
    min-width: 290px;
    max-width: 318px;
    min-height: 328px;
    max-height: 360px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);


    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  .card-type {
    position: absolute;
    top: 20px;
    right: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }


  .carddiv {
    position: relative;
    display: inline-block;
  }

  .card-typerest {
    position: absolute;
    top: 25px;
    right: 0px;


    color: #fff;
    font-family: NexaSlab;
    font-weight: 700;
    font-size: 1.125rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 42px;
    background-color: #FF7240;
    clip-path: polygon(100% 0%, 7.15% 0%, 0% 100%, 100% 100%);

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}

.cardfood {
  display: grid;
  grid-template-columns: 1.3fr 0.6fr;
  align-items: baseline;
  padding: 16px 14px;
  gap: 10px;

  &__title {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &--title {
      @include parragraph-normal(NexaSlab, 16px, 600, normal);
    }

    &--subtitle {
      @include parragraph-normal(Quicksand, 12px, 400, normal);
      margin-left: 14px;

      ul {
        padding: 0;

        ::marker {
          font-size: 12px;
        }
      }
    }
  }

  &__text {
    color: #D69C4F;

    &--paragraphup {
      @include parragraph-normal(Quicksand, 12px, 400, normal);

    }

    &--paragraphvalor {
      @include parragraph-normal(NexaSlab, 25px, 600, normal);

    }

    &--paragraphdown {
      @include parragraph-normal(Quicksand, 12px, 400, normal);

    }
  }

  &--final {
    padding: 20px;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 9px;

    p {
      text-align: center;
    }

    .btn {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: #D69C4F;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.cardhotel {
  display: grid;
  grid-template-columns: 1.1fr 0.9fr;
  align-items: baseline;
  padding: 10px 14px;
  gap: 10px;

  &__title {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__text {
    color: black;
    text-align: right;
  }

  &--final {
    padding: 20px;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 9px;

    p {
      text-align: center;
    }

    .btn {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: #D69C4F;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;


    }
  }
}

.cardheadere {
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: white;
  padding: 15px 20px;

  .paragraph {
    @include parragraph-normal(NexaSlab, 1.125rem, 700, normal);
    text-transform: uppercase;
  }

  .icon {
    @include parragraph-normal(Quicksand, 1.125rem, 300, normal);
    color: black;
  }
}

.card-content {
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;

  .cardheaderr {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    p.ttrestaurant {
      width: 54%;
    }

    p.sbrestaurant {
      width: 43%;
    }

    .ttrestaurant {
      color: #E9082A;
      font-family: NexaSlab;
      font-weight: bold;
      font-size: .875rem;
      margin: 0;
      height: 34px;

      @media (min-width: 768px) {
        font-size: 1rem;
      }
    }
  }

  .sbrestaurant {
    font-family: Sofia;
    font-weight: 100;
    font-size: 13px !important;
    margin: 0;

    @media (min-width: 768px) {
      font-size: .875rem;
    }
  }

  .desrestaurant {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .morerestaurant {
    font-family: Sofia;
    font-weight: 100;
    font-size: .875rem;
    margin: 0;
  }

}

.card-content {
  color: black;
  padding: 23px 20px;

  .tthotel {
    font-family: NexaSlab;
    font-weight: 700;
    font-size: .875rem;
    color: black;
    margin: 0;
  }

  .cardheaderh {
    display: flex;

    p.tthotel {
      width: 60%;
    }

    p.sbhoteles {
      width: 40%;
      font-size: 1rem;
      font-family: Sofia;
      margin: 0;
      text-align: end;
    }
  }



  .cardprices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .ttcardprices {
      font-family: Sofia;
      font-size: 12px;
      margin: 0;

      span {
        text-decoration: line-through;
      }
    }

    .sbcardprices {
      font-family: NexaSlab;
      font-weight: 700;
      font-size: 32px;
      margin: 0;
    }

    .sbbcardprices {
      font-family: NexaSlab;
      font-size: 12px;
      margin: 0;

      span {
        font-family: Sofia;
        font-weight: 100;
      }
    }

    .pricebefore {
      font-family: Sofia;
      font-weight: 100;
      font-size: 12px;
      margin: 0;
    }

    .pricenow {
      font-family: Sofia;
      font-weight: 100;
      font-size: 1rem;
      margin: 0;
    }

    .morehotel {
      font-family: Sofia !important;
      font-size: 12px !important;
      margin: 0;
    }

    .pricehotel {
      text-decoration: dashed;
      color: red;
    }
  }
}

.cardvisit {
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  text-decoration-line: none;
  color: black;
  user-select: none;
  @include touchmobile;
  @include scale;

  &.visits {
    width: 240px;
    height: 210px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);

    .visit-img {
      min-height: 138px;
      height: 138px;

      span {
        display: inline !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // transition: scale 400ms;
      }

      // &:hover img {
      //   scale: 110%
      // }
    }

  }

  .content {
    background-color: white;
    width: 100%;
    height: 100%;

    &__description {
      display: flex;
      gap: 8px;
      flex-direction: row;
      align-items: center;
      padding: 16px 14px 10px;

      &--number {
        @include parragraph-normal(NexaSlab, 40px, 600, normal);
        line-height: 38px
      }

      &--title {
        @include parragraph-normal(NexaSlab, 18px, 700, normal);
        line-height: 16px
      }

      &--subtitle {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        text-align: left;
        padding-inline: 0;
      }
    }
  }
}

.promoselect {
  cursor: pointer;
  width: 290px;
  height: 287px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__promo {
    height: 100%;
    border-radius: 9px;

    &--inner {
      width: 153px;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      padding: 6px 10px;
      gap: 6px;

      &--description {
        color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        .title {
          @include parragraph-normal(Quicksand, 16px, 400, normal);
          text-align: center;
          text-transform: uppercase;
          height: 20px;
          overflow: hidden
        }

        .subtitlename {
          @include parragraph-normal(NexaSlab, 18px, 600, normal);
          text-align: center;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
        }

        .textlife {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          text-align: center;
          height: 40px;
          overflow: hidden;
        }

        .textlife2 {
          @include parragraph-normal(NexaSlab, 14px, 400, normal);
          line-height: normal;
        }

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          height: 56px;
          overflow: hidden;

          .discount {
            @include parragraph-normal(NexaSlab, 60px, 700, normal);
            line-height: 56px;
          }

          &--right {
            display: flex;
            flex-direction: column;

            .percentage {
              @include parragraph-normal(NexaSlab, 40px, 400, normal);
            }

            .dscto {
              @include parragraph-normal(NexaSlab, 10px, 300, normal);
              text-transform: capitalize;
            }
          }
        }
      }

      &--container {
        border-radius: 3px;

        .img-promo {
          padding-inline: 6px;
          // img {
          //   width: 100%;
          //   max-width: 100px;
          //   max-height: 45px !important;
          //   object-fit: contain;
          // }
        }
      }

    }

    &--final {
      padding: 20px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 9px;
      gap: 26px;
      @include scale;

      p {
        color: white;
        text-align: center;
        padding-inline: 20px;
        @include parragraph-normal(NexaSlab, 16px, 400, normal);
        line-height: normal;
      }

      .btn {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background: #D69C4F;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }
  }
}

.promocalendar {
  width: 100%;
  height: 220px;
  text-decoration: none;

  &__promo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--inner {
      box-sizing: border-box;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      width: 260px;
      gap: 6px;
      cursor: pointer;
      padding: 20px;
      border: 1px solid #D3D3D3;

      &:hover {
        background-color: #F0F0F0;
      }

      .colum {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      .badge {
        @include parragraph-normal(Quicksand, 12px, 400, normal);
        color: white;
        text-align: center;
        padding-block: 5px;
        width: 100%;
      }

      .text {
        @include parragraph-normal(Quicksand, 12px, 400, normal);
      }

      .price {
        @include parragraph-normal(NexaSlab, 25px, 700, normal);
        text-transform: uppercase;
        text-align: center;
      }

      .price2 {
        @include parragraph-normal(NexaSlab, 20px, 700, normal);
        text-transform: uppercase;
        text-align: right;
      }

      .igv-tooltip {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .igv {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
        }
      }

      .terminos {
        @include parragraph-normal(Quicksand, 10px, 400, normal);
        text-align: center;
      }
    }
  }
}

.promocalendar2 {
  text-decoration: none;
  width: 100%;
  height: 220px;

  &__promo {
    // padding-block: 15px;
    // padding-inline: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--inner {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 260px;
      cursor: pointer;
      // padding: 20px 30px;
      border: 1px solid #D3D3D3;
      border-right: 0px solid #D3D3D3;
      text-align: center;

      &:hover {
        background-color: #d69b4f18;
      }

      .badge {
        @include parragraph-normal(Quicksand, 20px, 400, normal);
        // text-transform: uppercase;
        text-align: center;
        padding: 6px 0px;
        width: 100%;
      }

      .text {
        @include parragraph-normal(Quicksand, 12px, 400, normal);
      }

      .igv-tooltip {
        display: flex;
        justify-content: center;
        align-items: center;

        .igv {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
        }
      }
    }

    &--night {
      .prices {
        margin-inline: 20px;
      }

      .price {
        @include parragraph-normal(NexaSlab, 25px, 700, normal);
        text-transform: uppercase;
        text-align: center;
        padding-block: 4px;
      }
    }

    &--night2 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 62px;

      .no-disponible {
        width: 50%;
        @include parragraph-normal(Quicksand, 14px, 300, normal);
        text-align: center;
      }
    }
  }
}

.borde-verde {
  border-top: 6px solid #04C847;
}

.borde-rojo {
  border-top: 6px solid #FF0000;
}

.borde-blanco {
  border-top: 6px solid #FFFFFF;

  &:hover {
    border-top: 6px solid #F0F0F0
  }
}

.promocalendar3 {
  text-decoration: none;
  width: 100%;
  height: 220px;

  &__promo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--inner {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 260px;
      cursor: pointer;
      // padding: 20px 30px;
      border: 1px solid #D3D3D3;
      border-right: 0px solid #D3D3D3;

      &:hover {
        background-color: #F0F0F0;
      }

      .badge {
        @include parragraph-normal(Quicksand, 20px, 400, normal);
        // text-transform: uppercase;
        text-align: center;
        padding: 6px 0px;
        width: 100%;
      }

      .text {
        @include parragraph-normal(Quicksand, 12px, 400, normal);
      }

      .igv-tooltip {
        display: flex;
        justify-content: center;
        align-items: center;

        .igv {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
        }
      }
    }

    &--night {
      .prices {
        margin-inline: 20px;
      }

      .price {
        @include parragraph-normal(NexaSlab, 25px, 700, normal);
        text-transform: uppercase;
        text-align: center;
        padding-block: 4px;
      }
    }

    &--night2 {
      display: flex;
      justify-content: center;
      align-items: center;

      .no-disponible {
        width: 50%;
        @include parragraph-normal(Quicksand, 14px, 300, normal);
        text-align: center;
      }
    }
  }
}

.promoslogros {
  width: 290px;
  height: 287px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  background-color: #434343;

  &__promo {
    width: 100%;
    height: 100%;
    border-radius: 9px;

    &--inner {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
      padding: 14px 26px;
      color: white;

      .title {
        @include parragraph-normal(Quicksand, 18px, 400, normal);
      }

      .parragraph {
        @include parragraph-normal(Quicksand, 18px, 300, normal);
      }

    }
  }
}

.promosequipos {
  width: 290px;
  height: 320px;
  max-height: 320px;
  overflow: hidden;

  &__promo {
    width: 100%;
    height: 100%;
    border-radius: 9px;

    &--inner {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 6px;
      color: black;

      img {
        border-radius: 12px
      }

      .title {
        @include parragraph-normal(Quicksand, 20px, 400, normal);
        padding-top: 16px
      }

      .parragraph {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
      }

      .terminos {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        @include parragraph-normal(Quicksand, 12px, 400, normal);
      }

    }
  }
}

.promosreconocimiento {
  border-radius: 50%;

  &__promo {
    width: 150px;
    height: 150px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.promosrelax {
  width: 343px;
  height: 251px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
  border-radius: 9px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__promo {
    height: 100%;
    // border-radius: 9px;

    &--inner {
      width: 156px;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      // gap: 10px;
      border-radius: 0px 8px 9px 0px;
      background: rgba(255, 255, 255, 0.90);
      // background: linear-gradient(270deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.60) 99.99%, rgba(0, 0, 0, 0.00) 100%);
      padding: 6px 16px;

      &--description {
        color: black;
        text-align: left;
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
          @include parragraph-normal(NexaSlab, 16px, 700, normal);
          line-height: normal;
        }

        .content {
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          gap: 6px;

          .description,
          .condition {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
          }

          .price {
            @include parragraph-normal(NexaSlab, 18px, 700, normal);
          }
        }
      }
    }

    &--final {
      padding: 20px;
      height: 211px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 9px;

      p {
        color: white;
        text-align: center;
      }

      .btn {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background: #D69C4F;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

    .txhome {
      color: #008FAD;
      font-family: Dancing Script;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .txpareja {
      @include parragraph-normal(Dancing Script, 30px, 400, normal);
      color: #FF00C7;
      line-height: 28px;
      text-transform: lowercase;
    }

    .txenamorate {
      color: #F00;
      font-family: Dancing Script;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}