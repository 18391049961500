@import '../../atoms/text/styles.scss';

.contacto-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;

  @media (min-width: 1200px) {
    max-width: 1200px;
  }

  .titulo-contacto {
    margin-bottom: 10px;
  }

  .hotels-not-found-message {
    margin-top: 80px;
    @include parragraph-normal(Quicksand, 18px, 500, normal);
    text-align: center;
  }

  .search-contact-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &--input {
      width: 250px;
      height: 45px;
      border: 1px solid #6B6B6B;
      border-radius: 7px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
      outline: none;
      padding: 10px;
      box-sizing: border-box;
      transition: 0.3s;
      background: white;
      @include parragraph-normal(Quicksand, 16px, 500, normal);
      color: #6B6B6B;

      &::placeholder {
        color: #6B6B6B;
      }

      &:focus {
        border: 2px solid var(--color-gold);
      }
    }

    &--button {
      width: 100px;
      height: 45px;
      border: none;
      border-radius: 7px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      outline: none;
      padding: 10px;
      box-sizing: border-box;
      transition: 0.3s;
      background: var(--color-gold);
      color: white;
      cursor: pointer;
      @include parragraph-normal(Quicksand, 16px, 700, normal);

      &:disabled {
        background: var(--color-gold);
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .card-contacto {
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 40px 50px;
    border-radius: 10px;
    margin: 10px 20px;
    min-height: 160px;

    @media (max-width: 1200px) {
      padding: 40px 20px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 20px 20px;
    }

    &--columna-izquierda {
      width: 750px;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding-right: 20px;
      border-right: 2px solid #D9D9D9;
      justify-content: center;
      align-items: center;

      @media (max-width: 1200px) {
        width: 250px;
      }

      @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        padding-bottom: 20px;
        border-right: none;
        border-bottom: 2px solid #d9d9d9;
      }

      .icon-paragraph {
        display: block;
        margin-bottom: 10px;
      }

      h2 {
        @include parragraph-normal(NexaSlab, 20px, 600, normal);
        width: 300px;

        @media (max-width: 1200px) {
          width: 220px;
        }
      }
    }

    &--columna-derecha {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 80px;
      justify-content: center;

      @media (max-width: 1200px) {
        padding-left: 40px;
      }

      @media (max-width: 768px) {
        padding-left: 0;
        padding-top: 20px;
        // align-items: center;
      }

      &--icono-texto {
        display: flex;
        flex-direction: row;
        margin: 5px 0;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        a {
          @include parragraph-normal(NexaSlab, 14px, 400, normal);
          cursor: pointer;
          text-decoration: none;
          color: black;

          &:hover {
            text-decoration: underline;
          }
        }

        .border-left {
          border-left: 1.5px solid var(--color-gold);
          margin-left: 15px;
          padding-left: 15px;
        }
      }
    }
  }
}