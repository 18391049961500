.soon-discounts-for-you {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  background-repeat: repeat;
  background-position: center;
  background-image: url('../../../../assets/images/bkg-texture.webp');

  @media (max-width: 768px) {
	padding-top: 60px;
	align-items: baseline;
	height: 200px;
  }
}

h1 {
	@media (max-width: 768px) {
	  font-size: 20px;
	}
}
