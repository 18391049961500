@import '../../../components/atoms/text/styles.scss';


.agenciespage {

  &__reservation {}

  &__cards {

    &--grid {

      .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 60px 46px;
        margin-top: 20px;

        &__card {
          flex-grow: initial;
          flex-basis: 335px;
          width: 335px;
          height: 427px;
          border-radius: 9px;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.26);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          overflow: hidden;
          gap: 16px;
          @include scale;

          @media (min-width: 768px) {
            gap: 10px;
          }

          &--img {
            width: 200px;
            height: 227px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .paragraph {
            @include parragraph-normal(Quicksand, 22px, 700, normal);
            color: white;
            line-height: normal;
            text-align: center;
          }
        }

        &__cardend {
          width: 100%;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 25px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.703) 48.54%, rgba(0, 0, 0, 0.418) 71.13%, rgba(0, 0, 0, 0) 100%);
        }
      }
    }
  }
}

.inputsbg {
  width: 100%;
  max-width: 600px;
}

.inputreserva {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding-top: 46px;
  }

  button {
    width: 272px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #C5C5C5;
    border-radius: 7px;
    font-family: NexaSlab;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    transition: 0.3s;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #dedcd7ab;
      color: #D69C4F;
    }
  }


  &__col {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;

    div {
      width: 100%;
    }

    @media (min-width: 768px) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      gap: 10px;

    }

    &--col {
      width: 100%;

      input {
        width: 272px;
        height: 60px;
        border: 1px solid #6B6B6B;
        font-family: Sofia;
        border-radius: 7px;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        transition: 0.3s;
        background: white;
        font-weight: 100;
        font-size: 0.875rem;

        &::placeholder {
          color: #6B6B6B;
        }
      }
    }
  }
}

.modal__code {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;

  .paragraph {
    @include parragraph-normal(NexaSlab, 20px, 700, normal);
    line-height: normal;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 25px;
    }
  }

  .forminput {
    width: 348px;
  }
}

.forminput{
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (min-width: 768px) {
    justify-content: center;
    width: 320px;

    p {
      text-align: center;
    }

    span{
      text-align: left;
    }
  }

  select {
    width: 100%;
    height: 45px;
    border: 1px solid #6B6B6B;
    border-radius: 7px;
    outline: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../assets/icons/arrow_down.png');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
    padding: 10px;
    @include parragraph-normal(Quicksand, 16px, 500, normal);
    color: #6B6B6B;

    &:focus {
      border: 2px solid var(--color-gold);
    }
  }

  span {
    @include parragraph-normal(Quicksand, 14px, 500, normal);
    color: #ff0000;
  }

  input {
    width: 100%;
    height: 45px;
    border: 1px solid #6B6B6B;
    border-radius: 7px;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.3s;
    background: white;
    @include parragraph-normal(Quicksand, 16px, 500, normal);
    color: #6B6B6B;

    &::placeholder {
      color: #6B6B6B;
    }

    &:focus {
      border: 2px solid var(--color-gold);
    }
  }

  .btnagencies {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 7px;
    margin: 24px 0;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.3s;
    background: var(--color-gold);
    color: white;
    cursor: pointer;
    @include parragraph-normal(Quicksand, 16px, 600, normal);
    
    &:disabled {
      background: var(--color-gold);
      opacity: 0.5;
      cursor: not-allowed;
    }

  }

  .description {
    margin-top: 16px;
    cursor: pointer;

    p {
      @include parragraph-normal(NexaSlab, 16px, 400, normal);
      line-height: normal;
      text-align: left;
    }

    span {
      font-size: 16px;
      font-family: NexaSlab;
      font-weight: 400;
      color: var(--color-gold);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}