@import '../../atoms/text/styles.scss';

.card__promotionscard {
  border-radius: 9px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #E5E5E5;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  width: 300px;

  @media (min-width: 768px) {
    width: 328px;
  }

  &--slider {
    width: 100%;
    height: 216px;
    border-radius: inherit 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cardheaderr {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      font-family: NexaSlab;
      text-align: left;
      font-weight: bold;
      font-size: .875rem;

      width: 160px;
      margin: 0;

      @media (max-width: 375px) {
        width: 141px;
      }
    }

    .review {
      font-family: Sofia;
      font-weight: 100;
      font-size: .875rem;
      margin: 0;

    }
  }

  &--container {
    padding-top: 24px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

    @media (min-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    .tagposition {
      position: absolute;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      left: 5%;
      top: 0%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .cardprices {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &__card {
        display: flex;
        align-items: center;
        gap: 1px;
      }

      .ttcardprices {
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: 12px;
        font-family: Sofia;

        span {
          font-family: NexaSlab;
          text-decoration: line-through;
        }

        &__card {
          width: 278px;
        }
      }

      .carddescuento {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .ttdescuento {
        @include parragraph-normal(Quicksand, 12px, 100, normal);
        text-align: center;
        color: white;
        width: 57px;
        height: 23px;
        // border-radius: 3px;
      }

      .sbcardprices {
        font-family: NexaSlab;
        font-weight: bold;
        text-transform: uppercase;
      }

      .sbbcardprices {
        font-family: Sofia;
        font-size: 12px;
        margin: 0;

        &__text {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
        }
      }
    }

    .estancia {
      display: flex;
      justify-content: space-between;
      font-family: Sofia;
      font-weight: 100;
      font-size: 12px;
      text-align: left;

    }
  }
}

.promotionscard {
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--concept {

      @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }

      .subtitle {
        @include title-section(NexaSlab, 700, normal);
        margin-bottom: 20px;
        flex-grow: 1;

        @media(min-width: 768px) {
          margin-bottom: 30px;
        }
      }

      .filtro {
        display: flex;
        justify-content: flex-end;
        padding-inline: 16px;
        padding-bottom: 32px;

        @media (min-width: 1200px) {
          padding-inline: 0px;
        }

        @media (max-width: 678px) {
          padding-inline: 0px;
          flex-direction: column;
          gap: 10px;
          align-items: center;
          padding: 0 20px;
          padding-bottom: 20px;
        }

        .dropdown {
          width: 240px;

          @media (max-width: 678px) {
            width: 100%;
          }
        }

        .destinos_dropdown {
          width: 240px;

          @media (min-width: 678px) {
            margin-right: 20px;
          }

          @media (max-width: 678px) {
            width: 100%;
          }
        }
      }
    }
  }

  &__description {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 60px;
    justify-items: center;

    @media (min-width: 678px) {
      grid-template-columns: repeat(2, 2fr);
      grid-gap: 48px 0px;
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .skeletonbox {
    position: relative;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__container {
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background-color: #f3f2f2;
      animation: moveSkeleton2 1.5s infinite linear;
    }
  }

  @keyframes moveSkeleton2 {
    0% {
      left: -100%;
    }

    100% {
      left: 100%;
    }
  }

  .skeleton {
    position: relative;
    border-radius: 8px;
    width: 100%;
    height: 24px;
    overflow: hidden;

    &__container {
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background-color: #f3f2f2;
      animation: moveSkeleton 1.5s infinite linear;
    }
  }

  @keyframes moveSkeleton {
    0% {
      left: -100%;
    }

    100% {
      left: 100%;
    }
  }

}

.card__campaign {
  border-radius: 9px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #E5E5E5;
  position: relative;
  overflow: hidden;
  margin: 10px 12px;
  width: 290px;
  cursor: pointer;

  @media (min-width: 375px) {
    width: 350px;
  }

  &--slider {
    width: 100%;
    height: 216px;
    border-radius: inherit 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: none;
    }
  }

  .cardheaderr {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      font-family: NexaSlab;
      text-align: left;
      font-weight: bold;
      font-size: .875rem;

      width: 166px;
      margin: 0;

      @media (max-width: 375px) {
        width: 141px;
      }
    }

    .review {
      font-family: Sofia;
      font-weight: 100;
      font-size: .875rem;
      margin: 0;

    }
  }

  &--container {
    padding: 22px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;

    @media (min-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    .tagposition {
      position: absolute;
      // background-color: red;
      border-radius: 4px;
      left: 5%;
      top: 0%;
      transform: translateY(-50%);
      z-index: 100;

      p {
        font-family: Sofia;
        font-size: .875rem;
        margin: 0;
        padding: 6px 18px;
        color: white;
      }
    }

    .cardprices {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &__card {
        display: flex;
        align-items: flex-start;
        gap: 6px;
      }

      .ttcardprices {
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: 12px;
        font-family: Sofia;
        font-weight: 100;

        span {
          font-family: NexaSlab;
          text-decoration: line-through;
        }

        &__card {
          width: 278px;
        }
      }

      .carddescuento {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
      }

      .ttdescuento {
        margin: 0;
        font-family: 'Sofia';
        font-size: .875rem;
        font-weight: 100;
        text-align: center;
        color: white;
        // border-radius: 4px;
        width: 54px;
        height: 22px;
      }

      .sbcardprices {
        font-family: NexaSlab;
        font-weight: bold;
        font-size: 1.625rem;
        text-transform: uppercase;
        margin: 0;
      }

      .sbbcardprices {
        display: flex;
        width: 100%;
        gap: 6px;
        justify-content: space-between;
        @include parragraph-normal(Quicksand, 12px, 400, normal);
        margin: 0;

        &__text {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 0;
        }
      }
    }

    .estancia {
      display: flex;
      justify-content: space-between;
      @include parragraph-normal(Quicksand, 12px, 400, normal);
      text-align: left;

    }

    .bg {
      background-color: #FFC700;
      display: flex;
      padding-left: 4px;
      padding-right: 4px;
      justify-content: center;
      align-items: center;

      @media (max-width: 375px) {
        width: 158px;
      }
    }

    .estilos {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-family: Sofia;
        font-weight: 100;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }

      &__txt {
        color: #14C67A;
      }
    }
  }
}

.card__banks {
  border-radius: 9px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #E5E5E5;
  position: relative;
  overflow: hidden;
  margin: 10px 12px;
  width: 290px;
  cursor: pointer;

  @media (min-width: 375px) {
    width: 350px;
  }

  &--slider {
    width: 100%;
    height: 216px;
    border-radius: inherit 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: none;
    }
  }

  .cardheaderr {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      font-family: NexaSlab;
      text-align: left;
      font-weight: bold;
      font-size: .875rem;

      width: 166px;
      margin: 0;

      @media (max-width: 375px) {
        width: 141px;
      }
    }

    .review {
      font-family: Sofia;
      font-weight: 100;
      font-size: .875rem;
      margin: 0;

    }
  }

  &--container {
    padding-top: 24px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 160px;

    @media (min-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    .tagposition {
      position: absolute;
      // background-color: red;
      border-radius: 4px;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      left: 5%;
      top: 0%;
      transform: translateY(-50%);
      z-index: 100;

      p {
        font-family: Sofia;
        font-size: .875rem;
        margin: 0;
        padding: 6px 18px;
        color: white;
      }
    }

    .cardprices {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &__card {
        display: flex;
        align-items: center;
      }

      .ttcardprices {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-bottom: 12px;
        @include parragraph-normal(Quicksand, 12px, 400, normal);

        span {
          font-family: NexaSlab;
          text-decoration: line-through;
        }

        &__card {
          width: 278px;
        }
      }

      .carddescuento {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
      }

      .ttdescuento {
        margin: 0;
        font-family: 'Sofia';
        font-size: .875rem;
        font-weight: 100;
        text-align: center;
        color: white;
        // border-radius: 4px;
        // width: 44px;
        // height: 23px;
        width: 54px;
        height: 22px;
      }

      .sbcardprices {
        font-family: NexaSlab;
        font-weight: bold;
        font-size: 1.625rem;
        text-transform: uppercase;
        margin: 0;
      }

      .estancia {
        display: flex;
        justify-content: space-between;
        font-family: Sofia;
        font-weight: 100;
        font-size: 12px;
        text-align: left;
        width: 100%;

        .sbbcardprices {
          font-family: Sofia;
          font-weight: 100;
          font-size: 12px;
          margin: 0;

          &__text {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
        }
      }
    }



    .estilos {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-family: Sofia;
        font-weight: 100;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }

      &__txt {
        color: #14C67A;
      }
    }
  }
}

.card__restaurantplace {
  border-radius: 9px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #E5E5E5;
  position: relative;
  overflow: hidden;
  margin: 10px 12px;
  width: 290px;

  @media (min-width: 375px) {
    width: 350px;
  }

  &--slider {
    width: 100%;
    height: 200px;
    object-fit: cover;

    .card-type {
      position: absolute;
      top: 20px;
      right: 20px;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 60px;
      height: 60px;

      img {
        width: 45px;
        height: 45px;
        object-fit: contain;
      }
    }

    .card-type-img {
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }
  }

  .cardheaderr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .title {
      font-family: NexaSlab;
      text-align: left;
      font-weight: 700;
      font-size: 1rem;
      margin: 0;
    }

    .review {
      @include parragraph-normal(Quicksand, 14px, 400, normal);
    }
  }

  &--container {
    padding-top: 12px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 200px;

    @media (min-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    .cardprices {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .place {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
      }

      &__card {
        display: flex;
        flex-direction: column;
        width: 100%;
        // align-items: flex-end;
        gap: 10px;

        .txrestaurant {
          @include parragraph-normal(Quicksand, 18px, 300, normal);
        }

        .btnrestaurant {
          @include parragraph-normal(Quicksand, 18px, 300, normal);
          line-height: normal;
          color: var(--color-gold);
        }
      }

      .redireccion-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      .ttcardprices {
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: 12px;
        font-family: Sofia;
        font-weight: 100;

        span {
          font-family: NexaSlab;
          text-decoration: line-through;
        }

        &__card {
          width: 278px;
        }
      }

      .carddescuento {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
      }

      .ttdescuento {
        margin: 0;
        font-family: 'Sofia';
        font-size: .875rem;
        font-weight: 100;
        text-align: center;
        color: white;
        border-radius: 4px;
        width: 44px;
        height: 23px;
      }

      .sbcardprices {
        font-family: NexaSlab;
        font-weight: bold;
        font-size: 1.625rem;
        text-transform: uppercase;
        margin: 0;
      }
    }
  }
}

.card__restaurantcategory {
  border-radius: 9px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #E5E5E5;
  position: relative;
  overflow: hidden;
  margin: 10px 12px;
  width: 290px;
  // height: 100%;

  @media (min-width: 375px) {
    width: 360px;
  }

  &--slider {
    width: 100%;
    height: 220px;
    object-fit: cover;

    .card-type {
      position: absolute;
      top: 20px;
      right: 20px;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 60px;
      height: 60px;

      img {
        width: 45px;
        height: 45px;
        object-fit: contain;
      }
    }

    .card-type-img {
      width: 100%;
      height: 220px;
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .cardheaderr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .title {
      font-family: NexaSlab;
      text-align: left;
      font-weight: 700;
      font-size: 1rem;
      margin: 0;
    }

    .review {
      font-family: Sofia;
      font-weight: 100;
      font-size: .875rem;
      margin: 0;

    }
  }

  &--container {
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 356px;

    @media (min-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    .cardprices {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      &__card {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        line-height: normal;

        .titlecardicon,
        .termino p {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
        }
      }

      &__card2 {
        display: flex;
        flex-direction: column;
        align-items: self-end;
        width: 100%;

        .ttcardprices {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
        }

        .sbbcardprices {
          &__text {
            display: flex;
            justify-content: center;
            align-items: center;
            @include parragraph-normal(Quicksand, 12px, 400, normal);
          }
        }

      }

      .txtlink {
        margin: 0;
        cursor: pointer;
        color: var(--color-gold);
      }

      .redireccion-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      .ttcardprices {
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: 12px;
        font-family: Sofia;
        font-weight: 100;

        span {
          font-family: NexaSlab;
          text-decoration: line-through;
        }

        &__card {
          width: 278px;
        }
      }

      .carddescuento {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
      }

      .ttdescuento {
        margin: 0;
        font-family: 'Sofia';
        font-size: .875rem;
        font-weight: 100;
        text-align: center;
        color: white;
        border-radius: 4px;
        width: 44px;
        height: 23px;
      }

      .sbcardprices {
        font-family: NexaSlab;
        font-weight: bold;
        font-size: 1.625rem;
        text-transform: uppercase;
        margin: 0;
      }
    }


    .cardcategory {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 10px;
      height: 100%;

      &__body {
        max-height: 190px;
        overflow: hidden;

        &--title {
          @include parragraph-normal(NexaSlab, 16px, 600, normal);
          line-height: normal;
        }

        &--description {
          display: flex;
          flex-direction: column;
          gap: 14px;

          .title {
            @include parragraph-normal(NexaSlab, 16px, 400, normal);
            margin-bottom: 10px
          }

          .description {
            @include parragraph-normal(Quicksand, 14px, 400, normal);
            line-height: normal
          }

          .subtitle {
            @include parragraph-normal(Quicksand, 14px, 400, normal);
            margin-left: 16px;
            line-height: normal
          }

          .terms {
            @include parragraph-normal(Quicksand, 14px, 400, normal);
            margin-top: 10px;

            span {
              cursor: pointer;
              color: var(--color-gold);
              font-weight: 600;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        &--price {
          display: flex;
          flex-direction: column;
          align-items: self-end;
          gap: 4px;
          width: 100%;

          .adult {
            @include parragraph-normal(Quicksand, 13px, 400, normal);
          }

          .price {
            @include parragraph-normal(NexaSlab, 25px, 600, normal);
          }

          .discount {
            display: flex;
            justify-content: center;
            align-items: center;
            @include parragraph-normal(Quicksand, 13px, 400, normal);
          }
        }
      }

      &__button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding-top: 16px;

        .text {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          color: black;
          cursor: pointer;
        }
      }
    }
  }
}

.card__hotelesprices {
  border-radius: 9px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #E5E5E5;
  position: relative;
  overflow: hidden;
  width: 290px;
  cursor: pointer;
  @include scale;

  @media (min-width: 375px) {
    width: 350px;
  }

  &--slider {
    width: 100%;
    height: 216px;
    border-radius: inherit 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: none;
    }
  }

  .cardheaderr {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      font-family: NexaSlab;
      text-align: left;
      font-weight: bold;
      font-size: .875rem;

      width: 166px;
      margin: 0;

      @media (max-width: 375px) {
        width: 141px;
      }
    }

    .review {
      font-family: Sofia;
      font-weight: 100;
      font-size: .875rem;
      margin: 0;

    }
  }

  &--container {
    padding-top: 24px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 160px;

    @media (min-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    .cardprices {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .ttcardprices {
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: 12px;
        font-family: Quicksand;

        span {
          font-family: NexaSlab;
          text-decoration: line-through;
          color: #7E7E7E;
        }

        &__card {
          width: 278px;
        }

      }

      .sbcardprices {
        font-family: NexaSlab;
        font-weight: 700;
        font-size: 30px;
        text-transform: uppercase;
      }

      .sbbcardprices {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: Sofia;
        font-weight: 100;
        font-size: 12px;
        gap: 4px;
      }
    }

    .estancia {
      display: flex;
      justify-content: space-between;
      font-family: Sofia;
      font-weight: 100;
      font-size: 12px;
      text-align: left;

      .bg {
        background-color: #FFC700;
        display: flex;
        padding-left: 4px;
        padding-right: 4px;
        justify-content: center;
        align-items: center;

        @media (max-width: 375px) {
          width: 158px;
        }

        p {
          margin: 0;
          padding: 0;
        }
      }
    }

  }
}

.tagbank {
  position: absolute;
  // background-color: red;
  border-radius: 4px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  left: 5%;
  top: 0%;
  transform: translateY(-50%);
  z-index: 1;

  img {
    width: 100%;
    height: 20px;
    object-fit: contain;
  }
}

.bankcard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__description {
    display: flex;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 340px; // Medida de la card
      margin-right: 28px; // Espacio entre cards

      @media (min-width: 768px) {
        width: 360px; // Medida de la card
        margin-right: 42px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 26px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(50%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      width: 344px; // Medida de la card
      @include scale;
      @include touchmobile;

      @media (min-width: 1200px) {
        width: 360px; // Medida de la card
      }


      &--slider {
        width: 100%;
        height: 216px;
        border-radius: inherit 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: none;
        }

        .swiper-slide {
          margin-right: 0px; // Espacio en subslider entre img
        }
      }

      &--container {
        padding: 20px 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 186px;
        gap: 10px;

        // @media (min-width: 768px) {
        //   padding-inline: 24px;
        // }

        .tagbank {
          position: absolute;
          // background-color: red;
          border-radius: 4px;
          padding: 6px 10px;
          display: flex;
          align-items: center;
          left: 5%;
          top: 0%;
          transform: translateY(-50%);
          z-index: 1;

          img {
            width: 100%;
            height: 20px;
            object-fit: contain;
          }
        }

        .tagposition {
          position: absolute;
          // background-color: red;
          border-radius: 4px;
          padding: 6px 10px;
          display: flex;
          align-items: center;
          left: 5%;
          top: 0%;
          transform: translateY(-50%);
          z-index: 100;
    
          p {
            font-family: "Quicksand";
            font-weight: 600;
            font-size: 12px;
            margin: 0;
            color: white;
          }
        }

        .cardheaderr {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 5px;

          .title {
            @include parragraph-normal(NexaSlab, 13px, 600, normal);
            width: 160px;
            text-align: left;

            @media (min-width: 1200px) {
              width: 180px;
              font-size: 15px;
              line-height: normal;
            }
          }

          .review {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            p {
              @include parragraph-normal(Quicksand, 13px, 400, normal);
              line-height: normal;

              @media (min-width: 1200px) {
                font-size: 14px;
              }
            }
          }
        }

        .estilosicon {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 124px;
          gap: 4px;

          .atributo {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            text-transform: lowercase;

            &:first-letter {
              text-transform: uppercase;
            }
          }

        }

        .estilos {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;
          @include parragraph-normal(Quicksand, 12px, 400, normal);

          @media (min-width: 1200px) {
            font-size: 13px;
          }

          &__txt {
            color: #14C67A;
          }
        }
      }

      &--content {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .cardprices {
          display: flex;
          flex-direction: row;
          gap: 10px;
          // align-items: flex-start;
          justify-content: space-between;

          &__services {
            padding-bottom: 10px;
          }

          &__card {
            display: flex;
            align-items: flex-end;
            gap: 10px;

            .ttcardprices {
              display: flex;
              flex-direction: column;
              gap: 6px;
              width: max-content;
              @include parragraph-normal(Quicksand, 12px, 400, normal);

              span {
                @include parragraph-normal(NexaSlab, 14px, 300, normal);
                margin-bottom: 6px;
                color: #E9082A;
                text-decoration: line-through;
              }

              &__card {
                width: 278px;
              }
            }

            .carddescuento {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 4px;
            }

            .ttdescuento {
              @include parragraph-normal(Quicksand, 10px, 700, normal);
              text-align: center;
              color: white;
              border-radius: 4px;
              padding: 6px;
            }

            .sbcardprices {
              @include parragraph-normal(NexaSlab, 22px, 700, normal);
              text-transform: uppercase;

              @media (min-width: 1200px) {
                font-size: 25px;
              }
            }
          }
        }

        .text {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          text-align: left;

          .date {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
          }

          .column {
            @include flex(flex-end, flex-end, column, 0);
          }

          .sbbcardprices {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            line-height: normal;
          }

          .bg {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            line-height: normal;
            padding: 2px;
            background-color: #FFC700;
            display: flex;
            align-items: center;
          }
        }
      }

      &--extra {
        @include flex(center, center, column, 30px);
        padding: 34px;
        cursor: pointer;
        text-decoration: none;
        height: 416px;
        @include touchmobile;

        .border {
          @include flex-center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #D69C4F;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
        }

        .paragraph {
          @include parragraph-normal(NexaSlab, 14px, normal, normal);
          line-height: normal;
          text-align: center;
        }

      }
    }
  }
}

.betterprice {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__description {
    display: flex;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 340px; // Medida de la card
      margin-right: 28px; // Espacio entre cards

      @media (min-width: 768px) {
        width: 360px; // Medida de la card
        margin-right: 42px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 26px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(50%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      width: 340px; // Medida de la card
      @include scale;
      @include touchmobile;

      @media (min-width: 768px) {
        width: 360px; // Medida de la card
      }


      &--slider {
        width: 100%;
        height: 216px;
        border-radius: inherit 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: none;
        }

        .swiper-slide {
          margin-right: 0px; // Espacio en subslider entre img
        }
      }

      &--container {
        padding: 20px 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 200px;
        gap: 10px;

        // @media (min-width: 768px) {
        //   padding-inline: 24px;
        // }

        .tagposition {
          position: absolute;
          border-radius: 4px;
          left: 5%;
          top: 0%;
          transform: translateY(-50%);
          z-index: 100;

          .tag {
            @include parragraph-normal(Quicksand, 12px, 600, normal);
            padding: 6px 18px;
            color: white;
          }
        }

        .cardheaderr {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .title {
            @include parragraph-normal(NexaSlab, 13px, 600, normal);
            width: 160px;
            text-align: left;

            @media (min-width: 768px) {
              width: 180px;
              font-size: 15px;
              line-height: normal;
            }
          }

          .review {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            p {
              @include parragraph-normal(Quicksand, 13px, 400, normal);
              line-height: normal;

              @media (min-width: 768px) {
                font-size: 14px;
              }
            }
          }
        }

        .estilosicon {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          .atributo {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            text-transform: lowercase;

            &:first-letter {
              text-transform: uppercase;
            }
          }

        }

        .estilos {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;
          @include parragraph-normal(Quicksand, 12px, 400, normal);

          @media (min-width: 768px) {
            font-size: 13px;
          }

          &__txt {
            color: #14C67A;
          }
        }
      }

      &--content {
        display: flex;
        flex-direction: column;
        gap: 6px;
        height: 100%;

        .cardprices {
          display: flex;
          flex-direction: row;
          gap: 10px;
          height: 100%;
          // align-items: flex-start;
          justify-content: space-between;

          &__services {
            &--better {
              display: flex;
              flex-direction: column;
              gap: 4px;
            }
          }

          &__card {
            display: flex;
            align-items: flex-end;
            gap: 6px;

            .ttcardprices {
              display: flex;
              flex-direction: column;
              @include parragraph-normal(Quicksand, 12px, 400, normal);

              span {
                @include parragraph-normal(NexaSlab, 14px, 300, normal);
                margin-top: 4px;
                color: #E9082A;
                text-decoration: line-through;
              }

              &__card {
                width: 278px;
              }
            }

            .carddescuento {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 4px;
            }

            .ttdescuento {
              @include parragraph-normal(Quicksand, 10px, 700, normal);
              text-align: center;
              color: white;
              border-radius: 4px;
              padding: 6px;
            }

            .sbcardprices {
              @include parragraph-normal(NexaSlab, 22px, 700, normal);
              text-transform: uppercase;

              @media (min-width: 768px) {
                font-size: 25px;
              }
            }
          }
        }

        .text {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          text-align: left;

          .date {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
          }

          .column {
            @include flex(flex-end, flex-end, column, 0);
          }

          .sbbcardprices {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            line-height: normal;
          }

          .bg {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            line-height: normal;
            padding: 2px;
            background-color: #FFC700;
            display: flex;
            align-items: center;
          }
        }
      }

      &--extra {
        @include flex(center, center, column, 30px);
        padding: 34px;
        cursor: pointer;
        text-decoration: none;
        height: 416px;
        @include touchmobile;

        .border {
          @include flex-center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #D69C4F;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
        }

        .paragraph {
          @include parragraph-normal(NexaSlab, 14px, normal, normal);
          line-height: normal;
          text-align: center;
        }

      }
    }
  }
}

.hotelesmob {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__description {
    display: flex;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 340px; // Medida de la card
      margin-right: 28px; // Espacio entre cards

      @media (min-width: 768px) {
        width: 360px; // Medida de la card
        margin-right: 42px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 26px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(50%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      width: 340px; // Medida de la card
      @include scale;
      @include touchmobile;

      @media (min-width: 768px) {
        width: 360px; // Medida de la card
      }


      &--slider {
        width: 100%;
        height: 216px;
        border-radius: inherit 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: none;
        }

        .swiper-slide {
          margin-right: 0px; // Espacio en subslider entre img
        }
      }

      &--container {
        padding: 20px 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 170px;
        gap: 10px;

        // @media (min-width: 768px) {
        //   padding-inline: 24px;
        // }

        .tagposition {
          position: absolute;
          border-radius: 4px;
          left: 5%;
          top: 0%;
          transform: translateY(-50%);
          z-index: 100;

          .tag {
            @include parragraph-normal(Quicksand, 12px, 600, normal);
            padding: 6px 18px;
            color: white;
          }
        }

        .cardheaderr {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .title {
            @include parragraph-normal(NexaSlab, 13px, 600, normal);
            width: 160px;
            text-align: left;

            @media (min-width: 768px) {
              width: 180px;
              font-size: 15px;
              line-height: normal;
            }
          }

          .review {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            p {
              @include parragraph-normal(Quicksand, 13px, 400, normal);
              line-height: normal;

              @media (min-width: 768px) {
                font-size: 14px;
              }
            }
          }
        }

        .estilosicon {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          gap: 4px;
          width: 124px;

          .atributo {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            text-transform: lowercase;

            &:first-letter {
              text-transform: uppercase;
            }
          }

        }

        .estilos {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;
          @include parragraph-normal(Quicksand, 12px, 400, normal);

          @media (min-width: 768px) {
            font-size: 13px;
          }

          &__txt {
            color: #14C67A;
          }
        }
      }

      &--content {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .cardprices {
          display: flex;
          flex-direction: row;
          gap: 2px;
          justify-content: flex-end;

          &__card {
            display: flex;
            align-items: flex-end;
            gap: 6px;

            .ttcardprices {
              display: flex;
              flex-direction: column;
              @include parragraph-normal(Quicksand, 12px, 400, normal);
              gap: 6px;

              span {
                @include parragraph-normal(NexaSlab, 14px, 300, normal);
                margin-bottom: 4px;
                color: #E9082A;
                text-decoration: line-through;
              }

              &__card {
                width: 278px;
              }
            }

            .carddescuento {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 4px;
            }

            .ttdescuento {
              @include parragraph-normal(Quicksand, 10px, 700, normal);
              text-align: center;
              color: white;
              border-radius: 4px;
              padding: 6px;
            }

            .sbcardprices {
              @include parragraph-normal(NexaSlab, 22px, 700, normal);
              text-transform: uppercase;

              @media (min-width: 768px) {
                font-size: 25px;
              }
            }
          }
        }

        .text {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          text-align: left;

          .date {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
          }

          .column {
            @include flex(flex-end, flex-end, column, 0);
          }

          .sbbcardprices {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            line-height: normal;
            cursor: pointer;
          }

          .bg {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            line-height: normal;
            padding: 2px;
            background-color: #FFC700;
            display: flex;
            align-items: center;
          }
        }
      }

      &--extra {
        @include flex(center, center, column, 30px);
        padding: 34px;
        cursor: pointer;
        text-decoration: none;
        height: 396px;
        @include touchmobile;

        .border {
          @include flex-center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #D69C4F;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
        }

        .paragraph {
          @include parragraph-normal(NexaSlab, 14px, normal, normal);
          line-height: normal;
          text-align: center;
        }

      }
    }
  }
}