@import '../../atoms/text/styles.scss';

.inputcheckbox {
  line-height: 1.1;
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 8px;
  @include parragraph-normal(Quicksand, 14px, 400, normal);
  cursor: pointer;

  &_content {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #7E7E7E;
    border-radius: 2px;
  }

  .inputcheckbox:focus-within {
    color: black;
    font-weight: bold;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    color: white;
    width: 16px;
    height: 16px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-image: url("./check.svg");
  }

  input:checked[type=checkbox] {
    transform: scale(1);
  }

  input[type="checkbox"]:checked + .inputcheckbox_content {
    border-color: #1b2df4;
  }
}
