.custom__explore {

  &--container {
    width: 100%;
    height: 100%;

    &--slidediv {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 124px;
        object-fit: cover;
      }
    }
  }
}

