@import "../../atoms/text/styles.scss";

.card-promotion-hotel {
  width: 300px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  transition: scale 400ms;
  border-radius: 9px;

  &:hover {
    scale: 104%
  }

  .tooltip-promo {
    background-color: white;
    color: #222;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 15px;
    font-family: Quicksand;
    font-weight: 300;
    width: 200px;
    font-size: 10px;
    z-index: 10;
  }
  &--top-container {
    padding: 20px 20px;
    text-align: center;
    height: 200px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-position: center;

    img {
      max-height: 55px !important;
      max-width: 140px !important;
      object-fit: contain !important;
    }

    .discount-container {
      @include parragraph-normal(NexaSlab, 65px, 700, normal);
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 5px;
    }

    .content__percentage {
      @include parragraph-normal(Quicksand, 17px, 400, normal);
      color: white;
      text-transform: uppercase;
    }

    .ayb-container {
      flex-direction: column;
      justify-content: center;
      width: fit-content;
    }

    .content__texto_ayb {
      @include parragraph-normal(Quicksand, 25px, 600, normal);
      color: white;
      // letter-spacing: 2px;
      text-align: left;
    }

    .content__texto_ayb_abajo_precio {
      @include parragraph-normal(Quicksand, 16px, 600, normal);
      color: white;
    }
  }

  &--bottom-container {
    background-color: white;
    padding: 5px 20px;
    padding-top: 20px;
    position: relative;
    color: #000000;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    height: 140px;
    display: flex;
    flex-direction: column;

    .icon-right svg {
      cursor: pointer;
    }

    .prices-container {
      display: flex;
      flex-direction: row;
      justify-content: end;
      .night-from-container {
        padding-inline: 5px;

        .night-text {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
          margin-bottom: 5px;
        }

        .promo-price-original {
          @include parragraph-normal(NexaSlab, 16px, 400, normal);
          text-decoration: line-through;
          color: red;
        }
      }
      .promo-price {
        @include parragraph-normal(NexaSlab, 35px, 600, normal);
      }
    }

    .promo-igv-container {
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: 5px;

      .promo-igv {
        @include parragraph-normal(Quicksand, 12px, 300, normal);
        color: #0D0D0D;
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      margin-top: auto;

      .icongold {
        line-height: 18px;
        padding: 12px 20px !important;
      }

      .btn-arrow {
        @include parragraph-normal(Quicksand, 14px, 400, normal);

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-underline-offset: 4px;
        text-wrap: nowrap;

        &:hover {
          color: var(--color-gold);
        }
      }
    }

    .horario-container {
      .horario_ayb {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        text-align: right;
      }

      svg {
        margin-top: auto;
      }
    }
  }
}
