@import '../../atoms/text/styles.scss';

.dropdown {
  position: relative;
  user-select: none;
  // display: inline-block;

  .dropdown-toggle {
    display: flex;
    height: 55px;
    padding: 10px 18px;
    background-color: #ffffff;
    border: 1.5px solid #7E7E7E;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: black;
    @include touchmobile;

    .dropdown-toogle-arrow {
      width: 100%;
    }

    .title{
      @include parragraph-normal(NexaSlab, 14px, 700, normal);
    }

    .option{
      @include parragraph-normal(Quicksand, 14px, 400, normal);
      line-height: normal;
      overflow-x: clip;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      padding-right: 10px;
    }

    // &:hover {
    //   background-color: rgba(214, 156, 79, 0.45);
    // }

    .selecetplace{
      @include parragraph-normal(Quicksand, 12px, 400, normal);
      line-height: normal;
    }
  }

  .dropdown-list {
    position: absolute;
    top: 104%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1.5px solid #7E7E7E;
    border-radius: 3px 3px 10px 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 16;
    @include touchmobile;


    li {
      padding: 8px 18px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      @include parragraph-normal(Quicksand, 14px, 400, normal);
      line-height: normal;
      border-bottom: 1px solid #E7E7E7;

      &:hover {
        background-color: rgba(214, 156, 79, 0.45);
      }
    }
  }

  .dropdown-selecetplace {
    position: absolute;
    top: 104%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1.5px solid #7E7E7E;
    border-radius: 3px 3px 10px 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 16;
    @include touchmobile;


    li {
      padding: 8px 18px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      @include parragraph-normal(Quicksand, 12px, 400, normal);
      line-height: normal;

      &:hover {
        background-color: rgba(214, 156, 79, 0.45);
      }
    }
  }
}
