@import '../../atoms/text/styles.scss';

.sectionmolecule {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  background-repeat: repeat;
  background-position: center;
  position: relative;

  .bgActive {
    background-repeat: repeat;
    background-position: center;
    background-image: url('../../../assets/images/bkg-texture.webp');
    padding-block: 40px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px){
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }
}

.sectionactive {
  background-repeat: repeat;
  background-position: center;
  background-image: url('../../../assets/images/bkg-texture.webp');
  padding-block: 40px;
}