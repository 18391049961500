@import "../../../atoms/text/styles.scss";

.bannerslider {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    background: #ffffff;
    opacity: 1;
    width: 15px;
    height: 15px;
  }

  .swiper-pagination-bullet-active {
    background: #d69c4f;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 20px;
    left: 0;
    width: 100%;

    @media (min-width: 768px) {
      bottom: 60px;
    }
  }
}

.bankdiscount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 240px;
  margin-top: 45px;
  gap: 10px;

  @media (min-width: 768px) {
    width: 490px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 0;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      @include parragraph-normal(NexaSlab, 28px, 700, normal);
      text-transform: capitalize;

      @media (min-width: 425px) {
        font-size: 40px;
        line-height: 40px;
      }

      @media (min-width: 1024px) {
        font-size: 60px;
        line-height: 60px;
      }
    }

    &--box {
      display: flex;
      flex-direction: row;
      align-items: center;

      .discount {
        @include parragraph-normal(NexaSlab, 48px, 700, normal);

        @media (min-width: 425px) {
          font-size: 60px;
          line-height: 60px;
        }

        @media (min-width: 1024px) {
          font-size: 90px;
          line-height: 90px;
        }
      }
    }

    &--right {
      .percentage {
        @include parragraph-normal(NexaSlab, 30px, 700, normal);

        @media (min-width: 425px) {
          font-size: 40px;
          line-height: 40px;
        }

        @media (min-width: 1024px) {
          font-size: 50px;
          line-height: 50px;
        }
      }

      .dscto {
        @include parragraph-normal(NexaSlab, 14px, normal, normal);

        @media (min-width: 425px) {
          font-size: 16px;
          line-height: 16px;
        }

        @media (min-width: 1024px) {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  }

  &__cardcredit {
    gap: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      gap: 13px;
      width: 220px;
    }

    .subtitle {
      @include parragraph-normal(NexaSlab, 14px, 700, normal);

      @media (min-width: 425px) {
        font-size: 16px;
        line-height: 16px;
      }

      @media (min-width: 1024px) {
        font-size: 18px;
        line-height: 18px;
      }
    }

    &--cardcredit {
      border-radius: 12px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: 768px) {
        width: 190px;
        height: 60px;
      }
    }

    &--img {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 50px !important;
      }
    }
  }
}

.bannerbancos {
  display: flex;
  flex-direction: column;
  margin-top: 45px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  &__content {
    color: white;
    display: flex;
    gap: 10px;
    padding-inline: 10px;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px) {
      padding-inline: 0;
      gap: 20px;
    }

    &--text {
      .subtitle {
        @include parragraph-normal(NexaSlab, 17px, 500, normal);

        @media (min-width: 768px) {
          font-size: 35px;
        }
      }

      .title {
        @include parragraph-normal(NexaSlab, 35px, 600, normal);
        text-transform: uppercase;
        line-height: normal;

        @media (min-width: 768px) {
          font-size: 70px;
        }
      }
    }

    &--content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 12px 20px;

      @media (min-width: 768px) {
        gap: 20px;
      }

      .box {
        display: flex;
        justify-content: center;
        align-items: center;

        .bannerimglogo {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 140px;
            height: 100%;
            object-fit: cover;
            display: block;

            @media (min-width: 768px) {
              width: 100%;
              height: 53px;
            }
          }
        }
      }
    }
  }
}

.bannerpromocion {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    &--title {
      @include parragraph-normal(NexaSlab, 17px, 500, normal);
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 35px;
      }
    }

    &--subtitle {
      @include parragraph-normal(NexaSlab, 35px, 500, normal);
      text-transform: uppercase;
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 70px;
      }
    }

    &--box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      gap: 20px;

      @media (min-width: 768px) {
        height: 42;
        gap: 30px;
      }

      .bannerimglogo {
        width: 100px;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.bannerpromocionlogo {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    &--title {
      @include parragraph-normal(NexaSlab, 35px, 500, normal);
      text-transform: uppercase;
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 70px;
      }
    }

    &--box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;

      @media (min-width: 768px) {
        height: 60px;
      }

      .bannerimglogo {
        width: 100px;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.bannerpromocionimg {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px) {
      padding-top: 68px;
    }

    &--subtitle {
      @include parragraph-normal(NexaSlab, 17px, 500, normal);
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 30px;
      }
    }

    &--title {
      @include parragraph-normal(NexaSlab, 35px, 500, normal);
      text-transform: uppercase;
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 70px;
      }
    }

    &--box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      margin-top: 20px;
      gap: 20px;

      @media (min-width: 768px) {
        height: 42px;
        gap: 28px;
      }

      .bannerimglogo {
        width: 160px;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.bannerlandings {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px) {
      padding-top: 68px;
    }

    &--subtitle {
      @include parragraph-normal(NexaSlab, 17px, 500, normal);
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 30px;
      }
    }

    &--title {
      @include parragraph-normal(NexaSlab, 26px, 500, normal);
      width: 90%;
      text-transform: uppercase;
      line-height: normal;

      @media (min-width: 768px) {
        width: 100%;
        font-size: 40px;
      }
    }

    &--titleLarge {
      @include parragraph-normal(NexaSlab, 26px, 500, normal);
      width: 90%;
      text-transform: uppercase;
      line-height: normal;

      @media (min-width: 768px) {
        width: 60%;
        font-size: 40px;
      }
    }
  }
}

.bannerpromocioncollage {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px) {
      width: 60%;
    }

    &--subtitle {
      @include parragraph-normal(NexaSlab, 17px, 500, normal);
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 35px;
      }
    }

    &--title {
      @include parragraph-normal(NexaSlab, 35px, 500, normal);
      text-transform: uppercase;
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 70px;
      }
    }

    &--box {
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      gap: 10px;

      @media (min-width: 768px) {
        height: 50px;
        gap: 30px;
      }

      .bannerimglogo {
        width: 160px;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.bannerdestinociudad {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    &--subtitle {
      @include parragraph-normal(NexaSlab, 17px, 500, normal);
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 35px;
      }
    }

    &--title {
      @include parragraph-normal(NexaSlab, 35px, 500, normal);
      text-transform: uppercase;
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 70px;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bannerpromocions {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    min-width: 660px;
    max-width: 866px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    &--title {
      @include parragraph-normal(Quicksand, 17px, 500, normal);
      text-transform: uppercase;
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 35px;
      }
    }

    &--subtitle {
      @include parragraph-normal(NexaSlab, 35px, 600, normal);
      text-transform: uppercase;
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 70px;
      }
    }

    &--description {
      @include parragraph-normal(Quicksand, 14px, 500, normal);
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 20px;
      }
    }
  }
}

.bannerpromocionsizquierda {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    max-width: 660px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 768px) {
      gap: 20px;
    }

    &--content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }
}

.bannerrestaurante {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 768px) {
      gap: 20px;
    }

    &--content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      p {
        font-size: 40px;
      }
    }
  }
}

.bannerpromocioncursive {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    &--title {
      @include parragraph-normal(Dancing Script, 30px, 400, normal);

      @media (min-width: 765px) {
        font-size: 60px;
        line-height: 60px;
      }
    }

    .logo-ca {
      img {
        width: 300px;
      }
    }
  }
}

.bannerpromocionpackage {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    &--title {
      @include parragraph-normal(Dancing Script, 30px, 500, normal);

      @media (min-width: 765px) {
        font-size: 50px;
        line-height: 50px;
      }
    }

    &--subtitle {
      @include parragraph-normal(Quicksand, 26px, 400, normal);
      text-transform: uppercase;

      @media (min-width: 765px) {
        font-size: 35px;
        line-height: 35px;
      }
    }

    &--description {
      @include parragraph-normal(NexaSlab, 30px, 400, normal);
      text-transform: uppercase;

      @media (min-width: 765px) {
        font-size: 60px;
        line-height: 60px;
        font-weight: 700;
      }
    }
  }
}

.bannerimagenderechaboton {
  display: flex;
  flex-direction: column;
  transform: translate(200px, 0);
  gap: 20px;

  .imagen-1 {
    width: 210px !important;
    transform: translate(0, -5px);
  }

  .imagen-2 {
    padding-bottom: 20px;
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: translate(50%, 0);
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    padding-top: 60px;
    transform: translate(0, 0);

    .imagen-1 {
      width: 160px !important;
      transform: translate(0, -10px);
    }

    .imagen-2 {
      display: none !important;
    }
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }

  &--bgimg {
    img {
      filter: brightness(100%) !important;

      @media (max-width: 768px) {
        filter: brightness(100%) !important;
      }
    }

    .section__banner-overlay {
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0.25) 55%,
        rgba(0, 0, 0, 0.0666666667) 90%
      );

      @media (max-width: 768px) {
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.15)
        );
      }
    }
  }

  &--button {
    display: flex;

    button {
      margin: auto;
      background-color: #cd7e0d;

      &:hover {
        background-color: #b97413;
      }

      @media (max-width: 768px) {
        height: 45px;
        padding: 10px 20px !important;
        transform: translate(0, -10px);
      }
    }
  }
}

.bannerimagenizquierdaboton {
  padding: 10px;

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 60px;
    padding-bottom: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0;
    }

    .imagen,
    .imagen-mobile {
      width: 100% !important;
      margin-left: auto;
      margin-right: auto;
      object-fit: contain !important;
    }

    .imagen {
      display: block !important;
      min-width: 300px;
      max-width: 340px;

      @media (max-width: 768px) {
        height: 130px !important;
        margin-top: 0px;
        margin-bottom: 20px;
      }
    }

    .container-columna-derecha {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;


      @media (max-width: 768px) {
        gap: 10px;
      }

      .imagen-derecha {
        max-height: 160px;

        @media (max-width: 768px) {
          max-height: 100px;
        }
      }
    }

    .bannerimagenizquierdaboton--button {
      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }
  }
}
