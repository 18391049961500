.cuotas-sin-intereses{
  display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
}

.cuotas-sin-intereses__container{
  max-width: 1220px;
}

.cuotas-sin-intereses {
  h1{text-align: center; margin-bottom: 30px;}
  p{margin:20px 0;}
  li{margin:10px 0;}
  p, li{text-align: justify;} 

  .title-amarillo{color: rgb(255, 153, 0);}

}