.layout__general {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  position: relative;

  .header {
    height: auto;
    // background-color: #333;
    color: #fff;
    text-align: center;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
  }

  &--main {
    flex: 1;
  }

  .footer {
    height: 120px;
    background-color: #000;
    color: #fff;
    text-align: center;
    line-height: 120px;
  }
}
