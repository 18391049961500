@import '../../atoms/text/styles.scss';

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.70);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &__modal {
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 20px;
      gap: 10px;

      @media (min-width: 768px) {
        width: 645px;
        height: 700px;
        padding: 20px;
      }

      .pad {
        padding-top: 100px;
        width: 100%;

        @media (min-width: 768px) {
          padding-top: 0;
        }
      }

      .close-buttond {
        border: none;
        cursor: pointer;
      }

      .modal-contentd {
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-height: 100%;
        // overflow-y: auto;
      }
    }
  }
}

.modal-contentd::-webkit-scrollbar {
  width: 7px;
  height: 200px;
}

.modal-contentd::-webkit-scrollbar-thumb {
  background-color: #D69C4F;
  border: none;
  border-radius: 10px;
}

.modal-contentd::-webkit-scrollbar-track {
  background-color: #E6E6E6;
  border-radius: 10px;
}

.screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .modalb-close {
    width: 100%;
    
    .titleroute {
      @include parragraph-normal(NexaSlab, 18px, 100, normal);
    }
  }
  
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.70);
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: 12px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    z-index: 1000;
    
    &--content {
      // border-radius: 5px;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding: 10px;
      box-sizing: border-box;
      gap: 14px;
      @media (min-width: 1200px) {
        max-width: 1220px;
      }
    }

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      // @media (min-width: 1200px) {
      //   max-width: 1220px;
      // }
    }

    &--btn {
      cursor: pointer;
      color: #D69C4F;
      border-radius: 12px;
      padding: 10px;

      &:hover {
        background-color: rgb(214, 156, 79, 0.4);
      }
    }

    &--txt {
      font-size: 13px;
      font-weight: 400;
      padding-inline: 16px;
      text-align: right;

      @media(min-width: 768px) {
        font-size: 18px;
      }


    }


    .screendren {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #D69C4F;
        border: none;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: #E6E6E6;
        border-radius: 10px;
      }

      &__container {
        width: 100%;
        height: 100%;

        // @media (min-width: 1200px) {
        //   // padding: 32px;
        //   max-width: 1220px;
        // }
      }

    }
  }
}