@import '../../atoms/text/styles.scss';

.search-dropdown {
  position: relative;
  user-select: none;
  // display: inline-block;

  .boxsearch {
    display: flex;
      position: relative;
      width: 100%;
      border: 1px solid #f2f2f2;
      border-radius: 5px;
      margin-block: 16px 8px;


      .place {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        color: #ccc;
      }

      input {
        width: 100%;
        height: 46px;
        padding: 10px;
        cursor: pointer;
        border-radius: 5px;
        background-color: white;
        // border: 1.5px solid #f2f2f2; // BORDER
        border: none;
        text-align: left;
        padding-left: 42px;
        @include parragraph-normal(NexaSlab, 14px, 400, normal);

        &::placeholder {
          color: black;
        }
      }
  }

  .dropdown-list {
    position: absolute;
    top: 102%;
    left: 0;
    width: 100%;
    background-color: #fff;
    // border-left: 1px solid rgba(126, 126, 126, 1);
    // border-right: 1px solid rgba(126, 126, 126, 1);
    // border-top: 1px solid rgba(126, 126, 126, 1);
    // border-bottom: 1px solid rgba(126, 126, 126, 1);
    border-radius: 3px 3px 10px 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 16;
    max-height: 230px;
    overflow-x: hidden;

    .no-message{
      padding: 10px;
      @include parragraph-normal(NexaSlab, 14px, 500, normal);
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 200px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #D69C4F;
      border: none;
      border-radius: 10px;
    }

      &::-webkit-scrollbar-track {
      background-color: #E6E6E6;
      border-radius: 10px;
    }


    &:last-child {
      border-top: none;
      border-bottom: none;
    }


    .borderp {
      border-top: 1px solid rgba(126, 126, 126, 1);
      border-bottom: 1px solid rgba(126, 126, 126, 1);
      padding: 10px;
      text-transform: capitalize;
      @include parragraph-normal(NexaSlab, 14px, 500, normal);

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: rgba(214, 156, 79, 0.45);
      }
    }

    .border {
      border-top: 1px solid #E7E7E7;
      // border-bottom: 1px solid #E7E7E7;
      padding: 10px;
      text-transform: capitalize;
      @include parragraph-normal(NexaSlab, 14px, 300, normal);


      .premium {
        color: #0E2E61;
        font-weight: 600;
      }

      .select {
        color: #008EAA;
        font-weight: 600;
      }

      .standard {
        color: #F85A20;
        font-weight: 600;
      }

      &:hover {
        background-color: rgba(214, 156, 79, 0.45);
      }
    }

    .ophoteles {
      font-size: 0.875rem;
      font-weight: 700;

      &:hover {
        background: rgba(214, 156, 79, 0.19);
      }
    }

    ul,
    ol {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .optioncity {
      li {
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-family: Sofia;
        font-size: 14px;
        line-height: 1rem;
        font-weight: 100;
  
        &:hover {
          background-color: rgba(214, 156, 79, 0.45);
        }
        
      }

      &:hover {
        background: rgba(214, 156, 79, 0.19);
      }
    }
  }

}
