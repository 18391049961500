@import "../../../components/atoms/text/styles.scss";

.section__banner {
  height: 460px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: 0px;

  @media (max-width: 768px) {
    height: 400px;
    margin-top: 65px;
  }

  &-content {
    text-align: center;
  }

  &-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  }

  &--video {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: white;
    gap: 10px;
    text-align: center;
    margin-top: 42px;

    @media (min-width: 768px) {
      margin-top: 0px;
      gap: 20px;
    }


    #background-video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    .banner::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 0;
    }

    .bannerdestinovideo {
      display: flex;
      flex-direction: column;
      padding-top: 30px;

      @media (min-width: 768px) {
        display: flex;
        justify-content: center;
        gap: 32px;
      }

      &__content {
        color: white;
        display: flex;
        align-items: center;
        flex-direction: column;

        &--subtitle {
          @include parragraph-normal(Quicksand, 26px, 500, normal);
          line-height: normal;
          text-transform: uppercase;

          @media (min-width: 768px) {
            font-size: 30px;
          }
        }

        &--title {
          @include parragraph-normal(NexaSlab, 35px, 600, normal);
          line-height: normal;

          @media (min-width: 768px) {
            font-size: 70px;
          }
        }
      }

      &__button {


        @media (min-width: 768px) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &--btn {
          cursor: pointer;
          @include parragraph-normal(NexaSlab, 18px, 600, normal);
          background: var(--color-gold);
          border-radius: 7px;
          text-align: center;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 200px;
          width: 100%;
          height: 50px;
          padding: 10px 16px;

          &:hover {
            background: #c6914c;
          }
        }

        .icon-right {
          gap: 12px;
        }
      }
    }


  }
}