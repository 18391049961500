/*
    CONTENT STRUCTURE:
    flex direction:   fd-
    flex wrap:        fw-
    gap:              g-#rem
*/

@import "../variables.scss";

$flexDirection: (
    row: row,
    row-reverse: row-reverse,
    column: column,
    column-reverse: column-reverse,
    inherit: inherit,
    initial: initial,
    unset: unset
);

@each $name, $value in $flexDirection {
    /* FLEX DIRECTION (fd-) */
    .fd-#{ $name } {
        flex-direction: #{$value} !important;
    }
}

$flexWrap: (
    nowrap: nowrap,
    wrap: wrap,
    wrap-reverse: wrap-reverse,
    inherit: inherit
);

@each $name, $value in $flexWrap {
    /* FLEX WRAP (fw-) */
    .fw-#{ $name } {
        flex-wrap: #{$value} !important;
    }
}

@each $name, $value in $rem {
    /* GAP (g-#rem) */
    .g-#{ $name } {
        gap: #{$value} !important;
    }
}

@media screen and (max-width: 768px) {
    .m768-d-flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end !important;
}
}