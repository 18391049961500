.sectionfaq {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F2F2F2;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .section {

      &__title {
        font-size: 1.375rem;
        line-height: 1.375rem;
        font-family: NexaSlab;
        color: #000000;
        margin: 0;
        padding: 0 20px;

        @media (min-width: 768px) {
          font-size: 1.875rem;
          line-height: 1.875rem;
        }

        span {
          font-family: Sofia;
          font-weight: 100;
          margin: 0;
          font-size: 1.375rem;
          line-height: 1.375rem;
          @media (min-width: 768px) {
            font-size: 1.875rem;
            line-height: 1.875rem;
          }
        }

      }

      &__faqs {
        background: #F2F2F2;
        font-size: 1.125rem;
        font-family: Sofia;
        font-weight: 100;
        padding: 20px 20px 20px;
      }
    }
  }
}