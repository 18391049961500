.linkActive {
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 21px;
  font-size: 1.125rem;
  text-decoration: none;
}

.nlink {
  text-decoration-line: none;
  color: black;
}

.cursor {
  cursor: pointer;
}