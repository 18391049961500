.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;

  .calendar-body {
    display: flex;
    width: 100%;
    gap: 16px;

    .calendar-header {
      font-size: 18px;
      font-weight: 700;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      padding-inline: 10px;

      .calendar-header {
        text-align: right;
        padding-bottom: 16px;
      }
    }
  }

  .line-calendar {
    margin-top: 40px;
    border-color: #DCDCDC;
  }

  .calendar-month1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .calendar-month2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .calendar-header1 {
    display: flex;
    justify-content: flex-start;

    .monthw {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 20px;
      font-weight: 700;
    }

    button {
      background-color: transparent;
      color: #d69c4f;
      border: none;
      font-size: 26px;
      border-radius: 5px;
      cursor: pointer;

      &:disabled {
        color: #BCBCBC;
        cursor: not-allowed;
      }
    }

    @media (max-width: 768px) {
      button {
        display: none;
      }

      justify-content: flex-end;
    }
  }

  .calendar-header2 {
    display: flex;
    justify-content: flex-end;

    .monthw {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 20px;
      font-weight: 700;
    }

    button {
      background-color: transparent;
      color: #d69c4f;
      border: none;
      font-size: 26px;
      border-radius: 5px;
      cursor: pointer;

      &:disabled {
        color: #BCBCBC;
        cursor: not-allowed;
      }
    }


    @media (max-width: 768px) {
      button {
        display: none;
      }

      justify-content: flex-end;
    }
  }

  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 6px 0;
    width: 100%;
    min-width: 280px;

    .day-header {
      text-align: center;
      font-weight: bold;
    }

    .day {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      // width: 42px;
      height: 40px;
      position: relative;
      cursor: pointer;
      font-weight: 700;
      text-align: center;

      @media (min-width: 768px) {
        width: 48px;
        height: 44px;
      }

      &.daysInRange {
        background-color: rgba(214, 156, 79, 0.3);
      }

      &.daysPass {
        color: #BCBCBC;
        pointer-events: none;
      }

      &.disabledInRange {
        background-color: #dfd5d5ea;

        p {
          color: #000000;
        }

        .date-text {
          color: #fff;
        }
      }

      &.daysNotAvailable {

        .date-day {
          color: #C9C9C9;
          text-decoration: line-through;
        }

        .date-text {
          color: #C9C9C9;
          font-size: 10px;
          line-height: 8px;
        }

        &.selectedFinish {
          background-color: #d69c4f;
          border-radius: 0 12px 12px 0;

          .date-day {
            color: black;
          }

          .date-text {
            color: #fff;
          }
        }

        &.selectedInit {
          background-color: #d69c4f;
          color: #fff;
          border-radius: 12px 0 0 12px;
        }
      }

      &.daysAvailable {
        font-size: 16px;
        color: black;

        @media (min-width: 768px) {
          &:hover {
            background-color: rgba(214, 156, 79, 0.3);
            border-radius: 12px;
          }
        }

        &.todayOn {
          border-bottom: 2px solid #d69c4f;
          border-width: 2px;
          border-radius: 4px;
        }


        &.selectedInit {
          background-color: #d69c4f;
          border-radius: 12px 0 0 12px;

          .date-text {
            color: #fff;
          }
        }

        &.selectedFinish {
          background-color: #d69c4f;
          border-radius: 0 12px 12px 0;

          .date-day {
            color: black;
          }

          .date-text {
            color: #fff;
          }
        }
      }

      .date-text {
        font-family: Quicksand;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .empty {
      visibility: hidden;
    }
  }

  .tooltip-container {
    display: inline-block;

    .tooltipcalendar {
      font-size: 10px;
      font-family: Quicksand;
      font-weight: 400;
      visibility: visible;
      background-color: white;
      color: black;
      text-align: center;
      border-radius: 4px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, -26%);
      padding: 10px 0px;
      width: 104px;
      min-height: 26px;
      max-height: 42px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
      }

      .icon-default {
        justify-content: center;
        font-family: Quicksand;
        font-size: 10px;
      }

      .checkout-text {
        margin: 0;
        padding: 0;
        line-height: 18px;
        font-size: 10px;
      }
    }

    .tooltipcalendar-monday {
      font-size: 10px;
      font-family: Quicksand;
      font-weight: 400;
      visibility: visible;
      background-color: white;
      color: black;
      text-align: center;
      border-radius: 4px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 50%;
      transform: translate(-20%, -26%);
      padding: 10px 0px;
      width: 104px;
      min-height: 26px;
      max-height: 42px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 22%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
      }

      .icon-default {
        justify-content: center;
        font-family: Quicksand;
        font-size: 10px;
      }

      .checkout-text {
        margin: 0;
        padding: 0;
        line-height: 18px;
        font-size: 10px;
      }
    }

    .tooltipcalendar-sunday {
      font-size: 10px;
      font-family: Quicksand;
      font-weight: 400;
      visibility: visible;
      background-color: white;
      color: black;
      text-align: center;
      border-radius: 4px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 6%;
      transform: translate(-62%, -26%);
      padding: 10px 0px;
      width: 104px;
      min-height: 26px;
      max-height: 42px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 80%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
      }

      .icon-default {
        justify-content: center;
        font-family: Quicksand;
        font-size: 10px;
      }

      .checkout-text {
        margin: 0;
        padding: 0;
        line-height: 18px;
        font-size: 10px;
      }
    }
  }


}