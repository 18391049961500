@import '../../../components/atoms/text/styles.scss';

.betterprices {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__description {
    display: flex;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 340px; // Medida de la card
      margin-right: 28px; // Espacio entre cards

      @media (min-width: 768px) {
        width: 360px; // Medida de la card
        margin-right: 42px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 26px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(50%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      width: 340px; // Medida de la card
      @include scale;
      @include touchmobile;

      @media (min-width: 768px) {
        width: 360px; // Medida de la card
      }


      &--slider {
        width: 100%;
        height: 216px;
        border-radius: inherit 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: none;
        }

        .swiper-slide {
          margin-right: 0px; // Espacio en subslider entre img
        }
      }

      &--container {
        padding: 20px 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 194px;
        gap: 10px;

        // @media (min-width: 768px) {
        //   padding-inline: 24px;
        // }

        .tagposition {
          position: absolute;
          border-radius: 4px;
          left: 5%;
          top: 0%;
          transform: translateY(-50%);
          z-index: 100;

          .tag {
            @include parragraph-normal(Quicksand, 12px, 600, normal);
            padding: 6px 18px;
            color: white;
          }
        }

        .cardheaderr {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .title {
            @include parragraph-normal(NexaSlab, 13px, 600, normal);
            width: 160px;
            text-align: left;

            @media (min-width: 768px) {
              width: 180px;
              font-size: 15px;
              line-height: normal;
            }
          }

          .review {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            p {
              @include parragraph-normal(Quicksand, 13px, 400, normal);
              line-height: normal;

              @media (min-width: 768px) {
                font-size: 14px;
              }
            }
          }
        }

        .estilosicon {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 4px;
           width: 120px;
          margin-top: 6px;

          .atributo {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            text-transform: lowercase;

            &:first-letter {
              text-transform: uppercase;
            }
          }

        }

        .estilos {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;
          @include parragraph-normal(Quicksand, 12px, 400, normal);

          @media (min-width: 768px) {
            font-size: 13px;
          }

          &__txt {
            color: #14C67A;
          }
        }
      }

      &--content {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .cardprices {
          display: flex;
          flex-direction: row;
          gap: 2px;
          // align-items: flex-start;
          justify-content: space-between;

          &__card {
            display: flex;
            align-items: flex-end;
            gap: 6px;

            .ttcardprices {
              display: flex;
              flex-direction: column;
              @include parragraph-normal(Quicksand, 12px, 400, normal);
              gap: 6px;

              span {
                @include parragraph-normal(NexaSlab, 14px, 300, normal);
                margin-bottom: 4px;
                color: #E9082A;
                text-decoration: line-through;
              }

              &__card {
                width: 278px;
              }
            }

            .carddescuento {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 4px;
            }

            .ttdescuento {
              @include parragraph-normal(Quicksand, 10px, 700, normal);
              text-align: center;
              color: white;
              border-radius: 4px;
              padding: 6px;
            }

            .sbcardprices {
              @include parragraph-normal(NexaSlab, 22px, 700, normal);
              text-transform: uppercase;

              @media (min-width: 768px) {
                font-size: 25px;
              }
            }
          }
        }

        .text {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          text-align: left;

          .date {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
          }

          .column {
            @include flex(flex-end, flex-end, column, 0);
          }

          .sbbcardprices {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            line-height: normal;
            cursor: pointer;
          }

          .bg {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            line-height: normal;
            padding: 2px;
            background-color: #FFC700;
            display: flex;
            align-items: center;
          }
        }
      }

      &--extra {
        @include flex(center, center, column, 30px);
        padding: 34px;
        cursor: pointer;
        text-decoration: none;
        height: 410px;
        @include touchmobile;

        .border {
          @include flex-center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #D69C4F;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
        }

        .paragraph {
          @include parragraph-normal(NexaSlab, 14px, normal, normal);
          line-height: normal;
          text-align: center;
        }

      }
    }
  }
}