.terminos_condiciones_latam_pass {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  font-family: Sofia;
}

.terminos_condiciones_latam_pass__container {
  padding: 0px 16px;

  @media (min-width: 768px) {
    padding: 0px 60px;
  }

  @media (min-width: 1200px) {
    padding: 0px 120px;
  }
}

.terminos_condiciones_latam_pass {
  h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    margin: 20px 0;
  }

  li {
    margin: 10px 0;
  }

  p,
  li {
    text-align: justify;
  }
}