@import '../../atoms/text/styles.scss';

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D69C4F;
  border-radius: 5px;
  font-family: Sofia;
  font-size: 16px;
  font-weight: 100;
  width: 56px;
  height: 30px;

  .starfull-rating{
    @include parragraph-normal(Quicksand, 14px, 400, normal);

  }
  .icon-right {
    gap: 6px;
  }
}

.rating.selected {
  color: rgb(255, 255, 255);
  background: var(--color-gold);
}

.rating:hover {
  cursor: pointer;
}