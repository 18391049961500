@import '../../../components/atoms/text/styles.scss';

.hotelspage {
  display: flex;
  justify-content: center;
  align-items: center;

  // margin-top: 50px;

  @media (min-width: 768px) {
    background-color: #F7F7F7;
    //   margin-top: 91px;
  }

  &__container {
    width: 100%;

    .containermobile {

      .subtitle {
        @include parragraph-normal(NexaSlab, 20px, 600, normal);
        text-align: center;
        margin-block: 20px;
      }
    }
  }


  &__title {
    @include parragraph-normal(NexaSlab, 40px, 600, normal);
    margin-block: 20px 10px;
  }

  &__description {
    display: flex;
    // margin-top: 95px;

    .left {
      flex: 1;
      overflow-y: auto;
      gap: 20px;
      margin-top: 95px;

      &__content {
        background-color: #F7F7F7;
        display: grid;
        padding-inline: 20px;
        gap: 20px;

        &--filter {

          .titlepage {
            @include parragraph-normal(NexaSlab, 28px, 600, normal);
            padding-top: 32px;
            margin-left: 20px;
          }

          .tfilters {
            // margin-left: 20px;
            transition: all 0.3s ease;
            // width: 100%;

            &.sticky {
              position: fixed;
              top: 91px;
              z-index: 11;
              background-color: #F7F7F7;
            }

            &__paragraph {
              @include parragraph-normal(NexaSlab, 18px, 300, normal);
              margin-left: 20px;
              margin-top: 16px;
            }

            &__buttons {
              display: flex;
              justify-content: flex-start;
              gap: 16px;
              margin-block: 16px;
              margin-left: 20px;

              .open-button,
              .open-maps {
                padding: 10px 18px;
                display: block;
                border-radius: 10px;
                background-color: white;
                border: 1.5px solid rgb(126, 126, 126);
                min-width: 134px;
                @include parragraph-normal(NexaSlab, 14px, 700, normal);
                line-height: normal;
                cursor: pointer;
              }

              .clearfilter {
                padding: 10px 18px;
                display: block;
                border: none;
                background-color: #F7F7F7;
                color: #D69C4F;
                min-width: 134px;
                @include parragraph-normal(NexaSlab, 14px, 700, normal);
                line-height: normal;
                cursor: pointer;

                &:hover {
                  text-decoration: underline;
                }
              }

              .open-maps {
                @media (min-width: 1020px) {
                  display: none;
                }

              }
            }
          }
        }

        &--cards {
          display: grid;
          padding-inline: 20px;
          padding-bottom: 42px;
          gap: 42px;

          .cardnewhotel {
            display: flex;
            align-items: flex-start;
            border-radius: 9px;
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
            position: relative;
            width: 100%;
            height: 245px;
            min-width: 633px;
            overflow: hidden;


            &__hotel {
              width: 100%;
              height: 100%;
              min-width: 232px;
              max-width: 274px;
              position: relative;
            }

            &__description {
              width: 100%;
              height: 100%;
              padding: 24px 18px;

              .cardheader {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                gap: 10px;

                &__content {
                  display: flex;
                  flex-direction: column;
                  gap: 6px;

                  .title {
                    @include parragraph-normal(NexaSlab, 16px, 700, normal);
                  }

                  .review {
                    @include parragraph-normal(Quicksand, 13px, 400, normal);
                  }

                }

                &__icon {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  gap: 4px;

                  .atributo {
                    @include parragraph-normal(Quicksand, 10px, 400, normal);
                    text-transform: lowercase;

                    &:first-letter {
                      text-transform: uppercase;
                    }
                  }
                }

                &__prices {
                  display: grid;
                  grid-template-columns: 0.8fr 0.9fr;

                  &--left {
                    @include parragraph-normal(Quicksand, 12px, 400, normal);
                    border-right: 1.5px solid #CBCBCB;
                    height: 100%;

                    .icontext {
                      display: flex;
                      justify-content: flex-start;
                      align-items: flex-start;
                      gap: 6px;
                    }

                    .list {
                      display: flex;
                      justify-content: space-between;
                      flex-direction: column;
                      gap: 18px;
                    }

                  }

                  &--night {
                    margin-left: 16px;
                  }

                  .ttcardprices {
                    @include parragraph-normal(Quicksand, 12px, 400, normal);
                    text-align: left;
                    margin-bottom: 6px;
                  }

                  .cardprices {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    &__card {
                      display: flex;
                      gap: 6px;
                      align-items: flex-end;
                      justify-content: space-between;


                      .night {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 4px;
                        @include parragraph-normal(Quicksand, 12px, 400, normal);

                        .pricebefore {
                          @include parragraph-normal(NexaSlab, 12px, 300, normal);
                          color: #E9082A;
                          text-decoration: line-through;
                        }

                        &__card {
                          width: 278px;
                        }
                      }

                      .carddescuento {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        gap: 4px;
                      }

                      .sbcardprices {
                        @include parragraph-normal(NexaSlab, 20px, 700, normal);
                        text-transform: uppercase;
                      }
                    }
                  }
                }

                &__btn {
                  display: grid;
                  grid-template-columns: 1fr 1fr;


                  .linknext {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
                    width: 94px;
                    cursor: pointer;
                    @include scale;

                    .text {
                      @include parragraph-normal(Quicksand, 13px, 400, normal);
                      cursor: pointer;
                      text-decoration: underline;
                      text-underline-offset: 4px;
                    }
                  }

                  .btnreserva {
                    padding: 14px 18px;
                    display: block;
                    height: 45px;
                    cursor: pointer;
                    border: none;
                    background: var(--color-gold);
                    border-radius: 7px;
                    color: white;
                    @include parragraph-normal(NexaSlab, 14px, 700, normal);
                    text-align: center;
                    line-height: normal;
                    z-index: 2;
                    @include scale;
                  }
                }

                &__igv {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  margin-top: 8px;
                  gap: 2px;
                  @include parragraph-normal(Quicksand, 12px, 400, normal);
                  line-height: normal;
                }
              }

            }

            &--price {
              width: 100%;
              max-width: 230px;
              padding: 29px 18px;

              .cardprices {
                display: flex;
                flex-direction: column;
                gap: 20px;

                &__card {
                  font-family: Sofia;
                  font-weight: 100;
                }

                .ttcardprices {
                  @include parragraph-normal(Quicksand, 14px, 400, normal);
                  margin-bottom: 16px
                }

                .pricebefore {
                  @include parragraph-normal(NexaSlab, 16px, 300, normal);
                  color: #8C8C8C;
                  line-height: normal;
                  text-decoration: line-through;
                }

                .sbcardprices {
                  @include parragraph-normal(NexaSlab, 25px, 600, normal);
                  line-height: normal;
                }

                .sbcardigv {
                  font-family: Sofia;
                  font-weight: 100;
                  font-size: 1rem;
                  padding-right: 4px;
                }

                .sbcardigvs {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 4px;
                  @include parragraph-normal(Quicksand, 14px, 400, normal);
                }
              }
            }

            .skeleton {
              position: relative;
              border-radius: 8px;
              width: 100%;
              height: 100%;
              overflow: hidden;

              &__container {
                position: absolute;
                top: 0;
                left: -100%;
                width: 600px;
                height: 100%;
                background-color: #f3f2f2;
                animation: moveSkeleton 2s infinite linear;
              }
            }

            .skeleton2 {
              position: relative;
              border-radius: 8px;
              width: 100%;
              height: 20px;
              overflow: hidden;

              &__container {
                position: absolute;
                top: 0;
                left: -100%;
                width: 100%;
                height: 100%;
                background-color: #f3f2f2;
                animation: moveSkeleton 2s infinite linear;
              }
            }
          }
        }
      }

    }

    .right {
      flex: 0 0;
      max-width: 600px;
      position: sticky;
      top: 0;
      height: 100vh;

      @media (min-width: 1024px) {
        flex: 0 0 100%;
        max-width: 27%;
      }

      @media (min-width: 1200px) {
        max-width: 46%;
      }

      // @media (min-width: 1400px) {
      //   max-width: 56%;
      // }



      &__content {
        margin-top: 92px;
        width: 100%;

        .googlemaps {
          width: 100%;
          height: calc(100vh - 92px);

          .gm-ui-hover-effect,
          .gm-style-iw-tc {
            display: none !important;
          }

          .gm-style .gm-style-iw-c {
            padding: 0px !important
          }

          .gm-style .gm-style-iw-c {
            max-width: 245px !important;
            min-width: 245px !important;
            overflow: hidden !important;
            top: -70px;
            left: 26px;

          }

          .gm-style-iw-d {
            overflow: hidden !important;

          }
        }
      }
    }

    .filtromodal {
      width: 100%;
      padding-inline: 10px;

      .contentstarts {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 10px;
        padding: 10px 0px;
      }
    }
  }
}

// MultiRangeSlider
.range-slider-container {
  padding: 20px 6px 16px 6px;

  .slider-range {

    .caption .max-caption,
    .caption .min-caption {
      height: 20px;
      font-family: Quicksand;
      font-weight: 100;
      font-size: 16px;
      line-height: 16px;
      margin: 0;
      background-color: transparent;
      border-radius: 0px;
      color: var(--color-black);
      box-shadow: none;
      padding: 0px 5px;
      white-space: nowrap;
    }
  }

  .multi-range-slider .bar-left,
  .multi-range-slider .bar-right {
    background-color: #f2f2f2;
    box-shadow: none;
    padding: 2px 0px;
  }

  .multi-range-slider .bar-inner {
    background-color: var(--color-gold);
    border: none;
    box-shadow: none;
  }

  .multi-range-slider .thumb {
    background-color: var(--color-gold);
  }

  .multi-range-slider .thumb::before {
    border: solid 1px var(--color-gold);
    box-shadow: #d69c4fcc 0px 0px 0px 1px;
    width: 15px;
    height: 15px;
    margin: -7px -12px;
  }

  .multi-range-slider .label:first-child,
  .multi-range-slider .label:last-child {
    width: 100%;
  }

  .multi-range-slider .caption {
    display: flex;
    bottom: 22px;
  }
}


.iconmap {
  position: relative;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
  border-radius: 50px 50px 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -72px;
  left: 0px;

  &__content {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      @include parragraph-normal(Quicksand, 10px, 500, normal);
      color: white;
      text-align: center;
    }
  }
}

.componentcontainer__description {
  display: flex;

  .swiper {
    height: 100%;
  }

  .swiper-slide {
    width: 246px;

  }

  &--swiper {
    width: 100%;
    padding: 16px 0px 16px 16px;
    position: relative;

    @media (max-width: 767px) {

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    @media (min-width: 768px) {
      padding: 14px 26px;

      .swiper-slide-thumb-active {
        opacity: 1;
      }

      .swiper-button-prev,
      .swiper-button-next {
        color: #D69C4F;
        position: absolute;
        transform: translateY(50%);
        z-index: 1000;
      }

      .swiper-button-prev {
        left: 1%;
      }

      .swiper-button-next {
        right: 2%;
      }

      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 24px;
        font-weight: 700;
        border-radius: 50%;
        padding: 10px 15px;
        background-color: white;
        box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
      }

      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        display: none;
        pointer-events: none;
      }
    }
  }

  &--card {
    user-select: none;
    border-radius: 9px;
    background-color: white;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    width: 245px; // Medida de la card

    &--slider {
      width: 245px;
      height: 134px;
      border-radius: inherit 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: none;
      }

      .swiper-slide {
        margin-right: 0px; // Espacio en subslider entre img
      }
    }

    &--container {
      padding: 12px 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 112px;

      .tagposition {
        position: absolute;
        border-radius: 4px;
        left: 5%;
        top: 0%;
        transform: translateY(-50%);
        z-index: 100;

        .tag {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
          text-transform: lowercase;
          padding: 6px 18px;
          color: white;

          &:first-letter {
            text-transform: uppercase;
          }
        }
      }

      .cardheaderr {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .title {
          @include parragraph-normal(NexaSlab, 12px, 600, normal);
          width: 120px;
          text-align: left;
        }

        .review {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          p {
            @include parragraph-normal(Quicksand, 10px, 400, normal);
            line-height: normal;
          }
        }
      }


      .estilosicon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;

        .atributo {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
          text-transform: lowercase;

          &:first-letter {
            text-transform: uppercase;
          }
        }

      }

      .estilos {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include parragraph-normal(Quicksand, 12px, 400, normal);

        &__txt {
          color: #14C67A;
        }
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .cardprices {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__card {
          display: flex;
          gap: 6px;
          align-items: flex-end;


          .night {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @include parragraph-normal(Quicksand, 12px, 400, normal);

            .pricebefore {
              @include parragraph-normal(NexaSlab, 14px, 300, normal);
              margin-top: 4px;
              color: #E9082A;
              text-decoration: line-through;
            }

            &__card {
              width: 278px;
            }
          }

          .carddescuento {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 4px;
          }

          .sbcardprices {
            @include parragraph-normal(NexaSlab, 20px, 700, normal);
            text-transform: uppercase;
          }
        }
      }

      .text {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .date {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
        }

        .column {
          @include flex(flex-end, flex-end, column, 0);
        }

        .sbbcardprices {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;
          @include parragraph-normal(Quicksand, 12px, 400, normal);
          line-height: normal;
        }


      }
    }

    &--extra {
      @include flex(center, center, column, 30px);
      padding: 34px;
      cursor: pointer;
      text-decoration: none;
      height: 406px;
      @include touchmobile;

      .border {
        @include flex-center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #D69C4F;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
      }

      .paragraph {
        @include parragraph-normal(NexaSlab, 14px, normal, normal);
        line-height: normal;
        text-align: center;
      }

    }
  }
}

.googlemapsmob {
  width: 100%;
  height: 100%;

  .gm-ui-hover-effect,
  .gm-style-iw-tc {
    display: none !important;
  }

  .gm-style .gm-style-iw-c {
    padding: 0px !important
  }

  .gm-style .gm-style-iw-c {
    max-width: 245px !important;
    min-width: 245px !important;
    overflow: hidden !important;

  }

  .gm-style-iw-d {
    overflow: hidden !important;

  }
}

.contentstarts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px 0px;
}