@import '../../atoms/text/styles.scss';

.promotionscopy {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat;
  background-position: center;
  background-image: url('../../../assets/images/bkg-texture.webp');
  position:relative;
  
  &__container {
    width: 100%;
    display: grid;
    height: max-content;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    
    @media (min-width: 1200px){
      text-align: left;
      padding-block: 40px;
      min-height: 220px;
      // grid-template-columns: 552px 78px 342px;
      grid-template-columns: 45% 10% 45%;
      max-width: 1220px;
    }

    .termino {
      display: flex;
      justify-content: center;
      align-items: center;
    
      @media (min-width: 768px ) {
        justify-content: center;
      }

      @media (min-width: 1200px){
        justify-content: flex-start;
      }
    }

    &--description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      @include parragraph-normal(NexaSlab, 16px, 600, normal);
      line-height: normal;

      @media (max-width: 1200px) {
        margin-bottom: 20px;
      }

      p,
      span {
        width: 100%;
      }

      .terms{
        font-weight: 400;
      }
    }

    &--codigo {
      display: flex;
      justify-content: flex-start ;
      flex-direction: column;
      gap: 10px;
      @include parragraph-normal(NexaSlab, 16px, 600, normal);


      @media (max-width: 1200px) {
        margin-top: 20px;
      }

      @media (min-width: 1200px ) {
        // align-items: flex-start;
        justify-content: center;
      }

    }



    &--icon {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(90deg);

      @media (min-width: 1200px){
        transform: rotate(0deg);
      }
    }

    .txtlink {
      cursor: pointer;
      margin-left: 8px;
      color: #D69C4F;
      text-decoration: none;
      border-bottom: #D69C4F 2px solid;

      :hover {
        transform: scale(1.1);
      }
    }

    .btnclip {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (min-width: 1200px) {
        align-items: flex-start;
      }
      
      &__container {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 322px;

      &--text {
        width: 100%;
        height: 100%;
        background-color: white;
        padding-left: 10px;
        border-radius: 10px 0px 0px 10px;
        border-left: 1.5px solid #E6E6E6;
        border-bottom: 1.5px solid #E6E6E6;
        border-top: 1.5px solid #E6E6E6;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (min-width: 768px) {
          padding-inline: 20px;
        }
      }

      &--copy {
        width: 100%;
        max-width: 120px;
        height: 50px;
        border: 1.5px solid #D69C4F;
        border-radius: 0px 10px 10px 0px;
        background-color: #D69C4F;
        padding-inline: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        @include parragraph-normal(NexaSlab, 14px, 600, normal);
      }
    }

    }

    .container-list{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 12px;
    }

    .list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      p {
       @include parragraph-normal(Quicksand, 12px, 400, normal);
       line-height: normal;
       text-align: left;
      }
    }
  }

  .modal__terminos{
    width: 100%;
    
    .paragraph{
      @include parragraph-normal(Quicksand, 12px, 400, normal);
      line-height: normal;
    }
  }
}