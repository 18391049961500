@import '../../atoms/text/styles.scss';

.promotionsform {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 16px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }


  &__container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    justify-items: center;

    @media (min-width: 768px) {
      gap: 40px;
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--slider {
      width: 100%;
      height: 100%;

      @media (min-width: 768px) {
        width: 481px;
        height: 100%;
      }

      .promotionslider {

        .swiperpromotion {
          img {
            width: 100%;
            height: 438px;
            object-fit: cover;
          }

          .swiper {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

        }


        .swiper-pagination-bullet {
          background: #FFFFFF;
          opacity: 1;
          width: 15px;
          height: 15px;
        }

        .swiper-pagination-bullet-active {
          background: #D69C4F;
        }

        .swiper-pagination-fraction,
        .swiper-pagination-custom,
        .swiper-horizontal>.swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal {
          bottom: 6%;
          left: 0;
          width: 100%;
        }
      }
    }

    &--form {
      @include parragraph-normal(NexaSlab, 18px, 400, normal);

      .box {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
  }
}

.forminput {
  padding-top: 16px;
  padding-bottom: 20px;
}

.input-form {
  input {
    width: 100%;
    height: 55px;
    border: 1px solid #6B6B6B;
    font-family: Sofia;
    border-radius: 7px;
    margin: 10px 0;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.3s;
    background: white;
    font-weight: 100;
    font-size: 0.875rem;

    &::placeholder {
      color: #6B6B6B;
    }
  }
}

.inputtwolines {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;

  div {
    width: 100%;
  }
}

.inputtwolinesc {
  display: flex;
  justify-content: space-between;
  align-items: center;


  div {
    width: 100%;
  }

  .line {
    width: 35%;

    input {
      width: 100%;
      height: 55px;
      border: 1px solid #6B6B6B;
      border-radius: 7px 0px 0px 7px;
      margin: 10px 0;
      outline: none;
      padding: 10px;
      box-sizing: border-box;
      transition: 0.3s;
      background: white;
      font-weight: 100;
      font-size: 0.875rem;

      &::placeholder {
        color: #6B6B6B;
      }
    }
  }

  .linedoc {
    width: 75%;

    input {
      color: #6B6B6B;
      width: 100%;
      height: 55px;
      border: 1px solid #6B6B6B;
      border-left: none;
      border-radius: 0px 7px 7px 0px;
      margin: 10px 0;
      outline: none;
      padding: 10px;
      box-sizing: border-box;
      transition: 0.3s;
      background: white;
      font-weight: 100;
      font-size: 0.875rem;

      &::placeholder {
        color: #6B6B6B;
      }
    }
  }

}

.inputline {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .dropdown-form {

    input {
      width: 100%;
      height: 45px;
      border: 1px solid #6B6B6B;
      border-radius: 7px 0px 0px 7px;
      margin: 10px 0;
      outline: none;
      padding: 10px;
      box-sizing: border-box;
      transition: 0.3s;
      background: white;
      font-weight: 100;
      font-size: 0.875rem;

      &::placeholder {
        color: #6B6B6B;
      }
    }
  }

  .dropdown-input {

    input {
      width: 100%;
      height: 45px;
      border: 1px solid #6B6B6B;
      border-radius: 7px 0px 0px 7px;
      margin: 10px 0;
      outline: none;
      padding: 10px;
      box-sizing: border-box;
      transition: 0.3s;
      background: white;
      font-weight: 100;
      font-size: 0.875rem;

      &::placeholder {
        color: #6B6B6B;
      }
    }
  }
}

.dropdown {
  .dropdowns-list {
    position: absolute;
    top: 102%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid rgba(126, 126, 126, 1);
    border-radius: 3px 3px 10px 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 2;

    li {
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      font-family: Sofia;
      font-size: .875rem;
      line-height: 1rem;
      font-weight: 100;

      &:hover {
        background-color: rgba(214, 156, 79, 0.45);
      }
    }
  }

  .dropdowns-toggles {
    width: 100%;
    height: 55px;
    border: 1px solid #6B6B6B;
    border-radius: 7px 0px 0px 7px;
    font-family: Sofia;
    color: #6B6B6B;
    font-size: 14px;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.3s;
    background: white;
    font-weight: 100;
    
    div{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
    }
  }
}