.icon-txt {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  gap: 6px;
}

.bottom-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconActive {
  transition: transform 0.3s ease;
}

.iconActive.rotate {
  transform: rotate(180deg);
}

.icon-paragraph {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.icon-footer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.icon-default{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.icon-zero{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon-right{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
}

.icon-left {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}