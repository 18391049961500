@import '../../atoms/text/styles.scss';

.section-cards-hotel-package {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;
  background-color: #F5F5F5;

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section2(NexaSlab, 700, normal);
      margin-bottom: 10px;
      font-size: 25px !important;

      @media(min-width: 768px) {
        margin-bottom: 20px;
      }
    }

    &--description {
      &--swiper {
        position: relative;
        width: 100%;
        height: 330px;

        @media (max-width: 768px) {
          height: 540px;
        }

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          width: fit-content;
          height: 100%;
          padding: 10px 20px;
        }

        @media (max-width: 768px) {
          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        @media (min-width: 768px) {
          .sactivities__swiper .swiper-slide-thumb-active {
            opacity: 1;
          }

          .sactivities__swiper {
            position: relative;
          }

          .swiper-button-prev,
          .swiper-button-next {
            color: #D69C4F;
            position: absolute;
            transform: translateY(50%);
            z-index: 1000;
          }

          .swiper-button-prev {
            left: 1%;
            top: 44%;
          }

          .swiper-button-next {
            right: 1%;
            top: 44%;
          }

          .swiper-button-prev:after,
          .swiper-button-next:after {
            font-size: 24px;
            font-weight: 700;
            border-radius: 50%;
            padding: 10px 15px;
            background-color: white;
            box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
          }

          .swiper-button-prev.swiper-button-disabled,
          .swiper-button-next.swiper-button-disabled {
            opacity: 0.35;
            cursor: auto;
            display: none;
            pointer-events: none;
          }
        }

        .swiper-pagination {
          height: 30px;
        }

        .swiper-slide img {
          display: block;
          width: 100%;
          height: 100%;
          max-height: 240px;
          object-fit: cover;
        }

        .swiper-pagination-bullet {
          background: white;
          opacity: 1;
          width: 15px;
          height: 15px;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
        }

        .swiper-pagination-bullet-active {
          background: #D69C4F;
        }

        .swiper-pagination-fraction,
        .swiper-pagination-custom,
        .swiper-horizontal>.swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal {
          bottom: 4%;
          left: 0;
          width: 100%;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        gap: 40px;

        .final {
          width: 240px;
          height: 100%;
          gap: 14px;
          padding-top: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }
  }
}

