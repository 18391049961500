/*
    CONTENT STRUCTURE:
    padding:            p-#rem
    padding top:        pt-#rem
    padding right:      pr-#rem
    padding left:       pl-#rem
    padding bottom:     pb-#rem
    padding horizontal: px-#rem
    padding vertical:   py-#rem
*/

@import "../variables.scss";

@each $name, $value in $rem {
    .p-#{ $name } {
        padding: #{$value} !important;
    }

    .pt-#{ $name } {
        padding-top: #{$value} !important;
    }

    .pr-#{ $name } {
        padding-right: #{$value} !important;
    }

    .pl-#{ $name } {
        padding-left: #{$value} !important;
    }

    .pb-#{ $name } {
        padding-bottom: #{$value} !important;
    }

    .px-#{ $name } {
        padding-right: #{$value} !important;
        padding-left: #{$value} !important;
    }

    .py-#{ $name } {
        padding-top: #{$value} !important;
        padding-bottom: #{$value} !important;
    }
}
