@import '../../atoms/text/styles.scss';

// Type Column
.sectionrestaurantcity {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  // @media (min-width: 768px) {
  //   padding-block: 50px;
  // }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section2(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__column {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    padding-inline: 16px;
    gap: 44px;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      justify-items: flex-end;

      padding-inline: 0px;
    }

    &--swiper {
      width: 100%;
      height: 100%;
      position: relative;

      .swiper-pagination-bullet {
        background: #FFFFFF;
        opacity: 1;
        width: 15px;
        height: 15px;
      }

      .swiper-pagination-bullet-active {
        background: #D69C4F;
      }

      .swiper-pagination-fraction,
      .swiper-pagination-custom,
      .swiper-horizontal>.swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 5%;
        left: 0;
        width: 100%;
      }

      .imgslide {
        width: 100%;
        height: 100%;

        &__logo {
          width: 80px;
          height: 80px;
          position: absolute;
          border-radius: 50%;
          top: 20px;
          right: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 65px;
            height: 65px;
            object-fit: contain;
          }
        }
      }
    }

    &--container {
      position: relative;

    }

    &--cardimg {
      width: 500px;
      height: 390px;
      border-radius: 9px;
      background-color: #E5E5E5;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--cardtext {
      width: 259px;
      height: max-content;
      max-height: 300px;
      position: absolute;
      top: 50px;
      left: -16%;
      transform: translateY(0%);
      border-radius: 9px 0px 0px 9px;
      background: #ffffff;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
      z-index: 1;

      .contain {
        height: 100%;
        padding: 28px 32px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .title {
          @include parragraph-normal(NexaSlab, 16px, 700, normal);
          text-align: center;
          margin-bottom: 12px
        }

        .list {
          .paragraph {
            @include parragraph-normal(Quicksand, 14px, 400, normal);

          }

          ul {
            padding-inline-start: 20px;

            li {
              @include parragraph-normal(Quicksand, 14px, 400, normal);
              line-height: normal;
            }
          }
        }

        .paragraph {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          margin-block: 10px;
        }

        .btn {
          @include parragraph-normal(Quicksand, 14px, 400, normal);

          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
          text-decoration: underline;
          text-underline-offset: 4px;

          &:hover {
            color: var(--color-gold);
          }
        }
      }


    }
  }

  &__slider {
    padding-left: 16px;
    // overflow: visible;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 320px;
      height: 510px;
      margin-right: 28px;
    }

    &--swiper {
      width: 100%;
      height: 100%;
      position: relative;

      .swiper-pagination-bullet {
        background: #FFFFFF;
        opacity: 1;
        width: 15px;
        height: 15px;
      }

      .swiper-pagination-bullet-active {
        background: #D69C4F;
      }

      .swiper-pagination-fraction,
      .swiper-pagination-custom,
      .swiper-horizontal>.swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 5%;
        left: 0;
        width: 100%;
      }

      .imgslide {
        width: 100%;
        height: 100%;

        &__logo {
          width: 80px;
          height: 80px;
          position: absolute;
          border-radius: 50%;
          top: 20px;
          right: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 65px;
            height: 65px;
            object-fit: contain;
          }
        }
      }
    }

    &--container {
      position: relative;

    }

    &--cardimg {
      width: 314px;
      height: 241px;
      border-radius: 9px;
      background-color: #E5E5E5;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .swiper-slide {
        margin-right: 0px; // Espacio en subslider entre img
      }
    }

    &--cardtext {
      width: 274px;
      height: 268px;
      position: absolute;
      // overflow: hidden;
      bottom: 10%;
      left: 20px;
      transform: translateY(0%);
      border-radius: 9px;
      background: #ffffff;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
      z-index: 1;

      .contain {
        height: 100%;
        padding: 18px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        p{
          @include parragraph-normal(Quicksand, 13px, 400, normal);
        }

        .title {
          @include parragraph-normal(NexaSlab, 16px, 700, normal);
          text-align: center;
          margin-bottom: 12px
        }

        .list {
          @include parragraph-normal(Quicksand, 12px, 400, normal);

          ul {
            padding-inline-start: 20px;

            li {
              @include parragraph-normal(Quicksand, 12px, 400, normal);
              line-height: normal;
            }
          }
        }

        .paragraph {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
          margin-block: 12px;
        }

        .btn {
          @include parragraph-normal(Quicksand, 12px, 400, normal);

          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }

    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: flex-end;
    padding-inline: 16px;
    gap: 44px;

    @media (min-width: 940px) {
      justify-items: center;
    }

    &--swiper {
      width: 100%;
      height: 100%;
      position: relative;

      .swiper-pagination-bullet {
        background: #FFFFFF;
        opacity: 1;
        width: 15px;
        height: 15px;
      }

      .swiper-pagination-bullet-active {
        background: #D69C4F;
      }

      .swiper-pagination-fraction,
      .swiper-pagination-custom,
      .swiper-horizontal>.swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 5%;
        left: 0;
        width: 100%;
      }

      .imgslide {
        width: 100%;
        height: 100%;

        &__logo {
          width: 80px;
          height: 80px;
          position: absolute;
          border-radius: 50%;
          top: 20px;
          right: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 65px;
            height: 65px;
            object-fit: contain;
          }
        }
      }
    }

    &--container {
      position: relative;
    }

    &--cardimg {
      width: 573px;
      height: 697px;
      border-radius: 9px;
      background-color: #E5E5E5;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (min-width: 1024px) {
        width: 769px;
      }
    }

    &--cardtext {
      width: 403px;
      height: max-content;
      max-height: 617px;
      position: absolute;
      top: 42px;
      left: -29%;
      // overflow: hidden;
      transform: translateY(0%);
      border-radius: 9px 0px 0px 9px;
      background: #ffffff;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
      z-index: 1;

      @media (min-width: 1024px) {
        // width: max-content;
        top: 0px;
        left: -16%;
        transform: translateY(10%);

      }

      .contain {
        height: 100%;
        padding: 32px 40px;
        display: flex;
        gap: 16px;
        flex-direction: column;
        // justify-content: space-between;

        .title {
          @include parragraph-normal(NexaSlab, 16px, 700, normal);
          margin-bottom: 12px
        }

        .txnormal {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          margin-bottom: 10px;
        }

        .list {
          @include parragraph-normal(Quicksand, 14px, 400, normal);

          ul {
            padding-inline-start: 20px;

            li {
              @include parragraph-normal(Quicksand, 14px, 400, normal);
              line-height: normal;

            }
          }
        }

        .paragraph {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          margin-block: 12px;
        }

        .paragraph2 {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
        }

        .more {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          padding-bottom: 20px;
          color: var(--color-gold);
          cursor: pointer;
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }
    }
  }

  &__sliderm {
    padding-inline: 16px;
    margin-bottom: 42px;
    width: 100%;
    height: 760px;
    position: relative;
    // overflow: visible;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 100%;
      height: 100%;
      // margin-right: 28px;
    }

    &--swiper {
      width: 100%;
      height: 100%;
      position: relative;

      .swiper-pagination-bullet {
        background: #FFFFFF;
        opacity: 1;
        width: 15px;
        height: 15px;
      }

      .swiper-pagination-bullet-active {
        background: #D69C4F;
      }

      .swiper-pagination-fraction,
      .swiper-pagination-custom,
      .swiper-horizontal>.swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 5%;
        left: 0;
        width: 100%;
      }

      .imgslide {
        width: 100%;
        height: 100%;

        &__logo {
          width: 80px;
          height: 80px;
          position: absolute;
          border-radius: 50%;
          top: 20px;
          right: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 65px;
            height: 65px;
            object-fit: contain;
          }
        }
      }
    }

    &--container {
      position: relative;
      width: 100%;
    }

    &--cardimg {
      width: 100%;
      max-width: 314px;
      height: 241px;
      border-radius: 9px;
      background-color: #E5E5E5;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .swiper-slide {
        margin-right: 0px; // Espacio en subslider entre img
      }
    }

    &--cardtext {
      width: 274px;
      height: auto;
      position: absolute;
      // overflow: hidden;
      top: 78%;
      left: 20px;
      transform: translateY(0%);
      border-radius: 9px;
      background: #ffffff;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
      z-index: 1;

      .contain {
        height: 100%;
        padding: 18px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .title {
          @include parragraph-normal(NexaSlab, 16px, 700, normal);
          margin-bottom: 12px
        }

        .txnormal{
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          margin-bottom: 10px;
        }

        .list {
          @include parragraph-normal(Quicksand, 14px, 400, normal);

          ul {
            padding-inline-start: 12px;

            li {
              @include parragraph-normal(Quicksand, 14px, 400, normal);
              line-height: normal;
            }
          }
        }

        .paragraph {
          @include parragraph-normal(Quicksand, 14px, 600, normal);
          margin-block: 12px;
        }

        .paragraph2 {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          cursor: pointer;

          &:first-letter {
            text-transform: uppercase;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        .btn {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
        }

        .more {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          padding-bottom: 20px;
          color: var(--color-gold);
          cursor: pointer;
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }

    }
  }
}

.restaurantcity__center {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
}

.restaurantcity__swiper {
  padding-top: 30px;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 320px;
    height: 900px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .restaurantcity__city {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #E5E5E5;
    position: relative;
    border-radius: 9px;
    margin: 10px;
    width: 300px;


    &--slider {
      height: 241px;
      position: relative;
      border-radius: 9px;
      overflow: hidden;

      .imgbg {
        width: 100%;
        height: 100%;
        object-fit: cover;

      }

      .imglogo {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    &--content {
      width: 274px;
      height: 590px;
      border-radius: 9px;
      background: #FFF;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
      position: absolute;
      top: 68%;
      left: 4%;
      transform: translateY(10%);
      overflow: hidden;

      .card {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: -webkit-fill-available;
        padding: 20px;
        color: black;


        .btn {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}