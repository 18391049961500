@import '../../atoms/text/styles.scss';

.tabhotel {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  &__section {
    width: 100%;
    display: grid;
    grid-template-columns: 3.5fr 1fr;
    gap: 26px;

    @media (min-width: 1024px) {
      max-width: 1220px;
    }

    @media (max-width: 1200px) {
      padding-inline: 16px;
    }

    &--descriptions {

      .onlytab {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        padding-top: 16px;
        margin-bottom: 40px;
        border-bottom: 1px solid #E5E5E5;


        &::-webkit-scrollbar {
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #D69C4F;
          border: none;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: #E6E6E6;
          border-radius: 10px;
        }

        div {
          scroll-snap-align: start;
          cursor: pointer;
          transition: background-color 0.3s ease;
          @include parragraph-normal(NexaSlab, 16px, 400, normal);

          &:hover,
          &.active {
            color: #D69C4F;
            border-bottom: 2.5px solid #D69C4F;
            display: inline;

            p {
              font-weight: 500;
              margin-bottom: 6px;
            }
          }

          &.inactive{
            color: #5F5F5F;
            border-bottom: 2.5px solid #E5E5E5;
            display: inline;

            p {
              font-weight: 400;
              margin-bottom: 6px;
            }
          }
        }

        &__tab {
          p {
            text-align: center;
            @include parragraph-normal(Quicksand, 10px, 400, normal);
            margin-bottom: 10px;
            width: 80px;
          }
        }
      }

      .onlytab.hidden {
        visibility: hidden;
      }

      .tabsh {
        display: flex;
        padding-left: 16px;
        padding-right: 16px;
        position: sticky;
        top: 84px;
        background-color: white;
        z-index: 1;

        div {
          color: #333;
          margin-right: 40px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            border-bottom: 2px solid #D69C4F;
            color: #D69C4F;

          }

          &.active {
            border-bottom: 3px solid #D69C4F;
            color: #D69C4F;
          }
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        gap: 20px;

        .list {
          display: flex;

          @include parragraph-normal(Quicksand, 14px, 400, normal);

          .listIcons {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 6px;
            padding-right: 20px;
            border-right: 2.5px solid rgba(214, 156, 79, 0.50);
            padding-left: 20px;

            &:first-child {
              padding-left: 0px;
            }

            &:last-child {
              padding-right: 0px;
              border-right: none
            }

          }
        }


        .items {

          .description {
            @include parragraph-normal(Quicksand, 14px, 400, normal);
            line-height: normal;
          }
        }

        .buttons {
          display: flex;
          gap: 40px;

          .button-icon {
            border-radius: 10px;
            border: 1.5px solid #D69C4F;
            background: #FFF;
            box-sizing: border-box;
            color: #D69C4F;
            cursor: pointer;
            display: inline-block;
            outline: none;
            padding: 2px 4px;
            position: relative;
            text-decoration: none;
            touch-action: manipulation;
            transition: box-shadow .2s, -ms-transform .1s, -webkit-transform .1s, transform .1s;
            user-select: none;
            width: 120px;
            max-height: 68px;

            .paragraph {
              @include parragraph-normal(NexaSlab, 14px, 400, normal);
              line-height: normal;
              text-align: center;
            }
          }

          .button-icon:focus-visible {
            box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
            transition: box-shadow .2s;
          }

          .button-icon:hover {
            background-color: var(--color-gold);
            color: white;

            &>svg use {
              fill: white;
              stroke: white;
            }
          }
        }
      }
    }

    &--container {
      // padding-left: 34px;
      display: flex;
      flex-direction: column;
      // justify-content: space-evenly;
      // gap: 10px;


      .tripadvisor {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        width: 100%;
        text-align: left;
        @include parragraph-normal(Quicksand, 12px, 400, normal);

        img {
          width: 76px;
          height: 76px;
          object-fit: cover;
        }
      }

      &--review {
        width: 100%;
        padding-left: 40px;
        border-left: 1px solid #E5E5E5;

        .night {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include parragraph-normal(Quicksand, 12px, 400, normal);

          span {
            @include parragraph-normal(NexaSlab, 35px, 700, normal);
          }
        }

        .igv {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
          margin-bottom: 10px;
          text-align: right;
        }
      }

    }
  }
}

.slider-container {
  position: fixed;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  width: 100%;

  .swiper {
    width: auto;
  }
  
  .swiper-slide {
    width: auto;
  }
  
  .slider-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  
  .slider-content {
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}



.mapsact {
  // height: 100%;
  // width: calc(100% - 80px);
  // padding: 20px 40px;

  height: 100%;
  width: 100%;
  gap: 10px;
  display: inline-block;

  .mapcontainer {
    width: 100%;
    height: 100%;
    // padding-top: 28px;
    // padding-bottom: 42px;

    @media (min-width: 768px) {
      height: 437px;
    }

    .tooltipmap{
      width: auto;
      height: 100px;
      padding: 10px;
      background-image: url(../../../assets//icons/tooltip.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: -10%;

      p {
        @include parragraph-normal(Quicksand, 10px, 600, normal);
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 2px;
      }
    }
  }
}



.tabhotelm {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .mapcontainer {
    width: 100%;
    height: 437px;
    // padding-top: 28px;
    // padding-bottom: 42px;
  }

  &__section {
    width: 100%;

    .container {
      @include parragraph-normal(Quicksand, 14px, 400, normal);
      line-height: normal;

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding-top: 24px;
      }

      .itesm {
        padding-top: 1rem;
        padding-bottom: 1rem;

        ul {
          padding-inline-start: 26px;

          li {
            font-family: Sofia;
            font-weight: 100;
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }

        p {

        ul {
          padding-inline-start: 26px;

          li {
            font-family: Sofia;
            font-weight: 100;
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }
        }
      }

      .buttons {
        padding-bottom: 1rem;

        .button-icon {
          border-radius: 10px;
          border: 1.5px solid #D69C4F;
          background: #FFF;
          box-sizing: border-box;
          color: #D69C4F;
          cursor: pointer;
          display: inline-block;
          outline: none;
          padding: 2px 4px;
          position: relative;
          text-decoration: none;
          touch-action: manipulation;
          transition: box-shadow .2s, -ms-transform .1s, -webkit-transform .1s, transform .1s;
          user-select: none;
          width: 120px;
          max-height: 68px;

          p {
            margin: 0;
            font-family: NexaSlab;
            font-size: .875rem;
            text-align: center;
          }
        }

        .button-icon:focus-visible {
          box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
          transition: box-shadow .2s;
        }

        .button-icon:hover {
          background-color: #D9D9D9;
          border: 1.5px solid #D9D9D9;
        }
      }

      .reviews {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: #BEBEBE;

        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #BEBEBE;

        .list {
          display: flex;
          flex-direction: column;
          padding-top: 1rem;
          padding-bottom: 1rem;
          gap: 1rem;

          .line {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;

            img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              object-fit: contain;
            }

            .price {
              @include parragraph-normal(NexaSlab, 20px, 700, normal);
            }

            .paragraph {
              @include parragraph-normal(Quicksand, 12px, 400, normal);
              line-height: normal;
            }
          }
        }
      }
    }

    &--container {
      padding: 20px;
      border-left: 1px solid #E5E5E5;

      @media (min-width: 1024px) {
        padding-bottom: 40px;
      }

      .tripadvisor {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        img {
          width: 76px;
          height: 76px;
          border-radius: 50%;
        }
      }

      .review {
        p {
          margin: 0;
        }

        span {
          color: #000;
          font-family: NexaSlab;
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0;
        }
      }
    }
  }

  .btnstabs__swiper {
    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 122px;
      height: 52px;
      padding: 10px;
    }
  }
}


.btnnext {
  cursor: pointer;
  background: var(--color-gold);
  border-radius: 10px;
  border: none;
  font-size: 1rem;
  text-align: center;
  color: white;
  padding: 14px 30px;
  display: block;

  &:hover {
    background: #c6914c;
  }

  &:active {
    background: #bb8b4c;
  }

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
}

.custom-map {
  .pin-container {
    position: relative;
    text-align: center;
  }

  .pin-text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateY(-20%);

    img {
      height: 16px;
    }

    p {
      margin: 0;
      font-family: NexaSlab;
      font-weight: 700;
      font-size: .75rem;
      line-height: 1.2;
      text-align: center;
    }
  }
}

.transport {
  width: 100%;
  padding: 10px;

  .title {
    @include parragraph-normal(NexaSlab, 25px, normal, normal);
    text-align: center;
    margin-bottom: 20px;
  }

  .paragraph {
    @include parragraph-normal(Quicksand, 14px, 400, normal);
    margin-bottom: 20px;
    line-height: normal;
  }

  .table-container {
    @media (max-width: 768px) {
      max-width: 100%;
      overflow-x: auto;
    }

    table {
      text-align: center;

      th {
        @include parragraph-normal(Quicksand, 12px, 400, normal);
      }

      td {
        @include parragraph-normal(Quicksand, 12px, 400, normal);
      }
    }

  }

  .table-container::-webkit-scrollbar-track {
    background: #efeaea;
  }

  .icontext {
    @include parragraph-normal(Quicksand, 14px, 400, normal);
    line-height: normal;
    padding-block: 20px 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .info {
      padding-top: 10px;

      ul {
        padding-inline-start: 26px;

        li {
          font-family: Quicksand;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.5rem;
        }
      }

      p {

      ul {
        padding-inline-start: 26px;

        li {
          font-family: Quicksand;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.5rem;
        }
      }
      }
    }
  }
}

.btn-google {
  cursor: pointer;
  background: var(--color-gold);
  border-radius: 7px;
  border: none;
  padding: 0px 12px;
  height: 45px;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: .875rem;
    line-height: .875rem;
    text-align: center;
    font-family: NexaSlab;
    font-weight: 700;
    color: white;
  }
}

.subtitle-maps {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;

  .title {
    @include parragraph-normal(NexaSlab, 25px, 700, normal);
    text-align: center;
  }

  .paragraph {
    @include parragraph-normal(Quicksand, 12px, 400, normal);
    line-height: normal;
    text-align: center;
  }

  .place-city {
    @include parragraph-normal(Quicksand, 16px, 400, normal);
  }

  .icon-left {
    justify-content: flex-start;
    align-items: center;
  }
}

.transporta {
  width: 100%;
  height: 100%;
}

.enlace360 {
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    height: 600px;
  }
}

.la {
  display: inline-block;
  // font: 16px/1 IconCasaAndina;
  font-family: IconCasaAndina;
  // font-size: inherit;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased
}

.la-wifi:before {
  content: "\f37c"
}

.la-laptop:before {
  content: "\f259"
}

.cardvalue{
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__container{
    position: relative;
    top: 0;
    right: 16px;
    transform: translateY(-55%);
    width: auto;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 10px 16px;
    color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);

    .title{
      @include parragraph-normal(NexaSlab, 14px, 400, normal);
      line-height: normal;
    }

    .subtitle{
      @include parragraph-normal(NexaSlab, 14px, 400, normal);
      text-transform: uppercase;
    }

    .calificacion{
      @include parragraph-normal(NexaSlab, 24px, 700, normal);
    }
  }

  &__sections{
    .swiper {
      width: 100%;
      height: 100%;
    }
  
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 40px;
    }

    &--text{
      p {
        text-align: center;
        @include parragraph-normal(Quicksand, 12px, 400, normal);
        margin-bottom: 10px;
      }
    }
  }

  &__sectionstab{
    position: fixed;
    top: 65px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    width: 100%;
    
    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      width: 16%;
    }

    &--text{
      p {
        text-align: center;
        @include parragraph-normal(Quicksand, 12px, 400, normal);
        margin-bottom: 10px;
      }
    }
  }
}

.cardvalue__sections.visible {
  display: block;
}

.cardvalue__sections.hidden {
  // display: none;
  visibility: hidden;
}

.cardvalueselect {
  border-bottom: 1.5px solid #F0F0F0;
  height: 100%;
  padding: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-left: 1px solid #F2F2F2;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: 1px solid #F2F2F2;
  }

  &:hover {
    border-bottom: 1.5px solid #D69C4F;

    p {
      color: #D69C4F;
    }
  }

  p {
    @include parragraph-normal(Quicksand, 14px, 400, normal);
    color: #5F5F5F;
    text-align: center;
    // padding-block: 12px;
  }

}

.cardvalueselect.active {
  border-bottom: 1.5px solid #D69C4F;

  p {
    color: #D69C4F;
    font-weight: 600;
  }
}