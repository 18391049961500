/*
  CONTENT STRUCTURE:
    display:      d-  
*/

$display: (    
    block: block,
    inline: inline,
    run-in: run-in,
    flow: flow,
    flow-root: flow-root,
    table: table,
    flex: flex,
    grid: grid,
    contents: contents,
    none: none,
    ruby: ruby,
    subgrid: subgrid,
    block-flow: block flow,
    inline-block: inline-block,
    inline-table: inline-table,
    inline-flex: inline-flex,
    inline-grid: inline-grid,
    flex-run-in: flex run-in,
    list-item: list-item,
    list-item-block: list-item block,
    list-item-inline: list-item inline,
    list-item-flow: list-item flow,
    list-item-flow-root: list-item flow-root,
    list-item-block-flow: list-item block flow,
    list-item-block-flow-root: list-item block flow-root,
    flow-list-item-block: flow list-item block,
    table-row-group: table-row-group,
    table-header-group: table-header-group,
    table-footer-group: table-footer-group,
    table-row: table-row,
    table-cell: table-cell,
    table-column-group: table-column-group,
    table-column: table-column,
    table-caption: table-caption,
    ruby-base: ruby-base,
    ruby-text: ruby-text,
    ruby-base-container: ruby-base-container,
    ruby-text-container: ruby-text-container,   
    heredar: heredar,
    initial: initial,
    unset: unset,
);

@each $name, $value in $display {
    /* DISPLAY (d-) */
    .d-#{ $name } {
        display: #{$value} !important;
    }
}