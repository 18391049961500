
.custom-slider-nav{
  margin-bottom: 10px;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  .custom-slider-navm {
    .activitycardcontainer {
      border-right: 1px solid #F2F2F2;
    }
    .activetext {
      color: #D69C4F;
    }
    
    .inactivetext {
      color: #7E7E7E;
    }
  }
}

// .slidediv {
//   position: relative;
//   display: inline-block;
//   overflow: hidden;
//   width: 100%;

//   &__tag {
//     position: absolute;
//     top: 25px;
//     right: 0px;

//     color: #fff;
//     font-family: NexaSlab;
//     font-weight: 700;
//     font-size: 1.125rem;

//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 140px;
//     height: 42px;
//     background-color: #002855;
//     clip-path: polygon(100% 0%, 7.15% 0%, 0% 100%, 100% 100%);

//     img {
//       max-width: 100%;
//       height: auto;
//       object-fit: cover;
//     }
//   }
// }
