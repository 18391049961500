@import '../../atoms/text/styles.scss';

.listheader {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-inline: 16px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      padding-inline: 0;
    }

    &--text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;

      @media (min-width: 768px) {
        width: 34%;
        align-items: normal;
      }

      .title {
        font-size: 20px;
        line-height: normal;
        font-family: NexaSlab;
        font-weight: 700;
        @media (min-width: 425px) {
          font-size: 28px;
        }
      
        @media (min-width: 1024px) {
          font-size: 36px;
        }
      }

      .description {
        @include parragraph-normal(NexaSlab, 18px, 400, normal);
        line-height: normal;
      }
    }

    &--content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      gap: 10px 16px;


      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        justify-items: normal;
        width: 60%;
      }

      .column {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        .list{
          @include parragraph-normal(Quicksand, 14px, 400, normal);
        }
      }
    }
  }
}