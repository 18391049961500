.associatedhotels {
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    padding-block: 25px;
    padding-inline: 16px;

    @media (min-width: 768px) {
      padding-block: 60px;
      padding-inline: 120px;
    }

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--section {
      font-family: "Quicksand";

      h1 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;

        @media (min-width: 768px) {
          font-size: 30px;
          margin-bottom: 32px;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: justify;

        @media (min-width: 768px) {
          font-size: 18px;
          margin-bottom: 32px;
        }
      }

      span {
        font-weight: 700;
      }

      a {
        color: #D69C4F;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

    }
  }
}

.insite {

}