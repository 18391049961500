@import '../../../components/atoms/text/styles.scss';

.quota{
  padding-inline: 16px;

  @media (min-width: 1200px){
    padding-inline: 0px;
  }

  .description{
    @include parragraph-normal(NexaSlab, 18px, 300, normal);
    line-height: normal;
    margin-bottom: 16px;
  }

  .list{
    @include parragraph-normal(NexaSlab, 16px, 300, normal);
    line-height: normal;

    .paragraph{
      color: red;
    }
  }


  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    flex-direction: column;
    @media (min-width: 768px) {
      gap: 20px;
      flex-direction: row;
    }

    p {
      @include parragraph-normal(NexaSlab, 20px, 700, normal);
    }

    &--restaurantes {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 20px;

      @media (min-width: 768px) {
        flex-direction: row;
        gap: 40px;
      }

      
      .circle {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
        @include scale(110%);
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
        
        @media (min-width: 768px) {
          width: 122px;
          height: 122px;
          
          img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
}