@import '../../../atoms/text/styles.scss';

.flip-unit {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  @include parragraph-normal(NexaSlab, 28px, 700, normal);
}

.top,
.bottom,
.flip-unit .top-flip,
.flip-unit .bottom-flip {
  height: 16px;
  width: 34px;
  line-height: 1;
  padding: 2px;
  overflow: hidden;

  @media (max-width: 400px) {
    width: 29px;
  }
}

.bottom {
  display: flex;
  align-items: flex-end;
}

.top,
.flip-unit .top-flip {
  display: flex;
  justify-content: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.bottom,
.flip-unit .bottom-flip {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.bottom {
  &:after{
    top: 50%;
  }
}

.flip-unit .bottom-flip {
  z-index: 2;

  &:after{
    z-index: 3;
  }
}

.top-flip {
  position: absolute;
  width: 100%;
}

.bottom-flip {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: rotateX(90deg);
}

.flip-unit.flip .top-flip {
  animation: flip-top 250ms ease-in forwards;
  transform-origin: bottom;
}

@keyframes flip-top {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(90deg);
  }
}

.flip-unit.flip .bottom-flip {
  animation: flip-bottom 250ms ease-out forwards;
  transform-origin: top;
  animation-delay: 250ms;
}

@keyframes flip-bottom {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}