@import '../../atoms/text/styles.scss';

.destinations {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  &__description {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;

    @media (min-width: 1200px){
      max-width: 1220px;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }
}