@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');
@import '../../atoms/text/styles.scss';

.navbar {
  background-color: white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: background-color 0.3s;

  .navbar-item-destacado {
    border: 2px solid var(--color-gold);
    color: var(--color-gold) !important;
    font-weight: 700;
    border-radius: 10px;
    padding: 10px;
    z-index: 2;

    &::after {
      height: 0px !important;
    }

    &:hover {
      background-color: var(--color-gold);
      color: white !important;
      text-decoration: none;
    }
  }

  .navbar-item-destacado.our-hotels-button{
    margin-left: auto;
    font-weight: 400;
    cursor: pointer;

    @media (max-width: 1200px) {
      width: 170px;
      margin-left: 0;
      font-size: 14px;
    }
  }

  .navmobile {
    &.scrolled {
      background: black;
      position: fixed;
      width: 100%;
      top: 0;
    }

    &__container {
      &--open {
        padding: 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .imgcenter {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          cursor: pointer;
        }
      }

      &--scroll {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      &--overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2px);
        z-index: 100;
      }

      &--modal {
        position: fixed;
        top: 0;
        left: 0;
        // width: calc(100% - 35%);
        // min-width: 300px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
        z-index: 200;
        -webkit-animation: scale-up-hor-left 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-hor-left 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;


        @-webkit-keyframes scale-up-hor-left {
          0% {
            -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
          }

          100% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
          }
        }

        @keyframes scale-up-hor-left {
          0% {
            -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
          }

          100% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
          }
        }

        .sidem {
          padding: 20px;
          overflow-y: auto;
          height: 100%;
          animation: showslide 0.7s ease-in-out;
        }

        .sidem__modal {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 10px;
          height: 100%;

          .closes {
            display: flex;
            justify-content: space-between;

            svg {
              cursor: pointer;

              &:hover {
                transform: scale(1.2);
              }
            }

            &__content {
              display: flex;
              justify-content: flex-start;
              gap: 10px;
            }
          }


          .buttons {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 10px;

            &__select {
              border-top: 1px solid #f2f2f2;
              border-bottom: 1px solid #f2f2f2;

              &--sections {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .options {
                  border-right: 1px solid #f2f2f2;
                  padding-right: 10px;

                  select {
                    border: none;
                    width: auto;
                    background-color: transparent;
                    font-size: 16px;
                    font-family: Sofia;
                    font-weight: 100;
                  }
                }

                .icons {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 8px 12px;
                }
              }

              &__options {
                padding-top: 10px;
                padding-bottom: 10px;

                &:not(:last-child) {
                  padding-right: 12px;
                  border-right: 1px solid #f2f2f2;
                }
              }
            }

            &__list {
              max-height: 460px;
              padding-bottom: 10px;
              color: black;
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 7px;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #d69c4f;
                border: none;
                border-radius: 10px;
              }

              &::-webkit-scrollbar-track {
                background-color: #e6e6e6;
                border-radius: 10px;
              }

              ul {
                list-style: none;
                padding: 0;
                margin: 0;
              }

              li {
                font-family: NexaSlab;
                font-weight: 700;
                font-size: 0.875rem;
                cursor: pointer;
              }
            }
          }

          .buttonclose {
            padding-top: 15px;
            border-top: 1px solid #f2f2f2;
          }

          .items {


            &__life {
              background-color: #F5F5F5;
              border-block: 0.5px solid #CBCBCB;
              padding: 14px;

              &--container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 6px;
              }

              &--text {
                font-family: NexaSlab;

                .title {
                  font-size: 12px;
                  font-weight: 700;
                }

                .subtitle {
                  font-size: 12px;
                  font-weight: 400;
                  color: #D69C4F;
                  cursor: pointer;
                }
              }
            }

            &__links {
              padding: 14px 0;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              color: #D69C4F;
              border-bottom: 1px solid #D9D9D9;

              div {
                display: flex;
                align-items: center;
                padding-inline: 10px;

                &:not(:last-child) {
                  border-right: 1px solid #D9D9D9;
                }
              }

              select {
                border: none;
                color: #D69C4F;
                outline: none;
                background-color: transparent;
                font-family: NexaSlab;
                text-transform: uppercase;
                font-size: 12px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-image: url('../../../assets/icons/arrow_down.png');
                background-repeat: no-repeat;
                background-position: right center;
                padding-left: 4px;
                padding-right: 20px;
                cursor: pointer;

                option {
                  color: black;
                  background-color: white;
                }

                &.listscrolled {
                  color: white;
                }
              }
            }

            &__pages {

              // div {
              //   border-top: 1px solid #D9D9D9;
              // }

              .dropdown-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 14px;
                text-transform: uppercase;
                border-bottom: 1px solid #D9D9D9;
                font-family: Quicksand;
                font-size: 12px;


                &:hover {
                  background-color: #F5F5F5;
                  cursor: pointer;
                }

              }

              .dropdown-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 14px;
                border-bottom: 1px solid #D9D9D9;
                margin-left: 20px;

                &:hover {
                  background-color: #F5F5F5;
                  cursor: pointer;
                }
              }

              .dropdown-hotel {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 14px 0px;
                border-bottom: 1px solid #D9D9D9;
                font-size: 12px;
                gap: 6px;
                margin-left: 40px;

                &:hover {
                  background-color: #F5F5F5;
                  cursor: pointer;
                }
              }

              .dropdown-hotel2 {
                font-size: 12px;
                margin-left: 40px;
              }

              .dropdown-text {
                display: flex;
                width: 50%;
                text-transform: uppercase;
                font-family: Quicksand;
                font-size: 12px;
              }

              .dropdown-subtext {
                color: #B3B3B3;
                font-family: Quicksand;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.disable-scroll {
  overflow: hidden;
}

.navweb.white {
  position: absolute;
  background: white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

  &.scrolled {
    background: white;
    position: fixed;
  }
}

.navweb.white .buttons .title,
.navweb.white .buttons .phone,
.navweb.white .buttons .phone2 p {
  color: black
}

.navweb.white .list .navbar-list .navbar-item.normal,
.navweb.white .dropdown-content__container .tab-content .hoteles-content1 {
  color: #000;

  &.active {
    color: #d69c4f;
    border-bottom: 1px solid currentColor;
  }
}

.navweb.white .dropdown-content {
  background-color: white;

  &__container {


    .tabs {

      .tab {
        color: black;

        &.active {
          color: black;
          font-weight: 600;
          background-color: #F5F5F5;
          border-bottom: 4px solid #D69C4F;
        }
      }
    }
  }
}

.navweb.white:hover {
  background-color: white;
}

.navweb.white .dropdown-content__container .tab-content .destinos-container .destinos-list.single-column .destination:hover,
.navweb.white .dropdown-content__container .tab-content .destinos-container .destinos-list.single-column .destination.active {
  color: black;
  background-color: #F5F5F5;
  border-bottom: 1.5px solid #D69C4F;
}

.navweb.white .dropdown-content__container .tab-content .hoteles-content2 .category,
.navweb.white .dropdown-content__container .tab-content .hoteles-content2 .category2 {
  color: black;

}

.navweb.white .dropdown-content__container .tab-content .destinos-container .destinos-list.single-column .destination.inactive {
  color: #F5F5F5;
}


.navweb.white .dropdown-content__container .tab-content .destinos-container .destinos-list {

  .destination-name {
    color: #000;
  }
}

.navweb.white .dropdown-content__container .tab-content .destinos-container .destinos-list.grid-column .destination:hover,
.navweb.white .dropdown-content__container .tab-content .destinos-container .destinos-list.grid-column .destination.active {
  background-color: #F5F5F5;
}

.navweb.white .activenav,
.navweb.white .buttons select {
  color: black;
}

.navweb {
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0.9) 3.13%,
      rgba(0, 0, 0, 0.3) 77.6%,
      rgba(0, 0, 0, 0) 100%,
      rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media (min-width: 1200px) {
    padding: 16px 20px 12px 20px;
  }

  &:hover {
    background-color: black;
  }

  &.scrolled {
    background: black;
    position: fixed;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--nav {
      display: flex;
      // gap: 64px;

      img {
        width: 100%;
        height: 40px;
        object-fit: contain;
      }

      @media (max-width: 1200px) {
        display: none;
      }
    }

    &--overlay {
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      background-color: #0003;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }

    &--mob {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 320px;
      max-height: 100vh;
      background-color: white;

      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    // position: relative;
    font-family: Quicksand;
    margin-right: 90px;

    .navbar-list {
      list-style: none;
      padding: 0;
      display: flex;
      gap: 40px;
      // border-bottom: 1px solid #D69C4F;

      .navbar-item {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        transition: color 0.4s, border-bottom 0.4s;
        height: 34px;
        display: flex;
        align-items: center;

        &:hover .dropdown {
          display: block;
        }


        &.normal {
          color: white;
          cursor: pointer;
          position: relative;

          &:hover {
            color: #d69c4f;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            transform: scaleX(0);
            transition: transform 0.3s ease;
          }

          &:hover::after {
            transform: scaleX(1);
          }

        }

        &.inverso {
          color: white;
          cursor: pointer;

          &:hover {
            color: #d69c4f;
            border-bottom: 1px solid currentColor;
          }
        }

        &.active {
          color: #d69c4f;
          border-bottom: 1px solid currentColor;
        }

        .dropdown {
          position: absolute;
          top: 60px;
          left: 0;
          background-color: #141614;
          display: none; // cambiar
          width: 100%;
          padding: 40px;
          -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

          @-webkit-keyframes scale-up-ver-top {
            0% {
              -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
              -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
            }

            100% {
              -webkit-transform: scaleY(1);
              transform: scaleY(1);
              -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
            }
          }

          @keyframes scale-up-ver-top {
            0% {
              -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
              -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
            }

            100% {
              -webkit-transform: scaleY(1);
              transform: scaleY(1);
              -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
            }
          }

          .tabs {
            display: flex;
            max-width: 1200px;
            margin: 0 auto;
            justify-content: flex-start;
            border-bottom: 1px solid #D69C4F;
            margin-bottom: 24px;
            font-family: Quicksand;
            font-size: 16px;
            color: white;

            .tab {
              cursor: pointer;
              padding: 10px 20px;
              text-align: center;
              border-bottom: none;

              &.active {
                color: white;
                font-weight: 600;
                background-color: #222;
                border-bottom: 4px solid #D69C4F;
              }
            }
          }

          .tab-content {

            .destinos-container {
              max-width: 1200px;
              display: flex;
              margin: 0 auto;

              .destinos-list {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                width: 100%;
                gap: 6px;

                .flex {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  color: white;
                }

                &.single-column {
                  border-right: 1px solid #444;
                  grid-template-columns: 1fr;
                  padding-right: 12px;
                  width: 260px;
                  max-height: 320px;
                  overflow-y: auto;
                }

                .destination {
                  cursor: pointer;
                  width: 90%;
                  padding: 10px;
                  border: 1px solid transparent;
                  transition: all 0.3s ease;
                  color: #4E4E4E;

                  &:hover,
                  &.active {
                    border-bottom: 1.5px solid #D69C4F;
                    color: #fff;
                    background-color: #222;
                  }

                  .destination-name {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    font-family: Quicksand;
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: space-between;

                    div {
                      display: flex;
                      align-items: center;
                      gap: 6px;
                    }


                  }
                }
              }
            }

            .hoteles-content2 {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              max-width: 1200px;
              margin: 0 auto;
              gap: 30px 40px;
              padding: 16px;

              .text {
                font-family: Quicksand;
                font-size: 12px;
                margin-bottom: 16px;
              }

              .textHotel {
                font-family: Quicksand;
                text-transform: uppercase;
                margin-bottom: 16px;
              }

              .category {
                box-sizing: border-box;
                min-width: 200px;
                color: white;

                .category-title {
                  font-family: Quicksand;
                  text-transform: uppercase;
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 10px;
                  text-align: center;
                  // border-bottom: 4px solid currentColor;
                  padding-bottom: 5px;
                }

                .hotels-list {
                  list-style-type: none;
                  padding: 0;
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  gap: 20px 16px;

                  .hotel-item {
                    box-sizing: border-box;
                    font-family: Quicksand;
                    text-transform: uppercase;
                    font-size: 14px;

                    &:hover {
                      color: #D69C4F;
                      border-bottom: 1.5px solid currentColor;
                      cursor: pointer;
                    }
                  }
                }
              }
            }

            .hoteles-content1 {
              // display: flex;
              // flex-direction: column;
              color: white;
              width: calc(100% - 280px);
              padding-top: 20px;
              padding-inline: 50px;

              &__close {
                display: flex;
                justify-content: space-between;
                padding-bottom: 16px;

                .text {
                  font-family: Quicksand;
                  font-size: 14px;
                  color: #CCCCCC;
                }

                .closex {
                  cursor: pointer;

                  &:hover {
                    scale: 1.2;
                  }
                }
              }

              .hotelname {
                font-family: Quicksand;
                font-weight: 600;
                font-size: 16px;
                color: #D69C4F;
                text-transform: uppercase;
                margin-bottom: 16px;
                border-bottom: 1.5px solid currentColor;
                width: max-content;
              }

              .selecthotel {
                font-family: Quicksand;
                font-size: 14px;
                color: #CCCCCC;
                margin-bottom: 16px;
              }

              .category {
                padding: 10px;
                margin: 10px;
                box-sizing: border-box;
                min-width: 200px;
                color: white;

                .category-title {
                  font-family: Quicksand;
                  text-transform: uppercase;
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 10px;
                  text-align: center;
                  // border-bottom: 4px solid currentColor;
                  padding-bottom: 5px;
                }

              }

              .hotels-list {

                li {
                  list-style: none;
                  padding-block: 6px;
                }

                .hotel-item {
                  font-family: NexaSlab;
                  font-size: 14px;

                  &:hover {
                    width: max-content;
                    color: #D69C4F;
                    border-bottom: 1px solid currentColor;
                    cursor: pointer;

                  }

                }
              }


            }

          }
        }

      }
    }
  }

  .dropdown-content {
    position: absolute;
    left: 0;
    top: 68px;
    width: 100%;
    z-index: 1;
    background-color: #141416;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

    -webkit-animation: scale-up-ver-top 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-ver-top 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    @-webkit-keyframes scale-up-ver-top {
      0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }

      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
    }

    @keyframes scale-up-ver-top {
      0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }

      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
    }

    &__container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 32px 20px;
      // min-height: 352px;
      animation: showslide 0.7s ease-in-out;

      .tabs {
        display: flex;
        margin: 0 auto;
        justify-content: flex-start;
        // border-bottom: 1px solid #D69C4F;
        margin-bottom: 20px;
        font-family: Quicksand;
        font-size: 15px;
        color: white;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s ease, visibility 0.4s ease;

        &.visible {
          opacity: 1;
          visibility: visible;
        }


        .tab {
          cursor: pointer;
          padding: 10px 20px;
          text-align: center;
          border-bottom: none;

          &.active {
            color: white;
            font-weight: 600;
            background-color: #222;
            border-bottom: 2px solid #D69C4F;
          }
        }
      }

      .tab-content {

        .destinos-container {
          display: flex;
          height: 100%;
          min-height: 306px;

          .destinos-list {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            width: 100%;
            grid-auto-flow: column;

            .flex {
              display: flex;
              justify-content: space-between;
              align-items: center;
              // color: white;
            }

            &.single-column {
              border-right: 1px solid #444;
              grid-template-columns: 1fr;
              padding-right: 12px;

              width: 260px;
              min-height: 230px;
              overflow-y: auto;

              .destination {
                cursor: pointer;
                width: 86%;
                height: 46px;
                padding: 10px;
                border: 1px solid transparent;
                transition: all 0.3s ease;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover,
                &.active {
                  border-bottom: 1.5px solid #D69C4F;
                  color: #fff;
                  background-color: #222;
                  gap: 6px;
                }

                &.inactive {
                  color: #4E4E4E;
                  display: none;
                }
              }
            }

            &.grid-column {

              color: white;

              .destination {
                cursor: pointer;
                width: 70%;
                padding: 10px;
                border: 1px solid transparent;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;

                &:hover,
                &.active {
                  border-bottom: 1.5px solid #D69C4F;
                  color: #fff;
                  background-color: #222;
                }
              }
            }

            .destination-name {
              gap: 10px;
              font-family: Quicksand;
              font-size: 13px;
              font-weight: 300;
              text-transform: uppercase;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              div {
                display: flex;
                align-items: center;
                gap: 6px;
              }


            }
          }
        }

        .hoteles-content2 {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          max-width: 1200px;
          margin: 0 auto;
          gap: 20px 40px;
          padding-top: 10px;

          .text {
            font-family: Quicksand;
            font-size: 12px;
            margin-bottom: 16px;
          }

          .textHotel {
            font-family: Quicksand;
            text-transform: uppercase;
            margin-bottom: 16px;
          }

          .category {
            box-sizing: border-box;
            min-width: 200px;
            color: white;

            .category-title {
              font-family: Quicksand;
              text-transform: uppercase;
              font-size: 13px;
              font-weight: 500;
              margin-bottom: 20px;
              text-align: center;
              // border-bottom: 4px solid currentColor;
              padding-bottom: 10px;
              cursor: pointer;

              &:hover {
                // color: currentColor;
                font-weight: 700;
              }
            }

            .hotels-list {
              display: flex;
              flex-direction: row;
              gap: 16px;

              ul {
                width: 50%;
                padding: 0;
                list-style: none;
                margin: 0;

                li {
                  @include parragraph-normal(Quicksand, 13px, 500, normal);
                  line-height: normal;
                  text-transform: uppercase;
                  box-sizing: border-box;
                  list-style: none;
                  margin-bottom: 16px;

                  &:hover {
                    color: #D69C4F !important;
                    cursor: pointer;
                  }
                }
              }
            }
          }

          .category2 {
            box-sizing: border-box;
            min-width: 200px;
            color: white;

            .category-title2 {
              font-family: Quicksand;
              text-transform: uppercase;
              font-size: 13px;
              font-weight: 500;
              margin-bottom: 16px;
              text-align: center;
              padding-bottom: 10px;
              cursor: pointer;

              &:hover {
                font-weight: 700;
                color: #D69C4F;
              }
            }

            .hotels-list2 {
              display: flex;
              flex-direction: row;
              gap: 16px;

              ul {
                width: 50%;
                padding: 0;
                list-style: none;
                margin: 0;

                li {
                  @include parragraph-normal(Quicksand, 13px, 500, normal);
                  line-height: normal;
                  text-transform: uppercase;
                  box-sizing: border-box;
                  list-style: none;
                  margin-bottom: 10px;

                  &:hover {
                    color: #D69C4F !important;
                    // border-bottom: 1.5px solid #D69C4F;
                    cursor: pointer;
                  }
                }
              }

            }
          }
        }

        .hoteles-content1 {
          // display: flex;
          // flex-direction: column;
          color: white;
          width: calc(100% - 280px);
          padding-top: 20px;
          padding-inline: 50px;

          &__close {
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;

            .text {
              font-family: Quicksand;
              font-size: 14px;
              color: #CCCCCC;
            }

            .closex {
              cursor: pointer;

              &:hover {
                scale: 1.2;
              }
            }
          }

          .hotelname {
            font-family: Quicksand;
            font-weight: 600;
            font-size: 16px;
            color: #D69C4F;
            text-transform: uppercase;
            margin-bottom: 16px;
            width: max-content;
            cursor: pointer;

            // &:hover {
            //   border-bottom: 1.5px solid currentColor;
            // }
          }

          .selecthotel {
            font-family: Quicksand;
            font-size: 14px;
            color: #CCCCCC;
            margin-bottom: 16px;
          }

          .category {
            padding: 10px;
            margin: 10px;
            box-sizing: border-box;
            min-width: 200px;
            color: white;

            .category-title {
              font-family: Quicksand;
              text-transform: uppercase;
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 10px;
              text-align: center;
              // border-bottom: 4px solid currentColor;
              padding-bottom: 5px;
            }
          }

          .hotels-list {
            li {
              list-style: none;
              padding-block: 6px;
            }

            .hotel-item {
              font-family: NexaSlab;
              font-size: 14px;
              width: max-content;

              &:hover {
                color: #D69C4F;
                // border-bottom: 1px solid currentColor;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .activenav {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: #d69c4f;
      border-bottom: #d69c4f 2px solid;
    }
  }

  .listscrolled .activenav {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: #d69c4f;
      border-bottom: #d69c4f 2px solid;
      transition: all 0.1s ease;
    }
  }

  .active {
    text-decoration: none;
    color: #d69c4f;
    // border-bottom: #d69c4f 2px solid;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 20px;

    select {
      border: none;
      color: white;
      outline: none;
      background-color: transparent;
      font-family: NexaSlab;
      text-transform: uppercase;
      font-size: 12px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url('../../../assets/icons/arrow_down.png');
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 12px;
      padding-right: 20px;
      cursor: pointer;

      option {
        color: black;
        background-color: white;
      }

      &.listscrolled {
        color: white;
      }
    }

    .icon__select {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }

    .icontext {
      font-size: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px
    }

    .title {
      color: white;
      font-size: 12px;
    }

    .phone {
      color: white;
      cursor: pointer;
      font-size: 12px;

      &:hover {
        color: #d69c4f;
        text-decoration: underline;
      }
    }

    .activeReservation {
      color: #d69c4f;
    }

    .phone2 {
      display: flex;

      hr {
        margin-inline: 4px;
      }

      p {
        color: white;
        cursor: pointer;
        font-size: 12px;

        &:hover {
          color: #d69c4f;
          text-decoration: underline;
        }
      }
    }
  }
}

.boxSearch {
  // position: absolute;
  // width: 100%;

  // &__container {
  //   position: relative;
  //   background-color: white;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   // min-height: 300px;
  //   height: max-content;
  //   box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

  //   animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  // }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
  z-index: 200;
  // -webkit-animation: scale-up-hor-left 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  // animation: scale-up-hor-left 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  @media (min-width:768px) {
    display: none;
  }

  &__container {
    padding: 20px;
    // overflow-y: auto;
    height: 100%;
    // animation: showslide 0.7s ease-in-out;
  }

}

@keyframes slide-bottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0px);
  }
}

.searchboxgeneral {
  // padding: 20px 24px;
  // height: calc(100vh - 65px);
  // display: flex;
  // flex-direction: column;
  align-items: flex-start;
  background-color: white;
  border-radius: 5px;
  // box-shadow: 0 0 10px #0003;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 100%;
  justify-content: flex-start;
  // padding: 10px;
  position: relative;
  width: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
    height: 94%;

    &--calendardate {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      height: 100%;
      // max-height: 500px;

      .calendarmonths {
        overflow-y: scroll;

        // color scroll 
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #d69c4f;
          border: none;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: #f5f5f5;
          border-radius: 10px;
        }
      }

      .rdrDateRangePickerWrapper {
        width: 100%;
        height: 100%;
      }

      .rdrDefinedRangesWrapper {
        display: none;
      }

      .rdrDayDisabled {
        background: transparent;
      }

      .rdrMonthAndYearWrapper {
        padding-top: 0;
        height: fit-content;
      }

      .dayscontent {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        border: none;
        outline: none;
        background-color: transparent;

        &__date {
          @include parragraph-normal(Quicksand, 15px, 500, normal);
        }

        &__price {
          @include parragraph-normal(Quicksand, 11px, 500, normal);
        }
      }

      .rdrMonth {
        padding: 0 0 20px;
      }

      .rdrStartEdge {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        left: 2px;
        height: 90%;
        background-color: #d69c4f;
      }

      .rdrInRange {
        height: 90%;
      }

      .rdrDayStartOfMonth .rdrInRange,
      .rdrDayStartOfMonth .rdrEndEdge,
      .rdrDayStartOfWeek .rdrInRange,
      .rdrDayStartOfWeek .rdrEndEdge {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        left: 2px;
      }

      .rdrDayEndOfMonth .rdrInRange,
      .rdrDayEndOfMonth .rdrStartEdge,
      .rdrDayEndOfWeek .rdrInRange,
      .rdrDayEndOfWeek .rdrStartEdge {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        right: 2px;
      }

      .rdrEndEdge {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        right: 2px;
        height: 90%;
        background-color: #d69c4f;
      }

      .rdrDayNumber {
        height: 90%;
      }

      .rdrDayNumber .dayscontent__price {
        color: #7e7e7e;
      }

      .rdrCalendarWrapper {
        width: 100%;
        // height: 100%;
        // overflow-y: scroll;
        height: 520px;
        overflow: hidden;
      }

      .rdrMonth {
        width: 100%;
      }

      // .rdrInfiniteMonths {
      //   height: max-content;
      //   max-height: 400px;

      //   div {
      //     max-height: 400px;
      //     height: max-content !important;
      //   }
      // }

      .rdrMonthName {
        text-transform: capitalize;
        text-align: right;
        color: black;
        @include parragraph-normal(NexaSlab, 16px, 600, normal);
      }

      .rdrMonthAndYearPickers select {
        text-transform: capitalize;
        @include parragraph-normal(NexaSlab, 16px, 600, normal);
        line-height: 18px;
        text-align: center;
      }

      .rdrDayToday .rdrDayNumber span:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 18px;
        height: 2px;
        border-radius: 2px;
        background: #d69c4f;
      }

      .rdrDayPassive {
        visibility: hidden;
      }

      .rdrDayExtra {
        @include parragraph-normal(Quicksand, 10px, 500, normal);
      }

      // .rdrMonthAndYearPickers {
      //   display: none;
      // }

      .rdrWeekDays {
        padding-block: 16px;
      }

      .rdrWeekDay {
        text-transform: uppercase;
        @include parragraph-normal(NexaSlab, 16px, 600, normal);
        color: #000;
      }

      .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
        content: '';
        border: 1px solid #d69c4f;
        border-radius: 4px;
        position: absolute;
        top: -2px;
        bottom: -2px;
        left: 0px;
        right: 0px;
        background: transparent;
      }
    }

    &--close {
      display: flex;
      justify-content: flex-end;

      &:hover {
        cursor: pointer;
      }
    }

    &--hotels {
      display: flex;
      width: 100%;

      &--search {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
      }
    }

    &--place {
      display: flex;
      position: relative;
      width: 100%;

      .place {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        color: #ccc;
      }

      input {
        width: 100%;
        padding: 10px;
        cursor: pointer;
        border-radius: 5px;
        background-color: white;
        border: 1.5px solid #f2f2f2;
        text-align: left;
        padding-left: 42px;
        @include parragraph-normal(NexaSlab, 14px, 400, normal);

        &::placeholder {
          color: black;
        }
      }

      .buscadormobile {
        position: absolute;
        top: 100%;
        z-index: 10;
        left: 0;
        width: 100%;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

        &__content {
          display: flex;
          flex-direction: column;
          max-height: 200px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--color-gold);
            border-radius: 5px;
          }

          &::-webkit-scrollbar-track {
            background-color: rgb(128, 128, 128, 0.2);
          }

          .border {
            padding: 10px;
            text-transform: capitalize;
            @include parragraph-normal(NexaSlab, 14px, 300, normal);

            &:hover {
              background-color: rgba(214, 156, 79, 0.45);
            }
          }

          .premium {
            color: #0e2e61;
            font-weight: 600;
          }

          .select {
            color: #008eaa;
            font-weight: 600;
          }

          .standard {
            color: #e4460d;
            font-weight: 600;
          }

          .asociados {
            color: #bc2fe2;
            font-weight: 600;
          }
        }
      }
    }

    &--calendar {
      display: flex;
      justify-content: space-evenly;
      gap: 10px;
    }

    &--start {
      width: 100%;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #f2f2f2;
      cursor: pointer;

      .startdate {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 6px;

        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          &--day {
            @include parragraph-normal(NexaSlab, 28px, 700, normal);
            line-height: 30px;
          }

          &--month {
            display: flex;
            flex-direction: column;

            .day {
              @include parragraph-normal(NexaSlab, 12px, 400, normal);
              text-transform: capitalize;
            }

            .month {
              @include parragraph-normal(NexaSlab, 12px, 400, normal);
              text-transform: uppercase;
            }
          }
        }
      }
    }

    &--end {
      width: 100%;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #f2f2f2;
      cursor: pointer;

      .startend {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 6px;

        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 6px;

          &--day {
            @include parragraph-normal(NexaSlab, 28px, 700, normal);
            line-height: 30px;
          }

          &--month {
            display: flex;
            flex-direction: column;

            .day {
              @include parragraph-normal(NexaSlab, 12px, 400, normal);
              text-transform: capitalize;
            }

            .month {
              @include parragraph-normal(NexaSlab, 12px, 400, normal);
              text-transform: uppercase;
            }
          }
        }
      }
    }

    &--coupon {
      input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        background-color: white;
        border: 1.5px solid #f2f2f2;
        text-align: center;
        @include parragraph-normal(NexaSlab, 14px, 400, normal);

        &::placeholder {
          color: black;
        }
      }
    }

    &--button {}
  }
}

.searchboxgeneral2 {
  @media (max-width: 767px) {
    display: none;
  }

  width: 100%;
  height: 100%;
  max-width: 1100px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    gap: 6px;
    width: 100%;
    padding: 16px 10px;
    background-color: white;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

    @media(min-width: 940px) {
      gap: 12px;
    }

    &--calendardate {
      position: absolute;
      background-color: white;
      width: max-content;
      padding: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      transform: translateX(-28%);
      top: 56px;
      left: 0px;
      z-index: 100;
      border-radius: 5px;

      @media (max-width: 1024px) {
        transform: translateX(-20%);
      }
    }

    .rdrMonthPicker {
      display: none;
    }

    .rdrNextPrevButton {
      background-color: transparent;

      i {
        display: none;
      }

      &:hover {
        background-color: transparent;
      }
    }

    .rdrNextNextButton {
      background-color: transparent;

      i {
        display: none;
      }

      &:hover {
        background-color: transparent;
      }
    }

    .rdrPprevButton {
      &::before {
        font-weight: 900;
        color: var(--color-gold);

        font-size: 20px;
        content: "\f053";
        /* Código Unicode del icono chevron-left de Font Awesome */
        font-family: "Font Awesome 6 Free";
      }
    }

    .rdrNextButton {
      &::before {
        font-weight: 900;
        color: var(--color-gold);

        font-size: 20px;
        content: "\f054";
        font-family: "Font Awesome 6 Free";
      }
    }

    .rdrDateRangePickerWrapper {
      display: flex;
      width: 100%;
    }

    .rdrDefinedRangesWrapper {
      display: none;
    }

    .rdrDayDisabled {
      background: transparent;
    }

    .dayscontent {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: none;
      outline: none;
      background-color: transparent;

      &__date {
        @include parragraph-normal(Quicksand, 15px, 500, normal);
      }

      &__price {
        @include parragraph-normal(Quicksand, 10px, 500, normal);
      }
    }

    .rdrStartEdge {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: 2px;
      height: 90%;
      background-color: #d69c4f;
    }

    .rdrInRange {
      height: 90%;
    }

    .rdrDayStartOfMonth .rdrInRange,
    .rdrDayStartOfMonth .rdrEndEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrEndEdge {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: 2px;
    }

    .rdrDayEndOfMonth .rdrInRange,
    .rdrDayEndOfMonth .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrStartEdge {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      right: 2px;
    }

    .rdrEndEdge {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      right: 2px;
      height: 90%;
      background-color: #d69c4f;
    }

    .rdrDayNumber {
      height: 90%;
    }

    .rdrDayPassive {
      visibility: hidden;
    }

    .rdrDayNumber .dayscontent__price {
      color: #ADADAD;
    }

    .rdrCalendarWrapper {
      border-radius: 5px;
      box-shadow: 0 0 15px #0004;
      width: 100%;

      select {
        text-transform: capitalize;

        &:hover {
          background-color: transparent;
        }

        appearance: none;
        /* Deshabilita la apariencia nativa del select */
        -webkit-appearance: none;
        /* Para navegadores basados en WebKit (como Chrome y Safari) */
        -moz-appearance: none;
        /* Para navegadores basados en Gecko (como Firefox) */
        background: transparent;
        /* Fondo transparente para que no aparezca la flecha */
        /* Otros estilos según tus necesidades */
        font-size: 15px;
        font-weight: 700;
        font-family: NexaSlab;
        width: fit-content;
        padding: 0px;
        margin-right: 5px;
        text-align: initial;
      }
    }

    .rdrMonth {
      width: 100%;
    }

    .rdrInfiniteMonths {
      // height: max-content;
      // max-height: 400px;

      // div {
      //   max-height: 400px;
      //   height: max-content !important;
      // }
    }

    .rdrMonth {
      padding: 0 8px 16px;
    }

    .rdrMonthName {
      text-transform: capitalize;
      text-align: center;
      color: black;
      @include parragraph-normal(NexaSlab, 16px, 600, normal);
      padding: 0;
    }

    .rdrMonthAndYearPickers select {
      text-transform: capitalize;
      @include parragraph-normal(NexaSlab, 16px, 600, normal);
      text-align: center;
    }

    .rdrDayToday .rdrDayNumber span:after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: #d69c4f;
    }

    .rdrDayExtra {
      @include parragraph-normal(Quicksand, 10px, 500, normal);
    }

    .rdrWeekDays .rdrWeekDay {
      padding-top: 10px;
      text-transform: uppercase;
      @include parragraph-normal(NexaSlab, 14px, 600, normal);
      color: black;
    }

    .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
      content: '';
      border: 1px solid #d69c4f;
      border-radius: 4px;
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: 0px;
      right: 0px;
      background: transparent;
    }

    &--hotels {
      display: flex;
      width: 100%;
      min-width: 160px;

      &--search {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
      }
    }

    &--place {
      display: flex;
      position: relative;
      width: 100%;

      .place {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        color: #ccc;
      }

      input {
        width: 100%;
        height: 46px;
        padding: 10px;
        cursor: pointer;
        border-radius: 5px;
        background-color: white;
        // border: 1.5px solid #f2f2f2; // BORDER
        border: none;
        text-align: left;
        padding-left: 42px;
        @include parragraph-normal(NexaSlab, 14px, 400, normal);

        &::placeholder {
          color: black;
        }

        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
          outline-color: #d69c4f;
        }
      }

      input[type="search"]::-webkit-search-cancel-button {
        color: #d69c4f !important;
        cursor: pointer;
      }

      .buscadormobile {
        position: absolute;
        top: 100%;
        z-index: 10;
        left: 0;
        width: 100%;
        border-radius: 4px;
        overflow-x: hidden;
        // padding: 10px;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

        &__content {
          display: flex;
          flex-direction: column;
          max-height: 200px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--color-gold);
            border-radius: 5px;
          }

          &::-webkit-scrollbar-track {
            background-color: rgb(128, 128, 128, 0.2);
          }

          .border {
            padding: 10px;
            border-top: 1px solid #E7E7E7;
            text-transform: capitalize;
            @include parragraph-normal(NexaSlab, 14px, 300, normal);

            &:hover {
              background-color: rgba(214, 156, 79, 0.45);
              cursor: pointer;
            }
          }

          .premium {
            color: #0e2e61;
            font-weight: 600;
          }

          .select {
            color: #008eaa;
            font-weight: 600;
          }

          .standard {
            color: #e4460d;
            font-weight: 600;
          }

          .asociados {
            color: #bc2fe2;
            font-weight: 600;
          }
        }
      }
    }

    &--calendar {
      display: flex;
      justify-content: space-evenly;
      gap: 10px;
      position: relative;
    }

    &--containerstartend {
      display: flex;
      gap: 10px;
      // width: 100%;
      // min-width: 400px;
    }

    &--start {
      width: 100%;
      min-width: 140px;
      border-radius: 5px;
      background-color: white;
      // border: 1px solid #f2f2f2;
      cursor: pointer;

      .startdate {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 6px;

        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 6px;

          &--day {
            @include parragraph-normal(NexaSlab, 28px, 700, normal);
            line-height: 30px;
          }

          &--month {
            display: flex;
            flex-direction: column;

            .day {
              @include parragraph-normal(NexaSlab, 12px, 400, normal);
              text-transform: capitalize;
            }

            .month {
              @include parragraph-normal(NexaSlab, 12px, 400, normal);
              text-transform: uppercase;
            }
          }
        }
      }
    }

    &--end {
      width: 100%;
      min-width: 140px;
      border-radius: 5px;
      background-color: white;
      // border: 1px solid #f2f2f2;
      cursor: pointer;

      .startend {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 6px;

        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 6px;

          &--day {
            @include parragraph-normal(NexaSlab, 28px, 700, normal);
            line-height: 30px;
          }

          &--month {
            display: flex;
            flex-direction: column;

            .day {
              @include parragraph-normal(NexaSlab, 12px, 400, normal);
              text-transform: capitalize;
            }

            .month {
              @include parragraph-normal(NexaSlab, 12px, 400, normal);
              text-transform: uppercase;
            }
          }
        }
      }
    }

    &--coupon {
      width: 100%;
      max-width: 168px;

      input {
        width: 100%;
        height: 46px;
        padding: 10px;
        border-radius: 5px;
        background-color: white;
        // border: 1.5px solid #f2f2f2;
        border: none;
        text-align: center;
        @include parragraph-normal(NexaSlab, 14px, 400, normal);

        &::placeholder {
          color: black;
        }

        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
          outline-color: #d69c4f;
        }
      }
    }

    &--button {
      width: 100%;
      max-width: 168px;
    }

    .line {
      width: 4px;
      background-color: #F2F2F2;
    }
  }
}


.skeleton {
  position: relative;
  border-radius: 8px;
  width: 30px;
  height: 12px;
  overflow: hidden;

  &__container {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100px;
    height: 100%;
    background-color: #f3f2f2;
    animation: moveSkeleton 1s infinite linear;
  }
}


.isdesktopn {
  display: none;
}

.ismobilen {
  display: block;
}

@media screen and (min-width: 1200px) {
  .isdesktopn {
    display: block;
  }

  .ismobilen {
    display: none;
  }
}


@keyframes showslide {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.unavailable {
  .dayscontent__date {
    color: #999;
    text-decoration: line-through;
  }

  .dayscontent__price {
    // color: white !important;
    height: 10px;
  }
}


.rdrEndEdge+.rdrDayNumber.dayscontent.unavailable {
  background-color: #d69c4f;
}

.no-scroll {
  overflow: hidden;
}