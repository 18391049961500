.comprobantes {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 25px 16px;
  font-family: Sofia;

  
  &__container {
    padding: 0px 16px;

    @media (min-width: 768px) {
      padding: 0px 60px;
    }
    
    @media (min-width: 1200px){
      max-width: 1220px;
      padding: 0px 128px;
    }
    
    &--form {
      font-family: "Quicksand";

      h2 {
        font-size: 26px;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 16px;
      }

      .content-button{
        margin: 20px 0px 20px 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        button {
          width: 100%;
          font-family: Sofia;
          padding: 0;
        }
      }

      ul {
        width: 100%;
        padding: 0;
        list-style-type: disc;
        list-style-position: inside;
        margin: 0;

        li {
          box-sizing: border-box;
          list-style-position: outside;
          margin-left: 20px;
          margin-bottom: 6px;

          &::marker {
            font-size: 16px;
          }
        }
      
      }
    }
  }
}


.cursor{
  cursor: pointer;
  text-decoration: none;
  color: #D69C4F;

  &:hover{
    color: #D69C4F;
    text-decoration: underline;
  }
}