@import '../../atoms/text/styles.scss';

.countdown-timer {
  text-align: center;

  .countdown-display {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 60px;
    font-size: 36px;
    font-weight: 600;

    @media (max-width: 400px) {
      gap: 3px;
    }

    .dots {
      display: flex;
      align-items: flex-start;
      height: 100%;
      margin-bottom: 6px;
    }

    .countdown-display-box {
      .timer {
        display: flex;
        flex-direction: row;
        gap: 2px;
      }

      p {
        @include parragraph-normal(Quicksand, 12px, 400, normal);
      }
    }
  }

  .time-input {
    margin-top: 20px;

    label {
      font-size: 16px;
    }

    input {
      width: 100%;
      padding: 5px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
}