@import '../../atoms/text/styles.scss';

.Accordion {
  color: black;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  user-select: none;
  @include touchmobile;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__edit{
      font-weight: 400;
      font-size: 1.125rem;
      font-family: Sofia;
      line-height: 1.5;
      padding: 10px 0px;
      margin: 0px;
    }

    &__arrow {
      width: 18px;
      height: 18px;
    }

    svg {
      transition: all 0.4s ease;
    }
  }

  .description {
    display: none;
    padding: 10px 0px;
    font-family: Sofia;
    font-weight: 100;
    font-size: 1rem;
    line-height: 1.5;

    &__line {
      // border: 1px solid white;

      &:not(:last-of-type) {
        display: block;
      }
    }
  }

  &:last-child {
    .description__line {
      display: none;
    }
  }
}

.Accordion.active {
  .title {
    svg {
      transform: rotate(-180deg);
    }
  }

  .description {
    display: block;
  }
}
