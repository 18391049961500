.infinite-carousel {
  display: flex;
  overflow: hidden;

  img {
    animation: scroll 60s linear infinite;
  }

  @media (min-width: 1024px) {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%)
  }
}