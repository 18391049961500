.parrafo__text__ikam_pakete {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  font-family: Sofia;
  // padding-bottom: 32px;

  .titulo {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    font-family: Sofia;
  }

  .paragraph {
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
    font-family: Sofia;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  button {
    display: block;
    text-align: center;
    margin: auto;
    margin-bottom: 80px;
  }
}
