@import '../../../components/atoms/text/styles.scss';

.friends_family {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;


  .terms-url {
    font-family: Quicksand;
    font-weight: 700;
    font-style: normal;
    color: var(--color-gold);
    cursor: pointer;
    text-decoration: none;
  }

  .input {
    background: #fff;
    border: 1px solid #6b6b6b;
    border-radius: 7px;
    box-sizing: border-box;
    color: #6b6b6b;
    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 45px;
    line-height: 16px;
    outline: none;
    padding: 10px;
    transition: .3s;
    width: 100%;
  }

  .input-container span, .checkbox__form span {
    @include parragraph-normal(Quicksand, 12px, 600, normal);
    color: red;
  }

  .checkbox__form {
    p {
      margin: 0;
    }

    label {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 10px 0;
    }

    input[type="checkbox"] {
      width: 18px;
      height: 18px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 4px;
      border: 1px solid var(--color-gold);
    }

    input[type="checkbox"]:checked::before {
      content: "\2713";
      color: white;
      text-align: center;
      display: grid;
      place-content: center;
      background-color: var(--color-gold);
    }
  }

  button {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 7px;
    margin: 10px 0;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.3s;
    background: var(--color-gold);
    color: white;
    cursor: pointer;
    @include parragraph-normal(Quicksand, 16px, 700, normal);
    text-transform: uppercase;

    &:disabled {
      background: var(--color-gold);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.friends_family__container {
  max-width: 1220px;
  text-align: center;
}

.friends_family {
  h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    margin: 20px 0;
  }

  li {
    margin: 10px 0;
  }

  /*p, li{text-align: justify;} */


  .title {
    font-size: 38px;
    letter-spacing: 2px;
    line-height: 38px;
  }

  .txt-celeste {
    color: #00A7CB;
  }

  .txt-blanco {
    color: #fff;
  }

  .section-descripcion-fyf {
    padding: 40px 30px;
    background-color: #00A7CB;
    background-size: 70px;
    color: #fff;
    text-align: center;
  }

  .section-beneficios-colaboradores {
    padding: 45px;
    text-align: center;
  }

  .section-value {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .value-dato {
    display: inline-block;
    background: #00A7CB;
    color: #fff;
    width: 250px;
    padding: 30px 0 20px 0;
  }

  .value-dato p {
    margin: 0;
  }

  .value-num {
    font-size: 80px;
    line-height: 50px;
    font-weight: 700;
  }

  .value-simbolo {
    margin: 0 30px;
    font-size: 45px;
    color: #00A7CB;
  }

  .section-beneficios-referidos {
    padding: 45px 0;
    background: #F5F5F5;
    text-align: center;
  }

  .section-percent {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    font-weight: bold;
  }

  .percent-num {
    font-size: 120px;
    line-height: 100px;
  }

  .percent-text {
    text-align: left;
    margin-left: 7px;
  }

  .percent-simbolo {
    display: block;
    font-size: 60px;
    margin-bottom: -18px;
  }

  .section-participa {
    padding: 45px 0;
    text-align: center;
  }

  .section-paso {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .item-p {
    padding: 30px 80px;
    width: 50%;
  }

  .item-p img {
    width: 160px;
  }

  .item-p h3 {
    margin: 0 0 -18px;
    font-weight: bold;
    font-size: 20px;
  }

  .section-formulario {
    padding: 45px;
    text-align: center;
    background: #00A7CB;
    color: #fff;
  }

  .section-nota {
    padding: 45px;
    text-align: center;
  }

  .section-formulario .form-afiliado {
    text-align: center;
    max-width: 800px;
    margin: auto;

    span {
      @include parragraph-normal(Quicksand, 12px, 600, normal);
    }
  }

  .section-formulario .form-text {
    color: #333 !important;
    font-size: 20px;
    padding: 0px 15px 4px;
  }

  .section-formulario .form-text:focus {
    color: #333 !important;
  }

  .section-formulario .form-text::placeholder {
    color: #999;
    font-size: 16px;
  }

  .section-formulario .campo-dato {
    background: none;
    border-bottom: 1px solid #fff;
  }

  .section-formulario a {
    color: #fff;
    text-decoration: underline !important;
  }

  .section-formulario .btn-select {
    display: inline-block;
    border: 1px solid #CCCCCC;
    margin-left: 5px;
    border-radius: 0px;
    vertical-align: middle;
    background: #f9f9f9;
    cursor: pointer;
    color: #333;
    text-decoration: none !important;
    font-size: 16px;
    padding: 4px 8px 4px;

    &:disabled {
      background: #CCCCCC;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .section-formulario .campohab {
    color: #333;
  }

  .section-formulario .btn-primary {
    background: #000;
    text-decoration: none !important;
    cursor: pointer;
  }

  .section-formulario .msg-success {
    border: 1px solid #fff;
    padding: 0 5px;
    color: #fff;
  }

  #dato-requied {
    border: 1px solid #fff;
    padding: 0 5px;
    color: #fff;
  }

  .validate {
    display: none;
  }

  .msg-validate {
    display: inline-block;
  }
}

@media screen and (max-width: 768px) {


  .friends_family {
    .title {
      font-size: 28px;
      line-height: 30px;
    }

    .value-dato {
      width: 100px
    }

    .section-paso {
      flex-direction: column;
    }

    .item-p {
      width: 100%;
    }

    .section-formulario .form-text {
      width: 100%;
    }

    .section-formulario .btn-select {
      width: 100%;
      margin-left: 0;
      margin-top: 5px;
    }
  }

}
