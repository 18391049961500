/*
  CONTENT STRUCTURE:
    text align:         ta-
    text transform:     tt-
    text decoration:    td-
    text shadow:        ts- 
*/

$textTransform: ( 
    none: none,
    capitalize: capitalize,
    uppercase: uppercase,
    lowercase: lowercase,
    full-width: full-width,
    full-size-kana: full-size-kana,
    inherit: inherit,
    initial: initial,
    revert: revert,
    revert-layer: revert-layer,
    unset: unset
);

@each $name, $value in $textTransform {
    /* TEXT TRANSFORM (tt-) */
    .tt-#{ $name } {
      text-transform: #{$value} !important;
    }
}

$textDecoration: ( 
    underline: underline,
    none: none,   
    inherit: inherit,
    initial: initial,
    revert: revert,
    revert-layer: revert-layer,
    unset: unset,
    line: line-through,
);

@each $name, $value in $textDecoration {
    /* TEXT DECORATION (td-) */
    .td-#{ $name } {
        text-decoration: #{$value} !important;
    }
}

/* TEXT SHADOW (ts-) */
$textShadow: ( 
  "1": 1px 1px rgb(103, 101, 101),
);

@each $name, $value in $textShadow {
    .ts-#{ $name } {
      text-shadow: #{$value} !important;
    }
}

$textAlign: (    
    right: right,
    left: left,    
    center: center,
    justify: justify,
);

@each $name, $value in $textAlign {
    /* TEXT ALIGN (ta-) */ 
    .ta-#{ $name } {
      text-align: #{$value} !important;
    }

    @media print, screen and (min-width: 40em) {
        .m-ta-#{ $name } {
            text-align: #{$value} !important;
        }
    }

    @media print, screen and (min-width: 64em) {
        .l-ta-#{ $name } {
            text-align: #{$value} !important;
        }
    }
}

