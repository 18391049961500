@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

@font-face {
  font-family: NexaSlab;
  src: url('../../fonts/NexaSlab-Light.otf?v=1.102') format('opentype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: NexaSlab;
  src: url('../../fonts/NexaSlab-Light_Italic.otf?v=1.102') format('opentype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: NexaSlab;
  src: url('../../fonts/NexaSlab-book.woff2?v=1.102') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: NexaSlab;
  src: url('../../fonts/NexaSlab-Regular.otf?v=1.102') format('opentype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: NexaSlab;
  src: url('../../fonts/NexaSlab-bold.woff2?v=1.102') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: NexaSlab;
  src: url('../../fonts/NexaSlab-Black.otf?v=1.102') format('opentype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Sofia;
  src: url('../../fonts/SofiaPro-ExtraLight.woff?v=1.102') format("woff");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Sofia;
  src: url('../../fonts/SofiaPro-Light.woff?v=1.102') format("woff");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Sofia;
  src: url('../../fonts/SofiaPro-Regular.woff?v=1.102') format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Sofia;
  src: url('../../fonts/SofiaPro-Bold.woff?v=1.102') format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
