.custom-slider-tag {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
  background-color: rgb(255, 255, 255);
  position: sticky;
  // top: 63px;
  z-index: 11;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 82px;

  @media (min-width: 1200px) {
    top: 72px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    top: 55px;
  }


  .container {
    width: 100%;

    @media (min-width: 1200px){
      max-width: 1220px;
    }
  }

  .containerpad {
    // padding: 20px
  }

}

.custom-slider-tag-section {
  width: 100%;

  .tabsections {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: border-bottom 0.3s ease;
    user-select: none;
    width: 100%;
    height: 100%;
    border-bottom: 4px solid #ffffff;

    .texttab{
      width: 100%;
      text-align: center;
    }

    // p {
    //   margin: 0;

    //   &:hover,
    //   &.active-tab{
    //     color: #D69C4F;
    //     border-bottom: 3px solid #D69C4F;
    //   }
    // }
    &.active-text, &:hover {
      font-weight: 500;
      color: #D69C4F;
      border-bottom: 4px solid #D69C4F;
      p {
        margin-top: 4px;
      }
    }

    &.inactive-tab {
      color: #333;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 44px;
  }

  .swiper-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

}

.custom-slider-tag.visible {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.custom-slider-tag.hidden {
  display: none;

}
