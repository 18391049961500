.tooltip {
  position: absolute;
  padding: 8px;
  border-radius: 4px;
  color: #fff;
  font-size: .875rem;
  z-index: 9999;
  pointer-events: none;
  white-space: nowrap;
}

// .arrow {
//   position: absolute;
//   width: 0;
//   height: 0;
//   border-style: solid;
// }

.tooltip-top {
  transform: translate(-50%, 0);
}

.tooltip-top .arrow {
  bottom: -6px;
  left: 50%;
  margin-left: -6px;
  border-width: 6px 6px 0 6px;
}

.tooltip-right {
  transform: translate(0, -50%);
}

// .tooltip-right .arrow {
//   top: 50%;
//   left: -6px;
//   margin-top: -6px;
//   border-width: 6px 6px 6px 0;
// }

.tooltip-bottom {
  transform: translate(-50%, 0);
}

.tooltip-bottom .arrow {
  top: -6px;
  left: 50%;
  margin-left: -6px;
  border-width: 0 6px 6px 6px;
}

.tooltip-left {
  transform: translate(0, -50%);
}

// .tooltip-left .arrow {
//   top: 50%;
//   right: -6px;
//   margin-top: -6px;
//   border-width: 6px 0 6px 6px;
// }
