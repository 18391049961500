.icongold {
  cursor: pointer;
  background: var(--color-gold);
  border-radius: 7px;
  border: none;
  font-size: .875rem;
  text-align: center;
  color: white;
  font-family: Sofia;
  padding: 10px 48px !important;
  display: block;

  &:hover {
    background: #c6914c;
  }

  &:active {
    background: #bb8b4c;
  }

  @media (min-width: 768px) {
    font-size: 1.125rem;
    padding: 10px 48px;
  }
}

.gold {
  cursor: pointer;
  background: var(--color-gold);
  border-radius: 7px;
  border: none;
  text-align: center;
  color: white;
  font-family: NexaSlab;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  padding: 12px 30px;
  display: block;
  height: 45px;

  &:hover {
    background: #c6914c;
  }

  &:active {
    background: #bb8b4c;
  }
}

.disabled {
  background: #d1cac3;
  border-radius: 7px;
  border: none;
  text-align: center;
  color: white;
  font-family: NexaSlab;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  padding: 12px 30px;
  display: block;
  height: 45px;
  cursor: not-allowed;
}

.borders {
  cursor: pointer;
  background: transparent;
  border-radius: 7px;
  border: 2.5px solid var(--color-gold);
  font-size: .875rem;
  text-align: center;
  color: var(--color-gold);
  font-family: NexaSlab;
  font-weight: 700;
  padding: 12px 30px;
  display: block;
  height: 45px;

  &:hover {
    background: #c6914c;
    color: white;
  }

  &:active {
    background: #bb8b4c;
  }
}


.black {
  cursor: pointer;
  background: #000000;
  border-radius: 5px;
  padding: 14px 15px;
  color: white;
  border: 2px solid var(--color-gold);
  font-size: .875rem;
  font-family: Sofia;
  display: block;

  &:hover {
    background: #141414;
  }

  &:active {
    background: #000000;
  }

  @media (min-width: 768px) {
    font-size: 1.125rem;
    padding: 14px 28px;
  }
}

