@import '../../atoms/text/styles.scss';

.favdestinations {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 100px;

  // @media (min-width: 768px) {
  //   padding-top: 130px;
  // }

  &.scrolled{
    padding-top: 60px;
  }

  &.notscrolled{
    padding-top: 130px;
  }


  &__container {
    width: 100%;
    text-align: center;

    @media (min-width: 1200px) {
      max-width: 1200px;
    }

    .title {
      font-size: 26px;
      line-height: 26px;
      font-family: NexaSlab;
      font-weight: 700;
      padding-inline: 16px;

      @media (min-width: 425px) {
        font-size: 38px;
        line-height: 38px;
      }

      @media (min-width: 1024px) {
        font-size: 50px;
        line-height: normal;
      }
    }

    .subtitle {
      font-size: 22px;
      line-height: 22px;
      font-family: NexaSlab;
      padding-inline: 16px;
      font-weight: 300;

      @media (min-width: 425px) {
        font-size: 30px;
        line-height: 30px;
      }

      @media (min-width: 1024px) {
        font-size: 42px;
        line-height: normal;
      }
    }
  }
}