.filtersection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat;
  background-position: center;
  background-image: url('../../../assets/images/bkg-texture.webp');
  height: 100px;
  padding-top: 40px;
  padding-bottom: 40px;
  position:relative;

  &__container {
    width: 100%;
    @media (min-width: 1200px){
      max-width: 1220px;
    }

    &--buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;

      .button-icon {
        border-radius: 10px;
        border: 1.5px solid #7E7E7E;
        background: #FFF;
        box-sizing: border-box;
        color: #7E7E7E;
        cursor: pointer;
        display: inline-block;
        outline: none;
        position: relative;
        text-decoration: none;
        touch-action: manipulation;
        transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
        user-select: none;
        width: 149px;
        height: 70px;

        p {
          margin: 0;
          font-family: NexaSlab;
          font-size: 1.125rem;
          text-align: center;
        }
      }

      .activebtn {
        border: 1.5px solid #D69C4F;
      }
      .inactivebtn {
        border: 1.5px solid #7E7E7E;
      }
      .activetext {
        color: #D69C4F;
      }
      .inactivetext {
        color: #7E7E7E;
      }
    }

    .buttonsm {
      display: flex;
      justify-content: center;
      align-items: center;

      .button-icon {
        border-radius: 10px;
        border: 1.5px solid #7E7E7E;
        background: #FFF;
        box-sizing: border-box;
        color: #7E7E7E;
        cursor: pointer;
        width: 149px;
        height: 70px;

        p {
          margin: 0;
          font-family: NexaSlab;
          font-size: 1.125rem;
          text-align: center;
        }
      }

      .activebtn {
        border: 1.5px solid #D69C4F;
      }
      .inactivebtn {
        border: 1.5px solid #7E7E7E;
      }
      .activetext {
        color: #D69C4F;
      }
      .inactivetext {
        color: #7E7E7E;
      }
    }
  }
}
