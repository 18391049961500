@import '../../atoms/text/styles.scss';

footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #232323;

  &__container1 {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }
  }
}

.footer2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;

  &__container2 {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }
  }
}

.section1 {
  background: #232323;
  color: white;

  // @media screen and (min-width: 768px) {
  //   background: #232323;
  // }

  .section1-container {
    display: flex;
    flex-direction: column;
    // padding: 20px 16px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    &__left {
      display: flex;

      @media (min-width: 768px) {
        flex: 1;
        justify-content: flex-start;
      }

      .paragraph {
        @include parragraph-normal(NexaSlab, 18px, 700, normal);
        line-height: normal;
        margin-bottom: 16px;
      }
    }

    &__right {
      background-color: #000000;
      font-family: Sofia;
      font-weight: 100;
      font-size: 1.125rem;

      label {
        font-family: Sofia;
        font-weight: 100;
        font-size: 1rem;
      }

      &--row {
        display: flex;
        flex-direction: row;
        padding-top: 16px;
      }

      .email__input {
        border: 0.5px solid #D9D9D9;
        border-radius: 5px;
        background-color: transparent;
        color: white;
        width: 100%;
        height: 48px;
        padding: 0 15px;
        margin-right: 10px;
      }

      button {
        background: #D69C4F;
        border: 0.5px solid #D69C4F;
        border-radius: 12px;
        color: white;
        cursor: pointer;
        width: 60px;
        height: 48px;
        margin-left: 4px;

        &:disabled {
          background: #d9b17cdf;
          cursor: not-allowed;
        }
      }

      @media (min-width: 768px) {
        padding: 0;
        flex: 1;

        .email__input {
          width: 220px;
        }
      }
    }
  }
}

.section2 {
  background: black;
  color: white;

  .section2-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 16px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }

    &__left {
      display: flex;
      justify-content: center;

      @media (min-width: 768px) {
        padding: 0;
        flex: 1;
        justify-content: flex-start;
      }
    }

    &__right {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;

      img {
        width: 32px;
        height: 22px;
      }

      @media (min-width: 768px) {
        padding: 0;
        flex: 1;
      }

      &--left {
        text-align: left;

        .paragraph {
          @include parragraph-normal(NexaSlab, 18px, 700, normal);
          line-height: normal;
        }

        @media (min-width: 768px) {
          text-align: right;
          flex: 1;
          margin-right: 10px;
        }
      }

      &--right {
        img:nth-child(-n+3) {
          margin-right: 5px;

          @media (min-width: 768px) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.section3-1 {
  background: black;
  padding: 16px;
  font-family: Arquitecta;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid #303030;
    border-bottom: 1px solid #303030;
  }

  .accordion-link {
    width: 100%;

    @media (min-width: 768px) {
      width: 25%;
    }
  }
}

.conditions {
  background: black;
  padding: 16px 16px 32px 16px;
  color: white;

  @media (min-width: 768px) {
    padding: 16px 16px 24px 16px;
  }

  &__terms {
    padding-bottom: 16px;
    @include flex(flex-start, flex-start, column, 6px);

    @media (min-width: 768px) {
      padding-bottom: 26px;

      justify-content: center;
      align-items: center;
    }

    &--link {
      text-decoration: none;
      @include touchmobile;

      .text {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        color: white;

        :hover {
          text-decoration: underline;
          color: var(--color-gold);
        }
      }
    }
  }

  &__copyright {
    display: flex;
    justify-content: center;

    .text {
      @include parragraph-normal(Quicksand, 14px, 400, normal);
    }
  }


}



$radio-color: #D69C4F;
$radio-border-width: 6px;

input[type="radio"] {
  appearance: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  outline: auto;
  outline-offset: 3px;
  transition: border-color 0.3s;
  margin-right: 16px;

  &:checked {
    background-color: $radio-color;
    border-color: #D69C4F;
  }

  &:hover {
    border-color: darken($radio-color, 10%);
  }
}

.footersection3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #141416;

  &__container {
    width: 100%;
    color: white;
    font-family: "Quicksand";
    font-size: 13px;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--line {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      border-block: 0.5px solid #ffffff;
      padding: 30px 16px;
      text-align: center;

      @media (min-width: 768px) {
        gap: 40px;
        border-top: 0.5px solid #ffffff;
        flex-direction: row;
      }

      .icondot {
        display: none;

        @media (min-width: 768px) {
          display: block;
        }
      }
    }

    &--link {
      color: white;
      text-decoration: none;

      &:hover {
        color: #D69C4F;
      }
    }

    &--text {
      text-align: center;
      padding-block: 16px;
    }
  }
}

.footersection2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #141416;

  &__container {
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-top: 0.5px solid #ffffff;
    gap: 20px;
    padding: 30px 16px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .col {
      width: 100%;
      // max-width: 200px;
      
      @media (min-width: 768px) {
        width: fit-content;
        min-width: 120px;
      }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        text-transform: uppercase;
        font-size: 13px;
        user-select: none;
        color: #D9D9D9;
        cursor: pointer;

        @media (min-width: 768px) {
          cursor: default;
        }

        .iconmob {
          display: block;

          @media (min-width: 768px) {
            display: none;
          }
        }
      }

      .description {
        font-family: "Quicksand";
        display: flex;
        flex-direction: column;
        font-size: 13px;
        margin-top: 13px;
        gap: 10px;
        width: 100%;
        
        @media (min-width: 768px) {
          width: 180px;
        }

        &__link {
          color: white;
          font-weight: 600;
          text-decoration: none;

          &:hover {
            color: #D69C4F;
          }
        }

        .reclamaciones {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 32px;

          img {
            width: 100px;
            height: 60px;
            object-fit: contain;
          }
        }

      }
    }
  }
}

.accordion-item {
  flex: 1;

  .accordion {
    background-color: #141416;
    color: white;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    font-size: 16px;
    transition: 0.4s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .arrow {
      display: inline-block;
      margin-left: 5px;
      transition: transform 0.4s;
      border: solid white;
      border-width: 0 2px 2px 0;
      padding: 3px;
      transform: rotate(45deg);

      &.active {
        transform: rotate(-135deg);
      }
    }
  }

  .panel {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    transition: max-height 0.4s ease-out;

    &.open {
      display: block;
      max-height: 500px;
    }

    p {
      margin: 0;
      padding: 10px 0;
    }
  }
}


.footersection1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #141416;

  &__container {
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    padding: 30px 16px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--left {
      flex: 1;
      display: flex;
      justify-content: center;

      @media (min-width: 768px) {
        justify-content: flex-start;
      }

      img {
        width: 180px;
        height: 45px;
        aspect-ratio: 16 / 9;
      }
    }

    &--right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }

      .containerimg {
        display: flex;
        gap: 6px;
      }
    }

  }
}

.footersection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232323;

  &__container {
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    padding: 30px 16px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 10px;

      @media (min-width: 768px) {
        gap: 24px;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }

    &--right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      @media (min-width: 768px) {
        gap: 24px;
      }

      form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        justify-content: space-between;
        width: 100%;

        div {
          flex: 1;
        }

        @media (min-width: 768px) {
          align-items: center;
          flex-direction: row;
          gap: 10px;
        }

        .label-input {
          display: flex;
          gap: 20px;

          label {
            font-family: "Quicksand";
            font-size: 16px;
            color: white;
          }
        }

        .inputform {
          display: flex;
          align-items: center;

          input {
            height: 40px;
            width: 100%;
            border: 1px solid #D9D9D9;
            border-radius: 5px 0 0 5px;
            padding: 0 10px;
            color: white;
            background-color: #141416;

            @media (min-width: 768px) {
              max-width: 240px;
            }

            &::placeholder {
              color: white;
              background-color: #141416;
            }

            &:internal-autofill-selected {
              background-color: #141416;
            }
          }

          button {
            background-color: #D69C4F;
            color: white;
            border: 1px solid #D69C4F;
            border-radius: 0 5px 5px 0;
            padding: 0 10px;
            height: 40px;
            cursor: pointer;

            &:disabled {
              background-color: #D9B17C;
              border: 1px solid #D9B17C;
              cursor: not-allowed;
            }
          }
        }

        .errors {
          font-family: "Quicksand";
          font-size: 12px;
          color: #D69C4F;
          font-weight: 600;
          margin-top: 5px;
        }
      }

      .politicalink {
        font-family: "Quicksand";
        font-size: 16px;
        color: white;
        cursor: pointer;

        &:hover {
          color: #D69C4F;
        }
      }
    }

  }
}