@import '../../../components/atoms/text/styles.scss';

.quota{
  padding-inline: 16px;

  @media (min-width: 1200px){
    padding-inline: 0px;
  }

  &__description{
    @include parragraph-normal(Quicksand, 20px, 300, normal);
    line-height: normal;
    margin-bottom: 16px;
  }

  &__list{
    @include parragraph-normal(Quicksand, 16px, 300, normal);

    .paragraph{
      @include parragraph-normal(Quicksand, 16px, 300, normal);
      line-height: normal;
      color: red;
    }
  }
}