@import '../../atoms/text/styles.scss';

.galleryhotels {
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;
    text-align: center;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--items {
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 5px;
      height: 405px;
      border-radius: 9px;
      overflow: hidden;

      @media (max-width: 1200px) {
        padding-inline: 16px;
      }

      >div {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: 0.5s;
        cursor: pointer;

        &:hover {
          text-decoration: underline white;
        }

        &.item-0 {
          grid-column: span 3;
        }

        &.item-1 {
          grid-row: span 2;
        }

        &.item-2 {
          grid-row: span 1;
        }

        &.item-3 {
          grid-column: span 2;
        }
      }

      // Caso: data.length === 1
      &.length-1>div {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
      }

      // Caso: data.length === 2
      &.length-2 {
        grid-template-columns: repeat(2, 1fr);
      }

      // Caso: data.length === 3
      &.length-3 {
        grid-template-columns: repeat(3, 1fr);
      }

      // Caso: data.length === 4
      &.length-4 {
        grid-template-columns: repeat(4, 1fr);
      }
      &.length-4>div:nth-child(1) {
        grid-column: span 2;
      }

      &.length-4>div:nth-child(2) {
        grid-row: span 2;
        grid-column: span 2;
      }

      &.length-4>div:nth-child(3) {
        grid-column: span 1;
      }

      // Caso: data.length === 5
      &.length-5 {
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(100px, auto);

        > div:nth-child(1) {
          grid-column: span 2;
        }

        > div:nth-child(2) {
          grid-row: span 2;

          @media (min-width: 1200px) {
            width: 354px;
          }
        }

        > div:nth-child(3) {

          @media (min-width: 1200px) {
            grid-column: span 1;
          }
        }

        > div:nth-child(4) {
          grid-column: span 2;
        }
      }

      // Caso: data.length === 6
      &.length-6 {
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(100px, auto);

        > div:nth-child(1) {
          grid-column: span 2;
        }

        > div:nth-child(2) {
          grid-row: span 2;

          @media (min-width: 1200px) {
            width: 354px;
          }
        }

        > div:nth-child(3) {

          @media (min-width: 1200px) {
            grid-row: span 1;
          }
        }

        > div:nth-child(4) {
          grid-column: span 1;
        }
      }

      // Caso: data.length === 7
      &.length-7 {
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(100px, auto);

        >div:nth-child(3) {
          grid-row: span 2;

          @media (min-width: 1200px) {
            width: 354px;
          }
        }
      }

      // Caso: data.length === 8
      &.length-8 {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(100px, auto);

        @media (min-width: 1200px) {
          grid-template-columns: repeat(5, 1fr);
          grid-auto-rows: minmax(190px, auto);
        }

        > div:nth-child(4) {
          width: 100%;
          height: 100%;

          @media (min-width: 1200px) {
            grid-row: span 2;
            width: 290px;
          }
        }

        > div:nth-child(5) {
          grid-row: span 2;
          width: 100%;
          height: 100%;

          @media (min-width: 1200px) {
            width: 290px;
          }
        }
      }

      // Caso: data.length === 9
      &.length-9 {
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: minmax(200px, auto);
      }

      &.length-9>div:nth-child(1) {
        grid-row: span 2;
        width: 270px;
      }

      .textgallery {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;

        &:hover {
          scale: 102%;
        }

        &__content {
          display: flex;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);
          justify-content: space-between;
          align-items: flex-start;
          color: white;
          padding: 0px 20px 20px 30px;

          .paragraph {
            @include parragraph-normal(Quicksand, 16px, 600, normal);
            text-align: left
          }

          .count {
            @include parragraph-normal(Quicksand, 16px, 600, normal);

          }
        }
      }
    }
  }

  .gallerymodal {
    height: 100%;
    // width: calc(100% - 80px);
    // padding: 0px 40px;
    width: 100%;

    img {
      width: 100%;
      height: 312px;
      object-fit: fill;
    }

    .carditems {

      &__card {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: .375rem;
        padding-top: .5rem;
      }
    }

    .activity-tabs {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 10px;

      div {
        color: #333;
        @include parragraph-normal(Quicksand, 14px, 700, normal);
        margin-right: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover,
        &.active {
          text-decoration: underline;
          text-underline-offset: 10px;
          text-decoration-thickness: 3px;
          color: #D69C4F;
          font-weight: 400;
        }
      }

    }

    .gallery-swiper .swiper-slide-thumb-active {
      opacity: 1;
    }

    .gallery-swiper {
      position: relative;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: white;
    }

    .swiper-button-prev {
      left: 5%;
    }

    .swiper-button-next {
      right: 5%;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 24px;
      font-weight: 700;
      border-radius: 50%;
      padding: 10px 15px;
      background: rgba(0, 0, 0, 0.59);
      box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      display: none;
      pointer-events: none;
    }
  }

  .swipertabs {

    .swiper {
      overflow-y: auto;
    }
  }

  .galleryhotels__swiper {
    padding-left: 16px;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 258px;
      height: 221px;
      margin-right: 28px;
    }
  }
}

.galleriestabs {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 40px;
    margin-right: 12px;

  }
}

.galleriestabsborder {
  // border: 1px solid #D69C4F;
  // border-radius: 12px;
  border-bottom: 2px solid #F0F0F0;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid #D69C4F;

    p {
      color: #D69C4F;
    }
  }

  p {
    @include parragraph-normal(Quicksand, 14px, 400, normal);
    color: #5F5F5F;
    text-align: center;
    // padding-block: 12px;
  }

}

.galleriestabsborder.active {
  border-bottom: 1.5px solid #D69C4F;

  p {
    color: #D69C4F;
  }
}


.bgselected {
  position: relative;

  &__btn {
    background-color: rgba(0, 0, 0, 0.59);
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}