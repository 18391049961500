@import '../../../components/atoms/text/styles.scss';

.tarifas_corporativas {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding-block: 40px;

  @media (min-width: 768px) {
    padding-block: 56px;
  }
}

.tarifas_corporativas__container {
  max-width: 1220px;
}

.tarifas_corporativas {
  h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  // p{margin:20px 0;}
  li {
    margin: 10px 0;
  }

  p,
  li {
    text-align: justify;
  }


  .content-form h3 {
    text-align: center;
  }

  .content-form .group-field {
    margin: 8px 0;
    display: inline-block;
    width: 100%;
  }

  .text-field {
    display: block;
    width: calc(100% - 24px);
    background-color: #FFF;
    border-radius: 0px;
    box-shadow: none;
    color: #8c8c8c;
    border: 1px solid #000;
    text-align: center;
    padding: 15px 12px;
    border-radius: 5px;
  }

  .content-form .col1 {
    width: calc(50% - 7px);
    margin-right: 7px;
  }

  .content-form .col2 {
    width: calc(50% - 7px);
    margin-left: 7px;
  }

  .content-form select {
    width: 100%;
  }

  .content-form .boton_enviar {
    background-color: #d69c4f !important;
    color: #fff !important;
    border: 1px solid #d69c4f !important;


    display: block;
    width: 100%;
    background-color: #FFF;
    border-radius: 0px;
    box-shadow: none;
    color: #8c8c8c;
    border: 1px solid #000;
    text-align: center;
    padding: 15px 12px;
    border-radius: 5px;
  }

  .content-form {
    width: 100%;

    @media (min-width: 768px) {
      width: 100%;
      max-width: 600px;
    }

    .input-formcontact1 {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-block: 20px;

      @media (min-width: 768px) {
        flex-direction: row;
        gap: 10px;
      }

      div {
        width: 100%;

        @media (min-width: 768px) {
          width: 50%;
        }
      }

      input {
        width: 100%;
        height: 45px;
        border: 1px solid #6B6B6B;
        border-radius: 7px;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        transition: 0.3s;
        background: white;
        @include parragraph-normal(Quicksand, 16px, 500, normal);
        color: #6B6B6B;

        &::placeholder {
          color: #6B6B6B;
        }

        &:focus {
          border: 2px solid var(--color-gold);
        }
      }
    }

    .terms-url {
      font-family: Quicksand;
      font-weight: 700;
      font-style: normal;
      color: var(--color-gold);
      cursor: pointer;
      text-decoration: none;
    }

    .input-formcontact {
      margin-block: 20px;

      input {
        width: 100%;
        height: 45px;
        border: 1px solid #6B6B6B;
        border-radius: 7px;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        transition: 0.3s;
        background: white;
        @include parragraph-normal(Quicksand, 16px, 500, normal);
        color: #6B6B6B;

        &::placeholder {
          color: #6B6B6B;
        }

        &:focus {
          border: 2px solid var(--color-gold);
        }
      }
    }

    .select-formcontact {

      display: flex;
      gap: 10px;

      div {
        width: 100%;

        @media (min-width: 768px) {
          width: 50%;
        }
      }

      select {
        width: 100%;
        height: 45px;
        border: 1px solid #6B6B6B;
        border-radius: 7px;
        outline: none;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('../../../assets/icons/arrow_down.png');
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) center;
        padding: 10px;
        @include parragraph-normal(Quicksand, 16px, 500, normal);
        line-height: normal;
        color: #6B6B6B;

        &:focus {
          border: 2px solid var(--color-gold);
        }
      }

      input {
        width: 100%;
        height: 45px;
        border: 1px solid #6B6B6B;
        border-radius: 7px;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        transition: 0.3s;
        background: white;
        @include parragraph-normal(Quicksand, 16px, 500, normal);
        color: #6B6B6B;

        &::placeholder {
          color: #6B6B6B;
        }

        &:focus {
          border: 2px solid var(--color-gold);
        }
      }
    }

    .radiobutton {
      margin-block: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      @include parragraph-normal(Quicksand, 16px, 400, normal);
    }

    .checkbox__form {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 12px;
      margin-top: 16px;
      @include parragraph-normal(Quicksand, 16px, 500, normal);

      span {
        @include parragraph-normal(Quicksand, 12px, 600, normal);
        color: red;
      }

      label {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 10px 0;
      }

      &__terms{
        margin: 10px 0;
        font-weight: 400;

        label {
          margin: 0;
        }
      }

      input[type="checkbox"] {
        width: 100%;
        max-width: 18px;
        height: 18px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 4px;
        border: 1px solid var(--color-gold);
      }

      input[type="checkbox"]:checked::before {
        content: "\2713";
        color: white;
        text-align: center;
        display: grid;
        place-content: center;
        background-color: var(--color-gold);
      }
    }

    span {
      @include parragraph-normal(Quicksand, 12px, 600, normal);
      color: red;
    }


    .select-form {
      flex-direction: column;
      display: flex;
      gap: 10px;

      select {
        width: 100%;
        height: 45px;
        border: 1px solid #6B6B6B;
        border-radius: 7px;
        outline: none;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('../../../assets/icons/arrow_down.png');
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) center;
        padding: 10px;
        @include parragraph-normal(Quicksand, 16px, 500, normal);
        color: #6B6B6B;

        &:focus {
          border: 2px solid var(--color-gold);
        }
      }

      input {
        width: 100%;
        height: 45px;
        border: 1px solid #6B6B6B;
        border-radius: 7px;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        transition: 0.3s;
        background: white;
        @include parragraph-normal(Quicksand, 16px, 500, normal);
        color: #6B6B6B;

        &::placeholder {
          color: #6B6B6B;
        }

        &:focus {
          border: 2px solid var(--color-gold);
        }
      }
    }

    .input-inlines {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      @include parragraph-normal(Quicksand, 16px, 400, normal);

      @media (min-width: 768px) {
        flex-direction: row;
      }

      &__content {
        width: 100%;
        margin-block: 16px;

        @media (min-width: 768px) {
          width: 50%;
        }

        label {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        input {
          width: 100%;
          height: 45px;
          border: 1px solid #6B6B6B;
          border-radius: 7px;
          outline: none;
          padding: 10px;
          box-sizing: border-box;
          transition: 0.3s;
          background: white;
          @include parragraph-normal(Quicksand, 16px, 500, normal);
          color: #6B6B6B;

          &::placeholder {
            color: #6B6B6B;
          }

          &:focus {
            border: 2px solid var(--color-gold);
          }
        }
      }
    }

    .btnsguest,
    .btnsguest2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include parragraph-normal(Quicksand, 16px, 400, normal);


      .btn-content {
        display: flex;
        align-items: center;
        gap: 8px;


        button {
          width: 40px;
          height: 45px;
          border: none;
          border-radius: 7px;
          outline: none;
          padding: 10px;
          box-sizing: border-box;
          transition: 0.3s;
          background: var(--color-gold);
          color: white;
          cursor: pointer;
          @include parragraph-normal(Quicksand, 18px, 600, normal);

          &:hover {
            background: #c6914c;
          }

          &:disabled {
            background: var(--color-gold);
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        input {
          @include parragraph-normal(Quicksand, 16px, 400, normal);
          height: 40px;
          width: 100%;
          max-width: 80px;
          border: 1px solid #D9D9D9;
          border-radius: 8px;
          text-align: center;
        }
      }

    }

    .btnsguest2 {
      margin-top: 16px;
    }

    .btn-submit {

      width: 100%;
      height: 45px;
      border: none;
      border-radius: 7px;
      margin: 24px 0;
      outline: none;
      padding: 10px;
      box-sizing: border-box;
      transition: 0.3s;
      background: var(--color-gold);
      color: white;
      cursor: pointer;
      @include parragraph-normal(Quicksand, 16px, 600, normal);

      &:disabled {
        background: var(--color-gold);
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }


}