.titlepage {
  font-size: 3.125rem;
  line-height: 3.125rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
  text-transform: uppercase;

  @media (min-width: 425px) {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }

  @media (min-width: 1024px) {
    font-size: 5.625rem;
    line-height: 5.625rem;
  }
}

// .paragraph {
//   font-size: .875rem;
//   line-height: 1.5;
//   margin: 0;
//   font-family: Sofia;
//   font-weight: 100;

//   @media (min-width: 425px) {
//     font-size: 1rem;
//   }
// }

.titlebanner{
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;

  @media (min-width: 425px) {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }

  @media (min-width: 1024px) {
    font-size: 5.625rem;
    line-height: 5.625rem;
  }
}

.titlebannerc {
  font-size: 2.375rem;
  line-height: 2.375rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: 425px) {
    font-size: 2.625rem;
    line-height: 2.625rem;
  }

  @media (min-width: 1024px) {
    font-size: 4.375rem;
    line-height: 4.5rem;
  }
}

.subtitlebanner {
  font-size: 1.625rem;
  line-height: 1.625rem;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 16px;

  @media (min-width: 425px) {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

.descriptionbanner {
  font-size: .875rem;
  line-height: 1.5;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.subtitlepage {
  font-size: 1.625rem;
  line-height: 1.625rem;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
  text-transform: uppercase;

  @media (min-width: 425px) {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

.title2page {
  font-size: 1.625rem;
  line-height: 1.625rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: bold;
  text-transform: uppercase;

  @media (min-width: 425px) {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }
}

.subtitle2page {
  font-size: 1.375rem;
  line-height: 1.375rem;
  padding-bottom: .5rem;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
  text-transform: uppercase;

  @media (min-width: 425px) {
    padding-bottom: 1.875rem;
    font-size: 1.875rem;
  }

  @media (min-width: 1024px) {
    padding-bottom: 2.5rem;
    font-size: 2.1875rem;
  }
}

.titlesection {
  font-size: 1.375rem;
  line-height: 1.375rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;

  @media (min-width: 425px) {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

.title2section{
  font-size: 1.375rem;
  line-height: 1.375rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
  color: white;

  @media (min-width: 425px) {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

.subtitlesection {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.textGallery {
  font-size: 1.25rem;
  line-height: 1.5625rem;
  margin-bottom: .875rem;
  font-family: NexaSlab;
  font-weight: 700;
}

// .textmobGallery {
//   text-transform: uppercase;
//   font-size: 1.125rem;
//   line-height: 1rem;
//   margin: 0;
//   padding-left: .5rem;
//   padding-bottom: .625rem;
//   font-family: NexaSlab;
//   font-weight: 700;
//   text-align: left;
// }

.textGallery2 {
  font-size: 1.5625rem;
  line-height: 1.5625rem;
  margin-bottom: .875rem;
  font-family: Sofia;
  font-weight: 100;
}

.titletab {
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-family: Sofia;
  font-weight: 100;

  @media (min-width: 1024px) {
    font-size: 1.1875rem;
    line-height: 1.1875rem;
  }
}

.titletabmodal {
  font-size: 1rem;
  line-height: 1.125rem;
  font-family: Sofia;
  font-weight: 100;

}

.titletabm {
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-family: Sofia;
  font-weight: 100;
}

.subtitletab {
  font-size: 1.125rem;
  line-height: 1.5;
  font-family: NexaSlab;
  font-weight: 700;
  
  @media (min-width: 1024px) {
    color: #D69C4F;
    font-size: 1.25rem;
  }
}

.subtitletabm {
  font-size: 1.125rem;
  line-height: 1.5;
  font-family: NexaSlab;
  font-weight: 700;
  margin: 0;
}

.descriptiontab {
  font-size: 1rem;
  line-height: 1.5;
  font-family: Sofia;
  font-weight: 100;
}

.descriptiontabm {
  font-size: 1rem;
  line-height: 1.5;
  font-family: Sofia;
  font-weight: 100;
}

.textcarddiscountbf,
.titlecarddiscount {
  font-size: 1.875rem;
  line-height: 1.875rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
}

.titlecardrelax {
  font-size: 1.0625rem;
  line-height: 1.0625rem;
  margin: 0;
  padding: 0;
  font-family: NexaSlab;
  font-weight: 700;
}

.subtitlecardrelax{
  font-size: .875rem;
  line-height: .875rem;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.pricecardrelax {
  font-size: 1.5625rem;
  line-height: 1.5625rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
}

.sbbcardrelax{
  font-size: .75rem;
  line-height: .75rem;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.textcarddiscountbf {
  text-align: center;
}

.subtitlecarddiscount {
  font-size: 3.125rem;
  line-height: 3.125rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
}

.textcarddiscountp {
  font-size: 1.5625rem;
  line-height: 1.5625rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
}

.textcarddiscount {
  font-size: .625rem;
  line-height: .625rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
}

.textcardpromotion {
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
  text-align: center;
}

.textcardexclusive {
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  font-family: Sofia;
  text-align: center;
  font-weight: 100;
}

.textcardquota {
  font-size: .875rem;
  line-height: 1rem;
  margin: 0;
  font-family: Sofia;
  text-align: center;
  font-weight: 100;
}

.titlevisit {
  font-size: 3.125rem;
  line-height: 3.125rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
}
.subtitlevisit {
  font-size: 1.125rem;
  line-height: 1.125rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
}

.subsubtitlevisit {
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.titlehotel{
  font-size: 1.125rem;
  line-height: 1.125rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
}

.subtitlehotel {
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.titlemodal {
  font-size: 1.25rem;
  line-height: 1.125rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
}

.textrestaurant {
  font-size: .875rem;
  line-height: 1.5;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
}

.buttonrestaurant {
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  font-family: Sofia;
  font-weight: 400;
  color: #D69C4F;
}

.textbannerslider {
  font-size: 2.1875rem;
  line-height: 2.1875rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;

  @media (min-width: 425px) {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }
}

.textcursivebannerslider {
  font-size: 2.1875rem;
  line-height: 2.1875rem;
  margin: 0;
  font-family: Dancing Script,cursive;
  font-weight: 400;

  @media (min-width: 425px) {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }
}

.titlebannerslider {
  font-size: 3.125rem;
  line-height: 3.125rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;

  @media (min-width: 425px) {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }

  @media (min-width: 1024px) {
    font-size: 5.625rem;
    line-height: 5.625rem;
  }
}

.textbannersliderprc {
  font-size: 1.875rem;
  line-height: 1.875rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;

  @media (min-width: 425px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }
}

.textbannersliderdsct {
  font-size: .875rem;
  line-height: .875rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;

  @media (min-width: 425px) {
    font-size: 1rem;
    line-height: 1rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
}

.textbannersCard {
  font-size: .875rem;
  line-height: .875rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;

  @media (min-width: 425px) {
    font-size: 1rem;
    line-height: 1rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }
}

.textfooter {
  font-size: .75rem;
  line-height: .75rem;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;

  @media (min-width: 425px) {
    font-size: .875rem;
    line-height: .875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.textnormal {
  font-size: .75rem;
  line-height: 1.5;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;

  @media (min-width: 425px) {
    font-size: .875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }
}

.textfilter {
  font-size: .875rem;
  line-height: 1.5;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;

  @media (min-width: 1024px) {
    font-size: .875rem;
  }
}

.textfilter2 {
  font-size: .875rem;
  line-height: 1.5;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
  text-align: left;

  @media (min-width: 1024px) {
    font-size: .875rem;
  }
}

.subtitlefilter {
  font-size: .875rem;
  line-height: 1;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.titlecardicon {
  font-size: 1rem;
  line-height: 1;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.tagcardicon{
  font-size: .875rem;
  line-height: 1rem;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.subtitlecardicon {
  font-size: .875rem;
  line-height: 1;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.sbbcardicon {
  font-size: .75rem;
  line-height: 1;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
}

.sectionrestaurant,
.sectionfilter {
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;
}

.cleanfilter {
  font-size: .875rem;
  line-height: 1;
  margin: 0;
  border: none;
  background: transparent;
  font-family: NexaSlab;
  font-weight: 700;
  color:#D69C4F;
  user-select: none;
  cursor: pointer;
  
  &:active {
    font-size: .9063rem;
  }
}

.textdestiny {
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 100;
}

.textexperience {
  font-size: .875rem;
  line-height: 1.5;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 700;

  @media (min-width: 1024px) {
    font-size: 1.125rem;
  }
}

.sliderItem {
  font-family: NexaSlab;
  font-weight: 700;
  font-size: .875rem;
  margin: 0;
  line-height: 100%;
  padding-top: 7px;
}

.sliderBanner {
  font-family: NexaSlab;
  font-weight: 700;
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.125rem;
}

.texticon {
  font-size: .75rem;
  line-height: 1.5;
  margin: 0;
  font-family: Sofia;
  font-weight: 100;
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;

  @media (min-width: 425px) {
    font-size: .875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }
}

.bcyberw {
  color: #FF1E74;
  text-align: center;
  font-family: Sofia;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  margin: 0;
}

.txpromotion {
  font-size: 1rem;
  line-height: 1.2;
  margin: 0;
  font-family: NexaSlab;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }

  p {
    margin: 0;
  }
}