@import '../../../components/atoms/text/styles.scss';

.agenciaseventos {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding-block: 50px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  &__text {
    .subtitle {
      @include parragraph-normal(NexaSlab, 30px, 400, normal);
      line-height: normal;
      margin-bottom: 20px
    }

    .paragraph {
      @include parragraph-normal(Quicksand, 14px, 400, normal);
      margin-bottom: 30px
    }

  }

  &__img {
    @include flex-center;

    figure {
      width: 100%;
      max-width: 449px;
      height: 304px;
      object-fit: cover;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: cover;
    }
  }
}

.terms-url {
  font-family: Quicksand;
  font-weight: 700;
  font-style: normal;
  color: var(--color-gold);
  cursor: pointer;
  text-decoration: none;
}

.agenciascelebra {
  background-repeat: repeat;
  background-position: center;
  background-image: url('../../../assets/images/bkg-texture.webp');
  padding-block: 50px 25px;

  &__text {
    .subtitle {
      @include parragraph-normal(NexaSlab, 30px, 400, normal);
      line-height: normal;
      text-align: center;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-template-rows: auto;
    justify-items: center;
    gap: 20px;
    padding-block: 50px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }

    &--icon {
      @include flex(flex-start, center, column, 10px);
      width: 160px;

      .bg-icon {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background-color: var(--color-gold);
        @include flex-center;
        @include scale;

        img {
          width: 70px;
          height: 90px;
          object-fit: contain
        }
      }

      .paragraph {
        @include parragraph-normal(Quicksand, 18px, 300, normal);
        text-align: center;
      }
    }
  }
}


.agenciasestilos {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 50px;
  gap: 40px;

  &__text {
    .subtitle {
      @include parragraph-normal(NexaSlab, 30px, 400, normal);
      line-height: normal;
      text-align: center;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    height: 100%;
    align-items: center;
    gap: 20px;
    padding-block: 20px;
    justify-items: center;

    @media (min-width: 768px) {
      grid-template-columns: repeat(7, auto);
      justify-items: flex-start;
    }

    .subtitlecolumn {
      @include parragraph-normal(NexaSlab, 20px, 500, normal);
      text-align: center;
      margin-bottom: 10px;
    }

    .paragraph {
      @include parragraph-normal(Quicksand, 14px, 400, normal);
      line-height: normal;
      text-align: center;

      @media (min-width: 768px) {
        max-width: 260px;
        text-align: left;
      }
    }

    .column {
      @include flex(flex-start, center, column, 10px);
      background-color: #F9F9F9;
      border-radius: 15px;
      padding: 44px 18px;
      min-width: 256px;
      height: 100%;

      @media (max-width: 768px) {
        width: 100%;
      }

      &__data {
        display: flex;
        gap: 7px;
        align-items: center;

        .number {
          @include parragraph-normal(Quicksand, 30px, 400, normal);
        }

        .text {
          @include parragraph-normal(Quicksand, 16px, 400, normal);
        }
      }

      .card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 20px 20px;

        &__content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;

          .image {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {

              width: 60px;
              height: 60px;
              max-width: 100%;
              max-height: 100%;
              object-fit: cover;
            }
          }

          .text {
            @include parragraph-normal(Quicksand, 14px, 400, normal);
            text-align: center;
          }
        }
      }
    }

    .w30 {
      width: 30px;
    }

    .lista {
      ul {
        margin-inline-start: 20px;
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        line-height: normal;
      }
    }

    .icon-rotate {
      transform: rotate(90deg);

      @media (min-width: 768px) {
        transform: rotate(0);
      }
    }
  }
}

.eventosforms {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  background-image: url('../../../assets/images/home/eventos.webp');

  @media (min-width: 1200px) {
    height: 1180px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__container {
    padding-inline: 16px;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--box {
      display: grid;
      grid-template-columns: 1fr;
      padding: 70px 0 60px 0;
      gap: 20px;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1.2fr;
      }
    }

    p.form-label {
      @include parragraph-normal(Quicksand, 16px, 600, normal);
      color: black;
      margin-top: 10px;
    }

    &--text {
      color: white;
      padding-top: 25px;

      .title {
        @include parragraph-normal(NexaSlab, 60px, 400, normal);
        line-height: normal;
      }

      .subtitle {
        @include parragraph-normal(Quicksand, 28px, 100, normal);
        text-transform: uppercase;
      }

      ul {
        @include parragraph-normal(Quicksand, 18px, 200, normal);
        margin-bottom: 12px;
        margin-inline-start: 20px;
      }
    }


    &--form {
      background-color: white;
      border-radius: 12px;
      padding: 40px 40px;

      @media (min-width: 768px) {
        padding: 40px 60px;
      }

      .radiobutton {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        flex-direction: column;

        @media (min-width: 768px) {
          flex-direction: row;
        }
      }

      .form-option1 {
        padding-block: 10px;

        .input-formcontact {
          input {
            width: 100%;
            height: 45px;
            margin: 10px 0;
            border: 1px solid #6B6B6B;
            border-radius: 7px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
            transition: 0.3s;
            background: white;
            @include parragraph-normal(Quicksand, 16px, 500, normal);
            color: #6B6B6B;

            &::placeholder {
              color: #6B6B6B;
            }

            &:focus {
              border: 2px solid var(--color-gold);
            }
          }

          span {
            @include parragraph-normal(Quicksand, 12px, 600, normal);
            color: red;
          }
        }

        .input-inlines {
          display: flex;
          gap: 20px;
          align-items: flex-end;

          div {
            width: 100%;
          }

          input {
            margin: 10px 0;
            width: 100%;
            height: 45px;
            border: 1px solid #6B6B6B;
            border-radius: 7px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
            transition: 0.3s;
            background: white;
            @include parragraph-normal(Quicksand, 16px, 500, normal);
            color: #6B6B6B;

            &::placeholder {
              color: #6B6B6B;
            }

            &:focus {
              border: 2px solid var(--color-gold);
            }

            span {
              @include parragraph-normal(Quicksand, 12px, 600, normal);
              color: red;
            }
          }

          select {
            margin: 10px 0;
            width: 100%;
            height: 45px;
            border: 1px solid #6B6B6B;
            border-radius: 7px;
            outline: none;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: url('../../../assets/icons/arrow_down.png');
            background-size: 15px;
            background-repeat: no-repeat;
            background-position: calc(100% - 16px) center;
            padding: 10px;
            @include parragraph-normal(Quicksand, 16px, 500, normal);
            color: #6B6B6B;

            &:focus {
              border: 2px solid var(--color-gold);
            }
          }

          span {
            @include parragraph-normal(Quicksand, 12px, 600, normal);
            color: red;
          }
        }

        .select-form {

          select {
            width: 100%;
            height: 45px;
            margin: 10px 0;
            border: 1px solid #6B6B6B;
            border-radius: 7px;
            outline: none;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: url('../../../assets/icons/arrow_down.png');
            background-size: 15px;
            background-repeat: no-repeat;
            background-position: calc(100% - 16px) center;
            padding: 10px;
            @include parragraph-normal(Quicksand, 16px, 500, normal);
            color: #6B6B6B;

            &:focus {
              border: 2px solid var(--color-gold);
            }
          }
        }

        span {
          @include parragraph-normal(Quicksand, 12px, 600, normal);
          color: red;
        }

      }

      .form-option2 {
        padding-block: 10px;

        .input-formcontact {
          input {
            width: 100%;
            height: 45px;
            border: 1px solid #6B6B6B;
            margin: 10px 0;
            border-radius: 7px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
            transition: 0.3s;
            background: white;
            @include parragraph-normal(Quicksand, 16px, 500, normal);
            color: #6B6B6B;

            &::placeholder {
              color: #6B6B6B;
            }

            &:focus {
              border: 2px solid var(--color-gold);
            }
          }

          span {
            @include parragraph-normal(Quicksand, 12px, 600, normal);
            color: red;
          }
        }

        .input-inlines.input-inlines-hours {
          margin-bottom: 0;

          @media (min-width: 768px) {
            width: 100%;
          }
        }

        .input-inlines {
          display: flex;
          gap: 20px;
          align-items: flex-end;

          div {
            width: 100%;
          }

          input, select {
            margin: 10px 0;
            width: 100%;
            height: 45px;
            border: 1px solid #6B6B6B;
            border-radius: 7px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
            transition: 0.3s;
            background: white;
            @include parragraph-normal(Quicksand, 16px, 500, normal);
            color: #6B6B6B;

            &::placeholder {
              color: #6B6B6B;
            }

            &:focus {
              border: 2px solid var(--color-gold);
            }
          }

          select {
            width: 100%;
            height: 45px;
            border: 1px solid #6B6B6B;
            border-radius: 7px;
            outline: none;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: url('../../../assets/icons/arrow_down.png');
            background-size: 15px;
            background-repeat: no-repeat;
            background-position: calc(100% - 16px) center;
            padding: 10px;
            @include parragraph-normal(Quicksand, 16px, 500, normal);
            color: #6B6B6B;

            &:focus {
              border: 2px solid var(--color-gold);
            }
          }
        }

        .select-form {
          select {
            width: 100%;
            height: 45px;
            border: 1px solid #6B6B6B;
            border-radius: 7px;
            outline: none;
            margin: 10px 0;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: url('../../../assets/icons/arrow_down.png');
            background-size: 15px;
            background-repeat: no-repeat;
            background-position: calc(100% - 16px) center;
            padding: 10px;
            @include parragraph-normal(Quicksand, 16px, 500, normal);
            color: #6B6B6B;

            &:focus {
              border: 2px solid var(--color-gold);
            }
          }
        }
      }

      .check {
        padding-block: 20px;
        @include parragraph-normal(Quicksand, 14px, 400, normal);

      }

      .subtitle {
        @include parragraph-normal(NexaSlab, 20px, 700, normal);
        margin-bottom: 20px;
      }

      .paragraph {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        line-height: normal;
      }

      .box {
        padding-block: 20px;
      }

      .checkbox__form {
        display: flex;
        justify-content: flex-start;
        gap: 12px;
        cursor: pointer;
        user-select: none;

        label {
          display: flex;
          align-items: center;
          gap: 10px;
          margin: 10px 0;
        }

        &__terms{
          margin: 10px 0;
          font-weight: 400;

          label {
            margin: 0;
          }
        }

        input[type="checkbox"] {
          width: 100%;
          max-width: 18px;
          height: 18px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          border-radius: 4px;
          border: 1px solid var(--color-gold);
        }

        input[type="checkbox"]:checked::before {
          content: "\2713";
          color: white;
          text-align: center;
          display: grid;
          place-content: center;
          background-color: var(--color-gold);
        }
      }

      .errors {
        @include parragraph-normal(Quicksand, 12px, 600, normal);
        color: red;
        user-select: none;
      }


      button {
        width: 100%;
        height: 45px;
        border: none;
        border-radius: 7px;
        margin: 10px 0;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        transition: 0.3s;
        background: var(--color-gold);
        color: white;
        cursor: pointer;
        @include parragraph-normal(NexaSlab, 16px, 600, normal);
        // text-transform: uppercase;

        &:disabled {
          background: var(--color-gold);
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

    }
  }

  &__text {
    &--numbers {
      @include flex(flex-start, center, row, 40px);
      text-align: center;
      padding-block: 60px;

      .subtitle {
        @include parragraph-normal(Quicksand, 42px, 300, normal);
      }

      .paragraph {
        @include parragraph-normal(Quicksand, 26px, 300, normal);
        text-transform: uppercase;
        line-height: normal;
      }
    }
  }

}

.eventspage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  &__container {
    width: 100%;
    padding-inline: 16px;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }
  }
}