@import '../../components/atoms/text/styles.scss';

.quienessomos {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: Sofia;
  position: relative;

  &__container {
    padding: 0px 16px;

    @media (min-width: 768px) {
      padding: 0px 60px;
    }

    @media (min-width: 1200px) {
      padding: 0px 120px;
    }

    h2 {
      font-family: NexaSlab;
      line-height: 1.2;
    }

    p:nth-child(1) {
      text-align: center;
    }

    &-mapa {

      img {
        width: 100%;
        max-height: 520px;
        object-fit: cover;
      }
    }

    &-iconos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 20px;
      padding: 30px 0px 60px;
    }
  }
}


.quienessomos2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: Sofia;

  &__container {
    width: 100%;
    padding: 0px 16px;

    @media (min-width: 768px) {
      padding: 0px 60px;
    }

    @media (min-width: 1200px) {
      padding: 0px 120px;
    }

    &-text{
      display: grid;
      grid-template-columns: 1fr;
      padding-top: 42px;
      padding-bottom: 42px;
      gap: 30px;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }

      .content{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        gap: 10px;

        .title{
          @include parragraph-normal(NexaSlab, 25px, 700, normal);
        }

        .paragraph{
          @include parragraph-normal(Quicksand, 18px, 400, normal);
        }
      }
    }

    h2 {
      font-family: NexaSlab;
      line-height: 1.2;
    }

    &-mapa {

      img {
        width: 100%;
        max-height: 520px;
        object-fit: cover;
      }
    }

    &-iconos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 20px;
      padding: 30px 0px 60px;

      .text1{
        @include parragraph-normal(NexaSlab, 25px, 400, normal);
        padding-top: 16px;
      }

      .text2{
        @include parragraph-normal(NexaSlab, 25px, 700, normal);

      }
    }



    .bordercultura{
      // width: 284px;
      // height: 251px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
      width: 244px;
      height: 260px;
      border-radius: 9px;
      background: #434343;
      padding: 20px;

      p {
        font-family: Sofia;
        line-height: 1.2;
        color: white;

        &:nth-child(1) {
          font-size: 18px;
        }

        &:nth-child(2) {
          font-size: 16px;
          margin: 0;
        }
      }
        
    }

    .borderlnk {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      width: 240px;
      height: 400px;

      img{
        border-radius: 12px;
      }

      p {
        text-align: center;
        font-family: Sofia;
        line-height: 1.2;

        &:nth-child(1) {
          font-size: 20px;
          margin: 0;
        }

        &:nth-child(2) {
          font-size: 16px;
          margin: 0;
        }

        &:nth-child(3) {
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
        }
      }
    }

    .bordertrip img{
      border-radius: 50%;
      padding: 40px 0px;
    }
  }
}

.bordercolumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  min-height: 420px;

  
  img{
    width: 100%;
    min-height: 440px;
    object-fit: cover;
  }

  &__text {
    padding: 40px 20px;

    @media (min-width: 768px) {
      padding: 20px 120px;
    }

    .title{
      font-family: NexaSlab;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
    }

    .lista {
      margin-bottom: 20px;

      p:nth-child(1) {
        font-weight: 700;
      }
    }

    p {
      font-family: Sofia;
      font-weight: 100;
      font-size: 18px;
      margin: 0;
    }

    .final {
      text-align: center;
    }

  }
}

.quienessomos3 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: Sofia;

  &__container {
    width: 100%;
    padding: 0px 16px;

    @media (min-width: 768px) {
      padding: 0px 60px;
    }

    @media (min-width: 1200px) {
      padding: 0px 120px;
    }

    h2 {
      font-family: NexaSlab;
      line-height: 1.2;
    }

    &-mapa {

      img {
        width: 100%;
        max-height: 520px;
        object-fit: cover;
      }
    }

    &-iconos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 20px;
      padding: 30px 0px 60px;
    }

    .bordercultura{
      // width: 284px;
      // height: 251px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
      width: 244px;
      height: 260px;
      border-radius: 9px;
      background: #434343;
      padding: 20px;

      p {
        font-family: Sofia;
        line-height: 1.2;
        color: white;

        &:nth-child(1) {
          font-size: 18px;
        }

        &:nth-child(2) {
          font-size: 16px;
          margin: 0;
        }
      }
        
    }

    .borderlnk {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      width: 240px;
      height: 400px;

      img{
        border-radius: 12px;
      }

      p {
        text-align: center;
        font-family: Sofia;
        line-height: 1.2;

        &:nth-child(1) {
          font-size: 20px;
          margin: 0;
        }

        &:nth-child(2) {
          font-size: 16px;
          margin: 0;
        }

        &:nth-child(3) {
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
        }
      }
    }

    .bordertrip img{
      border-radius: 50%;
      padding: 40px 0px;
    }
  }
}