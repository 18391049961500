@import '../../../components/atoms/text/styles.scss';

.formmomentos {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  font-family: Quicksand;

  @media (min-width: 768px) {
    padding: 50px;
  }

  &__container {
    justify-content: center;
    padding-block: 20px;
    line-height: 1.5;

    @media (min-width: 1200px) {
      max-width: 800px;
    }

    ol li {
      margin-left: 20px;
    }

    ul li {
      margin-left: 20px;
    }

    .columinputs1 span {
      @include parragraph-normal(Quicksand, 12px, 600, normal);
      color: red;
    }
    .columinputs1 p {
      @include parragraph-normal(Quicksand, 14px, 500, normal);
      color: #6B6B6B;
    }

    &--form {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: 20px;
      // row-gap: 16px;
      margin: 24px 0 16px 0;

      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      span {
        @include parragraph-normal(Quicksand, 12px, 600, normal);
        color: red;
      }

      .input-form {
        input {
          width: 100%;
          height: 45px;
          border: 1px solid #6B6B6B;
          border-radius: 7px;
          outline: none;
          padding: 10px;
          box-sizing: border-box;
          transition: 0.3s;
          background: white;
          @include parragraph-normal(Quicksand, 16px, 500, normal);
          color: #6B6B6B;

          &::placeholder {
            color: #6B6B6B;
          }

          &:focus {
            border: 2px solid var(--color-gold);
          }
        }
      }

      .select-form {

        select {
          width: 100%;
          height: 45px;
          border: 1px solid #6B6B6B;
          border-radius: 7px;
          outline: none;
          background-color: transparent;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-image: url('../../../assets/icons/arrow_down.png');
          background-size: 15px;
          background-repeat: no-repeat;
          background-position: calc(100% - 16px) center;
          padding: 10px;
          margin: 10px 0;
          @include parragraph-normal(Quicksand, 16px, 500, normal);
          color: #6B6B6B;

          &:focus {
            border: 2px solid var(--color-gold);
          }
        }
      }

    }

    .textarea-form {
      textarea {
        width: 100%;
        height: 100px;
        border: 1px solid #6B6B6B;
        border-radius: 7px;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        transition: 0.3s;
        background: white;
        resize: none;
        @include parragraph-normal(Quicksand, 16px, 500, normal);
        color: #6B6B6B;

        &::placeholder {
          color: #6B6B6B;
        }

        &:focus {
          border: 2px solid var(--color-gold);
        }

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-gold);
          border-radius: 10px;
        }

        /* Cambiar el color de fondo del scrollbar */
        &::-webkit-scrollbar-track {
          background-color: #f1ecec;
          border-radius: 10px;
        }
      }

      span {
        @include parragraph-normal(Quicksand, 12px, 600, normal);
        color: red;
      }
    }

    .radio__form {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .text-terms {
      margin: 16px 0;
      @include parragraph-normal(Quicksand, 16px, 400, normal);

      .checkbox__form {
        span {
          @include parragraph-normal(Quicksand, 12px, 600, normal);
          color: red;
        }

        label {
          align-items: center;
          display: flex;
          margin: 10px 0;
        }

        input[type="checkbox"] {
          width: 100%;
          max-width: 20px;
          height: 20px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          border-radius: 4px;
          border: 1px solid var(--color-gold);
          margin-right: 10px;
        }

        input[type="checkbox"]:checked::before {
          content: "\2713";
          color: white;
          align-items: center;
          justify-content: center;
          background-color: var(--color-gold);
          width: auto;
          height: 18px;
          display: flex;
        }
      }
    }

    button {
      width: 100%;
      height: 45px;
      border: none;
      border-radius: 7px;
      margin: 10px 0;
      outline: none;
      padding: 10px;
      box-sizing: border-box;
      transition: 0.3s;
      background: var(--color-gold);
      color: white;
      cursor: pointer;
      @include parragraph-normal(Quicksand, 16px, 700, normal);
      text-transform: uppercase;

      &:disabled {
        background: var(--color-gold);
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .image-gallery {

    &__container {
      &--item{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        height: 100%;

        @media (min-width: 768px) {
          gap: 30px;
        }
      }

      &--img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .enlace_terminos_y_condiciones {
    display: block;
    margin-bottom: 20px;

    font-family: Quicksand;
    font-style: normal;
    color: #d69c4f;
    font-weight: bold;
    font-size: 18px;
    text-align: center;

    margin-bottom: 30px;
  }

  .enlace {
    font-size: 16px;
    font-weight: 400;
    color: inherit;
  }
}
