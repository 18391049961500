@import '../../atoms/text/styles.scss';

.bgimg {
  width: 240px;
  // height: 240px;
  border-radius: 9px;
  background-image: url('../../../assets/images/home/desct1.webp');
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 30px 30px 40px 30px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

  &__inner {
    box-sizing: border-box;
    width: 190px;
    height: 190px;
    position: relative;

    &--container {
      position: absolute;
      bottom: -5%;
      right: 50%;
      transform: translateX(50%);
      background-color: white;
      border-radius: 3px;
      width: 138px;
      height: 38px;
      filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.12));
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--description {
      color: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 28px 0px;

      .content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        &--right {
          display: flex;
          flex-direction: column;
          margin-left: .25rem;
        }
      }

      .contentq {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 4px;
      }
    }
  }
}

.bgquota {
  width: 100%;
  max-width: 260px;
  height: 273px;
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 30px 30px 50px 30px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

  &__inner {
    box-sizing: border-box;
    width: 100%;
    position: relative;

    &--container {
      position: absolute;
      bottom: -12%;
      right: 50%;
      transform: translateX(50%);
      background-color: white;
      border-radius: 3px;
      width: 151px;
      height: 45px;
      filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.12));
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--description {
      color: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      height: 193px;
      padding: 16px 12px 28px;

      .valor {
        @include parragraph-normal(NexaSlab, 60px, 700, normal);
      }


      .contentq {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 4px;
      }
    }

    &--title {
      @include parragraph-normal(NexaSlab, 35px, 600, normal);
    }

    &--subtitle {
      @include parragraph-normal(Quicksand, 12px, 400, normal);
      line-height: normal;
      text-align: center;
    }
  }
}

.bgbankrestaurant {
  width: 260px;
  height: 237px;
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  // overflow: hidden;
  padding: 40px 42px 40px 42px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__inner {
    box-sizing: border-box;
    position: relative;

    &--container {
      position: absolute;
      bottom: -12%;
      right: 50%;
      transform: translateX(50%);
      background-color: white;
      border-radius: 3px;
      width: 151px;
      height: 45px;
      filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.12));
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--description {
      color: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      // width: 200px;
      height: 157px;
      padding: 20px 26px;

      .valor {
        @include parragraph-normal(NexaSlab, 60px, 700, normal);
      }


      .contentq {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 4px;
      }
    }

    &--title {
      @include parragraph-normal(NexaSlab, 35px, 600, normal);
    }

    &--subtitle {
      @include parragraph-normal(Quicksand, 12px, 400, normal);
      line-height: normal;
      text-align: center;
    }
  }
}

.bgimgbuffet {
  width: 240px;
  height: 240px;
  border-radius: 9px;
  background-image: url('../../../assets/images/home/desct1.webp');
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);


  &__inner {
    box-sizing: border-box;
    width: 190px;
    height: 190px;
    position: relative;

    &--container {
      position: absolute;
      bottom: -5%;
      right: 50%;
      transform: translateX(50%);
      background-color: white;
      border-radius: 3px;
      width: 141px;
      height: 50px;
      filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.12));
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--description {
      color: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px 0px;
      height: -webkit-fill-available;

      .content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        &--right {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.bgcolor {
  width: 240px;
  display: flex;
  border-radius: 9px;
  position: relative;
  padding: 30px 30px 40px 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.20);

  &__inner {
    box-sizing: border-box;
    width: 190px;
    height: 190px;
    position: relative;

    &--container {
      position: absolute;
      bottom: 0%;
      right: 50%;
      transform: translateX(50%);
      background-color: white;
      border-radius: 3px;
      width: 141px;
      height: 50px;
      filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.12));
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--description {
      color: white;
      display: flex;
      align-items: center;
      flex-direction: column;

      .title {
        @include parragraph-normal(NexaSlab, 30px, 700, normal);
        text-align: center;
      }

      .interest {
        @include parragraph-normal(Quicksand, 13px, 200, normal);
        text-align: center;
      }

      .content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 6px;

        .content__valor {
          @include parragraph-normal(NexaSlab, 60px, 700, normal);
          line-height: 56px;
        }

        &--right {
          display: flex;
          flex-direction: column;
          
          .content__percentage {
            @include parragraph-normal(NexaSlab, 40px, 400, normal);
          }

          .content__dscto {
            @include parragraph-normal(NexaSlab, 10px, 300, normal);
            text-transform: capitalize;

          }
        }
      }
    }
  }
}

.cardhome {
  width: 264px;
  height: 245px;
  display: flex;
  border-radius: 9px;
  position: relative;
  // padding: 29px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.20);
  overflow: hidden;
  transition: scale .6s;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   z-index: 1;
  // }

  &:hover {
    scale: 104%;
  }

  &__inner {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
    padding: 29px;

    &--container {
      display: flex;
      justify-content: center;
      align-items: center;


      .contain-banco {
        // background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        // border-radius: 12px;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .contain-campaign {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 130px;
          height: 60px;
          object-fit: contain;
        }

        p {
          @include parragraph-normal(Dancing Script, 28px, 700, normal);
          color: white;
          text-align: center;
        }
      }

      .contain-package {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 130px;
          height: 60px;
          object-fit: contain;
        }
      }

    }

    &--description {
      color: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 6px;

      .description__left {
        @include parragraph-normal(NexaSlab, 17px, 400, normal);
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 26px;
        width: 100%;
        padding-left: 0px;
      }

      .description__title {
        @include parragraph-normal(NexaSlab, 24px, 700, normal);
        text-align: center;
        height: 26px;
        overflow: hidden;
      }

      .description__subtitle {
        @include parragraph-normal(Quicksand, 17px, 400, normal);
        text-align: center;
        height: 30px;
        margin-inline: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        height: 56px;

        .content__valor {
          @include parragraph-normal(NexaSlab, 58px, 700, normal);
          line-height: 56px;
        }

        .content__valor2 {
          @include parragraph-normal(NexaSlab, 40px, 700, normal);
          line-height: 56px;
        }

        &--right {
          display: flex;
          flex-direction: column;

          .content__percentage {
            @include parragraph-normal(NexaSlab, 40px, 400, normal);
          }

          .content__dscto {
            @include parragraph-normal(NexaSlab, 10px, 300, normal);
            text-transform: capitalize;
          }

        }

        &__text2 {
          @include parragraph-normal(Quicksand, 18px, 400, normal);
          text-align: left;
          width: 100%;
          background-color: aqua;
        }

        &__price {
          @include parragraph-normal(NexaSlab, 58px, 700, normal);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 56px;
          overflow: hidden
        }

        &__subtitle{
          @include parragraph-normal(Quicksand, 18px, 400, normal);
          text-align: center;
        }


        &__banco{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;

          img {
            width: 100%;
            height: 36px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.destimg {
  width: 240px;
  height: 230px;
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.20);


  &__inner {

    &--description {
      color: white;
      border-radius: 0px 0px 9px 9px;
      padding: 20px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);

      .paragraph {
        @include parragraph-normal(NexaSlab, 18px, 700, normal);
        line-height: normal;
        text-align: left;
      }

      .subparagraph {
        @include parragraph-normal(Quicksand, 12px, 400, normal);
        text-align: left;
      }
    }
  }
}

.galleryg {
  width: 258px;
  height: 221px;
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.20);


  &__inner {

    &--description {
      color: white;
      border-radius: 9px;
      padding: 20px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        @include parragraph-normal(NexaSlab, 18px, 700, normal);
      }

      .count {
        @include parragraph-normal(Quicksand, 12px, 500, normal);
      }
    }
  }
}

.typehotel {
  width: 212px;
  height: 198px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.20);

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .paragraph {
      @include parragraph-normal(NexaSlab, 18px, 700, normal);
      color: white;
    }
  }
}