/*
  CONTENT STRUCTURE:
    margin:            m-#rem 
    margin top:        mt-#rem
    margin right:      mr-#rem
    margin left:       ml-#rem
    margin bottom:     mb-#rem    
    margin horizontal: mx-#rem    
    margin vertical:   my-#rem   
*/

@import "../variables.scss";

@each $name, $value in $rem {
    .m-#{ $name } {
        margin: #{$value} !important;
    }

    .mt-#{ $name } {
        margin-top: #{$value} !important;
    }

    .mr-#{ $name } {
        margin-right: #{$value} !important;
    }

    .ml-#{ $name } {
        margin-left: #{$value} !important;
    }

    .mb-#{ $name } {
        margin-bottom: #{$value} !important;
    }
    
    .mx-#{ $name } {
        margin-right: #{$value} !important;
        margin-left: #{$value} !important;
    }

    .my-#{ $name } {
        margin-top: #{$value} !important;
        margin-bottom: #{$value} !important;
    }
}

