@import "../../../atoms/text/styles.scss";

.bannertirapromocion {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 5px 0;

  @media (max-width: 1200px) {
    height: 60px;
    padding: 10px 10px;
  }

  .isdesktop-promo {
    display: flex;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .ismobile-promo {
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0;

    @media (min-width: 1200px) {
      max-width: 1220px;
      padding: 0 20px;
    }

    &--btnclose {
      display: flex;
      justify-content: flex-end;
      margin-bottom: auto;
    }

    &--logopromo {
      display: flex;
      img {
        width: 100%;
        height: 40px;
        object-fit: contain;
      }

      @media (max-width: 768px) {
        img {
          max-width: 100px;
        }
      }
    }

    &--text {
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 0;
      }

      .texto-descuento {
        @include parragraph-normal(Quicksand, 20px, 500, normal);

        @media (max-width: 1200px) {
          font-size: 20px;
          text-align: center;
          line-height: 20px;
        }
      }

      .texto-promocion {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        line-height: 1.2;
        text-align: left;
        max-width: 250px;
        margin-left: 20px
      }

      .texto-descuento-life {
        @include parragraph-normal(Quicksand, 14px, 800, normal);
        font-weight: 400;
        width: auto;
        text-align: center;
        margin: 0;
        padding: 5px 16px;

        @media (max-width: 1200px) {
          margin: 5px 0;
          padding: 5px 10px;
        }

        @media (max-width: 768px) {
          margin: 0;
          font-size: 12px;
          padding: 0px 5px;
        }
      }

      .mobile-seccion-descuento {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .contentvalor {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 6px;

        &__valor {
          @include parragraph-normal(NexaSlab, 34px, 700, normal);
        }

        &--right {
          display: flex;
          flex-direction: column;

          &__percentage {
            @include parragraph-normal(NexaSlab, 20px, 600, normal);
          }

          &__dscto {
            @include parragraph-normal(NexaSlab, 10px, 300, normal);
          }
        }
      }
    }

    .button-close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-left: 40px;

      @media (max-width: 1200px) {
        margin-left: 0px;
      }

      @media (max-width: 768px) {
        margin-left: 20px;
        width: 40px;
      }

      .redirect {
        height: auto;
        text-align: center;
        margin-right: 0;
        padding: 7px 14px;
        font-size: 14px;

        @media (max-width: 1200px) {
          margin-right: 20px;
          font-size: 14px;
          padding: 12px 10px;
          width: 85px;
        }
      }
    }
  }

  .close {
    @include parragraph-normal(Quicksand, 30px, 700, normal);
    display: flex;
    align-items: center;
    justify-content: right;
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: relative;
    top: -5px;

    &:hover {
      transform: scale(1.2);
    }

    @media (max-width: 1200px) {
      right: 0px;
    }

    @media (max-width: 768px) {
      position: absolute;
      right: -5px;
      top: -25px;
      color: #D69C4F;
    }
  }

  .arrow-right {
    background: none;
    border: none;
    position: absolute;
    bottom: -10px;
    right: 35px;
  }
}
