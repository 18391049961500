@import '../../atoms/text/styles.scss';

.accordionLink {
  flex: 0 0 180px;
  position: relative;
  cursor: pointer;
  user-select: none;
  @include touchmobile;

  &__container{
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      &__edit {
        @include parragraph-normal(Quicksand, 12px, 600, normal);
        text-transform: uppercase;
        padding-block: 10px;
      }
  
      &__arrow {
        width: 18px;
        height: 18px;
      }
  
      svg {
        transition: all 0.3s ease;
      }
    }
  
    .description {
      display: none;
      padding: 10px 0px;
  
      &__link {
        display: flex;
        text-decoration: none;
        color: white;
        cursor: pointer;
        margin-bottom: 10px;
        transition: all 0.3s;
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        text-decoration: none;
  
        &:hover {
          font-weight: 300;
          color: #D69C4F;
          text-decoration: underline;
        }
  
        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
    }

  }
}

.accordionLink.active {
  .title {
    svg {
      transform: rotate(-180deg);
    }
  }

  .description {
    display: block;
  }
}