@import '../../atoms/text/styles.scss';

.servicescommodities {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;

  // @media (min-width: 768px) {
  //   padding-block: 50px;
  // }

  &__container {
    width: 100%;
    @media (min-width: 1200px){
      max-width: 1220px;
    }

    .subtitle {
      @include title-section2(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }

    &--services {
      .description {
        @media (max-width: 1200px) {
          padding: 1rem;
        }

        .services {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: auto;
          gap: 12px 40px;

          @media (min-width: 768px) {
            grid-template-columns: 0.8fr 1fr;
          }

          .column {
            display: flex;
            align-items: center;
            gap: 24px;
          }

          .text{
            @include parragraph-normal(Quicksand, 16px, 400, normal);
          }
        }

        @media ( min-width: 768px) {
          padding-bottom: 40px;
        }
      }
    }

    &--commodities {
      padding-top: 40px;

      .description {
        @media (max-width: 1200px) {
          padding: 1rem;
        }
        

        .services {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: auto;
          gap: 10px 40px;

          @media (min-width: 768px) {
            grid-template-columns: repeat(4, 1fr);
          }

          .column {
            display: flex;
            align-items: center;
            height: 36px;
            gap: 10px;
          }

          .text{
            @include parragraph-normal(Quicksand, 14px, 400, normal);
          }

        }
        .more{
          // padding-block: 18px;
          display: flex;
          justify-content: end;
          align-items: center;
          gap: 4px;
          cursor: pointer;
          padding-top: 16px;

          &__btn{
            @include parragraph-normal(Quicksand, 14px, 400, normal);
            text-decoration: underline;
            text-underline-offset: 4px;
          }
        }

        @media ( min-width: 768px) {
          // padding-top: 40px;
        }
      }
    }

  }


}

.container-slider{
  position: relative;
  // left:50%;
  // top:50%;
  // transform: translate(-50%,-50%);
  // width:100%;
  margin-bottom: 60px;
  height:460px;
  padding:50px;
  background-color: #f5f5f5;
  // box-shadow: 0 30px 50px #dbdbdb;
}
#card-slider{
  width:max-content;
  margin-top:50px;
}
.item{
  width:200px;
  height:300px;
  background-position: 50% 50%;
  display: inline-block;
  transition: 0.5s;
  background-size: cover;
  position: absolute;
  z-index: 1;
  top:50%;
  transform: translate(0,-50%);
  border-radius: 20px;
  box-shadow:  0 30px 50px #505050;
}
.item:nth-child(1),
.item:nth-child(2){
  left:0;
  top:0;
  transform: translate(0,0);
  border-radius: 0;
  width:100%;
  height:100%;
  box-shadow: none;
}
.item:nth-child(3){
  left:50%;
}
.item:nth-child(4){
  left:calc(50% + 220px);
}
.item:nth-child(5){
  left:calc(50% + 440px);
}
.item:nth-child(n+6){
  left:calc(50% + 660px);
  opacity: 0;
}
.item .content{
  position: absolute;
  top:50%;
  left:100px;
  width: 100%;
  max-width: 400px;
  text-align: left;
  padding:0;
  color:#eee;
  transform: translate(0, -50%);
  // transform: translate(20%, 20%);
  display: none;

  // @media (min-width: 1124px) {
  //   transform: translate(90%, 20%);
  // }
}

.item:nth-child(2) .content{
  display: block;
  z-index: 11111;
}
.item .name{
  font-size: 40px;
  font-weight: bold;
  opacity: 0;
  animation:showcontent 1s ease-in-out 1 forwards
}
.item .des{
  margin:20px 0;
  opacity: 0;
  animation:showcontent 1s ease-in-out 0.3s 1 forwards
}
.item button{
  padding:10px 20px;
  border:none;
  opacity: 0;
  animation:showcontent 1s ease-in-out 0.6s 1 forwards
}
@keyframes showcontent{
  from{
      opacity: 0;
      transform: translate(0,100px);
      filter:blur(33px);
  }to{
      opacity: 1;
      transform: translate(0,0);
      filter:blur(0);
  }
}
.buttons-card-slider{
  position: absolute;
  bottom:30px;
  z-index: 1;
  text-align: center;
  width:100%;
}
.buttons-card-slider button{
  width:50px;
  height:50px;
  border-radius: 50%;
  border:1px solid white;
  transition: 0.5s;
  background-color: white;
  box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
}

.buttons-card-slider button:hover{
  background-color:  #ead8c0;
}
