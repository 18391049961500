.section__video {
  height: 320px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;

  @media screen and (min-width: 768px) {
    height: 460px;
  }

  .banner__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .section__banner-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }

}

