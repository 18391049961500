@import "../../../atoms/text/styles.scss";

.bannerchronometer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;

  @media (max-width: 1000px) {
    display: none;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0px 16px 26px 0px;

    @media (min-width: 940px) {
      flex-direction: row;
      padding: 16px;
      gap: 16px;
    }

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--text {
      .title {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        text-align: center;
      }

      .title-secondary {
        @include parragraph-normal(Quicksand, 15px, 400, normal);
        line-height: 1.2;
        text-align: center;
        margin-top: 2px;
      }
    }

    &--img {
      img {
        width: 100%;
        height: 40px;
        object-fit: contain;
        margin-top: 5px;
      }
    }

    .button-close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-left: 60px;

      @media (max-width: 768px) {
        margin-left: 20px;
        width: 40px;
      }

      .redirect {
        height: auto;
        text-align: center;
        margin-right: 0;
        padding: 7px 14px;
        font-size: 14px;

        @media (max-width: 1200px) {
          margin-right: 20px;
          font-size: 14px;
          padding: 12px 10px;
          width: 85px;
        }
      }
    }

    .close {
      @include parragraph-normal(Quicksand, 30px, 700, normal);
      display: flex;
      align-items: center;
      justify-content: right;
      cursor: pointer;
      border: none;
      background-color: transparent;
      position: relative;
      top: -5px;

      &:hover {
        transform: scale(1.2);
      }

      @media (max-width: 1200px) {
        right: -10px;
      }

      @media (max-width: 768px) {
        position: absolute;
        right: -5px;
        top: -25px;
        color: #D69C4F;
      }
    }
  }
}

.chronometer-popup {
  display: block;
  position: fixed;
  right: 25px;
  bottom: 200px;
  z-index: 100;
  border-radius: 15px;
  padding: 5px 20px;
  padding-bottom: 10px;
  cursor: pointer;

  @media (min-width: 1000px) {
    display: none;

    &.is-scrolled {
      display: block;
    }
  }

  @media (max-width: 1000px) {
    padding: 5px;
    right: 15px;
    bottom: 155px;
    top: auto;
  }

  .close {
    border-radius: 50%;
    border: none;
    height: 30px;
    width: 30px;
    padding: 2px;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
  }
}