@import '../../atoms/text/styles.scss';

.hotels {
  display: flex;
  justify-content: center;
  align-items: center;

  &__containers {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }
  }

  &__title {
    font-size: 1rem;
    font-family: NexaSlab;
    font-weight: 700;
    text-align: left;
  }

  &__description {

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }
}


.cardholteld__colum {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 9px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
  height: 245px;
  position: relative;
  max-width: 100%;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 42px;
  }

  &--hotel {
    width: 100%;
    max-width: 364px;
    height: 100%;
    position: relative;
  }

  &--description {
    max-width: 572px;
    min-width: 200px;
    width: 100%;
    height: 100%;
    padding: 10px 0px;

    .cardheader {
      width: 100%;
      height: 100%;
      border-right: 1px solid #CBCBCB;

      &__content {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 10px 20px 0px 20px;
        gap: 10px;
        height: 100%;

        .title {
          @include parragraph-normal(NexaSlab, 20px, 700, normal);
        }

        .review {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
        }

        .place,
        .description {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          line-height: normal;
        }

        .icon-left {
          justify-content: flex-start;
        }
      }
    }


  }

  &--price {
    width: 100%;
    height: 100%;
    max-width: 230px;
    padding: 29px 18px;

    .cardprices {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &__card {
        font-family: Sofia;
        font-weight: 100;
      }

      .ttcardprices {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        margin-bottom: 16px
      }

      .pricebefore {
        @include parragraph-normal(NexaSlab, 16px, 300, normal);
        color: #8C8C8C;
        line-height: normal;
        text-decoration: line-through;
      }

      .sbcardprices {
        @include parragraph-normal(NexaSlab, 25px, 600, normal);
        line-height: normal;
      }

      .sbcardigv {
        font-family: Sofia;
        font-weight: 100;
        font-size: 1rem;
        padding-right: 4px;
      }

      .sbcardigvs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        @include parragraph-normal(Quicksand, 14px, 400, normal);
      }
    }
  }
}

.padding__general {
  padding-top: 25px;
  padding-bottom: 25px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.padding__section {
  padding-top: 25px;
  padding-bottom: 25px;
}