@import "../../../atoms/text/styles.scss";

.packagepage {
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-block: 40px;
    padding-inline: 16px;
    gap: 20px;

    @media (min-width: 768px) {
      gap: 40px;
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      padding-inline: 0;
      max-width: 1220px;
      padding-inline: 16px;
      padding-block: 68px;
    }

    &--right {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (min-width: 1200px) {
        width: 60%;
      }
    }

    &--list {
      display: flex;
      flex-direction: column;
      @include parragraph-normal(Quicksand, 16px, 400, normal);
      line-height: normal;

      @media (min-width: 768px) {
        flex-direction: row;
        gap: 10px;
      }

      ul {
        width: 100%;
        padding: 0;
        list-style-type: disc;
        list-style-position: inside;
        margin: 0;

        @media (min-width: 768px) {
          width: 50%;
        }

        li {
          box-sizing: border-box;
          list-style-position: outside;
          margin-left: 20px;
          margin-bottom: 16px;

          &::marker {
            font-size: 12px;
          }
        }
      }

    }

    &--terms {
      @include parragraph-normal(Quicksand, 16px, 400, normal);

      span {
        text-decoration: underline;
        cursor: pointer;
        color: var(--color-gold);
      }

    }

    &--details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .prices {

        &__text {
          @include parragraph-normal(NexaSlab, 16px, 400, normal);
        }

        &__price {
          @include parragraph-normal(NexaSlab, 30px, 700, normal);
          line-height: 40px;
        }

        &__taxes {
          @include parragraph-normal(Quicksand, 16px, 400, normal);
        }
      }

      .contact {

        button {
          cursor: pointer;
          background: transparent;
          border-radius: 7px;
          border: 2.5px solid var(--color-gold);
          font-size: .875rem;
          text-align: center;
          color: var(--color-gold);
          font-family: NexaSlab;
          font-weight: 700;
          padding: 12px 30px;
          display: block;
          height: 45px;

          &:hover {
            background: #c6914c;
            color: white;
          }

          &:active {
            background: #bb8b4c;
          }
        }
      }
    }

    &--left {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 326px;
      position: relative;
      overflow: hidden;

      @media (min-width: 768px) {
        width: 412px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }

      @media (min-width: 1200px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
      }
    }
  }
}

.packagepage2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F9F6F0;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-block: 40px;

    padding-inline: 16px;
    gap: 20px;

    @media (min-width: 768px) {
      gap: 40px;
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      padding-inline: 0;
      max-width: 1220px;
      padding-inline: 16px;
      padding-block: 68px;

    }

    &--left {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (min-width: 1200px) {
        width: 60%;
      }
    }

    &--list {
      display: flex;
      flex-direction: column;
      @include parragraph-normal(Quicksand, 16px, 400, normal);
      line-height: normal;

      @media (min-width: 768px) {
        flex-direction: row;
        gap: 10px;
      }

      ul {
        width: 100%;
        padding: 0;
        list-style-type: disc;
        list-style-position: inside;
        margin: 0;

        @media (min-width: 768px) {
          width: 50%;
        }

        li {
          box-sizing: border-box;
          list-style-position: outside;
          margin-left: 20px;
          margin-bottom: 16px;

          &::marker {
            font-size: 12px;
          }
        }
      }
    }

    &--terms {
      @include parragraph-normal(Quicksand, 16px, 400, normal);

      span {
        text-decoration: underline;
        cursor: pointer;
        color: var(--color-gold);
      }

    }

    &--details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .prices {

        &__text {
          @include parragraph-normal(NexaSlab, 16px, 400, normal);
        }

        &__price {
          @include parragraph-normal(NexaSlab, 30px, 700, normal);
          line-height: 40px;
        }

        &__taxes {
          @include parragraph-normal(Quicksand, 16px, 400, normal);
        }
      }

      .contact {

        button {
          cursor: pointer;
          background: transparent;
          border-radius: 7px;
          border: 2.5px solid var(--color-gold);
          font-size: .875rem;
          text-align: center;
          color: var(--color-gold);
          font-family: NexaSlab;
          font-weight: 700;
          padding: 12px 30px;
          display: block;
          height: 45px;

          &:hover {
            background: #c6914c;
            color: white;
          }

          &:active {
            background: #bb8b4c;
          }
        }
      }
    }

    &--right {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 326px;
      position: relative;
      overflow: hidden;

      @media (min-width: 768px) {
        width: 412px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }

      @media (min-width: 1200px) {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.forminputpackge {
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media (min-width: 768px) {
    justify-content: center;
    width: 320px;

    span {
      text-align: left;
    }
  }

  .paragraph {
    @include parragraph-normal(Quicksand, 16px, 500, normal);
    color: #6B6B6B;
    margin-bottom: 10px;
  }

  select {
    width: 100%;
    height: 45px;
    border: 1px solid #6B6B6B;
    border-radius: 7px;
    outline: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../../assets/icons/arrow_down.png');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
    padding: 10px;
    @include parragraph-normal(Quicksand, 16px, 500, normal);
    color: #6B6B6B;

    &:focus {
      border: 2px solid var(--color-gold);
    }
  }

  span {
    @include parragraph-normal(Quicksand, 14px, 500, normal);
    color: #ff0000;
  }

  .input-form {

    input {
      width: 100%;
      height: 45px;
      border: 1px solid #6B6B6B;
      border-radius: 7px;
      outline: none;
      padding: 10px;
      margin: 6px 0;
      box-sizing: border-box;
      transition: 0.3s;
      background: white;
      @include parragraph-normal(Quicksand, 16px, 500, normal);
      color: #6B6B6B;

      &::placeholder {
        color: #6B6B6B;
      }

      &:focus {
        border: 2px solid var(--color-gold);
      }
    }
  }

  .checkbox__form {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;
    user-select: none;

    label {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin: 10px 0;
      font-family: Quicksand;
    }

    input[type="checkbox"] {
      width: 100%;
      max-width: 18px;
      height: 18px;
      margin-top: 4px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 4px;
      border: 1px solid var(--color-gold);
    }

    input[type="checkbox"]:checked::before {
      content: "\2713";
      color: white;
      text-align: center;
      display: grid;
      place-content: center;
      background-color: var(--color-gold);
    }
  }

  .errors {
    @include parragraph-normal(Quicksand, 12px, 600, normal);
    color: red;
    user-select: none;
  }

  .btnagencies {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 7px;
    margin: 24px 0;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.3s;
    background: var(--color-gold);
    color: white;
    cursor: pointer;
    @include parragraph-normal(Quicksand, 16px, 600, normal);

    &:disabled {
      background: var(--color-gold);
      opacity: 0.5;
      cursor: not-allowed;
    }

  }
}

.packagetab {
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-inline: 16px;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--title {
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
      padding-block: 20px;

      @media (min-width: 425px) {
        font-size: 28px;
      }

      @media (min-width: 768px) {
        padding-block: 40px 28px;
      }
    }

    &--tabs {
      display: flex;
      flex-direction: row;
    }

    &--link {
      @include parragraph-normal(Quicksand, 16px, 400, normal);
      color: #6B6B6B;
      border-bottom: 1px solid currentColor;
      cursor: pointer;
      padding: 10px 30px 10px 0px;

      transition: 0.3s;

      @media (min-width: 768px) {
        padding: 10px 50px 10px 0px;
      }

      &:hover {
        background: #f2f2f2;
      }

      &:not(:first-child) {
        padding-left: 16px;
      }

      &.active {
        color: var(--color-gold);
        border-bottom: 2px solid currentColor;
        font-weight: 600;
      }

    }
  }


}