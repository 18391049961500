@import '../../atoms/text/styles.scss';

.sectionactivities {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F5F5;
  padding-block: 25px;

  // @media (min-width: 768px) {
  //   padding-block: 50px;
  // }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section2(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  .activitiesmodal {
    height: 100%;
    width: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;

  }


  &__description {
    display: flex;
    padding-left: 16px;

    @media (min-width: 768px) {
      padding-left: 0;
    }

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 320px; // Medida de la card
      margin-right: 28px; // Espacio entre cards
      margin-bottom: 14px;

      @media (min-width: 768px) {
        width: 340px;
        margin-right: 60px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 26px !important;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(50%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      width: 320px; // Medida de la card
      @include scale;
      @include touchmobile;

      @media (min-width: 768px) {
        width: 340px;
      }

      &--slider {
        width: 100%;
        height: 220px;
        border-radius: inherit 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: none;
        }
      }

      &--container {
        padding-inline: 24px;
        padding-block: 30px;
        background-color: white;
        position: relative;
        height: 148px;

        .tagposition {
          position: absolute;
          min-width: 130px;
          max-width: 300px;
          padding: 0 20px;
          height: 34px;
          border-radius: 4px;
          left: 6%;
          top: 0%;
          transform: translateY(-50%);
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          &__reservation {
            @include parragraph-normal(NexaSlab, 14px, 600, normal);
            color: white;
          }

          &__title {
            @include parragraph-normal(NexaSlab, 16px, 600, normal);
            margin-bottom: 12px;
          }
        }

        .cardtitle {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
        .carditems {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 6px;

          &__card {

            &--place,
            &--date,
            &--hours,
            &--price {
              @include parragraph-normal(Quicksand, 13px, 400, normal);
            }
          }
        }

      }
    }
  }

  &__modal {

    @media (min-width: 768px) {
      height: 440px;
    }

    &--img {
      width: 100%;

      img {
        width: 100%;
        height: 326px;
        object-fit: fill;
      }
    }


    &--description {
      .cardtitle {

        &__title {
          @include parragraph-normal(NexaSlab, 25px, 600, normal);
          text-align: center;
          margin-block: 40px 16px;
        }

        &__paragraph {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
          line-height: normal;
          margin-bottom: 16px;
        }
      }

      .carditems {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;

        &__place,
        &__date,
        &__hours,
        &__price {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
        }

        &__info,
        &__info2 {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
          margin-block: 16px;
        }

        &__info2{
          color: var(--color-gold);
          cursor: pointer;
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }
    }
  }
}

.activitiestabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-block: 16px;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D69C4F;
    border: none;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #E6E6E6;
    border-radius: 10px;
  }

  div {
    scroll-snap-align: start;
    color: #333;
    // margin-right: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      cursor: pointer;
      border-bottom: 3.5px solid var(--color-gold);
    }

    &.active {
      // border-bottom: 3.5px solid #D69C4F;
      border-bottom: 3.5px solid var(--color-gold);
    }
  }

  .activity-tab {
    p {
      text-align: center;
      @include parragraph-normal(Quicksand, 12px, 400, normal);
      margin-bottom: 10px;
    }
  }
}
