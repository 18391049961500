@import '../../atoms/text/styles.scss';

.sectioncardspromotions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
    }

    .subtitle2 {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 32px;
    }

    &--description {


      &--swiper {
        position: relative;
        width: 90%;

        @media (min-width: 768px) {
          height: 380px;
        }

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-wrapper {
          // display: flex;
          // justify-content: center;
        }

        .swiper-slide {
          width: 292px; // Medida de la card
          margin-right: 28px; // Espacio entre cards
        }
        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        @media (min-width: 768px) {

          .sactivities__swiper .swiper-slide-thumb-active {
            opacity: 1;
          }

          .sactivities__swiper {
            position: relative;
          }

          .swiper-button-prev,
          .swiper-button-next {
            color: #D69C4F;
            position: absolute;
            transform: translateY(50%);
            z-index: 1000;
          }

          .swiper-button-prev {
            left: 30%;
            top: 84%;
            @include scale;
          }

          .swiper-button-next {
            right: 30%;
            top: 84%;
            @include scale;
          }

          .swiper-button-prev:after,
          .swiper-button-next:after {
            font-size: 24px;
            font-weight: 700;
            border-radius: 50%;
            border: 2px solid #D69C4F;
            padding: 10px 15px;
            background-color: white;
            box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
          }

          .swiper-button-prev.swiper-button-disabled,
          .swiper-button-next.swiper-button-disabled {
            opacity: 0.35;
            cursor: auto;
            display: none;
            pointer-events: none;
          }
        }

        .swiper-pagination {
          height: 30px;
        }

        .swiper-slide img {
          display: block;
          width: 100%;
          height: 100%;
          max-height: 240px;
          object-fit: cover;
        }

        .swiper-pagination-bullet {
          background-color: white;
          opacity: 1;
          width: 15px;
          height: 15px;
          margin: 0 15px;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
        }

        .small-dot {
          width: 16px;
          height: 16px;
        }

        .swiper-pagination-bullet-active {
          background-color: #D69C4F;
          width: 20px;
          height: 20px;
          overflow: hidden;
          margin: 0 5px;
          // border-radius: 50%;
        }

        .swiper-pagination-fraction,
        .swiper-pagination-custom,
        .swiper-horizontal>.swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal {
          bottom: 4%;
          left: 50%;
          width: 100%;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        gap: 60px;

        @media (max-width: 1200px) {
          padding-inline: 16px;
        }

        .final {
          width: 240px;
          height: 287px;
          gap: 14px;
          padding-top: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;

          ul {
            span{
              @include parragraph-normal(Quicksand, 14px, 400, normal);
            }
            // list-style-position: outside;

            li{
              margin-left: 20px;

              ::marker{
                font-size: 10px
              }
            }
          }

          ol {
            @include parragraph-normal(Quicksand, 14px, 400, normal);
            li{
              margin-left: 20px;

              ::marker{
                font-size: 10px
              }
            }
          }

          h1 {
            @include parragraph-normal(NexaSlab, 25px, 700, normal);
            margin-bottom: 10px;
          }

          p {
            @include parragraph-normal(Quicksand, 15px, 400, normal);
            line-height: normal;
          }
        }
      }
    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.promotionsfoods {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__description {
    display: flex;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 270px; // Medida de la card
      margin-right: 28px; // Espacio entre cards

      &:last-child {
        margin-right: 0px;
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 24px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(20%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      width: 280px; // Medida de la card
      @include scale;
      @include touchmobile;

      &--slider {
        width: 100%;
        height: 225px;
        border-radius: inherit 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: none;
        }
      }

      &--container {
        padding: 16px 16px;
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        height: 88px;

        @media (min-width: 768px) {
          padding-inline: 22px;
        }

      }

      &--left {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      &--right {
        color: #D69C4F;
      }

      &--extra {
        @include flex(center, center, column, 30px);
        padding: 34px;
        cursor: pointer;
        text-decoration: none;
        height: 313px;
        @include touchmobile;

        .border {
          @include flex-center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #D69C4F;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
        }

        .paragraph {
          @include parragraph-normal(NexaSlab, 14px, normal, normal);
          line-height: normal;
          text-align: center;
        }
      }
    }

    &--paragraphup {
      @include parragraph-normal(Quicksand, 12px, 400, normal);
    }

    &--paragraphvalor {
      @include parragraph-normal(NexaSlab, 24px, 600, normal);
    }

    &--paragraphdown {
      @include parragraph-normal(Quicksand, 10px, 400, normal);
    }

    &--title {
      @include parragraph-normal(NexaSlab, 16px, 600, normal);
    }

    &--subtitle {
      @include parragraph-normal(Quicksand, 12px, 400, normal);
    }
  }
}

.promotionspackages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__description {
    display: flex;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 369px; // Medida de la card
      margin-right: 26px; // Espacio entre cards

      &:last-child {
        margin-right: 0px;
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 24px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(20%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          font-family: NexaSlab;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      background-size: cover;
      background-position: center;
      border-radius: 9px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      overflow: hidden;
      width: 369px; // Medida de la card
      height: 270px;
      @include scale;
      @include touchmobile;

      &--container {
        width: 170px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        border-radius: 0px 8px 9px 0px;
        padding: 6px 22px;

        &--content {
          color: black;
          text-align: left;
          display: flex;
          align-items: center;
          flex-direction: column;
        }

        .title {
          @include parragraph-normal(NexaSlab, 16px, 700, normal);
          line-height: normal;
        }

        .paragraph {
          text-align: center;
        }

        .description,
        .condition {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 26px;
          overflow: hidden;
        }

        .price {
          @include parragraph-normal(NexaSlab, 18px, 700, normal);
        }

      }

      &--description {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        gap: 4px;
      }

      .txhome {
        color: #008FAD;
        font-family: Dancing Script;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .txpareja {
        @include parragraph-normal(Dancing Script, 30px, 400, normal);
        color: #FF00C7;
        line-height: 28px;
        text-transform: lowercase;
        text-align: center;
      }

      .txenamorate {
        color: #F00;
        font-family: Dancing Script;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .igvpromotionscard{
      @include parragraph-normal(NexaSlab, 12px, 400, normal);
    }
  }
}

.promotionshotels {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 290px;
          height: 100%;
          padding: 10px 14px;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 20px;

        .final {
          width: 172px;
          height: 100%;
          gap: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 270px;
      height: 100%;
      padding: 10px 14px;
    }

    @media (max-width: 767px) {

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #D69C4F;
    }

    .swiper-button-prev {
      left: 1%;
    }

    .swiper-button-next {
      right: 1%;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 24px;
      font-weight: 700;
      border-radius: 50%;
      padding: 10px 15px;
      background-color: white;
      box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      display: none;
      pointer-events: none;
    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.promotionsbankss {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 240px;
          height: 280px;
          padding: 10px 14px;
        }

        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 1%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 20px;

        .final {
          width: 172px;
          height: 100%;
          gap: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.promotionsbanks {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__description {
    display: flex;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 240px; // Medida de la card
      margin-right: 40px; // Espacio entre cards

      &:last-child {
        margin-right: 0px;
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 24px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(20%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      width: 240px; // Medida de la card
      @include scale;
      @include touchmobile;

      &--slider {
        width: 100%;
        height: 225px;
        border-radius: inherit 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: none;
        }
      }

      &--container {
        padding: 16px 16px;
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        height: 88px;

        @media (min-width: 768px) {
          padding-inline: 22px;
        }

      }

      &--left {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      &--right {
        color: #D69C4F;
      }

      &--extra {
        @include flex(center, center, column, 30px);
        padding: 34px;
        cursor: pointer;
        text-decoration: none;
        height: 313px;
        @include touchmobile;

        .border {
          @include flex-center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #D69C4F;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
        }

        .paragraph {
          @include parragraph-normal(NexaSlab, 14px, normal, normal);
          line-height: normal;
          text-align: center;
        }
      }
    }

    &--paragraphup {
      @include parragraph-normal(Quicksand, 12px, 400, normal);
    }

    &--paragraphvalor {
      @include parragraph-normal(NexaSlab, 25px, 600, normal);
    }

    &--paragraphdown {
      @include parragraph-normal(Quicksand, 12px, 400, normal);
    }

    &--title {
      @include parragraph-normal(NexaSlab, 16px, 600, normal);
    }

    &--subtitle {
      @include parragraph-normal(Quicksand, 14px, 400, normal);
    }
  }
}

// .promotionsrelax {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: white;
//   padding-top: 30px;
//   padding-bottom: 20px;


//   @media (min-width: 768px) {
//     padding-top: 50px;
//     padding-bottom: 30px;
//   }

//   &__container {
//     width: 100%;

//     @media (min-width: 1200px) {
//       max-width: 1220px;
//     }

//     &--description {
//       padding-top: 2rem;

//       &--swiper {
//         position: relative;

//         .swiper {
//           width: 100%;
//           height: 100%;
//         }

//         .swiper-slide {
//           width: 343px;
//           height: 100%;
//           padding: 10px 14px;
//           display: flex;
//           justify-content: center;
//           align-items: center;
//         }

//         @media (max-width: 767px) {

//           .swiper-button-prev,
//           .swiper-button-next {
//             display: none;
//           }
//         }

//         .swiper-slide-thumb-active {
//           opacity: 1;
//         }

//         .swiper-button-prev,
//         .swiper-button-next {
//           color: #D69C4F;
//         }

//         .swiper-button-prev {
//           left: 1%;
//         }

//         .swiper-button-next {
//           right: 1%;
//         }

//         .swiper-button-prev:after,
//         .swiper-button-next:after {
//           font-size: 24px;
//           font-weight: 700;
//           border-radius: 50%;
//           padding: 10px 15px;
//           background-color: white;
//           box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
//         }

//         .swiper-button-prev.swiper-button-disabled,
//         .swiper-button-next.swiper-button-disabled {
//           opacity: 0.35;
//           cursor: auto;
//           display: none;
//           pointer-events: none;
//         }
//       }

//     }
//   }

//   .promobgimg {
//     width: 290px;
//     border-radius: 9px;
//     background-size: cover;
//     background-position: center;
//     box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
//     display: flex;
//     justify-content: space-between;
//     align-items: center;

//     &__inner {
//       width: 100%;
//       height: 100%;
//       height: 287px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       color: white;

//       &--bgcolor {
//         background-color: #009DA0;
//         width: 153px;
//         height: 100%;
//         text-align: center;

//         .content {
//           display: flex;
//           flex-direction: row;
//           justify-content: center;
//           padding-top: 10px;

//           &--right {
//             display: flex;
//             margin-left: 0.25rem;
//             align-items: center;
//             flex-direction: column;
//           }

//           .porcent {
//             font-family: NexaSlabBold;
//             font-size: 45px;
//           }

//           .pornum {
//             font-size: 20px;
//           }
//         }
//       }
//     }
//   }

// }

.promotionscardrestaurant {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;


  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          width: 255px;
          height: 100%;
          padding: 10px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 1%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }

    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.textt {
  font-family: Sofia;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 100;
  line-height: 100%;
  margin: 0;
  text-transform: uppercase;

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}

.triimg {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 50%;
  }
}


// .small-dot {
//   width: 6px;
//   height: 6px;
//   margin: 0 4px;
//   background-color: #000;
// }

// .swiper-pagination-bullet {
//   width: 10px;
//   height: 10px;
//   background-color: #000;
// }