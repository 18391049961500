@import "./fonts/index.scss";
@import "./variables.scss";
@import "./general/align.scss";
@import "./general/boxmodel.scss";
@import "./general/colors.scss";
@import "./general/display.scss";
@import "./general/flex.scss";
@import "./general/font.scss";
@import "./general/h.scss";
@import "./general/height.scss";
@import "./general/link.scss";
@import "./general/margin.scss";
@import "./general/padding.scss";
@import "./general/position.scss";
@import "./general/text.scss";
@import "./general/width.scss";
@import "./layout/index.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: content-box;
  font-family: NexaSlab;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-gold);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: var(--color-black);
}

.isdesktop {
  display: none;
}

.ismobile {
  display: block;
}

@media screen and (min-width: 768px) {
  .isdesktop {
    display: block;
  }

  .ismobile {
    display: none;
  }
}

div.grecaptcha-badge {
  z-index: 10;
}

.skeleton {
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 24px;
  overflow: hidden;

  &__container {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #f3f2f2;
    animation: moveSkeleton 1.5s infinite linear;
  }
}

@keyframes moveSkeleton {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

.swiper-button-next,
.swiper-button-prev {
  &:hover {
    &::after {
      color: white;
      background-color: var(--color-gold) !important;
    }
  }
}

a {
  color: var(--color-gold);
}