.custom__restaurant {

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 324px;

    @media (min-width: 375px) {
      width: 373px;
    }
  }

  @media (max-width: 767px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  @media ( min-width: 768px) {
    padding-top: 40px;

    .custom__restaurant .swiper-slide-thumb-active{
      opacity: 1;
    }

    .custom__restaurant{
      position: relative;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #D69C4F;
      position: absolute;
      transform: translateY(50%);
      z-index: 1000;
    }

    .swiper-button-prev {
      left: 1%;
    }

    .swiper-button-next {
      right: 1%;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 24px;
      font-weight: 700;
      border-radius: 50%;
      padding: 10px 15px;
      background-color: white;
      box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      display: none;
      pointer-events: none;
    }
  }


  &--container {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
}


.sliderestaurant {
  position: relative;
  display: inline-block;

  &__tag {
    position: absolute;
    top: 25px;
    right: 0px;

    color: #fff;
    font-family: NexaSlab;
    font-weight: 700;
    font-size: 1.125rem;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 6px;
    width: auto;
    min-width: 140px;
    height: 42px;
    background-color: #f80505;
    clip-path: circle(50% at 50% 50%);

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
