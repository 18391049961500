@import '../../atoms/text/styles.scss';

.promotionscalendar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 25px;

  // @media (min-width: 768px) {
  //   padding-block: 50px;
  // }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .swiper-backface-hidden .swiper-slide {
      transform: none;
    }

    &--titlefilter {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      // margin-bottom: 20px;
      @media (min-width: 768px) {
        flex-direction: row;
      }

      .drop{
        padding-right: 24px ;
        display: flex;
        justify-content: flex-end;

        @media (min-width: 1200px) {
          padding-right: 0;
        }
      }

      .subtitle {
        @include title-section2(NexaSlab, 700, normal);
      }

    }

    &--description {
      // padding-top: 24px;

      @media (min-width: 1200px) {
        padding-left: 0;
      }

      .swiper {
        width: 100%;
        height: 100%;
        overflow-y: hidden;

        @media (min-width: 1200px) {
          max-width: 1220px;
        }
      }

      &--calendar {
        position: relative;


        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 220px;
          height: 100%;
        }

        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 1%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}