.card__cardpackages {
  border-radius: 9px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #E5E5E5;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  width: 300px;

   @media (min-width: 768px) {
    width: 328px;
   }

  &--slider {
    width: 100%;
    height: 216px;
    border-radius: inherit 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--container {
    padding-top: 24px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 230px;

    @media (min-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    .cardheaderr {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .titulo {
        font-family: NexaSlab;
        text-align: left;
        font-weight: bold;
        font-size: .875rem;
        line-height: 1.25rem;
        margin: 0;

        p {
          margin: 0;
        }

        i {
          font-family: 'Dancing Script', cursive;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          margin: 0;
          color: #D69C4F;
        }
      }
  
      .title {
        font-family: NexaSlab;
        text-align: left;
        font-weight: bold;
        font-size: .875rem;
        line-height: 1.25rem;
        margin: 0;
  
        @media (max-width: 375px) {
          width: 141px;
        }
      }
  
      .subtitle {
        font-family: 'Dancing Script', cursive;
        font-weight: 400;
        font-size: 24px;
        margin: 0;
        color: #D69C4F;
      }
    }

    .cardbody {
      padding: 10px 0px;

      .list{
        padding-top: 4px;
        padding-bottom: 4px;
      }

      p {
        font-family: Sofia;
        font-weight: 100;
        font-size: 12px;
        line-height: 1.2;
        margin: 0;
      }
    }

    .cardprices {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      p {
        font-family: Sofia;
        font-weight: 100;
        font-size: 12px;
        line-height: 1.2;
        margin: 0;
      }

      .price {
        font-family: NexaSlab;
        font-weight: 700;
        line-height: 1.2;
        font-size: 1.5625rem;
        padding-top: 6px;
      }
    }

    .estilos {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-family: Sofia;
        font-weight: 100;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.cardpackages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }


  &__container {
    width: 100%;
    @media (min-width: 1200px){
      max-width: 1220px;
    }
  }

  &__title {
    font-size: 1.125rem;
    font-family: NexaSlab;
    font-weight: 700;
  }

  &__description {
    padding-top: 20px;

    .linkcard {
      color: black;
      text-decoration-line: none;
    }

    @media (max-width: 767px) {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    @media ( min-width: 768px) {
      padding-top: 40px;

      .cardpackages__swiper .swiper-slide-thumb-active{
        opacity: 1;
      }

      .cardpackages__swiper{
        position: relative;
      }

      .swiper-button-prev,
      .swiper-button-next {
        color: #D69C4F;
        position: absolute;
        z-index: 1000;
      }

      .swiper-button-prev {
        left: 1%;
      }

      .swiper-button-next {
        right: 1%;
      }

      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 24px;
        font-weight: 700;
        border-radius: 50%;
        padding: 10px 15px;
        background-color: white;
        box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
      }

      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        display: none;
        pointer-events: none;
      }
    }
  }
}


