@import '../../../components/atoms/text/styles.scss';

.activityslidermob {
  padding-block: 25px;

  .subtitle {
    @include title-section(NexaSlab, 700, normal);
    margin-bottom: 20px;
  }
}


.activityslidertab {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  padding-block: 50px 60px;

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 30px;
    }
  }
}


.activityslider {


  &__container {
    position: relative;

    &--img {
      position: relative;

      img {
        display: block;
        width: 100%;
      }
    }

    .nights__description {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      min-width: 280px;
      filter: drop-shadow(6px 8px 6px rgba(0, 0, 0, 0.20));

      &--text {
        position: relative;
        background-color:white;
        clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
        color: #000000;
        width: 80%;
        height: 340px;
        box-sizing: border-box;
        overflow-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        display: flex;
        flex-direction: column;
        padding: 24px 40px;

        @media screen and (min-width: 320px) {
          bottom: 40px;
          left: 20px;
          width: 90%;
          padding: 24px 20px;
        }
      }

      .hiddenmore {
        position: relative;
        background-color:white;
        clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%); /* Aquí puedes definir la forma que deseas aplicar */
        color: #000000;
        width: 60%;
        height: 321px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        display: flex;
        flex-direction: column;

        div:nth-child(1) {
          white-space: wrap;
          padding-right: 62px;
          padding-left: 40px;
          padding-top: 24px;
        }

        @media screen and (min-width: 320px) {
          bottom: 40px;
          left: 20px;
          width: 90%;

          div:nth-child(1) {
            padding-right: 40px;
            padding-left: 26px;
            padding-top: 26px;
          }
        }
      }
    }

    &--description2 {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      min-width: 280px;
      filter: drop-shadow(6px 8px 6px rgba(71, 70, 70, 0.2));

    }
    &--trapezoidcont {
      position: relative;
      bottom: 66px;
      left: 20px;
      width: 100%;

      .trapezoid {
        width: calc(100% - 40px);
        min-width: 280px;
        height: 360px;
        clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      .trapezoid > div {
        color: black;
        padding: 30px 30px;
      }

      & > div {
        .title{
          @include parragraph-normal(NexaSlab, 16px, 600, normal);
          color: #D69C4F;
          padding-bottom: 20px;
        }

        .description{
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          line-height: normal;
          margin-right: 20px;
        }
      }
    }
  }

  .swiper {
    width: 100%;
    height: 400px;
  }

  .swiper-slide {
    font-size: 1.125rem;
    background: #fff;
  }

  .swiper-wrapper{
    height: 610px;
  }

  .swiper-pagination {
    height: 26px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 240px;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    background: white;
    opacity: 1;
    width: 15px;
    height: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  }

  .swiper-pagination-bullet-active {
    background: #D69C4F;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0%;
    left: 0;
    width: 100%;
  }
}

