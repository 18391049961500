@import "../../../atoms/text/styles.scss";

.bannerheader {
  height: 320px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  background-size: cover;

  @media screen and (min-width: 768px) {
    height: 460px;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--text {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;

      @media (min-width: 768px) {
        align-items: flex-start;
        padding: 0 20px;
      }
    }
  }
}


.bannerdestino {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    gap: 32px;
    padding-top: 30px;
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    &--title {
      @include parragraph-normal(NexaSlab, 36px, 700, normal);
      text-transform: uppercase;

      @media (min-width: 425px) {
        font-size: 40px;
        line-height: 40px;
      }

      @media (min-width: 1024px) {
        font-size: 60px;
        line-height: 60px;
      }
    }

    &--subtitle {
      @include parragraph-normal(NexaSlab, 18px, 400, normal);
      line-height: normal;

      @media (min-width: 425px) {
        font-size: 26px;
      }

      @media (min-width: 1024px) {
        font-size: 36px;
      }
    }
  }

  &__button {
    display: none;

    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--btn {
      cursor: pointer;
      @include parragraph-normal(NexaSlab, 18px, 600, normal);
      background: var(--color-gold);
      border-radius: 7px;
      text-align: center;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 200px;
      height: 50px;
      gap: 30px;

      &:hover {
        background: #c6914c;
      }
    }

    .icon-right{
      gap: 12px;
    }
  }
}