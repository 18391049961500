.terminos_condiciones {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  font-family: "Quicksand";
}

.terminos_condiciones__container {
  padding: 0px 16px;

  @media (min-width: 768px) {
    padding: 0px 60px;
  }

  @media (min-width: 1200px) {
    padding: 0px 120px;
  }

  ul {
    width: 100%;
    padding: 0;
    list-style-type: disc;
    list-style-position: inside;
    margin: 0;

    li {
      box-sizing: border-box;
      list-style-position: outside;
      margin-left: 20px;
      margin-bottom: 6px;

      &::marker {
        font-size: 16px;
      }
    }
  }

  a {
    color: #D69C4F;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.terminos_condiciones {
  h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    margin: 20px 0;
  }

  li {
    margin: 10px 0;
  }

  p,
  li {
    text-align: justify;
  }
}