.news {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: Quicksand;

  &__container {
    padding: 0px 16px;

    @media (min-width: 768px) {
      padding: 0px 60px;
    }

    @media (min-width: 1200px) {
      padding: 0px 120px;
    }

    h1 {
      font-family: NexaSlab;
      text-transform: uppercase;
    }


    h2 {
      font-family: Quicksand;
      text-transform: capitalize;
      line-height: 1.2;
    }

    div {
      margin-bottom: 26px;

      ul {
        width: 100%;
        padding: 0;
        list-style-type: disc;
        list-style-position: inside;
        margin: 0;

        li {
          box-sizing: border-box;
          list-style-position: outside;
          margin: 10px 0px 10px 20px;

          &::marker {
            font-size: 12px;
          }
        }
      }

      p {
        margin-block: 6px;
      }
    }
  }
}
