@import '../../atoms/text/styles.scss';

.sectionrooms {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat;
  background-position: center;
  background-image: url('../../../assets/images/bkg-texture.webp');
  padding-block: 25px;

  // @media (min-width: 768px) {
  //   padding-block: 50px;
  // }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section2(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 10px;
      }
    }
  }

  &__description {
    display: flex;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 320px; // Medida de la card
      margin-right: 28px; // Espacio entre cards

      @media (min-width: 768px) {
        width: 340px;
        margin-right: 66px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 26px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(50%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      width: 320px; // Medida de la card
      @include scale;
      @include touchmobile;

      @media (min-width: 768px) {
        width: 340px;
      }

      &--slider {
        width: 100%;
        height: 200px;
        border-radius: inherit 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: none;
        }

        .swiper-slide {
          margin-right: 0px; // Espacio en subslider entre img
        }
      }

      &--container {
        padding: 20px;
        background-color: white;
        height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 6px;

        .cardtitle {

          &__title {
            @include parragraph-normal(NexaSlab, 16px, 700, normal);
            text-align: left;
            height: 34px;
            overflow: hidden;
          }

          &__cardservices {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 6px 8px;
            margin-top: 6px;

            &--card {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 6px;
              @include parragraph-normal(Quicksand, 10px, 400, normal);

              .typebed,
              .ocupation,
              .title {
                @include parragraph-normal(Quicksand, 12px, 400, normal);
              }

              .maxocupation {
                font-size: 12px;
              }

              sup {
                font-size: 8px;
              }
            }

          }
        }



        .carddescription {

          &__title {
            @include parragraph-normal(Quicksand, 12px, 600, normal);
            margin-bottom: 10px;
          }

          &__cardservices {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 6px 8px;

            &--card {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 6px;

              .paragraph {
                @include parragraph-normal(Quicksand, 12px, 400, normal);
              }
            }

          }
        }

        .cardservicesbuttons {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__icontext {
            @include parragraph-normal(Quicksand, 13px, 400, normal);
            cursor: pointer;
            text-decoration: underline;
            text-underline-offset: 4px;
          }

          &__btnnext {
            cursor: pointer;
            background: var(--color-gold);
            border-radius: 10px;
            border: none;
            font-size: 14px;
            text-align: center;
            color: white;
            padding: 10px 14px;
            display: block;

            &:hover {
              background: #c6914c;
            }

            &:active {
              background: #bb8b4c;
            }
          }

          @media (min-width: 768px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.activity-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-block: 14px;

  &::-webkit-scrollbar {
    width: 7px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(214, 156, 79, 0.75);
    border: none;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #E6E6E6;
    border-radius: 10px;
  }

  div {
    scroll-snap-align: start;
    color: #333;
    // margin-right: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      // border-bottom: 3.5px solid #D69C4F;
      color: #D69C4F;
    }

    &.active {
      // border-bottom: 3.5px solid #D69C4F;
      color: #D69C4F;
    }
  }

  .activity-tab {
    p {
      text-align: center;
      @include parragraph-normal(Quicksand, 14px, 400, normal);
      width: 120px;
    }
  }
}

.scrollrooms {
  width: 100%;
  padding-right: 10px;

  &__title {
    @include parragraph-normal(NexaSlab, 18px, 600, normal);
    margin-bottom: 10px;
  }

  &__paragraph {
    @include parragraph-normal(Quicksand, 12px, 400, normal);
    line-height: normal;
  }

  .carditems {
    .cardservices {
      padding-block: 14px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 16px;

      @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }

      &__card {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        @include parragraph-normal(Quicksand, 12px, 400, normal);

        .typebed,
        .ocupation,
        .title,
        .type-items {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
        }

        .maxocupation {
          font-size: 12px;
        }

        sup {
          font-size: 10px;
        }
      }
    }

  }

  .servicesitems {
    &__category {
      padding-block: 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 16px;

      @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }

      &--card {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        .type-items {
          @include parragraph-normal(Quicksand, 12px, 400, normal);
        }
      }
    }
  }

  .roomservices {
    @include parragraph-normal(Quicksand, 12px, 400, normal);
    padding-block: 16px;
  }


}

.tabactivitys {
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 60px;
    margin-right: 12px;

  }
}

.tabactivityborder {
  // border: 1px solid #D69C4F;
  // border-radius: 12px;
  border-bottom: 1.5px solid #F0F0F0;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  
  &:hover {
    
    p {
      color: #D69C4F;
    }
  }
  
  p {
    @include parragraph-normal(Quicksand, 14px, 400, normal);
    color: #5F5F5F;
    text-align: center;
    // padding-block: 12px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

}

.tabactivityborder.active {
  border-bottom: 1.5px solid #D69C4F;

  p {
    color: #D69C4F;
    font-weight: 700;
  }
}

.columnroom {
  // display: grid;
  // grid-template-columns: 1fr;
  height: 100%;
  gap: 20px;
  padding-block: 30px;

  // @media (min-width: 768px) {
  //   grid-template-columns: 0.8fr 1fr;
  //   padding-block: 50px 30px;

  // }

  &__header {
    display: flex;
    align-items:flex-start;
    flex-direction: column;
    gap: 10px;
    
    @media (min-width: 768px) {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }
  }

  &__title {
    @include parragraph-normal(NexaSlab, 16px, 700, normal);
    line-height: normal;

    @media (min-width: 768px) {
      font-size: 25px;
    }
  }

  &__description {
    @include parragraph-normal(Quicksand, 14px, 400, normal);
    line-height: normal;
    margin-block: 20px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  &__carditems {
    @include parragraph-normal(Quicksand, 16px, 400, normal);
    margin-top: 16px;
    margin-bottom: 32px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .carditems__info {
      @include parragraph-normal(Quicksand, 16px, 400, normal);
      margin-block: 16px;

      span {
        color: var(--color-gold);
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }

  .servicesitems2 {

    .roomservices {
      padding-top: 12px;
      @include parragraph-normal(Quicksand, 16px, 700, normal);
    }

    &__category {
      padding-top: 10px;
      padding-bottom: 30px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 12px 10px;


      @media (min-width: 768px) {
        grid-template-columns: repeat(4, 1.2fr);
      }

      &--card {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        .type-items {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
        
          @media (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }

    .btnright {
      color: #D69C4F;
      @include parragraph-normal(NexaSlab, 14px, 600, normal);

    }
  }
}


.parentcontainer {

  &__container {
    &--item{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      @media (min-width: 768px) {
        gap: 30px;
      }

      > a:nth-child(1) {
        grid-column: span 3;
        height: 280px;

        @media (min-width: 768px) {
          height: 480px;
        }
      }

      > a:nth-child(n+2) {
        height: 180px;

        @media (min-width: 768px) {
          height: 300px;
        }
      }
    }

    &--img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.parentcontainer2 {

  &__container {
    &--item{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      > a:nth-child(1) {
        grid-column: span 2;
      }

      > a:nth-child(n+2) {
        height: 160px;
      }
    }

    &--img{
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 768px) {
        max-height: 350px;
      }
    }
  }
}

.columnroom2 {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  gap: 20px;
  padding-block: 30px;

  @media (min-width: 768px) {
    padding-block: 50px 30px;
  }

  &__title {
    @include parragraph-normal(NexaSlab, 16px, 600, normal);
    color: #D69C4F;
    border-bottom: 1px solid #D69C4F;
  }

  &__description {
    @include parragraph-normal(Quicksand, 10px, 400, normal);
    margin-block: 10px;
    line-height: normal;
  }

  &__carditems {
    @include parragraph-normal(Quicksand, 10px, 400, normal);
    margin-block: 16px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .servicesitems2 {

    .roomservices {
      padding-top: 12px;
      @include parragraph-normal(Quicksand, 10px, 400, normal);
    }

    &__category {

      padding-block: 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 8px 10px;


      @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }

      &--card {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        .type-items {
          @include parragraph-normal(Quicksand, 10px, 400, normal);
        }
      }
    }

    .btnright {
      color: #D69C4F;
      @include parragraph-normal(NexaSlab, 14px, 600, normal);

    }
  }
}