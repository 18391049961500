@import '../../atoms/text/styles.scss';

.sectioncardgrid {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }


  &__container {
    width: 100%;
    @media (min-width: 1200px){
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }

    &--concept {
      // justify-content: space-between;
      // align-items: center;
      
      // @media (min-width: 768px) {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      // }

      // .filtro {
      //   display: flex;
      //   justify-content: flex-end;
      //   padding-top: 20px;

      //   .dropdown {
      //     width: 230px;
      //   }
      // }
    }
  }

  &__description {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px 16px;
    justify-items: center;

    @media (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

