.gallerysection {
  // width: 100%;

  &__contain {
    // max-width: calc(100% - 10px);
    width: 100%;
    box-sizing: border-box;
  
    @media (min-width: 768px) {
      // max-width: 580px;
      width: 100%;
    }

    .img-animation {
        width: 100%;
        height: 150px;
        object-fit: cover;
        background: linear-gradient(270deg, #f3f3f3, #e0e0e0);
        background-size: 100%;
        animation: gradientAnimation 1.5s ease infinite;
    }

    .gallery-swiper {
      img {
        display: block;
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
    
      .gallery-swiper .swiper-slide-thumb-active {
        opacity: 1;
      }
    
      .gallery-swiper {
        position: relative;
      }
    
      .swiper-button-prev,
      .swiper-button-next {
        color: white;
      }
    
      .swiper-button-prev {
        left: 5%;
      }
    
      .swiper-button-next {
        right: 5%;
      }
    
      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 24px;
        font-weight: 700;
        border-radius: 50%;
        padding: 10px 15px;
        background: rgba(0, 0, 0, 0.59);
        box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
      }
    
      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        display: none;
        pointer-events: none;
      }
    }
  
    .gallery-swipertabs {
      width: 100%;
      height: 120px;
      box-sizing: border-box;
      padding: 10px 0;
      // overflow-x: auto;
    
      
      .swiper {
        width: 100%;
        height: 100%;
      }
      
      .swiper-slide {
        width: 324px;
        background-color: #333;

        img {
          opacity: 0.4;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    
      .swiper-slide-thumb-active {
        img {
          opacity: 1;
        }
      }
    }
  }


}