@import '../../../atoms/text/styles.scss';

.hoteleslider {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 1.125rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    background: #FFFFFF;
    opacity: 1;
    width: 15px;
    height: 15px;
  }

  .swiper-pagination-bullet-active {
    background: #D69C4F;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 10%;
    left: 0;
    width: 100%;
  }

  &__content {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    &--tags {
      position: absolute;
      top: 25px;
      right: 0px;
      letter-spacing: 0.03em;

      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-right: 6px;
      width: auto;
      min-width: 140px;
      height: 42px;
      clip-path: polygon(100% 0%, 7.15% 0%, 0% 100%, 100% 100%);
      padding-left: 26px;
      .tipo {
        @include parragraph-normal(NexaSlab, 12px, 400, normal);
      }

      .city {
        @include parragraph-normal(NexaSlab, 18px, 600, normal);
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.hotelcontain {

  .arrow {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-20%);
    margin-top: -5px;
    z-index: 10;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.20);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow.inactive {
    background-color: rgb(255, 255, 255, 0.50);
    pointer-events: none;
    cursor: default;
  }

  .arrow-left {
    background-color: rgba(0, 0, 0, 0.50);
    left: 5%;
  }

  .arrow-right {
    background-color: rgba(0, 0, 0, 0.50);
    right: 5%;
  }

}


// .slidediv {
//   position: relative;
//   display: inline-block;
//   width: 100%;
//   height: 100%;

//   &__tags {
//     position: absolute;
//     top: 25px;
//     right: 0px;
//     letter-spacing: 0.03em;

//     color: #fff;
//     font-family: NexaSlab;
//     font-weight: 700;
//     font-size: 1.2rem; // 1.2em = 1.2rem

//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 140px;
//     height: 42px;
//     clip-path: polygon(100% 0%, 7.15% 0%, 0% 100%, 100% 100%);

//     img {
//       display: block;
//       max-width: 100%;
//       height: auto;
//     }
//   }

//   .premium {
//     background-color: #002855;
//   }

//   .select {
//     background-color: #008EAA;
//   }

//   .standar {
//     background-color: #FF4200;
//   }
// }