
/*
  CONTENT STRUCTURE:
    position:               pos-
    top en porcentaje:      top-#por
    bottom en porcentaje:   bottom-#por
    left en porcentaje:     left-#por
    right en porcentaje:    right-#por

    top en rem:             top-#rem
    bottom en rem:          bottom-#rem
    left en rem:            left-#rem
    right en rem:           right-#rem

    top en pixel:             top-#px
    bottom en pixel:          bottom-#px
    left en pixel:            left-#px
    right en pixel:           right-#px

    transform:              t-middle, t-middle-x, t-middle-y
*/

@import "../variables.scss";

$position: ( 
    fixed: fixed,
    static: static,
    relative: relative,
    absolute: absolute,
    sticky: sticky,
    webkit-sticky: -webkit-sticky
);

@each $name, $value in $position {
    /* POSITION (pos-) */
    .pos-#{ $name } {
        position: #{$value} !important;
    }
}

.p-fixed-top {
    position: fixed !important;
    top: 0rem !important;
    right: 0rem !important;
    left: 0rem !important;
    z-index: 975;
}

.p-fixed-bottom {
    position: fixed !important;
    right: 0rem !important;
    bottom: 0rem !important;
    left: 0rem !important;
    z-index: 975;
}


@each $name, $value in $percentage {
    /* TOP (top-#por) */  
    .top-#{ $name }{
        top: #{$value} !important;
    }

    /* BOTTOM (bottom-#por) */  
    .bottom-#{ $name }{
        bottom: #{$value} !important;
    }

    /* LEFT (left-#por) */  
    .left-#{ $name }{
        left: #{$value} !important;
    }

    /* RIGHT (right-#por) */  
    .right-#{ $name }{
        right: #{$value} !important;
    }
}

@each $name, $value in $rem {
    /* TOP (top-#rem) */  
    .top-#{ $name }{
        top: #{$value} !important;
    }

    /* BOTTOM (bottom-#rem) */  
    .bottom-#{ $name }{
        bottom: #{$value} !important;
    }

    /* LEFT (left-#rem) */  
    .left-#{ $name }{
        left: #{$value} !important;
    }

    /* RIGHT (right-#rem) */  
    .right-#{ $name }{
        right: #{$value} !important;
    }
}

@each $name, $value in $pixel {
    /* TOP (top-#px) */  
    .top-#{ $name }{
        top: #{$value} !important;
    }

    /* BOTTOM (bottom-#px) */  
    .bottom-#{ $name }{
        bottom: #{$value} !important;
    }

    /* LEFT (left-#px) */  
    .left-#{ $name }{
        left: #{$value} !important;
    }

    /* RIGHT (right-#px) */  
    .right-#{ $name }{
        right: #{$value} !important;
    }
}

/* TRANSLATE MIDDLE (t-middle) */
.t-middle {
    transform: translate(-50%, -50%) !important;
}

/* TRANSLATE MIDDLE X (t-middle-x) */
.t-middle-x {
    transform: translateX(-50%) !important;
}

/* TRANSLATE MIDDLE Y (t-middle-y) */
.t-middle-y {
    transform: translateY(-50%) !important;
}

/* Z INDEX (zi)*/
.zi-1 {
    z-index: 1;
}  
.zi-2 {
    z-index: 2;
}
.zi-100 {
    z-index: 100;
}  