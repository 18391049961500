@import '../../atoms/text/styles.scss';

.destinationslink {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 765px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;
    text-align: center;
    @media (min-width: 1200px){
      max-width: 1220px;
    }
  }

  .colum-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(18, 1fr);
    grid-auto-flow: column;
    column-gap: 40px;
    row-gap: 25px;
    padding-inline: 16px;

    @media (min-width: 425px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(12, 1fr);
      column-gap: 20px;
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(9, 1fr);
      column-gap: 80px;
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(9, 1fr);
      column-gap: 120px;
    }
  }

  .linkdestination {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 425px) {
      padding: 0px;
    }

    .paragraph {
      @include parragraph-normal(NexaSlab, 16px, 400, normal);
    }
  }

  .link {
    text-decoration-line: none;
    color: black;
  }

  .iconactive {
    transition: transform 0.2s;
  }

  .linkdestination:hover .iconactive {
    transform: scale(1.3);
  }
}

