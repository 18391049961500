@import '../../atoms/text/styles.scss';

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.70);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &__modal {
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 20px;
      gap: 10px;

      @media (min-width: 768px) {
        width: 645px;
        height: 700px;
        padding: 20px;
      }

      .pad {
        padding-top: 100px;
        width: 100%;

        @media (min-width: 768px) {
          padding-top: 0;
        }
      }
      .close-buttond {
        border: none;
        cursor: pointer;
      }

      .modal-contentd {
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-height: 100%;
        // overflow-y: auto;
      }
    }
  }
}

.modal-contentd::-webkit-scrollbar {
  width: 7px;
  height: 200px;
}

.modal-contentd::-webkit-scrollbar-thumb {
  background-color: #D69C4F;
  border: none;
  border-radius: 10px;
}

.modal-contentd::-webkit-scrollbar-track {
  background-color: #E6E6E6;
  border-radius: 10px;
}

.modalb {
  display: flex;
  justify-content: center;
  align-items: center;

  .modalb-close {
    width: 100%;

    .titleroute{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include parragraph-normal(NexaSlab, 14px, 100, normal);
    
      @media (min-width: 768px) {
        font-size: 18px;
      }
    }

    .galleriestabs {
      margin-top: 10px;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.70);
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: 12px;
    z-index: 1000;

    &--content {
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 10px;
      box-sizing: border-box;
      gap: 14px;

      // @media (min-width: 768px) {
      //   margin-top: 0px;
      //   width: 685px;
      //   height: fit-content;
      //   justify-content: space-between;
      //   max-height: 640px;
      //   padding: 20px;
      // }

      .modalb-tabs {
        // overflow-y: auto;
        // padding: 0 10px;
        // height: calc(100% - 26%);
        width: 100%;
        height: 100%;

        // @media (min-width: 768px) {
        // }

        &__text {
          height: 100%;
          display: flex;
          justify-content: flex-start;
        }

        &::-webkit-scrollbar {
          width: 7px;
          height: 200px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #D69C4F;
          border: none;
          border-radius: 10px;
        }

          &::-webkit-scrollbar-track {
          background-color: #E6E6E6;
          border-radius: 10px;
        }
      }

      .modalb-content {
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        // height: calc(100% - 18%);
        height: 100%;
        margin-bottom: auto;

        @media (min-width: 768px) {
          height: 100%;
        }

        &__text {
          height: 100%;
          display: flex;
          justify-content: center;
          width: 100%;
        }

        &::-webkit-scrollbar {
          width: 7px;
          height: 200px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #D69C4F;
          border: none;
          border-radius: 10px;
        }

          &::-webkit-scrollbar-track {
          background-color: #E6E6E6;
          border-radius: 10px;
        }
      }

      .modalb-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;

        div {
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .large-width {
    margin-top: 0px;
    // width: 685px;
    width: 54%;
    height: fit-content;
    justify-content: space-between;
    max-height: 90%;
    padding: 20px;
  }

  .min-width {
    margin-top: 0px;
    width: 100%;
    max-width: 489px;
    min-width: 489px;
    height: 60%;
    height: fit-content;
    justify-content: space-between;
    max-height: 80%;
    padding: 20px;
  }
}