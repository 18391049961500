@import '../../atoms/text/styles.scss';

.events {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }

  }

  &__description {
    display: flex;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 212px; // Medida de la card
      margin-right: 24px; // Espacio entre cards

      @media (min-width: 768px) {
        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 24px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(30%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      width: 212px; // Medida de la card
      @include scale;

      &--slider {
        width: 100%;
        height: 124px;
        border-radius: inherit 0;
        overflow: hidden;

        span{
          display : inline !important;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: scale 400ms;
        }

        &:hover img {
          scale: 110%
        }
      }

      &--container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 14px;
        height: 76px;

        .titles {
          @include parragraph-normal(Quicksand, 16px, 600, normal);
          line-height: normal;
          text-transform: uppercase;
        }

        .hotelssubtitle {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          line-height: normal;
        }
      }

      &--extra {
        @include flex(center, center, column, 30px);
        cursor: pointer;
        text-decoration: none;
        height: 200px;
        padding: 16px;
        @include touchmobile;

        .border {
          @include flex-center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #D69C4F;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
        }

        .paragraph {
          @include parragraph-normal(NexaSlab, 14px, normal, normal);
          line-height: normal;
          text-align: center;
        }
      }
    }
  }
}