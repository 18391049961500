@import '../../atoms/text/styles.scss';

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 20px;

  @media (min-width: 425px) {
    padding-block: 30px;
  }

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 25px;
  }

  &__container {
    width: 100%;
    text-align: center;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .customswiper {
      .swiper {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 270px;
        height: 230px;
      }
    }

    // &--items {
    //   display: grid;
    //   border-radius: 12px;
    //   overflow: hidden;
    //   grid-template-columns: repeat(6, minmax(234px, auto));
    //   grid-template-rows: 150px 192px;
    //   gap: 3px;
    //   margin-inline: 16px;

    //   &--card {
    //     position: relative;
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     display: block;


    //     &:nth-child(1) {
    //       grid-row: span 2;
    //       grid-column: span 2;
    //     }

    //     &:nth-child(4) {
    //       grid-column: span 2;
    //     }

    //     &:nth-child(5) {
    //       grid-column: span 2;
    //     }

    //     &:nth-child(6) {
    //       grid-column: span 2;
    //     }
    //   }

    //   &--title {
    //     position: absolute;
    //     bottom: 0;
    //     width: 100%;
    //     // display: flex;
    //     // justify-content: flex-end;
    //     // // align-items: flex-start;
    //     // height: 100%;
    //     // flex-direction: column;

    //     .paragraph {
    //       @include parragraph-normal(NexaSlab, 20px, 700, normal);
    //       text-transform: capitalize;
    //       text-align: left;
    //       color: white;
    //       padding: 16px;
    //       width: 100%;
    //       background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);
    //     }
    //   }
    // }

    &--items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      padding-inline: 1rem;
      height: 345px;
      gap: 2px 2px;
      grid-auto-flow: row;
      grid-template-areas:
        "area1 area2 area3 area4"
        "area1 area5 area5 area6";

        span{
          display : inline !important;
        }

      @media (min-width: 1024px){
        padding-inline: 0;
      }

      .area1 {
        grid-area: area1;
        position: relative;
        width: 240px;
        height: auto;
        overflow: hidden;

        @media (min-width: 1024px) {
          width: 383px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: scale 400ms;
        }

        &:hover img {
          scale: 110%
        }

        p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: left;
          color: white;
          margin: 0;
          padding: 20px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);
        }
      }


      .area2 {
        grid-area: area2;
        position: relative;
        width: auto;
        height: auto;
        overflow: hidden;

        @media (min-width: 1024px) {
          height: 150px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: scale 400ms;
        }

        &:hover img {
          scale: 110%
        }

        p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: left;
          color: white;
          margin: 0;
          padding: 20px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);
        }
      }

      .area3 {
        grid-area: area3;
        position: relative;
        width: auto;
        height: auto;
        overflow: hidden;

        @media (min-width: 1024px) {
          height: 150px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: scale 400ms;
        }

        &:hover img {
          scale: 110%
        }

        p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: left;
          color: white;
          margin: 0;
          padding: 20px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);
        }
      }

      .area4 {
        grid-area: area4;
        position: relative;
        width: auto;
        height: auto;
        overflow: hidden;

        @media (min-width: 1024px) {
          width: 323px;
          height: 150px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: scale 400ms;
        }

        &:hover img {
          scale: 110%
        }

        p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: left;
          color: white;
          margin: 0;
          padding: 20px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);
        }
      }

      .area5 {
        grid-area: area5;
        position: relative;
        width: auto;
        height: 180px;
        overflow: hidden;

        @media (min-width: 1024px) {
          height: 192px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: scale 400ms;
        }

        &:hover img {
          scale: 110%
        }

        p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: left;
          color: white;
          margin: 0;
          padding: 20px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);
        }
      }

      .area6 {
        grid-area: area6;
        position: relative;
        width: auto;
        height: 180px;
        overflow: hidden;

        @media (min-width: 1024px) {
          height: 192px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: scale 400ms;
        }

        &:hover img {
          scale: 110%
        }

        p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: left;
          color: white;
          margin: 0;
          padding: 20px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%);
        }
      }

      .subparagraph {
        @include parragraph-normal(Quicksand, 12px, 400, normal);
        line-height: normal;
      }

      .paragraph {
        @include parragraph-normal(NexaSlab, 20px, 500, normal);
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }
}