.checkin_hotel {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  font-family: Sofia;
  margin-top: 100px
}

.checkin_hotel__container {
  padding: 0px 16px;

  @media (min-width: 768px) {
    padding: 0px 60px;
  }

  @media (min-width: 1200px) {
    padding: 0px 120px;
  }
}

.checkin_hotel {
  h1 {
    text-align: center;
    margin-bottom: 30px;
    font-family: NexaSlab;
  }

  p {
    margin: 20px 0;
  }

  li {
    margin: 10px 0;
  }

  p,
  li {
    text-align: justify;
  }

  .item-checkin{
    display: flex;
    align-items: center;
    box-shadow: 0 9px 11px -3px rgba(0, 0, 0, .1);
    margin-bottom: 60px;
    img{
      width: 40%;
      object-fit: cover;
    }
    .item-content{
      padding: 20px 40px;
      h2{
        font-family: NexaSlab;
      }
      a{
        font-size: 13px;
        font-weight: 700;
        text-align: center;
        letter-spacing: .067em;
        color: #fff;
        background: #d69c4f;
        padding: 15px 30px;
        float: right;
        display: inline-block;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 768px) 	{
  .item-checkin{    
    flex-direction: column;
    img{
      width: 100% !important;
    }
    a{
      float: none !important;
    }
  }
}