@import "../../../atoms/text/styles.scss";

.bannerheaders {
  height: 320px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
  position: relative;
  background-size: cover;

  @media screen and (min-width: 768px) {
    height: 460px;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: none;

    @media (min-width: 1200px) {
      display: flex;
    }

    &::before,
    &::after {
      content: "";
      flex: 0 0 50%;
      pointer-events: none;
    }

    &::before {
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0)
      );
    }

    &::after {
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0)
      );
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__container {
    width: 100%;
    margin: auto;

    @media (min-width: 1200px) {
      max-width: 1220px;
      margin: 0;
    }

    &--text {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;

      @media (min-width: 1200px) {
        align-items: flex-start;
        padding: 70px 16px;
      }

      .h1_alternativo {
        @include parragraph-normal(NexaSlab, 20px, 500, normal);
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 250px;
        top: 0;
        text-align: center;

        @media (min-width: 1200px) {
          @include parragraph-normal(NexaSlab, 25px, 400, normal);
          bottom: 300px;
          top: auto;
          text-align: right;
        }
      }

      .title {
        @include parragraph-normal(NexaSlab, 32px, 700, normal);
        text-transform: uppercase;
        text-align: center;

        @media (min-width: 1200px) {
          @include parragraph-normal(NexaSlab, 50px, 700, normal);
          text-align: left;
        }
      }

      .subtitle {
        @include parragraph-normal(NexaSlab, 16px, 300, normal);
        margin-bottom: 6px;
        text-align: center;
        text-transform: uppercase;
        @media (min-width: 1200px) {
          margin-bottom: 10px;
          font-size: 30px;
        }
      }
    }
  }
}

.renderdescription {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;

  @media (min-width: 768px) {
    margin-top: 16px;
    flex-direction: row;
  }
  .iconrender {
    @include parragraph-normal(Quicksand, 18px, 300, normal);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &__description {
      text-align: left;
      font-size: 14px;

      @media (min-width: 768px) {
        font-size: 18px;
      }
    }
  }

  &__description {
    &--title {
      @include parragraph-normal(Quicksand, 25px, 300, normal);

      span {
        text-transform: uppercase;
        font-weight: 400;
        margin-left: 10px;
      }
    }

    &--review {
      @include parragraph-normal(Quicksand, 30px, 600, normal);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 6px;

      .ota {
        @include parragraph-normal(NexaSlab, 30px, 600, normal);
      }

      .hotelsreview {
        @include parragraph-normal(Quicksand, 18px, 300, normal);
      }

      .link {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .icon-zero {
        gap: 6px;
      }
    }
  }
}

.bannerheaders__img {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
