@import '../../../components/atoms/text/styles.scss';

.listcards {

  &__container {
    width: 100%;
    padding-inline: 16px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (min-width: 768px) {
      gap: 70px;
    }

    @media (min-width: 1200px) {
      max-width: 1220px;
      padding-inline: 0px;
    }

    &--card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 30px;

      @media (min-width: 768px) {
        flex-direction: row;
        gap: 40px;
      }
    }

    &--description {
      @include parragraph-normal(Quicksand, 16px, 400, normal);
      line-height: normal;
      margin-bottom: 16px;

      .title {
        @include parragraph-normal(NexaSlab, 18px, 700, normal);
        margin-bottom: 16px

      }

      .restriction {
        @include parragraph-normal(Quicksand, 16px, 400, normal);
         line-height: normal;
      }

      ul {
        padding-left: 20px;

        ::marker {
          font-size: 12px;
        }
      }
    }
  }
}