.container{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.desktop {
  display: none;
}

.tablet {
  display: none;

}
.mobile {
  display: block;
}

@media print, screen and (min-width: 40em) {
  .tablet {
    display: block;
  }
}

@media print, screen and (min-width: 64em) {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}