@import '../variables.scss';

:root {
  --color-gold: #D69C4F;
  --color-black: #000000;
  --color-standard: #ff4200;
  --color-select: #008eaa;
  --color-premium: #002855;
  --color-asociados: #d31364;
  --color-line: #9F9F9F;
}

$colors: (
  'primary-color': $primary-color,
  'secondary-color1': $secondary-color1,
  'secondary-color2': $secondary-color2,
  'secondary-color3': $secondary-color3,
  'secondary-color4': $secondary-color4,
  'secondary-color5': $secondary-color5,
  'secondary-color6': $secondary-color6,
  'brand-color1': $brand-color1,
  'brand-color2': $brand-color2,
  'brand-color3': $brand-color3,
  'brand-color4': $brand-color4,
  'brand-color5': $brand-color5,
  'brand-color6': $brand-color6,
  'status-color1': $status-color1,
  'status-color2': $status-color2,
  'status-color3': $status-color3,
);

@each $name, $color in $colors {

  /* BORDER COLOR (b-2px) */
  .b-2px-#{$name} {
    border: 2px solid $color !important;
  }

  .bl-1px-#{$name} {
    border-left: 1px solid $color !important;
  }

  .br-1px-#{$name} {
    border-right: 1px solid $color !important;
  }

  .bt-0-3px-#{$name} {
    border-top: 1px solid $color !important;
  }

  /* TEXT COLOR (t-) */
  .tc-#{$name} {
    color: $color !important;
  }

  /* HOVER TEXT COLOR (hov-t-) */
  .hov-t-#{$name} {
    &:hover {
      color: $color !important;
    }
  }

  /* BACKGROUND COLOR (bg-) */
  .bg-#{$name} {
    background-color: $color !important;
  }

  /* HOVER BACKGROUND COLOR (hov-bg-) */
  .hov-bg-#{$name} {
    &:hover {
      background-color: $color !important;
    }
  }

  /* LINK COLOR (link-) */
  .l-#{$name} {
    color: $color !important;
  }

  /* HOVER LINK COLOR (hov-link-) */
  .hov-l-#{$name} {
    &:hover {
      color: $color !important;
    }
  }

  /* FOCUS LINK COLOR (foc-link-) */
  .foc-l-#{$name} {
    &:focus {
      color: $color !important;
    }
  }

  /* ACTIVE LINK COLOR (act-link-) */
  .act-l-#{$name} {
    &:active {
      color: $color !important;
    }
  }

  /* FILL COLOR (fill-) */
  .fill-#{$name} {
    fill: $color !important;
  }

  /* STROKE COLOR (stroke-) */
  .stroke-#{$name} {
    stroke: $color !important;
  } 
  
  /* HOVER SVG (hov-svg-) */
  .hov-svg-#{$name} {
    &:hover {
      svg {
        fill: $color !important;
      }
    }
  }
}
