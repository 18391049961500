@import '../../atoms/text/styles.scss';

.multi-select-dropdown {
  position: relative;
  user-select: none;
  // display: inline-block;

  .dropdown-toggle {
    display: flex;
    height: 55px;
    padding: 10px 18px;
    background-color: #ffffff;
    border: 1.5px solid #7E7E7E;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: black;
    @include touchmobile;

    .dropdown-toogle-arrow {
      width: 100%;
    }

    .title{
      @include parragraph-normal(NexaSlab, 14px, 700, normal);
    }

    .option{
      @include parragraph-normal(Quicksand, 14px, 400, normal);
      line-height: normal;
      overflow-x: clip;
      text-overflow: ellipsis;
      width: calc(100% - 20px);
      white-space: nowrap;
      padding-right: 10px;
    }

    .selecetplace{
      @include parragraph-normal(Quicksand, 12px, 400, normal);
      line-height: normal;
    }
  }

  .clear-button {
    height: 15px;
  }

  .dropdown-list {
    position: absolute;
    top: 104%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1.5px solid #7E7E7E;
    border-radius: 3px 3px 10px 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 16;
    max-height: 200px;
    overflow-y: auto;
    @include touchmobile;

    &::-webkit-scrollbar-track {
      background-color: rgb(128, 128, 128, 0.2);
    }

    li {
      display: flex;
      flex-direction: row;
      padding: 8px 18px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      @include parragraph-normal(Quicksand, 14px, 400, normal);
      line-height: normal;
      border-bottom: 1px solid #E7E7E7;

      &:hover {
        background-color: rgba(214, 156, 79, 0.45);
      }

      .is-selected {
        font-weight: 500;
      }
    }
  }

  .dropdown-selecetplace {
    position: absolute;
    top: 104%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1.5px solid #7E7E7E;
    border-radius: 3px 3px 10px 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 16;
    @include touchmobile;


    li {
      padding: 8px 18px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      @include parragraph-normal(Quicksand, 12px, 400, normal);
      line-height: normal;

      &:hover {
        background-color: rgba(214, 156, 79, 0.45);
      }
    }
  }

  input[type="checkbox"] {
    width: 100%;
    max-width: 20px;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 4px;
    border: 1px solid #7E7E7E;
    margin-right: 10px;
  }

  input[type="checkbox"]:checked::before {
    content: "\2713";
    color: var(--color-gold);
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-top: -2px;
    display: flex;
  }
}
