@import '../../atoms/text/styles.scss';

.promotionstypes {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  // @media (min-width: 768px) {
  //   padding-block: 50px;
  // }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 10px;
    }

    &--description {
      // padding-top: 2rem;
      // padding-left: 1rem;
      // padding-right: 1rem;

      &--swiper {
        position: relative;
        width: 90%;
        height: 380px;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          width: 290px;
          height: 100%;
          padding: 10px 14px;
        }

        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        @media (min-width: 768px) {

          .sactivities__swiper .swiper-slide-thumb-active {
            opacity: 1;
          }

          .sactivities__swiper {
            position: relative;
          }

          .swiper-button-prev,
          .swiper-button-next {
            color: #D69C4F;
            position: absolute;
            transform: translateY(50%);
            z-index: 1000;
          }

          .swiper-button-prev {
            left: 30%;
            top: 84%;
          }

          .swiper-button-next {
            right: 30%;
            top: 84%;
          }

          .swiper-button-prev:after,
          .swiper-button-next:after {
            font-size: 24px;
            font-weight: 700;
            border-radius: 50%;
            padding: 10px 15px;
            background-color: white;
            box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
          }

          .swiper-button-prev.swiper-button-disabled,
          .swiper-button-next.swiper-button-disabled {
            opacity: 0.35;
            cursor: auto;
            display: none;
            pointer-events: none;
          }
        }

        .swiper-pagination {
          height: 30px;
        }

        .swiper-slide img {
          display: block;
          width: 100%;
          height: 100%;
          max-height: 240px;
          object-fit: cover;
        }

        .swiper-pagination-bullet {
          background: white;
          opacity: 1;
          width: 15px;
          height: 15px;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
        }

        .swiper-pagination-bullet-active {
          background: #D69C4F;
        }

        .swiper-pagination-fraction,
        .swiper-pagination-custom,
        .swiper-horizontal>.swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal {
          bottom: 4%;
          left: 0;
          width: 100%;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        gap: 40px;

        .final {
          width: 240px;
          height: 100%;
          gap: 14px;
          padding-top: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.promotionshotels {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 290px;
          height: 100%;
          padding: 10px 14px;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 20px;

        .final {
          width: 172px;
          height: 100%;
          gap: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 270px;
      height: 100%;
      padding: 10px 14px;
    }

    @media (max-width: 767px) {

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #D69C4F;
    }

    .swiper-button-prev {
      left: 1%;
    }

    .swiper-button-next {
      right: 1%;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 24px;
      font-weight: 700;
      border-radius: 50%;
      padding: 10px 15px;
      background-color: white;
      box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      display: none;
      pointer-events: none;
    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.promotionsbanks {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 240px;
          height: 280px;
          padding: 10px 14px;
        }

        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 1%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 20px;

        .final {
          width: 172px;
          height: 100%;
          gap: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

// .promotionsrelax {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: white;
//   padding-top: 30px;
//   padding-bottom: 20px;


//   @media (min-width: 768px) {
//     padding-top: 50px;
//     padding-bottom: 30px;
//   }

//   &__container {
//     width: 100%;

//     @media (min-width: 1200px) {
//       max-width: 1220px;
//     }

//     &--description {
//       display: flex;

//       .swiper {
//         height: 100%;
//       }
  
//       .swiper-slide {
//         width: 369px; // Medida de la card
//         margin-right: 26px; // Espacio entre cards
  
//         &:last-child {
//           margin-right: 0px;
//         }
//       }
  
//       &--swiper {
//         width: 100%;
//         padding: 16px 0px 16px 16px;
//         position: relative;
  
//         @media (max-width: 767px) {
  
//           .swiper-button-prev,
//           .swiper-button-next {
//             display: none;
//           }
//         }
  
//         @media (min-width: 768px) {
//           padding: 14px 24px;
  
//           .swiper-slide-thumb-active {
//             opacity: 1;
//           }
  
//           .swiper-button-prev,
//           .swiper-button-next {
//             color: #D69C4F;
//             position: absolute;
//             transform: translateY(20%);
//             z-index: 1000;
//           }
  
//           .swiper-button-prev {
//             left: 1%;
//           }
  
//           .swiper-button-next {
//             right: 2%;
//           }
  
//           .swiper-button-prev:after,
//           .swiper-button-next:after {
//             font-size: 24px;
//             font-weight: 700;
//             font-family: NexaSlab;
//             border-radius: 50%;
//             padding: 10px 15px;
//             background-color: white;
//             box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
//           }
  
//           .swiper-button-prev.swiper-button-disabled,
//           .swiper-button-next.swiper-button-disabled {
//             opacity: 0.35;
//             cursor: auto;
//             display: none;
//             pointer-events: none;
//           }
//         }
//       }
//     }
//   }
// }

.promotionscardrestaurant {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;


  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          width: 255px;
          height: 100%;
          padding: 10px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 1%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }

    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.textt {
  font-family: Sofia;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 100;
  line-height: 100%;
  margin: 0;
  text-transform: uppercase;

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}

.triimg {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 50%;
  }
}

