@import '../../atoms/text/styles.scss';

.accordioncheckbox {
  user-select: none;

  .title{
    @include parragraph-normal(NexaSlab, 14px, 700, normal);
  }

  &__container{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding-block: 10px;
    cursor: pointer;
  }
}