@import '../../../components/atoms/text/styles.scss';

.promotion-not-found {
  padding-top: 50px;

  @media (max-width: 768px) {
    padding: 20px;
  }

  &__container {
    .promotion-pararaph {
      @include parragraph-normal(Quicksand, 20px, 400, normal);
      margin-bottom: 20px;

      @media (max-width: 768px) {
        @include parragraph-normal(NexaSlab, 16px, 400, normal);
      }
    }

    .title {
      @include parragraph-normal(NexaSlab, 40px, 400, normal);
      text-transform: uppercase;

      @media (max-width: 768px) {
        @include parragraph-normal(NexaSlab, 20px, 400, normal);
      }
    }

    .image-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 20px;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        gap: 10px;
      }

      img {
        width: 100%;
        max-width: 200px;
        max-height: 76px;
        height: auto;

        @media (max-width: 768px) {
          max-width: 150px;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: 10px;
      width: 100%;

      div {
        flex: 1;
      }

      .label-input {
        display: flex;
        gap: 20px;
        margin-bottom: 10px;

        label {
          font-family: "Quicksand";
          font-size: 16px;
          color: #6B6B6B;
        }
      }

      .promotion-input-form {
        display: flex;
        flex-direction: column;
        width: 100%;

        input {
          width: 300px;
          height: 45px;
          border: 1px solid #6B6B6B;
          border-radius: 7px;
          outline: none;
          padding: 10px;
          box-sizing: border-box;
          transition: 0.3s;
          background: white;
          @include parragraph-normal(Quicksand, 16px, 500, normal);

          &:focus {
            border: 2px solid var(--color-gold);
          }

          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }

      .errors {
        font-family: "Quicksand";
        font-size: 12px;
        color: red;
        font-weight: 600;
        margin-top: 5px;
      }

      button {
        width: 300px;
        height: 45px;
        border: none;
        border-radius: 7px;
        margin: 10px 0;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        transition: 0.3s;
        background: var(--color-gold);
        color: white;
        cursor: pointer;
        @include parragraph-normal(Quicksand, 16px, 700, normal);

        @media (max-width: 768px) {
          width: 100%;
        }

        &:disabled {
          background: var(--color-gold);
          opacity: 0.5;
          cursor: not-allowed;
        }
      }


      .checkbox__form {
        width: 100%;

        span {
          @include parragraph-normal(Quicksand, 12px, 600, normal);
          color: red;
        }

        label {
          align-items: center;
          display: flex;
          margin: 10px 0;

          @media (max-width: 768px) {
            margin: 0;
            margin-top: 5px;
            font-size: 15px;
          }
        }

        input[type="checkbox"] {
          width: 100%;
          max-width: 20px;
          height: 20px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          border-radius: 4px;
          border: 1px solid var(--color-gold);
          margin-right: 10px;
          background: white;
        }

        input[type="checkbox"]:checked::before {
          content: "\2713";
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--color-gold);
          width: auto;
          height: 18px;
        }
      }

    }

    .borders {
      width: 300px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}