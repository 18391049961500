@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.section-descripcion{
  padding: 40px 30px;
  background: url('../../assets/images/bkg-texture.webp');
  background-repeat: repeat;
  background-size: 70px;
  text-align: center;

  h2{text-align: left;}

  .section-value{display: flex; align-items: center; justify-content: center; margin-bottom: 30px; color: #0E2E61;}
  .section-value img{height: 70px;}
  .value-dato{display: inline-block; width: 250px; padding: 30px 0 20px 0; height: 140px;}
  .value-dato p{margin:0; text-align: center; margin-top: -15px;}
  .value-num{font-size: 50px; line-height: 70px; font-weight:700; }
  .value-num .simbol{font-size: 30px;}
  .value-simbolo{margin: 0 30px; font-size: 45px; color:#00A7CB; }

}

.alp{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
}

.alp__container{
  max-width: 1220px;
}

.alp {
  h1{text-align: center; margin-bottom: 30px;}
  h2{text-align: center;}
  p{margin:20px 0;}
  li{margin:10px 0;}
  p, li{text-align: justify;} 

  .section-condiciones{display: flex; align-items: flex-start; justify-content: center; margin-top: 20px; margin-bottom: 50px;}
  .item-c{padding: 0px; margin: 15px; width: 33%;}
  .item-c img{width: 100%;}
  .item-c-desc{background: #e9e9e9; padding: 20px; margin-top: -5px; border-radius: 0 0 10px 10px;}
  .item-c-desc h3{ margin: 0 0 5px;
      font-weight: bold;
      font-size: 20px;
      text-align: center;}
  .item-c-desc p{text-align: center;}


}

@media screen and (max-width: 768px) 	{

  .alp {
    .title{ font-size: 28px; line-height: 30px;}
    .value-dato{width:100px}
    .section-paso{flex-direction: column;}
    .item-p{ width: 100%;}
  }

}

// label {
//   cursor: pointer;
//   user-select: none;
//   text-decoration: none;
//   display: inline-block;
//   color: inherit;
//   transition: border 0.2s;
//   border-bottom: 5px solid rgba(142, 68, 173, 0.2);
//   padding: 3px 2px;
// }
// label:hover {
//   border-bottom-color: #9b59b6;
// }

.layout {
  display: grid;
  /*height: 100%;*/
  width: 100%;
  overflow: hidden;
  grid-template-rows: 40px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.layout > input[type=radio] {
  display: none;
}

label.nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: background 0.4s, padding-left 0.2s;
  padding-left: 0;
  border-bottom: 2px solid #fff;
  width:auto; /*100%*/
  /*max-width: 220px;*/
  margin: 0 auto;
  transition: .2s all linear;
  position:relative;
}
label.nav-item:before{
  content : "";
  position: absolute;
  bottom: -2px;
  height  : 5px;
  width   : 0;  
  border-bottom:2px solid #fff;
  transition:  1s;
}
label.nav-item:hover:before{
  width   : 100%;
  border-bottom: 2px solid #000;
}
.layout > input[type=radio]:checked + .page + label.nav {
  color: #d69c4f;
}
.layout > input[type=radio]:checked + .page + label.nav-item:before{
  border-bottom: 2px solid #d69c4f;
  width: 100%;
}
.layout > input[type=radio]:checked + .page + label.nav-item:hover:before{
  border-bottom: 2px solid #d69c4f;
}
label.nav span {
  padding-left: 0px;
  position: relative;
}
label.nav svg {
  left: 0;
  top: -3px;
  position: absolute;
  width: 15px;
  opacity: 0;
  transition: opacity 0.2s;
}

.page {
  grid-column-start: 1;
  grid-row-start: 2;
  grid-column-end: span 4;
  padding: 0px 20px;
  display: flex;
  align-items: flex-start;
}

.page-contents > * {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.2s, transform 0.2s;
}
.page-contents > *:nth-child(1) {
  transition-delay: 0.4s;
}
.page-contents > *:nth-child(2) {
  transition-delay: 0.6s;
}
.page-contents > *:nth-child(3) {
  transition-delay: 0.8s;
}
.page-contents > *:nth-child(4) {
  transition-delay: 1s;
}
.page-contents > *:nth-child(5) {
  transition-delay: 1.2s;
}
.page-contents > *:nth-child(6) {
  transition-delay: 1.4s;
}
.page-contents > *:nth-child(7) {
  transition-delay: 1.6s;
}
.page-contents > *:nth-child(8) {
  transition-delay: 1.8s;
}
.page-contents > *:nth-child(9) {
  transition-delay: 2s;
}
.page-contents > *:nth-child(10) {
  transition-delay: 2.2s;
}
.page-contents > *:nth-child(11) {
  transition-delay: 2.4s;
}
.page-contents > *:nth-child(12) {
  transition-delay: 2.6s;
}
.page-contents > *:nth-child(13) {
  transition-delay: 2.8s;
}
.page-contents > *:nth-child(14) {
  transition-delay: 3s;
}
.page-contents > *:nth-child(15) {
  transition-delay: 3.2s;
}
.page-contents > *:nth-child(16) {
  transition-delay: 3.4s;
}
.page-contents > *:nth-child(17) {
  transition-delay: 3.6s;
}
.page-contents > *:nth-child(18) {
  transition-delay: 3.8s;
}
.page-contents > *:nth-child(19) {
  transition-delay: 4s;
}
.page-contents > *:nth-child(20) {
  transition-delay: 4.2s;
}

.layout > input[type=radio] + .page {
  transition: transform 0.2s;
  transform: translateX(100%);
}

.layout > input[type=radio]:checked + .page {
  transform: translateX(0%);
}
.layout > input[type=radio]:checked + .page .page-contents > * {
  opacity: 1;
  transform: translateY(0px);
}

.page-contents {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
}

/*Acordeon*/
.accordion {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: auto;
  /*font-family: Arial, Helvetica, sans-serif;*/
  margin:0 auto;
} 
/*Contenedor común plegar/desplegar*/
.accordion-toggle,
.accordion-header{
    font-size: 15px;
    width: 100%;
    min-height: 50px;
    margin: 0;
    cursor: pointer;
    padding: 10px 60px 10px 0;
    display: flex;
    align-items: center;
}
/*El botón que pliega/despliega*/
.accordion-toggle {
  opacity: 0;
  background-color: transparent;
  z-index: 1;
  margin-bottom: 2px;
  width:auto !important;
  height: auto !important;
}
/*Bloque para el título*/
.accordion-header{
  background-color: #ffffff;
  color: #000;
  font-weight: bold;
  position: relative;
  margin-top: -3em;
  padding-left: 10px;
  border-top: 1px solid #000;
}
.accordion-header:nth-child(2){
  border-top: 0;
}
/*Bloque replegable con el contenido */
.accordion-content {
  box-sizing: border-box;
  color: #000;
  overflow: auto;
  position: relative;
  width: 100%;
  max-height: 0;
  padding: 0;
  transition: margin 0.3s ease 0.1s;
  }
.accordion-toggle:checked + .accordion-header + .accordion-content {
  max-height: 100vh; /* Se deja que use la altura necesaria hasta un máximo*/
  margin-right: 0;
  padding: 0 10px 15px;
}
.accordion-header::before,
.accordion-toggle:checked + .accordion-header::before {
  
  display: inline-block;
    text-align: center;
    width: 1em;
    font-size: 1em;
    position: absolute;
    right: 30px;
    content: "\f107";
    color: #d69c4f;
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: bold;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}
.accordion-toggle:checked + .accordion-header::before {
  content: "\f106";
}
.accordion-toggle:checked + .accordion-header {
  background-color: #fff;
  color: #d69c4f;
} 

@media screen and (max-width: 540px) 	{

.layout {
  grid-template-columns: 1fr 1fr ;
}
label.nav{width: 85%;}
.nav-inactive{display:none !important;}
.accordion-header{  
  padding-right: 60px;
  min-height: 50px;
}

}