@import "../../../components/atoms/text/styles.scss";

.arco {
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    justify-content: center;
    padding: 0px 16px;
    @include parragraph-normal(Quicksand, 16px, 500, normal);
    line-height: normal;

    @media (min-width: 768px) {
      padding: 0px 60px;
    }

    @media (min-width: 1200px) {
      max-width: 1220px;
      padding: 0px 120px;
    }

    &--form {
      @include parragraph-normal(Quicksand, 16px, 500, normal);

      form {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .columinputs {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include parragraph-normal(Quicksand, 16px, 500, normal);


        .maininputs {
          display: flex;
          flex-direction: column;
          gap: 10px;

          @media (min-width: 768px) {
            flex-direction: row;
          }

          div {
            @media (min-width: 768px) {
              width: 50%;
            }
          }

          input {
            width: 100%;
            height: 45px;
            border: 1px solid #6B6B6B;
            border-radius: 7px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
            transition: 0.3s;
            background: white;
            @include parragraph-normal(Quicksand, 16px, 500, normal);
            color: #6B6B6B;

            &::placeholder {
              color: #6B6B6B;
            }

            &:focus {
              border: 2px solid var(--color-gold);
            }
          }
        }

      }

      .columlabels {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 16px;
        @include parragraph-normal(Quicksand, 16px, 400, normal);

        .columlabels__input {
          align-items: center;
          display: flex;
          margin: 10px 0;
        }

        input[type="checkbox"] {
          width: 100%;
          max-width: 20px;
          height: 20px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          border-radius: 4px;
          border: 1px solid var(--color-gold);
          margin-right: 10px;
        }

        input[type="checkbox"]:checked::before {
          content: "\2713";
          color: white;
          align-items: center;
          justify-content: center;
          background-color: var(--color-gold);
          width: auto;
          height: 18px;
          display: flex;
        }
      }

      .textareainputs {
        @include parragraph-normal(Quicksand, 16px, 400, normal);

        textarea {
          width: 100%;
          height: 100px;
          margin-top: 16px;
          border: 1px solid #6B6B6B;
          border-radius: 7px;
          outline: none;
          padding: 10px;
          box-sizing: border-box;
          transition: 0.3s;
          background: white;
          resize: none;
          color: #6B6B6B;

          &::placeholder {
            color: #6B6B6B;
          }

          &:focus {
            border: 2px solid var(--color-gold);
          }

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--color-gold);
            border-radius: 10px;
          }

          &::-webkit-scrollbar-track {
            background-color: #f1ecec;
            border-radius: 10px;
          }
        }
      }

      .columinputs1 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include parragraph-normal(Quicksand, 16px, 500, normal);

        p {
          @include parragraph-normal(Quicksand, 14px, 500, normal);
          color: #6B6B6B;
        }

        .maininputs {
          display: flex;
          flex-direction: column;
          gap: 10px;

          @media (min-width: 768px) {
            flex-direction: row;
          }

        }

        input {
          width: 100%;
          height: 45px;
          border: 1px solid #6B6B6B;
          border-radius: 7px;
          outline: none;
          padding: 10px;
          box-sizing: border-box;
          transition: 0.3s;
          background: white;
          @include parragraph-normal(Quicksand, 16px, 500, normal);
          color: #6B6B6B;

          &::placeholder {
            color: #6B6B6B;
          }

          &:focus {
            border: 2px solid var(--color-gold);
          }
        }

      }

      .columinputs2 {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .maininputs2 {
          display: flex;
          flex-direction: row;
          gap: 10px;

          div {
            width: 50%;
          }
        }

        input {
          width: 100%;
          height: 45px;
          border: 1px solid #6B6B6B;
          border-radius: 7px;
          outline: none;
          padding: 10px;
          box-sizing: border-box;
          transition: 0.3s;
          background: white;
          @include parragraph-normal(Quicksand, 16px, 500, normal);
          color: #6B6B6B;

          &::placeholder {
            color: #6B6B6B;
          }

          &:focus {
            border: 2px solid var(--color-gold);
          }
        }

        select {
          width: 100%;
          height: 45px;
          border: 1px solid #6B6B6B;
          border-radius: 7px;
          outline: none;
          background-color: transparent;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-image: url('../../../assets/icons/arrow_down.png');
          background-size: 15px;
          background-repeat: no-repeat;
          background-position: calc(100% - 16px) center;
          padding: 10px;
          @include parragraph-normal(Quicksand, 16px, 500, normal);
          color: #6B6B6B;

          &:focus {
            border: 2px solid var(--color-gold);
          }
        }
      }

      .answer {
        margin-top: 16px;
        line-height: normal;
      }

      .table-arco {
        border: 1px solid #ebebeb;
        border-radius: 7px;
        border-spacing: 0;
        width: 100%;
        text-align: left;
        margin: 24px 0px;

        td,
        th {
          vertical-align: top;
          padding: 0.6rem 1rem;
          border-bottom: 1px solid #ebebeb;
          outline: none;
        }
      }

      .btnsubmit {
        width: 100%;
        height: 45px;
        border: none;
        border-radius: 7px;
        margin: 24px 0;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        transition: 0.3s;
        background: var(--color-gold);
        color: white;
        cursor: pointer;
        @include parragraph-normal(Quicksand, 16px, 600, normal);
        margin: auto;

        &:disabled {
          background: var(--color-gold);
          opacity: 0.5;
          cursor: not-allowed;
        }

        @media (min-width: 768px) {
          width: 320px;
        }
      }
    }
  }
}