@import '../../../atoms/text/styles.scss';

.bannercyberwow {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #1433FF;
  padding-block: 16px;

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        flex-direction: row;
        margin-bottom: 10px;
      }

      .title {
        text-transform: uppercase;
        text-align: center;
        @include parragraph-normal(Quicksand, 20px, 700, normal);

        @media (min-width: 768px) {
          padding-right: 16px;
          text-align: left;
          font-size: 28px;
        }
      }

      img {
        width: 80px;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}