.titulo-questions {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  font-family: NexaSlab;
}

.tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabsfaq-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  // background-color: #f2f2f2;

  .tabs-container {

    .tabs {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      @media (min-width: 768px) {

        flex-direction: row;
        gap: 22px;
      }

      p {
        text-align: center;
        font-weight: 700;

      }

      div {
        color: #333;
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 100%;

        &:hover {
          border-bottom: 2px solid #D69C4F;
          color: #D69C4F;
        }

        &.active {
          border-bottom: 3px solid #D69C4F;
          color: #D69C4F;
        }
      }
    }

    .active-bold {
      font-weight: 700;
    }
  }

  .content {
    text-align: center;
    padding: 25px 0px;
    border-radius: 4px;
    width: 100%;
    // height: 360px;

    @media (min-width: 1024px) {
      width: 800px;
      ;
    }

    section {
      display: none;

      &.active {
        display: block;
        width: 100%;
      }
    }
  }
}

.container-questions {
  padding: 0px 16px;

  .titulo-questions {
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    padding: 0px 60px;
  }
}