/*
  CONTENT STRUCTURE:
    height en propiedades:       h- 
    height en porcentaje:        h-#por
    height en pixel:             h-#px
    height en viewport width:    h-#vw
    height en viewport height:   h-#vh
*/

@import "../variables.scss";

$property: (    
    auto: auto,
    none: none,
    fit-content: fit-content,
    min-content: min-content,
    max-content: max-content,
    fill: -webkit-fill-available   
);

@each $name, $size in $property{
    /* HEIGHT GENERAL (h-) */
    .h-#{ $name }{
        height: #{$size} !important;
    }
}

@each $name, $value in $percentage{
    /* HEIGHT EN PORCENTAJE (h-#por) */
    .h-#{ $name }{
        height: #{$value} !important;
    }   
}

@each $name, $value in $viewportHeight{   
       /* HEIGHT VIEWPORT HEIGHT (h-#vh) */
    .h-#{ $name }{
        height: #{$value} !important;
    }
}

@each $name, $value in $viewportWidth{  
    /* HEIGHT VIEWPORT WIDTH  (h-#vw) */
    .h-#{ $name }{
        height: #{$value} !important;
    }
}

@each $name, $value in $pixel{
    /* HEIGHT EN PIXEL (h-#px) */
    .h-#{ $name }{
        height: #{$value} !important;
    }
}

/* OTHERS */
.max-h-100por {
    max-height: 100% !important;
}
