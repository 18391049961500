@import '../../../atoms/text/styles.scss';

.bannerestaurant2 {
  width: 100%;
  position: relative;

  &__content {
    width: 100%;
    height: 400px;

    @media (min-width: 768px) {
      height: 460px;
    }

    &--bg {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;

      @media (min-width: 768px) {
        clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
        width: 40%;
      }

      .type-restaurant {
        display: flex;
        padding-top: 40px;
        padding-inline: 40px 60px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: 520px;
        height: 100%;

        img {
          width: 100%;
          height: 100px;
          object-fit: contain;
        }

        .paragraph {
          @include parragraph-normal(NexaSlab, 14px, 400, normal);
          text-align: center;
          line-height: normal;
          color: white;
        }

        @media (min-width: 768px) {
          padding-top: 40px;
          gap: 30px;

          img {
            height: 180px;
          }
        }
      }

      .type-direction {
        padding-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        padding-top: 60px;
        padding-inline: 40px 60px;

        @media (min-width: 768px) {
          padding-top: 0;
          gap: 18px;
        }

        img {
          width: 100%;
          height: 100px;
          object-fit: contain;
        }

        .title {
          @include parragraph-normal(Quicksand, 20px, 400, normal);
          color: white;
        }

        .items {
          @include parragraph-normal(NexaSlab, 14px, 300, normal);
          color: white;

          .icon-txt {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
              text-underline-offset: 5px;
            }
          }
        }

        @media (min-width: 768px) {
          padding: 40px 20px 0 20px;
          max-width: 400px;
          gap: 24px;

          img {
            height: 170px;
          }

          .title {
            font-size: 28px;
            line-height: 28px;
          }

          .items {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }

      .type-hotel {
        padding-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 18px;
        padding-inline: 40px 60px;

        .title {
          @include parragraph-normal(NexaSlab, 40px, 600, normal);
          text-transform: uppercase;
          color: white;
        }

        .subtitle {
          @include parragraph-normal(Quicksand, 20px, 400, normal);
          text-transform: uppercase;
          color: white;
        }

        .btn-hotel {
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;
          border-radius: 7px;
          background: #D69C4F;
          padding: 10px 30px;
          // width: 100%;
          // max-width: 280px;
          height: 45px;

          .paragraph {
            @include parragraph-normal(NexaSlab, 16px, 600, normal);
            color: #FFF;
          }
        }

        @media (min-width: 768px) {
          align-items: flex-start;
          padding-top: 40px;
          gap: 24px;

          .title {
            font-size: 60px;
            line-height: 60px;
          }

          .subtitle {
            font-size: 35px;
            line-height: 35px;
          }
        }
      }
    }

    &--bgimg {
      height: 100%;
      width: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @media (min-width: 768px) {
        width: 64%;
      }
    }
  }
}