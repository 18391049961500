@import '../../../components/atoms/text/styles.scss';

.notificationmodal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  min-height: 100px;

  &__header {
    @include parragraph-normal(NexaSlab, 20px, 600, normal);
    text-align: center;
    color: #D69C4F;
  }
  
  &__description {
    @include parragraph-normal(NexaSlab, 14px, 400, normal);
    margin-block: 10px;
  }
}