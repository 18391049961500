@import '../../atoms/text/styles.scss';

.card__valuations {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  // width: 246px;
  // max-height: 320px;
  // height: 100%;
  // padding: 15px 20px;
  // border-radius: 9px;
  // background: #FFF;
  // box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // margin: 12px 10px;
  // gap: 10px;
  // cursor: pointer;


}

.card__valuationsmodal {
  width: calc(100% - 60px);
  max-width: 360px;
  padding: 30px 40px;
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 10px;
  min-height: 368px;

  &--container {

    .cardrating {
      padding-top: .5rem;
    }

    .cardtitle {
      padding-top: .75rem;
      padding-bottom: .75rem;

      &__title {
        font-family: Sofia;
        font-size: 1rem;
        line-height: 1.2;
        font-weight: 100;
        margin: 0;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      &__paragraph {
        padding-top: 1rem;
        font-family: Sofia;
        font-size: .75rem;
        line-height: 1.2;
        font-weight: 100;
        margin: 0;
      }
    }

  }

  &--carddescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .imgtrip {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    .imgmap {
      width: 24px;
      height: 15px;
    }
  }
}

.cardcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sectionvaluations {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;

  // @media (min-width: 768px) {
  //   padding-top: 50px;
  // }


  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .titlecontent {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;


      @media (min-width: 768px) {
        margin-bottom: 30px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .subtitle {
        @include title-section2(NexaSlab, 700, normal);
      }
    }

    .contentlogo {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
      padding-inline: 16px;
      padding-top: 16px;
      width: 100%;

      @media (min-width: 768px) {
        gap: 1.5rem;
        width: 50%;
        padding-top: 0px;
      }

      img {
        border-radius: 50%;
        object-fit: contain
      }

      .paragraph {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
      }

      .review {
        @include parragraph-normal(Quicksand, 25px, 500, normal);
        text-transform: uppercase;
        display: flex;
        align-items: flex-start;
        gap: 6px;
      }
    }

    .values {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px 20px;
      padding: 24px 16px;

      @media (min-width: 768px) {
        gap: 10px 60px;
        grid-template-columns: 420px 420px;
        padding: 24px 0px 36px;
      }

      &__line {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        @media (min-width: 768px) {
          gap: 40px;
        }


        ul {
          padding-left: 20px;

          ::marker {
            font-size: 12px;
          }
        }

        &--name {
          width: 190px;
          @include parragraph-normal(Quicksand, 14px, 400, normal);

          @media (min-width: 768px) {
            width: 200px;
          }


          // ::before {
          //   content: '.';
          //   font-weight: 700;
          //   margin-inline: 10px;
          // }
        }

        &--content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 150px;
          gap: 6px;

          @media (min-width: 768px) {
            width: 175px;
          }

          .linevalue {
            width: 168px;
            height: 7px;
            border-radius: 10px;
            background-color: #D69C4F;
          }

          .linetext {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;

            &__title {
              @include parragraph-normal(Quicksand, 12px, 400, normal);
              color: #888787;
            }

            &__scores {
              @include parragraph-normal(Quicksand, 14px, 400, normal);
            }
          }
        }
      }
    }
  }

  &__description {
    display: flex;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 284px; // Medida de la card
      height: 100%;
      margin-right: 28px; // Espacio entre cards

      @media (min-width: 768px) {
        width: 284px; // Medida de la card
        margin-right: 42px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 26px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(50%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      cursor: pointer;
      user-select: none;
      border-radius: 9px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      padding: 15px 20px;
      width: 284px; // Medida de la card
      @include scale;
      @include touchmobile;

      &--slider {
        width: 100%;
        border-radius: inherit 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: none;
        }

        .swiper-slide {
          margin-right: 0px; // Espacio en subslider entre img
        }
      }

      &--container {

        .cardrating {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 10px;
          height: 100%;
        }

        .cardtitle {
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: space-between;
          height: 210px;

          &__title {
            @include parragraph-normal(Quicksand, 16px, 700, normal);
            &::first-letter {
              text-transform: uppercase;
            }
          }

          &__paragraph {
            @include parragraph-normal(Quicksand, 14px, 400, normal);
            line-height: normal;
            margin-top: 10px;
          }

          &__valuations {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            .imgtrip {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              object-fit: contain;
            }

            .imgmap {
              width: 24px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}

.scrollcontent {
  width: 100%;
  overflow-y: auto;

  @media (min-width: 768px) {
    height: 440px;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 200px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D69C4F;
    border: none;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #E6E6E6;
    border-radius: 10px;
  }
}


.center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  width: 100%;
}