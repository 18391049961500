@import '../../atoms/text/styles.scss';

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnfiltros{
  padding-inline: 20px;
  .contentexperiencias {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width: 768px) {
      padding-block: 20px;
    }

    .buttonclose {
      background-color: rgba(214, 156, 79, 0.3);
      border-radius: 10px;
      cursor: pointer;
      color: #D69C4F;
      padding: 10px;
      max-width: max-content;
      display: flex;
      align-items: center;
      gap: 6px;
      @include parragraph-normal(Quicksand, 12px, 400, normal);

      &:hover {
        @include scale;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  display: flex;
  justify-content: center;
  align-items: center;
}


.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
}