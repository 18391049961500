@import "../../atoms/text/styles.scss";

.promotionsyqp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat;
  background-position: center;
  background-image: url("../../../assets/images/bkg-texture.webp");
  position: relative;

  &__container {
    width: 100%;
    display: grid;
    height: max-content;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 1200px) {
      min-height: 160px;
      text-align: left;
      padding-top: 35px;
      padding-bottom: 35px;
      grid-template-columns: 1fr 0.2fr 1fr;
      max-width: 1220px;
    }

    &--titulo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      @media (max-width: 1200px) {
        margin-bottom: 20px;
      }
    }

    .termino {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: 768px) {
        justify-content: center;
      }

      @media (min-width: 1200px) {
        justify-content: flex-start;
      }
    }

    .codigo {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      @media (max-width: 1200px) {
        margin-top: 20px;
        text-align: center;
      }

      p {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;

        @media (min-width: 1200px) {
          align-items: flex-start;
        }

        ol {
          @include parragraph-normal(NexaSlab, 16px, 400, normal);
          width: 100%;

          li {
            margin-left: 20px;

            ::marker {
              font-size: 10px;
            }
          }
        }
      }

      @media (min-width: 768px) {
        ul {
          @include parragraph-normal(Quicksand, 14px, 400, normal);

          span {
            @include parragraph-normal(Quicksand, 14px, 400, normal);
          }

          li {
            margin-left: 20px;

            ::marker {
              font-size: 10px;
            }
          }
        }

        ol {
          @include parragraph-normal(Quicksand, 14px, 400, normal);

          li {
            margin-left: 20px;

            ::marker {
              font-size: 10px;
            }
          }
        }
      }
    }

    &--icon {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(90deg);

      @media (min-width: 1200px) {
        transform: rotate(0deg);
      }
    }

    .txtlink {
      cursor: pointer;
      margin-left: 8px;
      color: #d69c4f;
      text-decoration: none;
      border-bottom: #d69c4f 2px solid;

      :hover {
        transform: scale(1.1);
      }
    }

    .modal__terminos{
      width: 100%;
      
      .paragraph{
        @include parragraph-normal(Quicksand, 12px, 400, normal);
        line-height: normal;
      }
    }

    .btnclip {
      width: 280px;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;

      @media (min-width: 768px) {
        width: 322px;
      }

      &__text {
        width: 100%;
        height: 100%;
        background-color: white;
        padding-left: 10px;
        border-radius: 10px 0px 0px 10px;
        border-left: 1.5px solid #e6e6e6;
        border-bottom: 1.5px solid #e6e6e6;
        border-top: 1.5px solid #e6e6e6;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (min-width: 768px) {
          padding-left: 20px;
        }
      }

      &__copy {
        width: 102px;
        height: 50px;
        border: 1.5px solid #d69c4f;
        padding-right: 14px;
        padding-left: 14px;
        border-radius: 0px 10px 10px 0px;
        background-color: #d69c4f;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: white;
      }
    }

    .list {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-family: Sofia;
        font-weight: 100;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
    }
  }
}
