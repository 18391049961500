@import '../../atoms/text/styles.scss';

.list {
  .description {

    .services {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 10px;
      padding-inline: 16px;

      @media (min-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (min-width: 1200px) {
        padding-inline: 0;
      }

      .column {
        display: flex;
        align-items: center;
        height: 36px;
        gap: 10px;

        .paragraph {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
        }
      }
    }
  }
}