@import '../../atoms/text/styles.scss';

.restaurantscards {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }
  }

  .subtitle {
    @include title-section(NexaSlab, 700, normal);
    margin-bottom: 20px;

    @media(min-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &__description {

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 320px; // Medida de la card
      margin-right: 26px; // Espacio entre cards

      @media (min-width: 768px) {
        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 20px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(30%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      @include touchmobile;
      cursor: pointer;
      border-radius: 9px;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      width: 320px; // Medida de la card
      height: 360px; // Medida de la card
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      color: black;

      &--logo {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        z-index: 2;

        img {
          width: 45px;
          height: 45px;
          object-fit: contain;
          z-index: 2;
        }
      }

      &--img {
        width: 100%;
        height: 200px;
        border-radius: inherit 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: none;
        }

        .swiper {
          height: 200px;
        }

        .swiper-slide {
          margin-right: 0px; // Espacio en subslider entre img
        }
      }

      &--container {
        color: black;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        max-height: 160px;
        padding: 16px;

        .cardheaderr {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;

          .ttrestaurant {
            @include parragraph-normal(NexaSlab, 14px, 700, normal);
          }

          .review {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            p {
              @include parragraph-normal(Quicksand, 14px, 400, normal);
            }
          }
        }

        .sbrestaurant {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
        }

        .description {
          @include parragraph-normal(Quicksand, 14px, 400, normal);

        }

        .icontext {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          @include parragraph-normal(Quicksand, 14px, 400, normal);
        }


      }
    }

  }
}