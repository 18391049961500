.custom__explore {
  width: 100%;
  height: 100%;

  &--container {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 124px;
      object-fit: cover;
    }
  }
}

.containersliderexplore {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 240px;
  }

  @media (max-width: 767px) {

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  @media (min-width: 768px) {
    // padding-top: 40px;
    .explore__swiper .swiper-slide-thumb-active {
      opacity: 1;
    }

    .explore__swiper {
      position: relative;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #D69C4F;
    }

    .swiper-button-prev {
      left: 1%;
    }

    .swiper-button-next {
      right: 1%;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 24px;
      font-weight: 700;
      border-radius: 50%;
      padding: 10px 15px;
      background-color: white;
      box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      display: none;
      pointer-events: none;
    }
  }
}

.custom__explorefinal {
  height: 100%;

  .card-explora {
    display: flex;
    flex-direction: column;
    border-radius: 9px;
    overflow: hidden;
    position: relative;
    width: 190px;
    height: 196px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
    text-decoration-line: none;
    color: black;
  }

  &--content {
    padding: 20px 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    gap: 10px;

    .btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #D69C4F;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}