@import '../../atoms/text/styles.scss';

.tabsection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 36px;
  max-width: 100%;
  background-color: #f2f2f2;

  &__container {
    &--tabs {
      display: flex;
      flex-wrap: wrap;
      padding-inline: 16px;
      @include parragraph-normal(NexaSlab, 18px, 300, normal);

      h3 {
        @include parragraph-normal(NexaSlab, 18px, 700, normal);
        line-height: normal;
        text-transform: lowercase;

        &:first-letter {
          text-transform: uppercase;
        }

        @media (min-width: 768px) {
          line-height: 30px;
        }
      }

      div {
        color: #333;
        margin-right: 40px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover,
        &.active {
          border-bottom: 3px solid #D69C4F;
          font-weight: 700;
          color: #D69C4F;
        }
      }
    }

    .active-bold {
      font-weight: 700;
    }
  }

  &__content {
    text-align: center;
    border-radius: 4px;
    width: 100%;

    section {
      display: none;

      &.active {
        display: block;
        width: 100%;
      }
    }

    &--slides {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      &--img {
        flex: 1;
        width: 50%;
        position: relative;
        transform: translateX(-10%);
        height: 350px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &--description {
        flex: 1;
        width: 50%;
        position: relative;
        filter: drop-shadow(6px 8px 6px rgba(0, 0, 0, 0.20));

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--card {
        position: relative;
        background-color: white;
        clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
        color: #000000;
        transform: translateX(5%);
        width: 100%;
        height: 312px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap !important;

        display: flex;
        flex-direction: column;
        padding: 30px 50px 30px 40px;
        text-wrap: wrap !important;
        text-align: initial;

        @media (min-width: 768px) {
          height: fit-content;
          max-height: 312px;
        }
      }

      &--left {

        .title {
          @include parragraph-normal(NexaSlab, 18px, 700, normal);
          color: #D69C4F;
          margin-bottom: 15px
        }

        .paragraph {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          line-height: 20px;

          @media (min-width: 768px) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}