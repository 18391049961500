@import '../../../components/atoms/text/styles.scss';

.visit {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-repeat: repeat;
  background-position: center;
  background-image: url('../../../assets/images/bkg-texture.webp');
  // height: -webkit-fill-available;
  padding-block: 25px 25px;
  position: relative;

  @media (min-width: 768px) {
    padding-top: 72px;
    padding-bottom: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px){
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;
      text-align: center;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }

    &--description {
      display: flex;

      .swiper {
        height: 100%;
      }

      .swiper-slide {
        width: 240px;
        margin: 12px 30px 12px 12px;
      }
    }
  }
}

