.directores{
  display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
}

.directores__container{
  max-width: 1220px;
}

.directores {
  h1{text-align: center; margin-bottom: 30px;}
  p{margin:20px 0;}
  li{margin:10px 0;}
  p, li{text-align: justify;} 

  .item-director{margin-bottom: 80px;}
  .item-director{
    h2, h4{text-align: center;}
  }
}