@import "../../atoms/text/styles.scss";

.card-promotion-hotel-package {
  width: 580px;
  height: 300px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  transition: scale 400ms;
  display: flex;
  flex-direction: row;
  border-radius: 9px;

  @media (max-width: 768px) {
    width: 320px;
    height: 500px;
    flex-direction: column;
  }

  &:hover {
    scale: 104%;
  }

  .tooltip-promo {
    background-color: white;
    color: #222;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 15px;
    font-family: Quicksand;
    font-weight: 300;
    width: 200px;
    font-size: 10px;
    z-index: 10;
  }

  .left-container {
    padding: 20px 20px;
    padding-top: 15px;
    padding-right: 10px;
    width: 53%;
    height: 100%;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include parragraph-normal(Quicksand, 13px, 400, normal);
    background-color: white;

    @media (max-width: 768px) {
      border-radius: 0;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      width: 100%;
      height: 300px;
    }

    .logo-container {
      width: 100%;
      height: 45px;
      display: flex;
      justify-content: center;

      .logo {
        text-align: center;
        object-fit: contain;
        max-height: 30px;
      }

      .titulo-logo {
        @include parragraph-normal(Sofia, 28px, 400, normal);
        font-family: "Dancing Script";
        text-align: center;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .service-item {
      display: flex;
      flex-direction: row;
      margin-top: 3px;
      gap: 6px;
      align-items: center;
      min-height: 20px;

      .service-image {
        height: 20px;
        width: 20px;
        object-fit: contain;
      }

      .service-empty-image::after {
        content: "•";
        color: black;
        height: 20px;
        width: 20px;
        font-size: 20px;
      }
    }

    .bottom-container {
      display: flex;
      flex-direction: row;

      .prices-container {
        .price-text {
          @include parragraph-normal(Quicksand, 11px, 300, normal);
          margin-bottom: 3px;
        }

        .package-price {
          @include parragraph-normal(NexaSlab, 30px, 700, normal);
        }

        .icon-right svg {
          cursor: pointer;
        }
      }

      .btn-arrow {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-underline-offset: 4px;
        text-wrap: nowrap;
        margin-right: 20px;

        &:hover {
          color: var(--color-gold);
        }
      }
    }
  }

  .right-container {
    position: relative;
    width: 47%;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      border-radius: 0;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      width: 100%;
      height: 200px;
    }

    .book-button {
      position: absolute;
      bottom: 30px;
      line-height: 18px;
      padding: 12px 20px !important;
    }
  }
}
