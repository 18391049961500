@import '../../../atoms/text/styles.scss';

.slidersiximg {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;

  // @media (min-width: 768px) {
  //   padding-block: 50px;
  // }

  &__description {
    width: 100%;

    @media (min-width: 1200px){
      max-width: 1220px;
    }

    .subtitle {
      @include title-section2(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }

    &--slider {
    }
  }
}