@import '../../../atoms/text/styles.scss';

.bannerlife {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 40px 20px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      width: 100%;
      color: white;


      @media (min-width: 768px) {
        align-items: center;
        gap: 40px;
      }

      &--img {
        img {
          width: 120px;
          height: 100%;

          @media (min-width: 768px) {
            width: 136px;
          }
        }
      }

      &--container {
        color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        @media (min-width: 768px) {
          display: flex;
          gap: 40px;
          flex-direction: row;
          align-items: center;
        }
      }

      .title {
        width: 100%;
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        text-align: center;

        @media (min-width: 768px) {
          @include parragraph-normal(Quicksand, 20px, 100, normal);
        }
      }

      .btn-black {
        cursor: pointer;
        @include parragraph-normal(NexaSlab, 14px, 400, normal);
        width: 168px;
        height: 45px;
        background: transparent;
        border-radius: 5px;
        color: white;
        border: 2px solid var(--color-gold);
        display: block;

        @media (min-width: 768px) {
          width: 210px;
          font-size: 18px;
        }

        &:hover {
          background-color: var(--color-gold);
          color: white !important;
        }

        &:active {
          background-color: var(--color-gold);
          color: white !important;
        }
      }

      .right {
        display: flex;
        // flex-wrap: wrap;
        // text-align: center;
        // justify-content: center;
        // gap: 12px;

        // @media (min-width: 768px) {
        //   flex-wrap: nowrap;
        //   justify-content: space-evenly;
        //   align-items: center;
        //   width: 100%;
        //   gap: 20px;
        // }
      }
    }
  }
}

.bannerimgbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 40px 20px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      width: 100%;
      color: white;


      @media (min-width: 768px) {
        align-items: center;
        gap: 40px;
      }

      @media (min-width: 1200px) {
        padding-inline: 100px;
      }

      &--img {
        width: 82px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &--container {
        color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        @media (min-width: 768px) {
          display: flex;
          gap: 40px;
          flex-direction: row;
          align-items: center;
        }


      }

      &--title {
        @include parragraph-normal(Quicksand, 14px, 400, normal);
        text-align: center;


        @media (min-width: 768px) {
          @include parragraph-normal(Quicksand, 25px, 100, normal);

          span {
            font-weight: 500;
          }
        }
      }

      &--gold {
        cursor: pointer;
        @include parragraph-normal(NexaSlab, 14px, 400, normal);
        width: 214px;
        height: 45px;
        background: #D69C4F;
        border-radius: 5px;
        color: white;
        border: 2px solid var(--color-gold);
        display: block;

        @media (min-width: 768px) {
          width: 214px;
          font-size: 18px;
        }

        &:hover {
          background: #D69C4F;
        }

        &:active {
          background: #000000;
        }
      }

    }
  }
}

.bannerbanks {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  // height: 230px;
  background-size: cover;
  background-position: center;

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--banner {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }

    &--content {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;


      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
        gap: 40px;
      }

      .textos {
        text-align: center;

        .texto_1 {
          @include parragraph-normal(Quicksand, 28px, 300, normal);
          color: white;

          @media (min-width: 768px) {
            font-size: 32px;
          }
        }

        .texto_2 {
          @include parragraph-normal(Quicksand, 20px, 400, normal);
          margin-top: 10px;
          color: #D69C4F;

          @media (min-width: 768px) {
            font-size: 26px;
          }
        }
      }

      .btnbanks {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        @media (min-width: 768px) {
          justify-content: center;
          flex-direction: row;
          max-width: 550px;
        }

        .btn-banner {
          width: 300px;
          box-sizing: border-box;
          padding: 10px;
          border-radius: 35px;


          @media (min-width: 768px) {
            // width: 50%;
            width: 224px;
          }

          &--content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 6px;

            p {
              @include parragraph-normal(Quicksand, 10px, 400, normal);
              color: white;
            }

            img{
              width: 100%;
              height: 32px;
              object-fit: contain;
            }
          }
        }
      }

    }
  }


}

.bannerrestaurant {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 190px;
  background-size: cover;
  background-position: center;

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--banner {
      display: flex;
      justify-content: center;
      align-items: center;

      .textos {
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
      }

      .content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        &--right {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
      }

      .btn-banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          font-size: 12px;
        }

        .btn-img {
          border-radius: 25px;
          display: flex;
          justify-content: center;
          width: 200px;

        }
      }
    }
  }

}

.bannerrestaurant2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-size: cover;
  background-position: center;

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--banner {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      color: white;
      flex-direction: column;

      @media (min-width: 768px) {
      }
      
      @media (min-width: 1024px) {
        height: 228px;
        flex-direction: row;
      }

      .column-banner-restaurant {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-direction: column;


        @media (min-width: 768px) {
          flex-direction: row;
        }

        .bancobanner {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
        }
      }

      .text-banner {

        .title {
          @include parragraph-normal(Quicksand, 30px, 300, normal);
          text-align: center;
        }

        .subtitle {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          text-transform: lowercase;
          text-align: center;
        }
      }

      .button-banner {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 16px;
        gap: 20px;
        flex-direction: column;


        @media (min-width: 768px) {
          flex-direction: row;
        }


        &__restaurant {
          background-color: red;
          border-radius: 46px;
          width: 167px;
          height: 65px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .banco-banner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 148px;
        height: 24px;

        .banco-img-banner {
          height: 22px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .contenido-banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 156px;
        height: 156px;
        // padding-inline: 18px;
        border-radius: 50%;
        // background-color: rgba(6, 190, 79, 1);

        .descuento-banner {
          margin-left: 14px;
          margin-right: 14px;

          .text-up {
            @include parragraph-normal(Quicksand, 35px, 400, normal);
            text-align: center;
          }

          .text-down {
            @include parragraph-normal(Quicksand, 12px, 400, normal);
            text-transform: lowercase;
            text-align: center;
            width: 108px;
          }
        }


        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 56px;
          overflow: hidden;

          .discount {
            @include parragraph-normal(NexaSlab, 50px, 700, normal);
          }

          &--right {
            display: flex;
            flex-direction: column;

            .percentage {
              @include parragraph-normal(NexaSlab, 32px, 400, normal);
            }

            .dscto {
              @include parragraph-normal(NexaSlab, 10px, 300, normal);
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.bannernext {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 40px 20px;
  }

  &__container {
    width: 100%;
    color: white;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;

      @media (min-width: 768px) {
        gap: 26px;
      }

      .paragraph {
        @include parragraph-normal(NexaSlab, 14px, 300, normal);
        text-align: center;

        @media (min-width: 768px) {
          font-size: 18px;
        }
      }

      img {
        width: 150px;
        height: 100%;

        @media (min-width: 768px) {
          width: 136px;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 6px;
      }

      .right {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        gap: 12px;

        @media (min-width: 768px) {
          flex-wrap: nowrap;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
          gap: 10px;
        }

        p {
          font-size: 12px;
          margin: 0;
          font-family: NexaSlab;

          @media (min-width: 768px) {
            font-family: Sofia;
            font-weight: 100;
            font-size: 1.25rem;
          }
        }

        .btn-black {
          cursor: pointer;
          background: #000000;
          border-radius: 5px;
          padding: 10px 15px;
          color: white;
          border: 2px solid var(--color-gold);
          font-family: NexaSlab;
          font-weight: 800;
          font-size: .875rem;
          display: block;
          height: 45px;

          @media (min-width: 768px) {
            font-size: 1.25rem;
            padding: 14px 15px;
          }

          &:hover {
            background-color: var(--color-gold);
            color: white !important;
          }

          &:active {
            background-color: var(--color-gold);
            color: white !important;
          }

        }
      }

      &--banner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .imgbannernext {
          display: flex;
          align-items: center;
          width: 100%;

          &.centered {
            justify-content: center;
          }

          &.spaced {
            justify-content: space-between;
            gap: 12px;
          }
        }
      }

    }
  }
}

.bannerhotel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  &__container {
    display: flex;
    width: 100%;
    gap: 20px;
    color: white;
    align-items: center;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--left {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .paragraph{
        @include parragraph-normal(NexaSlab, 14px, 300, normal);

        @media (min-width: 768px) {
          font-size: 20px;
          line-height: normal;
        }
      }
    }

    &--center {}

    &--right {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }

    &--banks {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 16px;

      img {
        max-height: 60px;
        object-fit: contain !important;

        &.centered {
          justify-content: center;
        }

        &.spaced {
          justify-content: space-between;
          gap: 12px;
        }
      }
    }

    &--button {
      .buttonredirect {
        cursor: pointer;
        @include parragraph-normal(NexaSlab, 14px, 400, normal);
        width: 168px;
        height: 45px;
        background-color: #D69C4F;
        border-radius: 5px;
        color: white;
        border: none;
        display: block;

        @media (min-width: 768px) {
          width: 210px;
          font-size: 16px;
        }

        &:hover,
        &:active {
          background: #bb8b4c;
        }
      }
    }

  }

}

.bannertiraslider {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide .swiper-slide-next{
    width: 100%;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    background: #FFFFFF;
    opacity: 1;
    width: 15px;
    height: 15px;
  }

  .swiper-pagination-bullet-active {
    background: #D69C4F;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 16%;
    left: 0;
    width: 100%;

    @media (min-width: 768px) {
      bottom: 20%;
    }
  }
}
