@import '../../atoms/text/styles.scss';

.sectioncardstypes {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #009DA0;
  padding-top: 30px;
  padding-bottom: 20px;


  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;
    color: white;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 290px;
          height: 100%;
          padding: 10px 14px;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 20px;

        .final {
          width: 172px;
          height: 100%;
          gap: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.promotionsfood {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          width: 256px;
          height: 100%;
          padding: 10px 10px;
          // border: 1px solid red;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 20px;

        .final {
          width: 172px;
          height: 100%;
          gap: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }

    @media (max-width: 767px) {

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #D69C4F;
    }

    .swiper-button-prev {
      left: 1%;
    }

    .swiper-button-next {
      right: 1%;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 24px;
      font-weight: 700;
      border-radius: 50%;
      padding: 10px 15px;
      background-color: white;
      box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      display: none;
      pointer-events: none;
    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.promotionsexplore {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__description {

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 220px; // Medida de la card
      margin-right: 16px; // Espacio entre cards

      @media (min-width: 768px) {
        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 20px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(30%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      user-select: none;
      cursor: pointer;
      border-radius: 9px;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      width: 220px; // Medida de la card
      height: 210px; // Medida de la card
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      color: black;

      &--slider {
        height: 120px;
        position: relative;
        overflow: hidden;

      }

      &--cardexploreimg {
        height: 120px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: scale 400ms;
        }

        &:hover img {
          scale: 110%
        }
      }

      &--exploraistag {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 50px;
        height: 50px;
        border-radius: 50%;

        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
      }

      &--container {
        color: black;
        background-color: white;
        padding: 14px 16px;

      }

      &--ttexplore {
        @include parragraph-normal(NexaSlab, 18px, 600, normal);
        line-height: normal;
        text-transform: uppercase;
      }

      &--sbtexplore {
        display: flex;
        align-items: flex-start;
        // height: 38px;
        // overflow: hidden;
        gap: 6px;

        .paragraph {
          @include parragraph-normal(Quicksand, 14px, 400, normal);
          line-height: normal;
        }
      }

    }

    &--final {
      width: 220px;
      height: 100%;
      gap: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;

      .cardrestaurant {
        padding: 20px;
        height: 210px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 9px;
        gap: 10px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

        .paragraph {
          @include parragraph-normal(NexaSlab, 14px, normal, normal);
          line-height: normal;
          text-align: center;
        }

        .btn {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #D69C4F;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.slider-restaurante {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  &__container {
    width: 100%;
    position: relative;

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
    }


    &--description {
      // padding-top: 2rem;

      &--swiper {
        // position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          width: 300px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

      }
    }

    &--final {
      width: 200px;
      height: 100%;
      gap: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;

      .cardrestaurant {
        padding: 20px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 9px;
        gap: 10px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

        p {
          margin: 0px;
          text-align: center;
        }

        .btn {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #D69C4F;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.filtrorestaurante {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../../assets/images/bkg-texture.webp');
  background-repeat: repeat;
  background-position: center;
  padding-block: 25px;

  @media (min-width: 768px) {
    padding-block: 50px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      text-align: center;
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }

    &--description {

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        @media (min-width: 768px) {
          .swiper-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .swiper-slide {
          width: 145px;
          height: 100%;
          padding: 10px 16px;

          @media (min-width: 768px) {
            width: 180px;
            margin-inline: 20px;
          }
        }

        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 1%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }

      }

      &--filtros {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
        @include scale(110%);

        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }

        @media (min-width: 768px) {
          width: 155px;
          height: 155px;

          img {
            width: 100px;
            height: 100px;
          }
        }

        .contenido {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.promotionshotels {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 270px;
          height: 100%;
          padding: 10px 14px;
        }

        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 1%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 20px;

        .final {
          width: 172px;
          height: 100%;
          gap: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }


  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.promologros {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 290px;
          height: 100%;
          padding: 10px 14px;
        }

        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 1%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 20px;

        .final {
          width: 172px;
          height: 100%;
          gap: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }


  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.promoreconocimiento {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      padding-top: 2rem;

      &--swiper {
        position: relative;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 260px;
          height: 100%;
          padding: 10px 14px;
        }

        @media (max-width: 767px) {

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 1%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }

      &--web {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 20px;

        .final {
          width: 172px;
          height: 100%;
          gap: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
        }
      }
    }


  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.promotionsrelax {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 20px;


  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--description {
      display: flex;

      .swiper {
        height: 100%;
      }
  
      .swiper-slide {
        // width: 369px; // Medida de la card
        width: max-content;
        height: max-content;
        margin-right: 37px; // Espacio entre cards
  
        &:last-child {
          margin-right: 0px;
        }
      }
  
      &--swiper {
        width: 100%;
        padding: 16px 0px 16px 16px;
        position: relative;
  
        @media (max-width: 767px) {
  
          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }
  
        @media (min-width: 768px) {
          padding: 14px 24px;
  
          .swiper-slide-thumb-active {
            opacity: 1;
          }
  
          .swiper-button-prev,
          .swiper-button-next {
            color: #D69C4F;
            position: absolute;
            transform: translateY(20%);
            z-index: 1000;
          }
  
          .swiper-button-prev {
            left: 1%;
          }
  
          .swiper-button-next {
            right: 2%;
          }
  
          .swiper-button-prev:after,
          .swiper-button-next:after {
            font-size: 24px;
            font-weight: 700;
            border-radius: 50%;
            padding: 10px 15px;
            background-color: white;
            box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
          }
  
          .swiper-button-prev.swiper-button-disabled,
          .swiper-button-next.swiper-button-disabled {
            opacity: 0.35;
            cursor: auto;
            display: none;
            pointer-events: none;
          }
        }
      }
    }
  }

  .promobgimg {
    width: 290px;
    border-radius: 9px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__inner {
      width: 100%;
      height: 100%;
      height: 287px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &--bgcolor {
        background-color: #009DA0;
        width: 153px;
        height: 100%;
        text-align: center;

        .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 10px;

          &--right {
            display: flex;
            margin-left: 0.25rem;
            align-items: center;
            flex-direction: column;
          }

          .porcent {
            font-family: NexaSlabBold;
            font-size: 45px;
          }

          .pornum {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.textt {
  font-family: Sofia;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 100;
  line-height: 100%;
  margin: 0;
  text-transform: uppercase;

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}

.triimg {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 50%;
  }
}

.slider-infinito {
  display: flex;
  overflow: hidden;
  height: 240px;
  width: 100%;
}

.slider-infinito__item {
  flex-shrink: 0;
  width: 320px;
  height: auto;
  animation: slide-infinito 10s linear infinite;
}

.slider-infinito__item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajuste de la imagen dentro del contenedor */
}

@keyframes slide-infinito {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}