.card__restaurant {
  border-radius: 9px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.20);
  border: 1px solid #E5E5E5;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;

  &--slider {
    width: 100%;
    height: 216px;
    border-radius: inherit 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cardheaderr {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      font-family: NexaSlab;
      font-weight: bold;
      font-size: .875rem;
      width: 160px;
      margin: 0;

      @media (max-width: 375px) {
        width: 137px;
      }
    }

    .review {
      font-family: Sofia;
      font-size: .75rem;
      font-weight: 100;
      margin: 0;
    }
  }

  &--container {
    padding-top: 17px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .cardprices {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .ttcardprices {
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: 12px;
        font-family: Sofia;
        
        span {
          font-family: NexaSlab;
          text-decoration: line-through;
        }

        &__card {
          width: 278px;
        }

      }

      .sbcardprices{
        font-family: NexaSlab;
        font-weight: 700;
        font-size: 32px;
        text-transform: uppercase;
        margin: 0;
      }

      .sbbcardprices {
        display: flex;
        justify-content: column;
        align-items: center;
        font-family: Sofia;
        font-weight: 100;
        font-size: 12px;
        margin: 0;
      }
    }
  }
}