@import '../../atoms/text/styles.scss';

.discounts {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__container {
    width: 100%;
    position: relative;

    @media (min-width: 1200px){
      max-width: 1220px;
    }
    .subtitle {
      @include title-section(NexaSlab, 700, normal);
    }
  }

  &__description {
    padding-top: 20px;
    padding-left: 12px;

    .swiper {
      width: 100%;
      height: 100%;
    }
  
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 240px;
      height: 280px;
      padding: 10px 14px;
    }

    @media (max-width: 767px) {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    @media ( min-width: 768px) {
      padding-top: 40px;

      .discounts__swiper .swiper-slide-thumb-active{
        opacity: 1;
      }

      .discounts__swiper{
        position: relative;
      }

      .swiper-button-prev,
      .swiper-button-next {
        color: #D69C4F;
      }

      .swiper-button-prev {
        left: 1%;
      }

      .swiper-button-next {
        right: 1%;
      }

      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 24px;
        font-weight: 700;
        border-radius: 50%;
        padding: 10px 15px;
        background-color: white;
        box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
      }

      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        display: none;
        pointer-events: none;
      }
    }
  }
}
