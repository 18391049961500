@import '../../components/atoms/text/styles.scss';

.social__responsability {
  padding-inline: 16px;

  h1 {
    @include parragraph-normal(NexaSlab, 36px, 700, normal);
  }

  h2 {
    @include parragraph-normal(Quicksand, 16px, 700, normal);
    margin-block: 16px;
  }

  p {
    @include parragraph-normal(Quicksand, 16px, 400, normal);
    line-height: normal;
  }
}