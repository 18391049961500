@import "../variables.scss";

$fontFamily: (
  'montserrat': $family-montserrat,
  'nexaBlack': $family-nexa-black,
  'nexaBold': $family-nexa-bold,
  'nexaLightItalic': $family-nexa-lightItalic,
  'nexaLight': $family-nexa-light,
  'nexaRegular': $family-nexa-regular,
  'sofia': $family-sofia,
  'Sofia': $family-Sofia,
  'sofiaBold': $family-sofiaBold,
);

$fontTransform: (
  'capitalize': capitalize,
  'uppercase': uppercase,
  'lowercase': lowercase,
  'none': none,
);

@each $name, $value in $fontTransform {
  /* FONT TRANSFORM (ft-) */
  .ft-#{ $name } {
    text-transform: #{$value} !important;
  }
}

@each $name, $value in $fontFamily {
  /* FONT FAMILY (ff-) */
  .ff-#{ $name } {
    font-family: #{$value} !important;
  }
}

@each $name, $value in $pixel {
  /* FONT SIZE PIXEL (fs-#px) */
  .fs-#{ $name } {
    font-size: #{$value} !important;
  }
}

$fontWeight: (
    "100": 100,
    "200": 200,
    "300": 300,
    "400": 400,
    "500": 500,
    "600": 600,
    "700": 700,
    "800": 800,
    "900": 900,

    "lighter": lighter,
    "bold": bold,
    "normal": normal,
    "bolver": bolder,

    /* Global values */
    "inherit": inherit,
    "initial": initial,
    "revert": revert,
    "revert-layer": revert-layer,
    "unset": unset,

    /* ::: TYPOGRAPHY ::: */
    "$size-80": $size-80,
    "$size-70": $size-70,
    "$size-60": $size-60,
    "$size-56": $size-56,
    "$size-48": $size-48,
    "$size-40": $size-40,
    "$size-36": $size-36,
    "$size-32": $size-32,
    "$size-30": $size-30,
    "$size-28": $size-28,
    "$size-24": $size-24,
    "$size-20": $size-20,
    "$size-18": $size-18,
    "$size-16": $size-16,
    "$size-14": $size-14,
    "$size-12": $size-12,
    "$size-10": $size-10,

    /* :::TEXT ALIGN::: */
    "right": right,
    "left": left,
    "end": end,
    "start": start,
    "justify": justify,
    "center": center,

    /*::: TEXT SHADOW :::*/
    "1": 1px 1px rgb(103, 101, 101),

    /* :::TEXT TRANSFORM::: */
    "none": none,
    "capitalize": capitalize,
    "uppercase": uppercase,
    "lowercase": lowercase
);

@each $name, $value in $fontWeight {
  /* FONT WEIGHT (fw-) */
  // .fw-#{ $name } {
  //   font-weight: #{$value} !important;
  // }
  // .ff-#{ $name } {
  //   font-family: #{$value} !important;
  // }

  // .txta-#{ $name } {
  //   text-align: #{$value} !important;
  // }
  // .txtd-#{ $name } {
  //   text-decoration: #{$value} !important;
  // }
  // .txt-shadow-#{ $name } {
  //   text-shadow: #{$value} !important;
  // }
}

/* TYPOGRAPHY */
.fs-70 {
  font-size: $size-70 !important;
  margin: 0 !important;
}

.fs-18 {
  font-size: $size-18 !important;
  margin: 0 !important;
}

.fs-30 {
  font-size: $size-30 !important;
  margin: 0 !important;
}


// Consultar medidas
@media screen and (max-width: $breakpoint-tablet) {
  .fs-70 {
    font-size: $size-60 !important;
  }
  .fs-30 {
    font-size: $size-20 !important;
    margin: 0 !important;
  }

}


@media screen and (max-width: $breakpoint-mobile) {
  .fs-70 {
    font-size: $size-48 !important;
  }
  .fs-30 {
    font-size: $size-24 !important;
    margin: 0 !important;
  }

}
