@import '../../atoms/text/styles.scss';

.scrolltop {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 25px;


  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    @include touchmobile;

    @media (min-width: 1200px){
      max-width: 1220px;
    }

    &--line{
      width: 100%;
      height: 2px;
      background-color: #F2F2F2;
      margin: 4px 0;
    }

    &--arrow{
      padding: 10px;

      .arrowcontent{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F2F2F2;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }
}