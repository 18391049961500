.parrafo__text{
  text-align: center;
  font-size: 16px;
    font-weight: 700;
    font-family: Sofia;
  // padding-bottom: 32px;

  .subtitulo{
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    font-family: Sofia;
    text-align: left;
    margin: 0;
  }

  .titulo{
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    font-family: Sofia;
  }

  .justify{
    padding-top: 20px;
    padding-bottom: 32px;
    text-align: justify;
    font-weight: 100;
  }

  .cursor{
    cursor: pointer;
    text-decoration: none;
    color: #D69C4F;

    &:hover{
      color: #D69C4F;
      text-decoration: underline;
    }
  }
}