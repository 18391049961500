/*
  CONTENT STRUCTURE:
    align content:      ac- 
    align items:        ai-
    align self:         as-
    justify content:    jc-
    justify items:      ji-    
*/

$alignContent: (       
    center: center, 
    start: start, 
    end: end,
    flex-start: flex-start, 
    flex-end: flex-end, 
    normal: normal,
    baseline: baseline,
    first-baseline: first baseline,
    last-baseline: last baseline,
    space-between: space-between,
    space-around: space-around,
    space-evenly: space-evenly, 
    stretch: stretch, 
    safe-center: safe center,
    unsafe-center: unsafe center,
    inherit: inherit,
    initial: initial,
    revert: revert,
    revert-layer: revert-layer,
    unset: unset,
);

@each $name, $value in $alignContent {
    /* ALIGN CONTENT (ac-) */
    .ac-#{ $name } {
        align-content: #{$value} !important;
    }
}

$alignItem: (    
    normal: normal,
    stretch: stretch,
    center: center, 
    start: start,
    end: end,
    flex-start: flex-start,
    flex-end: flex-end, 
    self-start: self-start, 
    self-end: self-end, 
    baseline: baseline,
    first-baseline: first baseline,
    last-baseline: last baseline,
    safe-center: safe center,
    unsafe-center: unsafe center,
    inherit: inherit,
    initial: initial,
    revert: revert,
    revert-layer: revert-layer,
    unset: unset,
);

@each $name, $value in $alignItem {
    /* ALIGN ITEMS (ai-) */
    .ai-#{ $name } {
        align-items: #{$value} !important;
    }
}

$alignSelf: (    
    auto: auto,
    normal: normal,   
    center: center, 
    start: start, 
    end: end, 
    self-start: self-start, 
    self-end: self-end, 
    flex-start: flex-start,
    flex-end: flex-end, 
    baseline: baseline,
    first-baseline: first baseline,
    last-baseline: last baseline,
    stretch: stretch, 
    safe-center: safe center,
    unsafe-center: unsafe center,
    inherit: inherit,
    initial: initial,
    revert: revert,
    revert-layer: revert-layer,
    unset: unset,
);

@each $name, $value in $alignSelf {
    /* ALIGN SELF (as-) */
    .as-#{ $name } {
        align-self: #{$value} !important;
    }
}

$justifyContent: (   
    center: center, 
    start: start, 
    end: end,
    flex-start: flex-start, 
    flex-end: flex-end, 
    left: left, 
    right: right, 
    normal: normal,
    space-between: space-between, 
    space-around: space-around,
    space-evenly: space-evenly, 
    stretch: stretch, 
    safe-center: safe center,
    unsafe-center: unsafe center,
    inherit: inherit,
    initial: initial,
    revert: revert,
    revert-layer: revert-layer,
    unset: unset
);

@each $name, $value in $justifyContent {
    /* JUSTIFY CONTENT (jc-) */
    .jc-#{ $name } {
        justify-content: #{$value} !important;
    }
}

$justifyItem: (        
    normal: normal,
    stretch: stretch,
    center: center,
    start: start,
    end: end,
    flex-start: flex-start,
    flex-end: flex-end,
    self-start: self-start,
    self-end: self-end,
    left: left, 
    right: right,
    baseline: baseline,
    first-baseline: first baseline,
    last-baseline: last baseline,
    safe-center: safe center,
    unsafe-center: unsafe center,
    legacy-right: legacy right,
    legacy-left: legacy left,
    legacy-center: legacy center,
    inherit: inherit,
    initial: initial,
    revert: revert,
    revert-layer: revert-layer,
    unset: unset
);

@each $name, $value in $justifyItem {
    /* JUSTIFY ITEMS (ji-) */
    .ji-#{ $name } {
        justify-items: #{$value} !important;
    }
}

.ac-ai-jc-center{
    justify-content:center;
    align-items:center;
    align-content:center
}

