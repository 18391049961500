/*
  CONTENT STRUCTURE:
    width en propiedades:        w- 
    width en porcentaje:         w-#por
    width en pixel:              w-#px
    width en viewport width:     w-#vw
    width en viewport height:    w-#vh
*/

@import "../variables.scss";

$property: (    
    auto: auto,
    none: none,
    fit-content: fit-content,
    min-content: min-content,
    max-content: max-content,
    fill: -webkit-fill-available   
);

@each $name, $size in $property{
    /* WIDTH GENERAL (w-) */
    .w-#{ $name }{
        width: #{$size} !important;
    }
}

@each $name, $value in $percentage{
    /* WIDTH EN PORCENTAJE (w-#por) */
    .w-#{ $name }{
        width: #{$value} !important;
    }   
}

@each $name, $value in $viewportHeight{   
       /* WIDTH VIEWPORT HEIGHT (w-#vh) */
    .w-#{ $name }{
        width: #{$value} !important;
    }
}

@each $name, $value in $viewportWidth{  
    /* WIDTH VIEWPORT WIDTH  (w-#vw) */
    .w-#{ $name }{
        width: #{$value} !important;
    }
}

@each $name, $value in $pixel{
    /* WIDTH EN PIXEL (w-#px) */
    .w-#{ $name }{
        width: #{$value} !important;
    }
}

/* OTHERS */
.max-w-100por {
    max-width: 100% !important;
}
