@import '../../atoms/text/styles.scss';

.better {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 40px;

  @media (min-width: 768px) {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  &__container {
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    .subtitle {
      @include title-section(NexaSlab, 700, normal);
      margin-bottom: 20px;

      @media(min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  &__description {

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      width: 212px; // Medida de la card
      margin-right: 26px; // Espacio entre cards

      @media (min-width: 768px) {
        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &--swiper {
      width: 100%;
      padding: 16px 0px 16px 16px;
      position: relative;

      @media (max-width: 767px) {

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }

      @media (min-width: 768px) {
        padding: 14px 24px;

        .swiper-slide-thumb-active {
          opacity: 1;
        }

        .swiper-button-prev,
        .swiper-button-next {
          color: #D69C4F;
          position: absolute;
          transform: translateY(30%);
          z-index: 1000;
        }

        .swiper-button-prev {
          left: 1%;
        }

        .swiper-button-next {
          right: 2%;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 24px;
          font-weight: 700;
          border-radius: 50%;
          padding: 10px 15px;
          background-color: white;
          box-shadow: -1px 4px 9px 0px rgba(0, 0, 0, 0.2);
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0.35;
          cursor: auto;
          display: none;
          pointer-events: none;
        }
      }
    }

    &--card {
      user-select: none;
      cursor: pointer;
      border-radius: 9px;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
      width: 212px; // Medida de la card
      height: 198px; // Medida de la card
      // @include scale;
      @include touchmobile;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      &--container {
        .paragraph {
          @include parragraph-normal(NexaSlab, 18px, 700, normal);
          line-height: normal;
          color: white;
        }
      }
    }

  }
}