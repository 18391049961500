.carousels {
  // padding: 20px;
  
  &__container {
    display: flex;
    cursor: grab;
    overflow: hidden;

    &--div {
      display: flex;
      background: white;

      &--img {
        min-height: 200px;
        min-width: 220px;
        width: 33%;
        padding-right: 14px;

        img {
          width: 100%;
          height: 90%;
          // border-radius: 12px;
          pointer-events: none;
        }
      }
    }

  }
}
