/*
  CONTENT STRUCTURE:   
    border radius: br-
    border radius top: br-t-
    border radius bottom: br-b-
*/

$numbers: (
  '0': 0rem,
  '05': 0.5rem,
  '07': 0.7rem,
  '1': 1rem,
  '2': 2rem,
  '3': 3rem,
  '4': 4rem,
  '5': 5rem,
  '6': 6rem,
  '7': 7rem,
  '8': 8rem,
  '9': 9rem,
  '5px': 5px,
  '10px': 10px,
  '20px': 20px,
  '25px': 25px,
  '30px': 30px,
  '35px': 35px,
  '40px': 40px,
  '45px': 45px,
  '50px': 50px,
  '100': 100%,
);

@each $name, $number in $numbers{
 
  .br-#{ $name } {
    border-radius: #{$number} !important;
  }

  .br-r-#{ $name } {
    border-radius: 0 #{$number} #{$number} 0 !important;
  }

  .br-l-#{ $name } {
    border-radius: #{$number} 0 0 #{$number} !important;
  }

  .br-t-#{ $name } {
    border-radius: #{$number} #{$number} 0 0 !important;
  }

  .br-b-#{ $name } {
    border-radius: 0 0 #{$number} #{$number} !important;
  }

}
