@import '../../atoms/text/styles.scss';

.content-des {
  @media (max-width: 1200px) {
    padding-inline: 16px;
  }

  .description{
    @include parragraph-normal(Quicksand, 14px, 400, normal);
    line-height: normal;
  }

  .contenicon{
    @include parragraph-normal(Quicksand, 14px, 400, normal);
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;

    @media(min-width: 768px) {
      margin-top: 30px;
    }
  }
}

.content-mob {
  padding-inline: 16px;

  .description{
    @include parragraph-normal(Quicksand, 14px, 400, normal);
    line-height: normal;
  }

  .toogle {
    display: flex;
    justify-content: flex-end;

    &__more {
      margin-block: 16px;
      @include parragraph-normal(Quicksand, 14px, 400, normal);
      color: #D69C4F;
      cursor: pointer;
      user-select: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .contenicon{
    @include parragraph-normal(Quicksand, 14px, 400, normal);
    line-height: normal;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}