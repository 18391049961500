@import '../../../atoms/text/styles.scss';

.hotelmobslider {
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 1.125rem;
    background: #fff;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    background: #FFFFFF;
    opacity: 1;
    width: 15px;
    height: 15px;
  }

  .swiper-pagination-bullet-active {
    background: #D69C4F;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 12%;
    left: 0;
    width: 100%;
  }
}

.slidediv {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;

  &__tag {
    position: absolute;
    top: 25px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: auto;
    min-width: 140px;
    padding-right: 6px;
    height: 42px;
    clip-path: polygon(100% 0%, 7.15% 0%, 0% 100%, 100% 100%);
    padding-left: 26px;
    color: white;

    .tipo {
      @include parragraph-normal(NexaSlab, 12px, 400, normal);
    }

    .city {
      @include parragraph-normal(NexaSlab, 18px, 600, normal);
    }

    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    // transition: scale 400ms;
    // &:hover img {
    //   scale: 110%
    // }
  }

  //   .premium {
  //   background-color: #002855;
  // }

  // .select {
  //   background-color: #008EAA;
  // }

  // .standar {
  //   background-color: #FF4200;
  // }

}
