.associatedhotels {
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    padding-block: 25px;
    padding-inline: 16px;

    @media (min-width: 768px) {
      padding-block: 60px;
      padding-inline: 120px;
    }

    @media (min-width: 1200px) {
      max-width: 1220px;
    }

    &--section {
      font-family: "Quicksand";

      h1 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;

        @media (min-width: 768px) {
          font-size: 30px;
          margin-bottom: 32px;
        }
      }

      ul {
        width: 100%;
        padding: 0;
        list-style-type: disc;
        list-style-position: inside;
        margin: 0;

        li {
          box-sizing: border-box;
          list-style-position: outside;
          margin-left: 20px;
          margin-bottom: 6px;

          &::marker {
            font-size: 16px;
          }
        }

        span {
          font-weight: 700;
          text-transform: uppercase;
        }
      }

    }

    &--section2 {
      margin-top: 25px;
      font-family: "Quicksand";

      h2 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;

        @media (min-width: 768px) {
          font-size: 28px;
          margin-bottom: 28px;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
      }

      span {
        font-weight: 700;
      }

      ul {
        width: 100%;
        padding: 0;
        list-style-type: disc;
        list-style-position: inside;
        margin: 0;

        li {
          box-sizing: border-box;
          list-style-position: outside;
          margin-left: 20px;
          font-family: "Quicksand";
          margin-bottom: 6px;

          &::marker {
            font-size: 16px;
          }
        }
      }

    }
  }
}